<div class="aar-user-list">
  <h1>Users</h1>
  <ul>
    <li *ngFor="let user of users">
      <div>Name: <span>{{user.fullName}}</span></div>
      <div>Username: <span>{{user.userName}}</span></div>
      <div>Email: <span>{{user.email}}</span></div>
      <div>Role: <span>{{user.roleName}}</span></div>
    </li>
  </ul>
</div>
