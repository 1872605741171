import { Subject } from "rxjs";

export class AAREventSourceGeneric<TEvent> {
    
    private source = new Subject<TEvent>();
	private observable = this.source.asObservable();

	public publish(newValue: TEvent): void{
		this.source.next(newValue);
	}

	public subscribe(next?: (value:TEvent)=> void, error?:(error:any)=> void, complete?:() => void){
		return this.observable.subscribe(next, error, complete);
	}
}
