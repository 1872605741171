import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonContact } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { AddEditContactComponent } from '../add-edit-dialog/add-edit-contact.component';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { SharedService } from '@app/shared/services/shared.service';

@Component({
  selector: 'aar-internal-contacts',
  templateUrl: './internal-contacts.component.html',
  styleUrls: ['./internal-contacts.component.scss']
})
export class InternalContactsComponent implements OnInit, OnChanges {

  internalContacts: CommonContact[] = [];
  columnsSettings = [
    { name: 'firstName', header: 'First Name'},
    { name: 'lastName', header: 'Last Name'},
    { name: 'title', header: 'Title'},
    { name: 'emailAddress', header: 'Email Address'},
    { name: 'mainPhoneNumber', header: 'Main Phone Number'},
    { name: 'alternatePhoneNumber', header: 'Alternate Phone Number'},
    { name: 'comments', header: ''},
    { name: 'action', header: ''}
  ];

  displayedColumns = this.columnsSettings.map(item => item.name);

  pageSizeOptions = [5, 10, 20];
  pageSize = 10;
  title = 'Contacts';
  initialSort = 'lastName';

  dataSource: MatTableDataSource<CommonContact>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  contact: CommonContact = new CommonContact();
  contactTypeId: number;
  companyId: number;
  searchKeyword: string;

  caseInsensitiveSorting = (data: any, sortHeaderId: string): string => {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }

  public setSort(id: string, start?: 'asc' | 'desc') {
    if (!id) { return; }

    start = start || 'asc';
    const matSort = this.sort;
    const disableClear = false;

    // Reset sort state so that "start" is the first sort direction
    matSort.sort({ id: null, start, disableClear });
    matSort.sort({ id, start, disableClear });

    // Workaround due to Angular bug with the sort arrow not updating: https://github.com/angular/components/issues/10242
    const sortHeader: MatSortHeader = matSort.sortables.get(this.sort.active) as MatSortHeader;
    if (sortHeader != null) { sortHeader._setAnimationTransitionState({fromState: sortHeader._arrowDirection, toState: 'active'}); }
  }

  constructor(
    private loadingService: LoadingService,
    private apiService: ApiService,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadingService.startLoading();
    this.apiService.GetContactByContactTypeId(3)
      .subscribe(internals => {
        this.internalContacts = internals;
        this.loadingService.stopLoading();
        this.ngOnChanges();
      }, () => this.loadingService.loadingError());

      this.sharedService.getSearchKeyWord().subscribe((message) => {
        this.searchKeyword = message;
        this.doFilter(message);
      });
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.internalContacts);
    if (!this.sort) { this.sort = new MatSort(); }
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.caseInsensitiveSorting;

    this.dataSource.paginator = this.paginator;
    this.setSort(this.initialSort, 'asc');
    this.doFilter(this.searchKeyword);

    // TO-DO: if there are no contacts at all, how do we determine the contact type & company IDs!
    const firstRecord = this.internalContacts[0];
    if (firstRecord !== undefined) {
      this.contactTypeId = firstRecord.contactTypeID;
      this.companyId = firstRecord.companyID;
    }
  }

  deleteContact(contactID: number) {
    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to delete this contact?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {
      if (shouldConfirm) {
        this.loadingService.startLoading();
        this.apiService.DeleteContactByContactId(contactID)
          .subscribe(result => {
            if (result) {
              const index = this.internalContacts.findIndex(x => x.contactID === contactID);
              this.internalContacts.splice(index, 1);
              this.ngOnChanges();
            }
            this.loadingService.stopLoading();
          }, err => this.loadingService.loadingError());
      }
    });
  }

  showAddContactDetailsForm() {
    const newContact: CommonContact = {
      contactID: 0,
      contactTypeID: this.contactTypeId,
      alternatePhoneNumber: null,
      comments: null,
      companyID: this.companyId,
      emailAddress: null,
      endCustomerID: null,
      firstName: null,
      lastName: null,
      mainPhoneNumber: null,
      title: null,
      isPrimaryContact: 0,
      supplierID: null
    };
    this.contact = newContact;
    this.showMaintainContactDetailsForm();
  }

  showUpdateContactDetailsForm(contactID: number) {
    this.contact = this.internalContacts.filter(x => x.contactID === contactID)[0];
    this.showMaintainContactDetailsForm();
  }

  showMaintainContactDetailsForm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      contact: this.contact
    };
    this.openEditDialog(dialogConfig);
  }

  public openEditDialog(dialogConfig: MatDialogConfig) {
    const dialogRef = this.dialog.open(AddEditContactComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.contact = data as CommonContact;

          if (this.contact.contactID > 0) {
            this.updateContact();
          } else {
            this.createContact();
          }
        }
      }
    );
  }

  createContact() {
    this.loadingService.startLoading();
    this.apiService.CreateContact(this.apiService.removeTypename(this.contact))
        .subscribe(result => {
          if (result !== null) {
            console.log('result', result);
            this.internalContacts.push(result);
            this.ngOnChanges();
          }
          this.loadingService.stopLoading();
      }, err => this.loadingService.loadingError());
  }

  updateContact() {
    this.loadingService.startLoading();
    this.apiService.UpdateContact(this.apiService.removeTypename(this.contact))
        .subscribe(result => {
          if (result !== null) {
            this.ngOnChanges();
          }
          this.loadingService.stopLoading();
      }, err => this.loadingService.loadingError());
  }

  hasComments(comments: string): boolean {
    return comments !== undefined && comments !== null && comments !== '';
  }

  doFilter = (value: string) => {
    const filterValue = value === undefined ? value : value.trim().toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }
}
