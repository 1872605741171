<div class="aar-login">
  <div class="gradient">
    <div class="aar-login-content-container">
      <h1>Air<span class="font-light">volution<sup>&trade;</sup></span></h1>
      <!-- <div *ngIf="users">
        <br><br>

        <ul>
          <li *ngFor="let user of users">
            {{user.id}}: {{user.username}} {{user.email}}
          </li>
        </ul>
      </div> -->
      <div class="content">
        <h2>Tailored Component Repair Management and Aircraft Warranty Support Services.</h2>
        <div class="features">
          <div class="feature">
            <button mat-fab disableRipple><mat-icon class="icon-dashboard">sync</mat-icon></button>
            <h4>CENTRALIZE RCM</h4>
          </div>
          <div class="feature">
            <button mat-fab disableRipple><mat-icon class="icon-rcm">access_time</mat-icon></button>
            <h4>MINIMIZE TAT</h4>
          </div>
          <div class="feature">
            <button mat-fab disableRipple><mat-icon class="icon-cost-control">check_circle_outline</mat-icon></button>
            <h4>CONTROL COSTS</h4>
          </div>
        </div>
      </div>
    </div>


    <div class="arr-login-form-container">
      <div class="content">
        <header>
          <nav>
            <ul>
              <!-- <li><a>Pricing</a></li> -->
              <li><a target="_blank" href="https://www.airinmar.com/">About</a></li>
              <li><a href="{{contactUsLink}}">Contact Us</a></li>
            </ul>
          </nav>
        </header>

        <div class="aar-login-form" *ngIf="false">
          <button mat-flat-button color="primary" class="btnMicrosoftLogo" (click)="loginMicrosoft()">Login with Microsoft</button>
          <div class="separator"><b>or</b></div>
        </div>

        <div class="aar-login-form">
          <div>
            <!-- <h3>Enter your Airvolution Account</h3> -->
            <form [formGroup]="loginForm" (ngSubmit)="authenticate()">
              <mat-form-field>
                <input matInput placeholder="Username" [formControl]="loginForm.controls['username']">
                <mat-error *ngIf="getError('username')">Required</mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Password" [formControl]="loginForm.controls['password']" type="password">
                <mat-error *ngIf="getError('password')">Required</mat-error>
              </mat-form-field>
              <button mat-flat-button color="primary" type="submit">Login</button>
            </form>
            <!-- <a>Sign Up</a> -->
          </div>

          <button mat-flat-button color="primary" (click)="recoverPassword()" *ngIf="false">Forgot password</button>
          <p *ngIf="passRecovered" class="arr-login-message">
            Temporary password was sent to your email
          </p>
          <mat-error *ngIf="getLoginErrorMessage()">{{getLoginErrorMessage()}}</mat-error>
        </div>

        <footer>
          <ul>
            <li>&copy; 2023 AAR</li>
            <li><a target="_blank" href="https://www.airinmar.com/">About</a></li>
            <li><a href="{{contactUsLink}}">Contact Us</a></li><br/>
            <li><a target="_blank" href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_tcs_06232023.pdf">Terms & Conditions</a></li>
            <li><a target="_blank" href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_privacynotice_09272023.pdf">Privacy Notice</a></li>
          </ul>
        </footer>
      </div>
    </div>
  </div>
</div>
