export enum ComponentType
{
    RCMReviewWorkscopeComparison,
    RCMReviewWorkscopeComplete,
    RCMReviewWorkscopeCost,
    RCMReviewWorkscopeStatus,
    RCMHeader,
    RCM,
    RCMShippingBlock,
    RCMEditShipping,
    RCMAddShipping,
    RCMGrid,
    RCMGridHistorical,
    RcmActivity
}
