'use strict';
import { UntypedFormGroup } from '@angular/forms';
import { ERROR_MESSAGES } from '@app/core/constants/globalConstants';
import { get } from 'lodash';
import * as moment from 'moment';

// export all shared funtions
export const getLocalStorage = (key) => {
  try {
    const storage = localStorage.getItem(key);
    if (storage && storage !== '') {
      return JSON.parse(storage);
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const saveLocalStorage = (key, value) => {
  try {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }

  } catch (e) {
    return null;
  }
};

export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    return null;
  }
};

export const isLoggedIn = (domain) => {
  const aaruser = getLocalStorage('aaruser:storage');
  const token = get(aaruser, 'token', null);

  if (domain === 'admin') {
    const isAdmin = aaruser.user.role === 'ADMIN';

    return {
      loggedIn: token !== null && isAdmin
    };
  }

  return {
    loggedIn: token !== null
  };
};

export const getFormFielErrorMessage = (form: UntypedFormGroup, control: string) => {
  if (!form.controls[control].valid) {
  return form.controls[control].hasError('required')
    ? ERROR_MESSAGES.required
    : form.controls[control].hasError('email')
      ? ERROR_MESSAGES.email
      : form.controls[control].hasError('maxlength')
        ? 'max length exceeded: max=' + form.controls[control].getError('maxlength').requiredLength +
          '; actual=' + form.controls[control].getError('maxlength').actualLength
        : '';
  }
  return '';
};

export const formatDropdownValues = (values: any[], property = 'name'): any[] => {
  const formatted = [];
  values.map(val => {
    formatted.push({
      label: val[property],
      value: val.id
    });
  });

  return formatted;
};

export const isEmpty = (obj, nullStrings = false): boolean => {
  // return true if obj is null
  if (obj === null || obj === undefined) {
    return true;
  }

  // if its a number and is zero
  if (typeof obj == 'number') {
    return obj == 0;
  }

  // if its an empty string
  if (typeof obj == 'string') {
    if (nullStrings) return obj.trim() == 'null' || obj.trim() == 'undefined' || obj.trim() == '';
    return obj.trim() == '';
  }

  // if its an empty array
  if (Array.isArray(obj)) {
    return obj.length > 0;
  }

  return false;
};

export const setUtcFlag = (momentObject: moment.Moment) : moment.Moment => {
  return moment(momentObject).utc(true);
}
