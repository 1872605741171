<div class="card__content card__element">
    <ng-template [ngIf]="isEmpty" [ngIfElse]="content">
      <aar-no-activity [message]="noActivityMessage"></aar-no-activity>
    </ng-template>

    <ng-template #content>
      <h4>Email Queue</h4>

      <div class="aar-communication-height">
        <aar-email-list-block
          *ngFor="let message of messages"
          [description]="message.metaDataResults.body"
          [title]="message.metaDataResults.subject"
          [addedBy]="message.companyName + ' <' + message.metaDataResults.fromEmail + '>'"
          [addedOn]="message.metaDataResults.sentDateTime"
          [tags]="['UNASSIGNED']"></aar-email-list-block>
      </div>
    </ng-template>
</div>

<div class="form-footer border-0"> 
    <div class="form-footer__elem">
      <button mat-flat-button class="button" (click)="openEmailDialog()">Compose Email</button>
      <button mat-flat-button class="button" (click)="openAddCommunicationDialog()">Add Communication</button>
    </div>
</div>
