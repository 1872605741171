import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { UntypedFormGroup } from "@angular/forms";
import { PERSONA_NAMES, ROLE_NAMES } from '@app/core/constants/persona-constants';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { ActivityType } from '@app/core/constants/enums';

export class PersonaService {
  public currentPersona: string;
  public currentRole: string;
  componentNonEditableMap: Map<ComponentType, Map<string, string[]>>;
  componentHiddenMap: Map<ComponentType, Map<string, string[]>>;
  dataGridColumnsMap: Map<ComponentType, Map<string, any[]>>;
  componentActivityHiddenStatus: Map<ComponentType, Map<string, any[]>>;

  constructor(currentPersona: string) {
    this.currentPersona = currentPersona;
    this.initialiseMaps();
    this.storeNonEditableFields();
    this.storeHiddenFields();
    this.buildDataGridColumnMaps();
    this.buildDataGridHistoricalColumnMaps();
    this.storeHiddenActivityStatus();
  }

  initialiseMaps() {
    this.componentNonEditableMap = new Map<ComponentType, Map<string, string[]>>();
    this.componentHiddenMap = new Map<ComponentType, Map<string, string[]>>();
    this.dataGridColumnsMap = new Map<ComponentType, Map<string, string[]>>();
    this.componentActivityHiddenStatus = new Map<ComponentType, Map<string,string[]>>();
  };

  storeNonEditableFields() {
    //Use a Map as a dictionary, one string[] for each Persona

    //RCMHeader
    var rcmHeaderNonEditableFieldsMap = new Map<string, string[]>();

    rcmHeaderNonEditableFieldsMap.set("BUYER", [
      'customerROCreatedDt',
      'customerROReceivedDt',
      'customerRMANumber',
      'customerPOValue',
      'customerPOValueCurrency',
      'returnToAddressCode',
      'customerReference',
      'customerResponsibleValue',
      'customerResponsibleValueCurrency',
      'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      'outboundSerialNumberChangeReasonId',
      'quantity',
      'inboundUnitCategory',
      'partNumber',
      'serialNumber',
      'description',
      /* Commented under #40361
      'poNumber',
      'customerInternalRefNumberDate',
      */
      'repairLineStatus',
      'quoteStatus',
      'logisticStatus',
      'statusDescription',
      'totalSupplierCost',
      'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      //'supplierRMANumber',
      'supplierPromisedShipDt',
      'closedDate',
      'gRComplete',
      'outboundGRCompleteDate',
      'inboundGRComplete',
      'inboundGRCompleteDate',
      'poNumber',
      'repairOrderNumber',
      'outboundholdTag',
      //'holdTagComments',
      //'customerROCreatedDate',
      'supplierName',
      'supplierLocation',
      'repairOrderDefect',
      'assignedUserID',
      'workRequired',
      //Unknowns or preexisting
      'roNumber',
      'roCreatedAt',
      'inboundholdTag',
      'outboundHoldTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments',
      'aogAircraftTailNumber',
      'aircraftSerialnumber'
    ]);

    rcmHeaderNonEditableFieldsMap.set("CAM", [
      'customerROCreatedDt',
      //'customerROReceivedDt',
      //'customerRMANumber',
      //'customerPOValue',
      //'customerPOValueCurrency',
      'returnToAddressCode',
      //'customerReference',
      //'customerResponsibleValue',
      //'customerResponsibleValueCurrency',
      'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      'outboundSerialNumberChangeReasonId',
      'quantity',
      'inboundUnitCategory',
      'partNumber',
      'serialNumber',
      'description',
      /* Commented under #40361
      'poNumber',
      'customerInternalRefNumberDate',
      */
      'repairLineStatus',
      'quoteStatus',
      'logisticStatus',
      'statusDescription',
      'totalSupplierCost',
      'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      'supplierRMANumber',
      'supplierPromisedShipDt',
      'closedDate',
      'gRComplete',
      'outboundGRCompleteDate',
      'inboundGRComplete',
      'inboundGRCompleteDate',
      'repairOrderNumber',
      //'holdTagComments',
      //'customerROCreatedDate',
      'supplierName',
      'supplierLocation',
      'repairOrderDefect',
      'assignedUserID',
      'workRequired',
      //Unknowns or preexisting
      'roNumber',
      'roCreatedAt',
      'outboundholdTag',
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments',
      'aogAircraftTailNumber',
      'aircraftSerialnumber'
    ]);

    rcmHeaderNonEditableFieldsMap.set("WAREHOUSE", [
      'customerROCreatedDt',
      'customerROReceivedDt',
      'customerRMANumber',
      'customerPOValue',
      'customerPOValueCurrency',
      'returnToAddressCode',
      'customerReference',
      'customerResponsibleValue',
      'customerResponsibleValueCurrency',
      'customerAccountName',
      'warranty',
      'contracted',
      'quantity',
      'inboundUnitCategory',
      'partNumber',
      'serialNumber',
      'description',
      /* Commented under #40361
      'poNumber',
      'customerInternalRefNumberDate',
      */
      'repairLineStatus',
      'quoteStatus',
      'logisticStatus',
      'statusDescription',
      'totalSupplierCost',
      'workScopeID',
      //'airCraftType',
      'aOG',
      'poNumber',
      //'directShip',
      'supplierRMANumber',
      'supplierPromisedShipDt',
      'closedDate',
      'gRComplete',
      'outboundGRCompleteDate',
      'outboundSerialNumberChangeReasonId',
      'inboundGRComplete',
      'inboundGRCompleteDate',
      //'gRComplete',
      'repairOrderNumber',
      //'outboundholdTag',
      //'holdTagComments',
      //'customerROCreatedDate',
      'supplierLocation',
      'repairOrderDefect',
      'assignedUserID',
      'workRequired',
      //Unknowns or preexisting
      'roNumber',
      'roCreatedAt',
      'aogAircraftTailNumber',
      'aircraftSerialnumber'
    ]);

    rcmHeaderNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'customerROCreatedDt',
      'customerROReceivedDt',
      'customerRMANumber',
      'customerPOValue',
      'customerPOValueCurrency',
      'returnToAddressCode',
      'customerReference',
      'customerResponsibleValue',
      'customerResponsibleValueCurrency',
      'customerAccountName',
      'warranty',
      'contracted',
      'outboundSerialNumber',
      'outboundSerialNumberChangeReasonId',
      'quantity',
      'inboundUnitCategory',
      'partNumber',
      'serialNumber',
      'description',
      /* Commented under #40361
      'poNumber',
      'customerInternalRefNumberDate',
      */
      'repairLineStatus',
      'quoteStatus',
      'logisticStatus',
      'statusDescription',
      'totalSupplierCost',
      'workScopeID',
      'airCraftType',
      'aOG',
      'directShip',
      'supplierRMANumber',
      'supplierPromisedShipDt',
      'closedDate',
      'gRComplete',
      'outboundholdTag',
      'holdTagComments',
      /* Commented under #40361
      'customerROCreatedDate',
      */
      'supplierName',
      'supplierLocation',
      'repairOrderDefect',
      'assignedUserID',
      'workRequired',
      'poNumber',
      //Unknowns or preexisting
      'roNumber',
      'roCreatedAt',
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments',
      'aogAircraftTailNumber',
      'aircraftSerialnumber'
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmHeaderNonEditableFieldsMap.set("__DEFAULT", [
      //'gRComplete',
      'roNumber',
      'roCreatedAt',
      'repairLineStatus',
      'statusDescription',
      'quoteStatus',
      'logisticStatus',
      'repairOrderDefect',
      'workScopeID',
      'partNumber',
      'description',
      'serialNumber',
      'quantity',
      'supplierPromisedShipDt',
      'closedDate',
      /* Commented under #40361
      'customerInternalRefNumberDate',
      'poNumber',
      'customerROCreatedDt',
      */
      'outboundSerialNumberChangeReasonId',
      'returnToAddressCode',
      'quotedToCustomerDate',
      'supplierLocation',
      'outboundholdTag',
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments',
      'aogAircraftTailNumber',
      'aircraftSerialnumber'
    ]);

    //RCMReviewWorkscopeComparison
    var rcmReviewWorkscopeComparisonNonEditableFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeComparisonNonEditableFieldsMap.set("BUYER", [
      //'supplierRef',
      //'datePosted',
      //'dateReceived',
      //'serialNumberReceived',
      //'mismatchReasonSnId',

      //'qtyReceived',
      //'repairCategoryId',
      //'proposedWorkScopeId',
      //'quoteWarrantyStatus',
      //'quotedTat',
      //'findings',
      //'masterOption',
      'partNumberReceived',
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments'
    ]);

    rcmReviewWorkscopeComparisonNonEditableFieldsMap.set("CAM", [

    ]);

    rcmReviewWorkscopeComparisonNonEditableFieldsMap.set("WAREHOUSE", [

    ]);

    rcmReviewWorkscopeComparisonNonEditableFieldsMap.set("PROGRAMMANAGER", [

    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeComparisonNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMReviewWorkscopeComplete
    var rcmReviewWorkscopeCompleteNonEditableFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeCompleteNonEditableFieldsMap.set("BUYER", [
      'supplierReference',
      'datePosted',
      'dateReceived',
      'partNumberReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',

      'qtyRceived',
      'repairCategoryValue',
      'proposedWorkscopeId',
      'quotedTat',
      'findings',
      'currency',
      'totalCost',






      '',
      '',

      ''
    ]);

    rcmReviewWorkscopeCompleteNonEditableFieldsMap.set("CAM", [
      'supplierReference',
      'datePosted',
      'dateReceived',
      'partNumberReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',

      'qtyRceived',
      'repairCategoryValue',
      'proposedWorkscopeId',
      'quotedTat',
      'findings',
      'currency',
      'totalCost',






      '',
      '',

      ''
    ]);

    rcmReviewWorkscopeCompleteNonEditableFieldsMap.set("WAREHOUSE", [

    ]);

    rcmReviewWorkscopeCompleteNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'supplierReference',
      'datePosted',
      'dateReceived',
      'partNumberReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',

      'qtyRceived',
      'repairCategoryValue',
      'proposedWorkscopeId',
      'quotedTat',
      'findings',
      'currency',
      'totalCost',






      '',
      '',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeCompleteNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMReviewWorkscopeCost
    var rcmReviewWorkscopeCostNonEditableFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeCostNonEditableFieldsMap.set("BUYER", [
      //'currency',
      //'unitCost',
      //'rai',
      //'sos',
      'totalCost',
      // 'comparisonModel.repairCategory ? comparisonModel.repairCategory.value : ''',
      // 'comparisonModel.proposedWorkscopeId'
      //'internalComments',



    ]);

    rcmReviewWorkscopeCostNonEditableFieldsMap.set("CAM", [

    ]);

    rcmReviewWorkscopeCostNonEditableFieldsMap.set("WAREHOUSE", [

    ]);

    rcmReviewWorkscopeCostNonEditableFieldsMap.set("PROGRAMMANAGER", [

    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeCostNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMReviewWorkscopeStatus
    var rcmReviewWorkscopeStatusNonEditableFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeStatusNonEditableFieldsMap.set("BUYER", [
      'quotedToCustomer',
      'quotedToCustomerDate',
      'quotedToCustomerByUser',
      'customerApproved',
      'customerApprovedDate',
      'customerApprovedByUser',
      'createdDate',
      'createdByUser',
      'processedDate',
      'processedByUser',
      'noApprovalRequired'

    ]);

    rcmReviewWorkscopeStatusNonEditableFieldsMap.set("CAM", [
      'authorizedOnSupplier',
      'authorizedOnSupplierDate',
      'authorizedOnSupplierByUser',
      'createdDate',
      'createdByUser',
      'processedDate',
      'processedByUser'

    ]);

    rcmReviewWorkscopeStatusNonEditableFieldsMap.set("WAREHOUSE", [

    ]);

    rcmReviewWorkscopeStatusNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'quotedToCustomer',
      'quotedToCustomerDate',
      'quotedToCustomerByUser',
      'customerApproved',
      'customerApprovedDate',
      'customerApprovedByUser',
      'authorizedOnSupplier',
      'authorizedOnSupplierDate',
      'authorizedOnSupplierByUser',
      'canceled',
      'canceledDate',
      'canceledByUser',
      'cancelationReason',
      'createdDate',
      'createdByUser',
      'processedDate',
      'processedByUser',
      'noApprovalRequired'
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeStatusNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCM
    var rcmNonEditableFieldsMap = new Map<string, string[]>();

    rcmNonEditableFieldsMap.set("BUYER", [
      'gtat',
      'ntat',
      'partNumber',
      // '',
      'serialNumber',
      'supplierName',
      'repairOrderStatusName'
      // '',

    ]);

    rcmNonEditableFieldsMap.set("CAM", [
      'gtat',
      'ntat',
      'partNumber',
      // '',
      'serialNumber',
      'supplierName',
      'repairOrderStatusName'
      // '',

    ]);

    rcmNonEditableFieldsMap.set("WAREHOUSE", [
      'gtat',
      'ntat',
      'partNumber',
      // '',
      'serialNumber',
      'supplierName',
      'repairOrderStatusName'
      // '',

    ]);

    rcmNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'gtat',
      'ntat',
      'partNumber',
      '',
      'serialNumber',
      'supplierName',
      'repairOrderStatusName',
      '',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMShippingBlock
    var rcmShippingBlockNonEditableFieldsMap = new Map<string, string[]>();

    rcmShippingBlockNonEditableFieldsMap.set("BUYER", [
      'sequence',
      'shippingCarrier',
      'trackingNumber',
      'shippingStatus',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      '',
      'address',

      ''
    ]);

    rcmShippingBlockNonEditableFieldsMap.set("CAM", [
      'sequence',
      'shippingCarrier',
      'trackingNumber',
      'shippingStatus',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      '',
      'address',

      ''
    ]);

    rcmShippingBlockNonEditableFieldsMap.set("WAREHOUSE", [
      'sequence',
      'shippingCarrier',
      'trackingNumber',
      'shippingStatus',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      '',
      'address',

      ''
    ]);

    rcmShippingBlockNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'sequence',
      'shippingCarrier',
      'trackingNumber',
      'shippingStatus',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      '',
      'address',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmShippingBlockNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMEditShipping
    var rcmEditShippingNonEditableFieldsMap = new Map<string, string[]>();

    rcmEditShippingNonEditableFieldsMap.set("BUYER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmEditShippingNonEditableFieldsMap.set("CAM", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmEditShippingNonEditableFieldsMap.set("WAREHOUSE", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmEditShippingNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'carrierId',
      'trackingNumber',
      'trackingLink',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      'directionId',
      'address',
      'shippingStatus',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmEditShippingNonEditableFieldsMap.set("__DEFAULT", [

    ]);

    //RCMAddShipping
    var rcmAddShippingNonEditableFieldsMap = new Map<string, string[]>();

    rcmAddShippingNonEditableFieldsMap.set("BUYER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmAddShippingNonEditableFieldsMap.set("CAM", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmAddShippingNonEditableFieldsMap.set("WAREHOUSE", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      'shippingStatus',

      ''
    ]);

    rcmAddShippingNonEditableFieldsMap.set("PROGRAMMANAGER", [
      'carrierId',
      'trackingNumber',
      'trackingLink',
      'dateShipped',
      'dateDelivered',
      'shippingReference',
      'directionId',
      'address',
      'shippingStatus',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmAddShippingNonEditableFieldsMap.set("__DEFAULT", [

    ]);




    this.componentNonEditableMap.set(ComponentType.RCMReviewWorkscopeComparison, rcmReviewWorkscopeComparisonNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMReviewWorkscopeComplete, rcmReviewWorkscopeCompleteNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMHeader, rcmHeaderNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMReviewWorkscopeCost, rcmReviewWorkscopeCostNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMReviewWorkscopeStatus, rcmReviewWorkscopeStatusNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCM, rcmNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMShippingBlock, rcmShippingBlockNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMEditShipping, rcmEditShippingNonEditableFieldsMap);
    this.componentNonEditableMap.set(ComponentType.RCMAddShipping, rcmAddShippingNonEditableFieldsMap);

  }

  storeHiddenFields() {
    //Use a Map as a dictionary, one string[] for each Persona

    //RCMHeader
    var rcmHeaderHiddenFieldsMap = new Map<string, string[]>();

    rcmHeaderHiddenFieldsMap.set("SUPPLIER", [
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments'
    ]);

    rcmHeaderHiddenFieldsMap.set("CUSTOMER", [
      'inboundholdTag',
      'outboundholdTagCreatedDate',
      'inboundholdTagCreatedDate',
      'outboundholdTagCloseDate',
      'inboundholdTagClosedDate',
      'outboundholdTagComments',
      'inboundholdTagComments'
    ]);

    rcmHeaderHiddenFieldsMap.set("BUYER", [
      'customerROCreatedDt',
      //'customerROReceivedDt',
      //'customerRMANumber',
      //'customerPOValue',
      //'customerPOValueCurrency',
      //'returnToAddressCode',
      //'customerReference',
      //'customerResponsibleValue',
      //'customerResponsibleValueCurrency',
      //'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      //'outboundSerialNumberChangeReasonId',
      //'quantity',
      //'inboundUnitCategory',
      //'partNumber',
      //'serialNumber',
      //'description',
      //'poNumber',
      //'customerInternalRefNumberDate',
      //'repairLineStatus',
      //'quoteStatus',
      //'logisticStatus',
      //'statusDescription',
      //'totalSupplierCost',
      //'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      //'supplierRMANumber',
      //'supplierPromisedShipDt',
      //'closedDate',
      //'gRComplete',
      //'outboundholdTag',
      //'holdTagComments',
      //'roDetails.customerROCreatedDate',
      //'supplierName',
      //'supplierLocation',
      //'repairOrderDefect',
      //'assignedUserID',
      //'workRequired',
      ''
    ]);

    rcmHeaderHiddenFieldsMap.set("CAM", [
      //'customerROCreatedDt',
      //'customerROReceivedDt',
      //'customerRMANumber',
      //'customerPOValue',
      //'customerPOValueCurrency',
      //'returnToAddressCode',
      //'customerReference',
      //'customerResponsibleValue',
      //'customerResponsibleValueCurrency',
      //'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      //'outboundSerialNumberChangeReasonId',
      //'quantity',
      //'inboundUnitCategory',
      //'partNumber',
      //'serialNumber',
      //'description',
      //'poNumber',
      //'customerInternalRefNumberDate',
      //'repairLineStatus',
      //'quoteStatus',
      //'logisticStatus',
      //'statusDescription',
      //'totalSupplierCost',
      //'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      //'supplierRMANumber',
      //'supplierPromisedShipDt',
      //'closedDate',
      //'gRComplete',
      //'outboundholdTag',
      //'holdTagComments',
      //'roDetails.customerROCreatedDate',
      //'supplierName',
      //'supplierLocation',
      //'repairOrderDefect',
      //'assignedUserID',
      //'workRequired',
      ''
    ]);

    rcmHeaderHiddenFieldsMap.set("WAREHOUSE", [
      //'customerROCreatedDt',
      //'customerROReceivedDt',
      //'customerRMANumber',
      //'customerPOValue',
      //'customerPOValueCurrency',
      //'returnToAddressCode',
      //'customerReference',
      //'customerResponsibleValue',
      //'customerResponsibleValueCurrency',
      //'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      //'outboundSerialNumberChangeReasonId',
      //'quantity',
      //'inboundUnitCategory',
      //'partNumber',
      //'serialNumber',
      //'description',
      //'poNumber',
      //'customerInternalRefNumberDate',
      //'repairLineStatus',
      //'quoteStatus',
      //'logisticStatus',
      //'statusDescription',
      //'totalSupplierCost',
      //'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      //'supplierRMANumber',
      //'supplierPromisedShipDt',
      //'closedDate',
      //'gRComplete',
      //'outboundholdTag',
      //'holdTagComments',
      //'roDetails.customerROCreatedDate',
      //'supplierName',
      //'supplierLocation',
      //'repairOrderDefect',
      //'assignedUserID',
      //'workRequired',
      ''
    ]);

    rcmHeaderHiddenFieldsMap.set("PROGRAMMANAGER", [
      'customerROCreatedDt',
      //'customerROReceivedDt',
      //'customerRMANumber',
      //'customerPOValue',
      //'customerPOValueCurrency',
      //'returnToAddressCode',
      //'customerReference',
      //'customerResponsibleValue',
      //'customerResponsibleValueCurrency',
      //'customerAccountName',
      //'warranty',
      //'contracted',
      //'outboundSerialNumber',
      //'outboundSerialNumberChangeReasonId',
      //'quantity',
      //'inboundUnitCategory',
      //'partNumber',
      //'serialNumber',
      //'description',
      //'poNumber',
      //'customerInternalRefNumberDate',
      //'repairLineStatus',
      //'quoteStatus',
      //'logisticStatus',
      //'statusDescription',
      //'totalSupplierCost',
      //'workScopeID',
      //'airCraftType',
      //'aOG',
      //'directShip',
      //'supplierRMANumber',
      //'supplierPromisedShipDt',
      //'closedDate',
      //'gRComplete',
      //'outboundholdTag',
      //'holdTagComments',
      //'customerROCreatedDate',
      //'supplierName',
      //'supplierLocation',
      //'repairOrderDefect',
      //'assignedUserID',
      //'workRequired',
      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmHeaderHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMReviewWorkscopeComparison
    var rcmReviewWorkscopeComparisonHiddenFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeComparisonHiddenFieldsMap.set("BUYER", [
      //'supplierRef',
      //'datePosted',
      //'dateReceived',
      //'serialNumberReceived',
      //'mismatchReasonSnId',
      'mismatchReasonPnId',
      //'qtyReceived',
      //'repairCategoryId',
      //'proposedWorkScopeId',
      //'quoteWarrantyStatus',
      //'quotedTat',
      //'findings',
      //'masterOption',
      //'partNumberReceived',
      ''
    ]);

    rcmReviewWorkscopeComparisonHiddenFieldsMap.set("CAM", [
      'supplierRef',
      'datePosted',
      'dateReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',
      'mismatchReasonPnId',
      'qtyReceived',
      'repairCategoryId',
      'proposedWorkScopeId',
      'quoteWarrantyStatus',
      'quotedTat',
      'findings',
      'masterOption',
      'partNumberReceived',
      ''
    ]);

    rcmReviewWorkscopeComparisonHiddenFieldsMap.set("WAREHOUSE", [
      'supplierRef',
      'datePosted',
      'dateReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',
      'mismatchReasonPnId',
      'qtyReceived',
      'repairCategoryId',
      'proposedWorkScopeId',
      'quoteWarrantyStatus',
      'quotedTat',
      'findings',
      'masterOption',
      'partNumberReceived',
      ''
    ]);

    rcmReviewWorkscopeComparisonHiddenFieldsMap.set("PROGRAMMANAGER", [
      'supplierRef',
      'datePosted',
      'dateReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',
      'mismatchReasonPnId',
      'qtyReceived',
      'repairCategoryId',
      'proposedWorkScopeId',
      'quoteWarrantyStatus',
      'quotedTat',
      'findings',
      'masterOption',
      'partNumberReceived',
      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeComparisonHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMReviewWorkscopeComplete
    var rcmReviewWorkscopeCompleteHiddenFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeCompleteHiddenFieldsMap.set("BUYER", [
      //'supplierReference',
      //'datePosted',
      //'dateReceived',
      //'partNumberReceived',
      //'serialNumberReceived',
      //'mismatchReasonSnId',
      'quoteLine.mismatchReasonPnId',
      //'qtyRceived',
      //'repairCategoryValue',
      //'proposedWorkscopeId',
      //'quotedTat',
      //'findings',
      //'currency',
      //'totalCost',
      'quoteLine.nupCurrency',
      'quoteLine.nUP',
      'quoteLine.nupPercent',
      'quoteLine.mvCurrency',
      'quoteLine.mV',
      'quoteLine.mvPercent',
      //'',
      //'',

      ''
    ]);

    rcmReviewWorkscopeCompleteHiddenFieldsMap.set("CAM", [
      //'supplierReference',
      //'datePosted',
      //'dateReceived',
      //'partNumberReceived',
      //'serialNumberReceived',
      //'mismatchReasonSnId',
      'quoteLine.mismatchReasonPnId',
      //'qtyRceived',
      //'repairCategoryValue',
      //'proposedWorkscopeId',
      //'quotedTat',
      //'findings',
      //'currency',
      //'totalCost',
      'quoteLine.nupCurrency',
      'quoteLine.nUP',
      'quoteLine.nupPercent',
      'quoteLine.mvCurrency',
      'quoteLine.mV',
      'quoteLine.mvPercent',
      //'',
      //'',

      ''
    ]);

    rcmReviewWorkscopeCompleteHiddenFieldsMap.set("WAREHOUSE", [
      'supplierReference',
      'datePosted',
      'dateReceived',
      'partNumberReceived',
      'serialNumberReceived',
      'mismatchReasonSnId',
      'quoteLine.mismatchReasonPnId',
      'qtyRceived',
      'repairCategoryValue',
      'proposedWorkscopeId',
      'quotedTat',
      'findings',
      'currency',
      'totalCost',
      'quoteLine.nupCurrency',
      'quoteLine.nUP',
      'quoteLine.nupPercent',
      'quoteLine.mvCurrency',
      'quoteLine.mV',
      'quoteLine.mvPercent',
      '',
      '',

      ''
    ]);

    rcmReviewWorkscopeCompleteHiddenFieldsMap.set("PROGRAMMANAGER", [
      //'supplierReference',
      //'datePosted',
      //'dateReceived',
      //'partNumberReceived',
      //'serialNumberReceived',
      //'mismatchReasonSnId',
      'quoteLine.mismatchReasonPnId',
      //'qtyRceived',
      //'repairCategoryValue',
      //'proposedWorkscopeId',
      //'quotedTat',
      //'findings',
      //'currency',
      //'totalCost',
      'quoteLine.nupCurrency',
      'quoteLine.nUP',
      'quoteLine.nupPercent',
      'quoteLine.mvCurrency',
      'quoteLine.mV',
      'quoteLine.mvPercent',
      //'',
      //'',


      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeCompleteHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMReviewWorkscopeCost
    var rcmReviewWorkscopeCostHiddenFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeCostHiddenFieldsMap.set("BUYER", [
      //'currency',
      //'unitCost',
      //'rai',
      //'sos',
      //'totalCost',
      //'repairCategory',
      //'proposedWorkscopeId',
      //'internalComments',
      'nupCurrency',
      'nupPercentage',
      ''
    ]);

    rcmReviewWorkscopeCostHiddenFieldsMap.set("CAM", [
      'currency',
      'unitCost',
      'rai',
      'sos',
      'totalCost',
      'repairCategory',
      'proposedWorkscopeId',
      'internalComments',
      'nupCurrency',
      'nupPercentage',
      ''
    ]);

    rcmReviewWorkscopeCostHiddenFieldsMap.set("WAREHOUSE", [
      'currency',
      'unitCost',
      'rai',
      'sos',
      'totalCost',
      'repairCategory',
      'proposedWorkscopeId',
      'internalComments',
      'nupCurrency',
      'nupPercentage',
      ''
    ]);

    rcmReviewWorkscopeCostHiddenFieldsMap.set("PROGRAMMANAGER", [
      'currency',
      'unitCost',
      'rai',
      'sos',
      'totalCost',
      'repairCategory',
      'proposedWorkscopeId',
      'internalComments',
      'nupCurrency',
      'nupPercentage',
      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeCostHiddenFieldsMap.set("__DEFAULT", [
    ]);

    //RCMReviewWorkscopeStatus
    var rcmReviewWorkscopeStatusHiddenFieldsMap = new Map<string, string[]>();

    rcmReviewWorkscopeStatusHiddenFieldsMap.set("BUYER", [
    ]);

    rcmReviewWorkscopeStatusHiddenFieldsMap.set("CAM", [
    ]);

    rcmReviewWorkscopeStatusHiddenFieldsMap.set("WAREHOUSE", [
      'quotedToCustomer',
      'quotedToCustomerDate',
      'quotedToCustomerByUser',
      'customerApproved',
      'customerApprovedDate',
      'customerApprovedByUser',
      'authorizedOnSupplier',
      'authorizedOnSupplierDate',
      'authorizedOnSupplierByUser',
      'canceled',
      'canceledDate',
      'canceledByUser',
      'cancelationReason',
      'createdDate',
      'createdByUser',
      'processedDate',
      'processedByUser'
    ]);

    rcmReviewWorkscopeStatusHiddenFieldsMap.set("PROGRAMMANAGER", [
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmReviewWorkscopeStatusHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCM
    var rcmHiddenFieldsMap = new Map<string, string[]>();

    rcmHiddenFieldsMap.set("BUYER", [
      //'gtat',
      //'ntat',
      //'partNumber',
      //'',
      //'serialNumber',
      //'supplierName',
      //'repairOrderStatusName',
      //'',
      ''
    ]);

    rcmHiddenFieldsMap.set("CAM", [
      //'gtat',
      //'ntat',
      //'partNumber',
      //'',
      //'serialNumber',
      //'supplierName',
      //'repairOrderStatusName',
      //'',
      ''
    ]);

    rcmHiddenFieldsMap.set("WAREHOUSE", [
      //'gtat',
      //'ntat',
      //'partNumber',
      //'',
      //'serialNumber',
      //'supplierName',
      //'repairOrderStatusName',
      //'',
      ''
    ]);

    rcmHiddenFieldsMap.set("PROGRAMMANAGER", [
      //'gtat',
      //'ntat',
      //'partNumber',
      //'',
      //'serialNumber',
      //'supplierName',
      //'repairOrderStatusName',
      //'',
      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMShippingBlock
    var rcmShippingBlockHiddenFieldsMap = new Map<string, string[]>();

    rcmShippingBlockHiddenFieldsMap.set("BUYER", [
      //'sequence',
      //'shippingCarrier',
      //'trackingNumber',
      //'shippingStatus',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'',
      //'address',

      ''
    ]);

    rcmShippingBlockHiddenFieldsMap.set("CAM", [
      //'sequence',
      //'shippingCarrier',
      //'trackingNumber',
      //'shippingStatus',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'',
      //'address',

      ''
    ]);

    rcmShippingBlockHiddenFieldsMap.set("WAREHOUSE", [
      //'sequence',
      //'shippingCarrier',
      //'trackingNumber',
      //'shippingStatus',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'',
      //'address',

      ''
    ]);

    rcmShippingBlockHiddenFieldsMap.set("PROGRAMMANAGER", [
      //'sequence',
      //'shippingCarrier',
      //'trackingNumber',
      //'shippingStatus',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'',
      //'address',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmShippingBlockHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMEditShipping
    var rcmEditShippingHiddenFieldsMap = new Map<string, string[]>();

    rcmEditShippingHiddenFieldsMap.set("BUYER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',

      ''
    ]);

    rcmEditShippingHiddenFieldsMap.set("CAM", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',
      ''
    ]);

    rcmEditShippingHiddenFieldsMap.set("WAREHOUSE", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',
      ''
    ]);

    rcmEditShippingHiddenFieldsMap.set("PROGRAMMANAGER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',
      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmEditShippingHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);

    //RCMAddShipping
    var rcmAddShippingHiddenFieldsMap = new Map<string, string[]>();

    rcmAddShippingHiddenFieldsMap.set("BUYER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',

      ''
    ]);

    rcmAddShippingHiddenFieldsMap.set("CAM", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',

      ''
    ]);

    rcmAddShippingHiddenFieldsMap.set("WAREHOUSE", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',

      ''
    ]);

    rcmAddShippingHiddenFieldsMap.set("PROGRAMMANAGER", [
      //'carrierId',
      //'trackingNumber',
      //'trackingLink',
      //'dateShipped',
      //'dateDelivered',
      //'shippingReference',
      //'directionId',
      //'address',
      //'shippingStatus',

      ''
    ]);

    //When we don't know (or it's a new persona) use what we had before
    rcmAddShippingHiddenFieldsMap.set("__DEFAULT", [
      ''
    ]);




    this.componentHiddenMap.set(ComponentType.RCMReviewWorkscopeComparison, rcmReviewWorkscopeComparisonHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMReviewWorkscopeComplete, rcmReviewWorkscopeCompleteHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMHeader, rcmHeaderHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMReviewWorkscopeCost, rcmReviewWorkscopeCostHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMReviewWorkscopeStatus, rcmReviewWorkscopeStatusHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCM, rcmHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMShippingBlock, rcmShippingBlockHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMEditShipping, rcmEditShippingHiddenFieldsMap);
    this.componentHiddenMap.set(ComponentType.RCMAddShipping, rcmAddShippingHiddenFieldsMap);

  }

  //This will all want to come from configuration
  getNonEditableFields(componentName: ComponentType): string[] {
    //Check if we have that component
    if (!this.componentNonEditableMap.has(componentName)) {
      return [] as string[];
    }

    //Check if we have the current persona
    if (this.componentNonEditableMap.get(componentName).has(this.currentPersona)) {
      return this.componentNonEditableMap.get(componentName).get(this.currentPersona);
    }
    else {
      if (!this.componentNonEditableMap.get(componentName).has("__DEFAULT")) {
        return [] as string[];
      }

      return this.componentNonEditableMap.get(componentName).get("__DEFAULT");
    }
  }

  //This will all want to come from configuration
  getHiddenFields(componentName: ComponentType): string[] {
    //Check if we have that component
    if (!this.componentHiddenMap.has(componentName)) {
      return [] as string[];
    }

    //Check if we have the current persona
    if (this.componentHiddenMap.get(componentName).has(this.currentPersona)) {
      return this.componentHiddenMap.get(componentName).get(this.currentPersona);
    }
    else {
      if (!this.componentHiddenMap.get(componentName).has("__DEFAULT")) {
        return [] as string[];
      }

      return this.componentHiddenMap.get(componentName).get("__DEFAULT");
    }
  }

  isInList(personaList: string[]) {
    if (personaList.includes(this.currentPersona.toUpperCase())) {
      return true;
    }
    return false;
  }

  skipQuoteEntry(): boolean {
    const nonQuoters = ['WAREHOUSE', 'CAM', 'PROGRAMMANAGER'];
    return this.isInList(nonQuoters);
  }

  hideQuotes(): boolean {
    const nonQuoteViewers = ['WAREHOUSE'];
    return this.isInList(nonQuoteViewers);
  }

  hideEditButton(): boolean {
    const nonEditors = ['PROGRAMMANAGER'];
    return this.isInList(nonEditors);
  }

  hideShippingUpdates(): boolean {
    const nonShippingEditors = [PERSONA_NAMES.PROGRAMMANAGER];
    return this.isInList(nonShippingEditors);
  }

  shippingInfoUpdateRoles(): string[] {
    return [ROLE_NAMES.SUPPLIER, ROLE_NAMES.CUSTOMER];
  }

  shippingFieldsToNonEdit(direction: string, persona: string): string[] {
    const shippingFields: string[] = [];

    if (direction === '1') {
      switch (persona) {
        case "SUPPLIER":
          shippingFields.push('directionId');
          shippingFields.push('shippingReference');
          shippingFields.push('trackingNumber');
          shippingFields.push('trackingLink');
          shippingFields.push('dateShipped');
          shippingFields.push('carrierId');
          shippingFields.push('address');
          break;
        case "CUSTOMER":
          shippingFields.push('address');
          shippingFields.push('directionId');
          shippingFields.push('dateDelivered');
          shippingFields.push('shippingReference');
          break;
        default:

          break;
      }
    } else {
      switch (persona) {
        case "SUPPLIER":
          shippingFields.push('address');
          shippingFields.push('directionId');
          shippingFields.push('dateDelivered');
          shippingFields.push('shippingReference');
          break;
        case "CUSTOMER":
          shippingFields.push('trackingNumber');
          shippingFields.push('trackingLink');
          shippingFields.push('dateShipped');
          shippingFields.push('carrierId');
          shippingFields.push('shippingReference');
          shippingFields.push('directionId');
          shippingFields.push('address');
          break;
        default:
          break;
      }
    }
    return shippingFields;
  }

  effectFieldDisables(form: UntypedFormGroup, nonEditableFields: string[]): void {
    Object.keys(form.controls).forEach(key => {
      if (nonEditableFields.includes(key)) {
        form.get(key).disable();
      }
    });
  }

  private buildDataGridColumnMaps() {
    this.dataGridColumnsMap.set(ComponentType.RCMGrid, this.getRcmDataGridColumns())
  }

  private buildDataGridHistoricalColumnMaps() {
    this.dataGridColumnsMap.set(ComponentType.RCMGridHistorical, this.getRcmDataGridHistoricalColumns())
  }

  private getRcmDataGridColumns(): Map<string, any[]> {

    var rcmDataGridColumnsMap = new Map<string, any[]>();

    rcmDataGridColumnsMap.set(PERSONA_NAMES.__DEFAULT, this.getRcmDataGridColumns_DEFAULT());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.CAM, this.getRcmDataGridColumns_CAM());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.WAREHOUSE, this.getRcmDataGridColumns_WAREHOUSE());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.BUYER, this.getRcmDataGridColumns_NOTAT());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.PROGRAMMANAGER, this.getRcmDataGridColumns_DEFAULT());

    return rcmDataGridColumnsMap;
  }

  private getRcmDataGridColumns_NOTAT(): any[] {
    return this.splice_NTAT(this.splice_GTAT(this.getRcmDataGridColumns_DEFAULT()));
  }

  private getRcmDataGridHistoricalColumns(): Map<string, any[]> {

    var rcmDataGridColumnsMap = new Map<string, any[]>();

    rcmDataGridColumnsMap.set(PERSONA_NAMES.__DEFAULT, this.getRcmDataGridHistoricalColumns_DEFAULT());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.BUYER, this.getRcmDataGridHistoricalColumns_DEFAULT());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.CAM, this.getRcmDataGridHistoricalColumns_DEFAULT());
    rcmDataGridColumnsMap.set(PERSONA_NAMES.WAREHOUSE, this.getRcmDataGridHistoricalColumns_DEFAULT());

    return rcmDataGridColumnsMap;
  }

private getRcmDataGridHistoricalColumns_DEFAULT(): any[] {
  return [
    { name: 'repairOrderNumber', header: 'Repair Order' },
    /*Commented under #40361
    { name: 'poNumber', header: 'Purchase Order' },
     */
    { name: 'partNumber', header: 'Part Number' },
    { name: 'serialNumber', header: 'Serial Number' },
    { name: 'supplierName', header: 'Supplier' },
    { name: 'repairLineStatus', header: 'Status' }
    /*Commented under #40361
    { name: 'directShip', header: 'Direct Ship' }
     */
  ];
}

private getRcmDataGridColumns_DEFAULT(): any[] {
  return [
    { name: 'repairOrderNumber', header: 'Repair Order' },
    /* Commented under #40361
    { name: 'poNumber', header: 'Purchase Order' },
     */
    { name: 'partNumber', header: 'Part Number' },
    { name: 'serialNumber', header: 'Serial Number' },
    { name: 'supplierName', header: 'Supplier' },
    { name: 'repairLineStatus', header: 'Status' },
    { name: 'gTat', header: 'GTAT' },
    { name: 'nTat', header: 'NTAT' },
    /* Commented under #40361 
    { name: 'directShip', header: 'Direct Ship' },
     */
    { name: 'clipIcon', header: 'Document(s)' }

  ];
}

  private getRcmDataGridColumns_CAM(): any[] {
    let columns = this.getRcmDataGridColumns_DEFAULT();
    columns.splice(6, 0, { name: 'cancelationRequired', header: 'Cancelation Required' });
    
    return this.splice_NTAT(this.splice_GTAT(columns));
  }

  private getRcmDataGridColumns_WAREHOUSE(): any[] {
    let columns = this.getRcmDataGridColumns_DEFAULT();
    columns.splice(6, 0, { name: 'cancelationRequired', header: 'Cancelation Required' });

    return this.splice_NTAT(this.splice_GTAT(columns));
  }

  getDataGridColumms(componentName: ComponentType): any[] {

    if (this.dataGridColumnsMap.get(componentName).has(this.currentPersona)) {
      return this.dataGridColumnsMap.get(componentName).get(this.currentPersona);
    }

    if (this.dataGridColumnsMap.get(componentName).has(PERSONA_NAMES.__DEFAULT)) {
      return this.dataGridColumnsMap.get(componentName).get(PERSONA_NAMES.__DEFAULT);
    }

    return [];
  }

  private splice_GTAT(columns: any[]): any[] {
    const indexofGTAT = columns.findIndex((object) => {
      return object.name === 'gTat';
    });

    if (indexofGTAT !== -1) {
      columns.splice(indexofGTAT, 1);
    }

    return columns;
  }

  private splice_NTAT(columns: any[]): any[] {
    const indexofNTAT = columns.findIndex((object) => {
      return object.name === 'nTat';
    });

    if (indexofNTAT !== -1) {
      columns.splice(indexofNTAT, 1);
    }

    return columns;
  }

  private removeColumn(columns:any[], columnName:string):any[]{
    const index = columns.findIndex(c=>c.name.toLowerCase() === columnName.toLowerCase());
    if(index > -1){
      columns.splice(index, 1);
    }
    return columns;
  }

  private storeHiddenActivityStatus() {
    var rcmActivityHiddenStatusMap = new Map<string, ActivityType[]>();

    rcmActivityHiddenStatusMap.set(PERSONA_NAMES.WAREHOUSE, [
      ActivityType.QuoteSentToCustomer,
      ActivityType.QuoteCustomerApproved,
      ActivityType.QuoteProcessed
    ]);

    rcmActivityHiddenStatusMap.set(ROLE_NAMES.SUPPLIER, [
      ActivityType.QuoteSentToCustomer,
      ActivityType.QuoteCustomerApproved,
      ActivityType.QuoteProcessed
    ]);

    this.componentActivityHiddenStatus.set(ComponentType.RcmActivity, rcmActivityHiddenStatusMap);
  }

  getHiddenActivityStatus(componentName: ComponentType, rolename:string): ActivityType[] {
    //Check if we have that component
    if (!this.componentActivityHiddenStatus.has(componentName)) {
      return [] as ActivityType[];
    }

    //Check if we have the current role
    if (this.componentActivityHiddenStatus.get(componentName).has(this.currentPersona)) {
      return this.componentActivityHiddenStatus.get(componentName).get(this.currentPersona);
    }
    if(this.componentActivityHiddenStatus.get(componentName).has(rolename)){
      return this.componentActivityHiddenStatus.get(componentName).get(rolename);
    }
    
    if (!this.componentActivityHiddenStatus.get(componentName).has("__DEFAULT")) {
        return [] as ActivityType[];
    }

    return this.componentActivityHiddenStatus.get(componentName).get("__DEFAULT");
    
  }
}