<div class="aar-shipping-status-icon">
  <div *ngIf="shippingStatus === 'Dispatched'; else notDispatched">
    <mat-icon>check_circle_outline</mat-icon>
  </div>
</div>

<ng-template #notDispatched>
  <mat-icon>radio_button_checked</mat-icon>
</ng-template>

<div class="aar-shipping-title">
  <div class="margin-right-sm">
    <h2>
      {{shippingStage}}
    </h2>
  </div>
  <div style="color:white">
    <h2>
      {{ directionDescription }}
    </h2>
  </div>
  <div class="right-justified-md">
    <h2>
      {{shippingStatus}}
    </h2>
  </div>
</div>

<div class="aar-shipping-sub-title">
  <div class="aar-shipping-label">
    Shipped:
  </div>
  <div class="margin-left-sm">
    {{!dateShipped ? "n/a" : dateShipped | dateLocalisedPipe}}
  </div>
  <div class="right-justified-label">
    Delivered:
  </div>
  <div class="right-justified-value">
    {{!dateDelivered ? "n/a" : dateDelivered | dateLocalisedPipe}}
  </div>
</div>

<div class="aar-shipping-button-container">
  <div *ngIf="showUpdate()">
    <button type="button" mat-flat-button color="primary" (click)="onUpdateClick($event)">Update</button>
  </div>
  <div class="margin-left-sm" *ngIf="showDelete()">
    <button type="button" mat-flat-button color="primary" (click)="onDeleteClick($event)">Delete</button>
  </div>
  <div class="right-justified-label">
    <a href="javascript:void(0)" (click)="onOpenClick()">View details</a>
    <div style="margin-top:-2px">
      <a href="javascript:void(0)" (click)="onOpenClick()"><mat-icon>expand_more</mat-icon></a>
    </div>
  </div>
</div>