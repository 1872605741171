import gql from 'graphql-tag';

export const User = {
  attributes: gql`
      fragment UserAttributes on UserType {
        id
        companyID
        fullName
        roleName
        userName
        personaName

      }
    `
};

export const login = {
  attributes: gql`
      fragment UserAttributes on LoginType {
        id
        companyID
        fullName
        roleName
        userName
        email
        token
        lastLoggedIn
        hasTemporaryPassword
        hasAcceptedTermsAndConditions
        hasAcceptedPrivacyPolicy
        personaName
        features {
          id, module
        }
      }
    `
};


export const RepairOrder = {
  attributes: gql`
  fragment RepairOrderAttributes on RepairOrderType {
    repairOrderLineId
    repairOrderNumber
    description
    partNumber
    notes
    quantity
    repairOrderDefect
    workRequired
    approvalStatus {
      id
      name
    }
    serialNumber
    supplier {
      id
      name
    }
    warranty
    priority {
      id
      priorityName
    }
    gtat
    repairOrderStatus
    {
      id
      name
    }
    ntat
    priorityID
    repairOrderPriorityName
    repairOrderStatusID
    repairOrderStatusName
    approvalStatusName
    workScopeID
    workScopeName
    supplierId
    supplierName
    repairOrderLine {
      repairOrderLineId
      description
      partNumber
      gtat
      ntat
      repairOrderDefect
      inboundUnitCategory
      outboundSerialNumber
      outboundSerialNumberChangeReason
      customerAccountManager
      contract
      repairOrderDefect
      repairOrderId
      warranty
      quantity
      workRequired
      serialNumber
      workScopeID
      workScope {
        id
        name
      }
      warranty
      status {
        id
        name
      }
    }
    assignedUserID
    daysSinceCreation
    daysSinceStatusUpdated
    roCreatedAt
    roUpdatedAt
  }

  `,
  updates: gql`
  fragment RepairOrderAttributeUpdate on RepairOrderType {
    repairOrderLineId
    repairOrderNumber
    rOLineNumber
    description
    partNumber
    notes
    quantity
    repairOrderDefect
    workRequired
    approvalStatus {
      id
      name
    }
    serialNumber
    supplier {
      id
      name
    }
    warranty
    priority {
      id
      priorityName
    }
    gtat
    ntat
    priorityID
    repairOrderPriorityName
    approvalStatusName
    workScopeID
    workScopeName
    supplierId
    supplierName
    repairOrderLine {
      repairOrderLineId
      rOLineNumber
      description
      partNumber
      repairOrderDefect
      inboundUnitCategory
      outboundSerialNumber
      outboundSerialNumberChangeReason
      customerAccountManager
      gtat
      ntat
      contract
      repairOrderDefect
      repairOrderId
      warranty
      quantity
      workRequired
      serialNumber
      workScopeID
      workScope {
        id
        name
      }
      warranty
      status {
        id
        name
      }
      holdTags {
        closedDate
        comments
        createDate
        directionId
        id
        sysCreateDate
      }
    }
    assignedUserID
    daysSinceCreation
    daysSinceStatusUpdated
    roCreatedAt
    roUpdatedAt
    tailNumber
  }

  `,
  priority: gql`
    fragment RepairOrderPriority on RepairOrderType {
      priority {
        ... PriorityAttributes
      }
    }
  `,
  repairOrderStatus: gql`
    fragment RepairOrderStatus on RepairOrderType {
      repairOrderStatus {
        ... RepairOrderStatusAttributes
      }
    }
  `,
  approvalStatus: gql`
    fragment ApprovalStatus on RepairOrderType {
      approvalStatus {
        ... ApprovalStatusAttributes
      }
    }
  `,
  assignedUser: gql`
    fragment AssignedUser on RepairOrderType {
      assignedUser {
        ... UserAttributes
      }
    }
  `,
  finalApprover: gql`
    fragment FinalApprover on RepairOrderType {
      finalApprover {
        ... UserAttributes
      }
    }
  `,
  workScope: gql`
    fragment Workscope on RepairOrderType {
      workScope {
        ... WorkscopeAttributes
      }
    }
  `,
  supplier: gql`
    fragment RepairOrderSupplier on RepairOrderType {
      supplier {
        ... SupplierAttributes
      }
    }
  `,
  repairOrderLines: gql`
    fragment RepairOrderLine on RepairOrderType {
      repairOrderLines {
        ... RepairOrderLineAttributes
      }
    }
  `
};

export const RepairOrderAx = {
  attributes: gql`
    fragment RepairOrderAxAttributes on RepairOrderAxDemoType {
      repairOrderNumber
      description
      warranty
      partNumber
      createdAt
    }
  `
};

export const RepairOrderAndLine = {
  attributes: gql`
    fragment RepairOrderAndLineAttributes on RepairOrderLineAndRoType {
      contract
      datePosted
      gtat
      id
      ntat
      repairOrderDescription
      repairOrderId
      repairOrderNumber
      repairOrderPriorityName
      requestedWorkScopeId
      requestedWorkscopeName
      repairOrderStatusName
      rOCombineId
      rOLineNumber
      serialNumber
      statusId
      statusName
      supplierLocation
      supplierName
      totalCost
      updatedAt
      warranty
    }
  `
};

export const RepairOrderLine = {
  attributes: gql`
    fragment  RepairOrderLineAttributes on RepairOrderLineType {
      repairLineId
      repairOrderId
      statusId
      supplierId
      datePosted
      dateReceived
      partNumberReceived
      repairOrderDefect
      inboundUnitCategory
      outboundSerialNumber
      outboundSerialNumberChangeReason
      customerAccountManager
      mismatchReasonPnId
      serialNumberReceived
      mismatchReasonSnId
      qtyReceived
      repairCategoryId
      proposedWorkScopeId
      eCD
      quotedTat
      findings
      attachedQuote
      currencyId
      totalCost
      laborCost
      materialsCost
      miscellaneousCost
      rAI
      sOS
      nupCurrencyId
      nUP
      nupPercent
      mvCurrencyId
      mV
      mvPercent
      rOLineNumber
      updatedAt
    }
  `
};

export const EmailMetadataInput = {
  attributes: gql`
    fragment  EmailMetaDataInputAttributes on EmailMetaDataResultsType {
      bccEmail
      isBodyHTML
      body
      senderUserId
      templateId
      categoryId
      emailImportLogFailed
      emailImportLogUnreviewed
      mailboxEmailAddress
      ccEmail
      customerId
      direction
      emailId
      toEmail
      fromEmail
      importance
      mailboxId
      markAsRead
      priorityId
      priority
      receivedDateTime
      requireReply
      rolNumber
      sensitivity
      sentDateTime
      serviceOrderId
      subject
      tagId

    }
  `
};

export const EmailMetadataOutPut = {
  attributes: gql`
    fragment  EmailMetaDataOutPutAttributes on EmailMetaDataResultsType {
    bccEmail
    body
    categoryId
    ccEmail
    customerId
    direction
    emailId
    fromEmail
    importance
    mailboxId
    markAsRead
    priority
    receivedDateTime
    requireReply
    sensitivity
    sentDateTime
    serviceOrderId
    subject
    templateId
    toEmail

    }
  `
};

export const RepairOrderDetails = {
  attributes: gql`
    fragment  RepairOrderDetailsAttributes on RepairOrderDetailType {
      id
      repairOrderNumber
      priorityName
      priorityID
      assignedUserID
      roCreatedAt
      roUpdatedAt
      partNumber
      serialNumber
      warranty
      workScopeID
      workScopeName
      supplierName
      supplierId
      repairOrderStatusName
      description
      repairOrderDefect
      workRequired
      quantity
      ntat
      gtat
    }
  `
};

export const HistoricalPricingList = {
  attributes: gql`
    fragment HistoricalPricingListAttributes on HistoricalPricingType {
      id
      contracted
      cost
      date
    }
  `,
  supplier: gql`
  fragment HistoricalPricingSupplier on HistoricalPricingType {
    supplier {
      ... SupplierAttributes
    }
  }
`,
};

export const Airline = {
  attributes: gql`
    fragment AirlineAttributes on AirlineType {
      id
      name
    }
  `
};

export const Currency = {
  attributes: gql`
    fragment CurrencyAttributes on CurrencyType {
      id
      name
    }
  `
};

export const MismatchReasons = {
  attributes: gql`
    fragment MismatchReasonsAttributes on MismatchReasonType {
      id
      reason
      reasonValue
      categoryId
      typeId
      internalOnly
    }
  `
};

export const Supplier = {
  attributes: gql`
    fragment SupplierAttributes on SupplierType {
      id
      name
      location
      phone
    }
  `
};

export const Priority = {
  attributes: gql`
    fragment PriorityAttributes on PriorityType {
      id
      priorityId
      priorityName
    }
  `
};

export const RepairCategory = {
  attributes: gql`
    fragment RepairCategoryAttributes on RepairCategoryType {
      repairCategoryId
      name
      value
    }
  `
};

export const RepairOrderStatus = {
  attributes: gql`
    fragment RepairOrderStatusAttributes on RepairOrderStatusType {
      id
      name
    }
  `
};

export const RepairOrderLineStatus = {
  attributes: gql`
    fragment RepairOrderLineStatusAttributes on RepairOrderStatusType {
      id
      name
    }
  `
};

export const ApprovalStatus = {
  attributes: gql`
    fragment ApprovalStatusAttributes on ApprovalStatusType {
      id
      name
    }
  `
};

export const Status = {
  attributes: gql`
    fragment StatusAttributes on StatusType {
      id
      name
    }
  `
};

export const WorkScope = {
  attributes: gql`
    fragment WorkScopeAttributes on WorkscopeType {
      id
      name
      workScopeId
    }
  `
};

export const Part = {
  attributes: gql`
    fragment PartAttributes on PartType {
      id
      partNumber
    }
  `
};

export const QuoteWarrantyStatus = {
  attributes: gql`
    fragment QuoteWarrantyStatusesAttributes on QuoteWarrantyStatusType {
      id
	    quoteWarrantyStatusID
	    reason
    }
  `
};

export const Carrier = {
  attributes: gql`
    fragment CarrierAttributes on CarrierType {
      id
      name
    }
  `
};

export const EmailMetadata = {
  attributes: gql`
    fragment EmailMetadataAttributes on EmailMetaDataType {
      bccEmail
      body
      categoryId
      ccEmail
      customerId
      direction
      emailImportLogFailed
      emailImportLogUnreviewed
      fromEmail
      importance
      isBodyHTML
      mailboxEmailAddress
      mailboxRecId
      markAsRead
      parentEmailId
      priority
      receivedDateTime
      requireReply
      sensitivity
      sentDateTime
      serviceOrderId
      subject
      templateId
      toEmail
    }
  `
};

export const EmailAttachment = {
  attributes: gql`
    fragment EmailAttachmentAttributes on EmailAttachmentType {
      companyId
      documentName
      documentTypeID
      emailId
      isDefaultAttachment
      uRL
    }
  `
};

export const Email = {
  attributes: gql`
    fragment EmailAttributes on AirvolutionEmailType {
      companyName
    }
  `
};

export const EmailTemplate = {
  attributes: gql`
    fragment EmailTemplateAttributes on EmailTemplateType{
      iD
      templateID
      templateName
    }
  `
};

export const EmailTag = {
  attributes: gql`
    fragment EmailTagAttributes on EmailTagType{
      iD
      name
    }
  `
};

export const EmailCategory = {
  attributes: gql`
    fragment EmailCategoryAttributes on EmailCategoryType{
      iD
      categoryID
      categoryName
    }
  `
};

export const EmailPriority = {
  attributes: gql`
    fragment EmailPriorityAttributes on EmailPriorityType{
      iD
      priorityID
      priorityName
    }
  `
};

export const RepairOrderActivity = {
  attributes: gql`
    fragment RepairOrderActivityAttributes on ActivityType {
      id
      description
      userId
      activityDate
      icon
      activityType
      {
        description
        activityTypeID
      }
    }
  `
};

export const RepairOrderActivityType = {
  attributes: gql`
    fragment RepairOrderActivityTypeAttributes on ActivityType {

        description
        activityTypeID

    }
  `
};

export const ShippingType = {
  /* Commented under #40361 
  attributes: gql`
    fragment ShippingTypeAttributes on ShippingType {
      id
      rONumber
      rOLineNumber
      trackingNumber
      trackingLink
      sequence
      address
      shippingStatus
      dateShipped
      dateDelivered
      directionId
      rOLNumber
      shippingReference
      isPreventFeedUpdate
      directionDescription
      directShip
      carrierId
      status {
        name
        shippingStatusId
      }
    }
  ` 
  */
  attributes: gql`
    fragment ShippingTypeAttributes on ShippingType {
      id
      rONumber
      rOLineNumber
      trackingNumber
      trackingLink
      sequence
      address
      shippingStatus
      dateShipped
      dateDelivered
      directionId
      rOLNumber
      shippingReference
      directionDescription
      directShip
      carrierId
      status {
        name
        shippingStatusId
      }
    }
`,
};

export const CommonContact = {
  attributes: gql`
    fragment CommonContactAttributes on CommonContactType {
      firstName
      lastName
      title
      emailAddress
      mainPhoneNumber
      alternatePhoneNumber
      comments
      isPrimaryContact
      contactTypeID
      contactID
      companyID
      supplierID
      endCustomerID
    }
  `
};
