import { Component, OnInit, NgModule, Input } from '@angular/core';

@Component({
  selector: 'aar-tertiary-card',
  templateUrl: './tertiary-card.component.html',
  styleUrls: ['./tertiary-card.component.scss']
})
export class TertiaryCardComponent implements OnInit {
  @Input('label') label: string;
  @Input('value') value: number;

  constructor() { }

  ngOnInit() { }
}

@NgModule({
  imports: [],
  exports: [ TertiaryCardComponent ],
  declarations: [ TertiaryCardComponent ]
})

export class TertiaryCardModule { }
