import { RcmHistoryComponent } from './rcm/rcm-history/rcm-history.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BREADCRUMBS_ACTIONS } from '@app/core/constants/globalConstants';
import { LoginComponent } from '@app/auth/components';
import { MainComponent } from '@app/main/main.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { NotificationsComponent } from '@app/notifications/notifications.component';
import { RcmComponent } from '@app/rcm/rcm.component';
import { RcmDetailsComponent } from '@app/rcm/rcm-details/rcm-details.component';
import { CostControlComponent } from '@app/cost-control/cost-control.component';
import { TatReportComponent } from '@app/analytics-bi/tat-report.component';
import { StatusReportComponent } from '@app/analytics-bi/status-report.component';
import { AarDomoReportComponent } from '@app/analytics-bi/aar-domo-report/aar-domo-report.component';
import { SuppliersComponent } from '@app/suppliers/suppliers.component';
import { SupplierWipComponent } from '@app/suppliers/supplier-session/supplier-wip/supplier-wip.component';
import { SupplierArchiveComponent } from '@app/suppliers/supplier-session/supplier-archive/supplier-archive.component';
import { SupplierCarriersComponent } from '@app/suppliers/supplier-session/supplier-carriers/supplier-carriers.component';
import { SupplierQuoteDropComponent } from '@app/suppliers/supplier-session/supplier-quote-drop/supplier-quote-drop.component';
import { MyAccountComponent } from '@app/my-account/my-account.component';
import { UserListComponent } from '@app/my-account/user-list/user-list.component';

// Guards
import { AppRoutingSecurityGuard } from '@app/app-routing-security.guard';
import { AppRoutingRoleGuard } from '@app/app-routing-role.guard';
import { SupplierAddQuoteComponent } from './suppliers/supplier-session/supplier-add-quote/supplier-add-quote.component';
import { EmailInboxComponent } from './email-inbox/email-inbox.component';
import { AppRoutingModuleGuard } from './app-routing-module.guard';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { LoginSamlIdpComponent } from './auth/components/login/login-saml-idp.component';
import { CustomerContactsComponent } from './contacts/customer-contacts/customer-contacts.component';
import { SupplierContactsComponent } from './contacts/supplier-contacts/supplier-contacts.component';
import { InternalContactsComponent } from './contacts/internal-contacts/internal-contacts.component';

const routes: Routes = [
  {
    path: 'login-saml-idp',
    component: LoginSamlIdpComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AppRoutingSecurityGuard]
  },
  {
    path: '', component: MainComponent,
    children: [
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: { label: 'Notifications' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      {
        path: 'about',
        component: AboutComponent,
        data: { label: 'About' }
      },
      {
        path: 'dashboard',
        data: { label: 'Dashboard' },
        component: DashboardComponent,
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        canActivateChild: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        children: [{
          path: 'domo-report-test',
          component: AarDomoReportComponent,
          data: { label: 'Domo Report Test', report: 'https://aarcorp.domo.com/embed/pages/private/bo3lX'}
        }
        ]
      },
      {
        path: 'rcm',
        component: RcmComponent,
        data: { label: 'RCM' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        canActivateChild: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        children: [{
          path: ':id',
          component: RcmDetailsComponent
        }]
      },

      {
        path: 'supplier',
        component: RcmComponent,
        data: { label: 'Supplier Portal' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        canActivateChild: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        children: [{
          path: ':id',
          component: RcmDetailsComponent
        }]
      },

      {
        path: 'cost-control',
        component: CostControlComponent,
        data: { label: 'Cost Control'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      {
        path: 'rcm',
        component: RcmComponent,
        data: { label: 'RCM' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      {
        path: 'history',
        component: RcmHistoryComponent,
        data: { label: 'Historical Data' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
      },
      {
        path: 'email-inbox',
        component: EmailInboxComponent,
        data: { label: 'ROGUE EMAIL QUEUE' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      {
        path: 'analytics/tat-report',
        component: TatReportComponent,
        data: { label: 'Tat Report', action: BREADCRUMBS_ACTIONS.tatExportReport, module: 'AnalyticsAndBI'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard, AppRoutingModuleGuard]
      },
      {
        path: 'analytics/status-report',
        component: StatusReportComponent,
        data: { label: 'Status Report', module: 'AnalyticsAndBI'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard, AppRoutingModuleGuard]
      },
      {
        path: 'suppliers',
        component: SuppliersComponent,
        data: { label: 'Suppliers' },
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        canActivateChild: [AppRoutingSecurityGuard, AppRoutingRoleGuard],
        children: [{
          path: 'wip',
          component: SupplierWipComponent,
          data: { label: 'WIP' }
        }, {
          path: 'archive',
          component: SupplierArchiveComponent,
          data: { label: 'Archive' }
        }, {
          path: 'carriers',
          component: SupplierCarriersComponent,
          data: { label: 'Carriers' }
        }, {
          path: 'quote-drop',
          component: SupplierQuoteDropComponent,
          data: { label: 'Quote Drop' }
        }
        , {
          path: 'add-quote',
          component: SupplierAddQuoteComponent,
          data: { label: 'Add Quote' }
        }
      ]
      },
      {
        path: 'my-account',
        component: MyAccountComponent,
        data: { label: 'My Account' },
        canActivate: [AppRoutingSecurityGuard],
        canActivateChild: [AppRoutingSecurityGuard],
        children: [{
          path: 'users',
          component: UserListComponent,
          data: { label: 'User List' }
        }]
      },
      {
        path: 'contacts/internals',
        component: InternalContactsComponent,
        data: { label: 'Internal'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      // Re-instate the customer & supplier contact routes once those screens have been reworked
      {
        path: 'contacts/customers',
        component: CustomerContactsComponent,
        data: { label: 'Customers'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      {
        path: 'contacts/suppliers',
        component: SupplierContactsComponent,
        data: { label: 'Suppliers'},
        canActivate: [AppRoutingSecurityGuard, AppRoutingRoleGuard]
      },
      { path: '**', redirectTo: '/dashboard' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
