import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { NativeDateModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule, MatSelect } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppMaterialModule } from '@app/app-material.module';
import { DashCardModule, DataTableModule, TimelineModule, NoActivityModule } from '@app/shared/components';
import { NgFileDropDirectiveModule } from '@app/shared/directives/ng-file-drop/ng-file-drop.directive';
import { EmailInboxComponent } from './email-inbox.component';
import { EmailListComponent } from './email-list/email-list.component';
import { EmailListBlockComponent } from './email-list/email-list-block/email-list-block.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        DashCardModule,
        DataTableModule,
        TimelineModule,
        NoActivityModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatCheckboxModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgFileDropDirectiveModule,
        MatSnackBarModule
    ],
    providers: [],
    declarations: [
        EmailInboxComponent,
        EmailListBlockComponent,
        EmailListComponent
    ]
})
export class EmailInboxModule { }
