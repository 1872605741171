import { Component, OnInit, Input, NgModule } from '@angular/core';
import { SliderGroupModule } from '@app/shared/components/slider-group/slider-group.component';

@Component({
  selector: 'aar-slider-card',
  templateUrl: './slider-card.component.html',
  styleUrls: ['./slider-card.component.scss']
})
export class SliderCardComponent implements OnInit {
  @Input('label') label: string;
  @Input('slider') slider: any;

  constructor() { }

  ngOnInit() { }
}

@NgModule({
  imports: [ SliderGroupModule ],
  exports: [ SliderCardComponent ],
  declarations: [ SliderCardComponent ]
})
export class SliderCardModule { }
