import {
  Directive,
  ElementRef,
  OnInit,
  NgModule,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';



@Directive({
  selector: '[aarNgFileDropDirective]',
})
export class NgFileDropDirective implements OnInit {


  private element: HTMLElement;

  @Output('fileDropped')fileDropped: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

  }

  constructor(elementReference: ElementRef) {
    this.element = elementReference.nativeElement;
  }

  @HostListener('dragstart', ['$event']) onDragStart(evt) {
    // evt.dataTransfer.setData('text/html', null);
  }

  @HostListener('drop', ['$event']) onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      //console.log(files);
      this.fileDropped.emit({ file: files[0] });
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {

    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      //console.log(files);
      this.fileDropped.emit({ file: files[0] });
    }
  }
}

@NgModule({
  imports: [],
  exports: [ NgFileDropDirective ],
  declarations: [ NgFileDropDirective ]
})

export class NgFileDropDirectiveModule {}
