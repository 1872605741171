import { Component, Input, NgModule, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';
import { CRITICAL_STATS_IDS } from '@app/core/constants/critical-stats-constants';
import { ROLE_NAMES } from '@app/core/constants/persona-constants';

@Component({
  selector: 'aar-dash-card',
  templateUrl: './dash-card.component.html',
  styleUrls: ['./dash-card.component.scss']
})
export class DashCardComponent {
  @Input() type = 'b';
  @Input() label: string;
  @Input() value: number;
  @Input() criticalStatId: string;
  @Input() goTo: string;
  @Input() isEnabled: boolean;
  @Input() isAlert: boolean;
  @Input() menuOptions: any;
  @Input() active: boolean;
  @Input() highlight: boolean;
  @Input() highlightText: string;
  @Input() filterBy: string;
  @Input() showOnly: string;
  @Input() persona: string;


  getHighlight(): boolean {
    if (this.persona == ROLE_NAMES.CUSTOMER)
      return this.value > 0 ? true : false
    else {
      return false;
    }
  }

  setAogColor(): boolean {
    return this.criticalStatId === CRITICAL_STATS_IDS.AOG && this.value > 0
  }

  setPastDueColor(): boolean {
    return this.criticalStatId === CRITICAL_STATS_IDS.PAST_DUE && this.value > 0
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule
  ],
  exports: [DashCardComponent],
  declarations: [DashCardComponent],
})
export class DashCardModule { }
