import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { AuthPayload } from '@app/auth/auth.models';
import { environment } from '@env/environment';
import { LoginAcceptance, TermsConditionsPrivacyAcceptanceComponent } from './terms-conditions-privacy-acceptance.component';
import { PERSONA_NAMES, ROLE_NAMES } from '@app/core/constants/persona-constants';
import { ApiService } from '@app/shared/services/api/api.service';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'aar-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginErrorMessage: string;
  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl({value
      : '', disabled: false}, [Validators.required]),
    password: new UntypedFormControl({value
      : '', disabled: false}, [Validators.required]),
  });
  redirect: string;
  passRecovered: boolean;
  isIframe = false;
  loggedInMicrosoft = false;
  loginMicrosoftButtonClicked = false;
  broadcastServiceSubscription$: Subscription;
  authServiceLoginSubscription$: Subscription;
  authServiceRecoverPasswordSubscription$: Subscription;
  dialogRefAfterClosedSubscription$: Subscription;
  repairCycleCompanyDetailsSubscription$: Subscription;
  contactUsLink: string = 'mailto:airvolution@airinmar.com'

  constructor(
    private authService: AuthService,
    private router: Router,    
    //Intentionally left commented as will be reinstated/refactored when
    //MSAL is updated.
    // private broadcastService: BroadcastService,
    // private msalService: MsalService,
    public dialog: MatDialog) {}


  ngOnInit() {

    this.isIframe = window !== window.parent && !window.opener;

    //Intentionally left commented as will be reinstated/refactored when
    //MSAL is updated.

    // this.onloginMicrosoftSuccess();

    // this.broadcastServiceSubscription$ = this.broadcastService.subscribe('msal:loginSuccess', () => {
    //   this.onloginMicrosoftSuccess();
    // });

    // this.msalService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     const errorMsg = `MSAL Redirect Error: ${authError.errorMessage}`;
    //     console.error(errorMsg);
    //     this.loginErrorMessage = errorMsg;
    //     return;
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.broadcastServiceSubscription$ !== undefined) {
      this.broadcastServiceSubscription$.unsubscribe();
    }
    if (this.authServiceLoginSubscription$ !== undefined) {
      this.authServiceLoginSubscription$.unsubscribe();
    }
    if (this.dialogRefAfterClosedSubscription$ !== undefined) {
      this.dialogRefAfterClosedSubscription$.unsubscribe();
    }
    if (this.repairCycleCompanyDetailsSubscription$ !== undefined) {
      this.repairCycleCompanyDetailsSubscription$.unsubscribe();
    }
  }

  getError(control: string): boolean {
    return !this.loginForm.controls[control].valid;
  }

  navigateTo(user: User): void {
    this.redirect = user.roleName === ROLE_NAMES.SUPPLIER ? '/rcm' : '/dashboard';
  }

  displayHomePage(payload: AuthPayload) {
      if (payload.user.personaName.toUpperCase() !== PERSONA_NAMES.WAREHOUSE) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/rcm']);
      }
  }

  private repairCycleCompanyDetails(authPayload:AuthPayload ):Observable<AuthPayload>{

    this.authService.setUserToken(authPayload);
    this.authService.setUser(authPayload.user);
    localStorage.setItem(environment.samlIdpLoginErrorStorageName, '');
    const repairCycleCompanyId = this.authService.getCompanyDetails();

    if(repairCycleCompanyId){
      if(repairCycleCompanyId.companyIdRef){
        if(repairCycleCompanyId.companyIdRef === authPayload.user.companyID){
          return of(authPayload);
        }
      }
    }
    return this.authService.getRepairCycleCompanyId().pipe(map((repairCycleCompany) => {
      authPayload.user.repairCycleCompanyId = repairCycleCompany.companyId;
      this.authService.setUserToken(authPayload);
      this.authService.setUser(authPayload.user);
      this.authService.setCompanyDetails(repairCycleCompany);
      return authPayload;
    }));
  }

  authenticate(): void {
    if ( this.loginForm.valid ) {
      this.loginErrorMessage = '';
      const controls = this.loginForm.controls;
      this.authServiceLoginSubscription$ = this.authService.login(controls['username'].value, controls['password'].value)
        .subscribe((data: User) => {
          const payload: AuthPayload = {
            user: data,
            token: data.token
          };
          const loginAcceptance: LoginAcceptance = {
            userId: data.id,
            hasAcceptedPrivacyPolicy: data.hasAcceptedPrivacyPolicy,
            hasAcceptedTermsAndConditions: data.hasAcceptedTermsAndConditions
          };

          if (!loginAcceptance.hasAcceptedTermsAndConditions || !loginAcceptance.hasAcceptedPrivacyPolicy ) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = loginAcceptance;
            dialogConfig.backdropClass = '';
            const dialogRef = this.dialog.open(TermsConditionsPrivacyAcceptanceComponent, dialogConfig);

            this.dialogRefAfterClosedSubscription$ = dialogRef.afterClosed().subscribe(
              popupData => {
                if (popupData) {
                  loginAcceptance.hasAcceptedPrivacyPolicy = popupData.hasAcceptedPrivacyPolicy;
                  loginAcceptance.hasAcceptedTermsAndConditions = popupData.hasAcceptedTermsAndConditions;
                }

                if (loginAcceptance.hasAcceptedPrivacyPolicy && loginAcceptance.hasAcceptedTermsAndConditions) {
                  this.repairCycleCompanyDetails(payload).subscribe((authPayload:AuthPayload)=>{
                    this.displayHomePage(authPayload);
                  });

                } else {
                  this.loginErrorMessage = 'Terms & Conditions and/or Privacy Notice have not been accepted.';
                  console.error(`Login account error: ${this.loginErrorMessage}`);
                  this.authService.processLogout();
                  window.location.reload();
                }
              }
            );
          } else {
              this.repairCycleCompanyDetailsSubscription$ = this.repairCycleCompanyDetails(payload).subscribe((authPayload:AuthPayload)=>{
              this.displayHomePage(authPayload);
            });
          }
        }, (error) => {

           this.loginErrorMessage = error.message.replace('GraphQL error: ', '');
           console.error(`Login account error: ${this.loginErrorMessage}`);
          // console.log('there was an error sending the query', error.message.replace('GraphQL error: ', ''));
         });
    }
  }

  recoverPassword(): void {
    const login = this.loginForm.controls.username;
    login.markAsTouched();
    if (!login.valid) { return; }

    this.authServiceRecoverPasswordSubscription$ = this.authService.recoverPassword(login.value)
      .subscribe(
        (email) => {
          this.passRecovered = true;
        },
        (error) => {
          this.loginErrorMessage = error.message.replace('GraphQL error: ', '');
          // console.log('there was an error sending the query', error.message.replace('GraphQL error: ', ''));
        }
      );

  }

  // loginMicrosoft(): void {
  //   const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  //   this.loginMicrosoftButtonClicked = true;

  //   if (isIE) {
  //     this.msalService.loginRedirect();
  //   } else {
  //     this.msalService.loginPopup();
  //   }
  // }

  // onloginMicrosoftSuccess(): void {
  //   const account = this.msalService.getAccount();
  //   this.loggedInMicrosoft = !!account;
  //   console.log(`Is Microsoft user logged in: ${this.loggedInMicrosoft}.`);
  //   if (this.loggedInMicrosoft && this.loginMicrosoftButtonClicked) {
  //     console.log(`User ${account.userName} has logged into a Microsoft account with tenant id=${account.idTokenClaims.tid}.`);

  //     this.authServiceLoginSubscription$ = this.authService.login(account.userName, `${account.idTokenClaims.tid}^A1R`)
  //     .subscribe((data: User) => {

  //       const payload: AuthPayload = {
  //         user: data,
  //         token: data.token
  //       };
  //       this.loginMicrosoftButtonClicked = false;
  //       this.authService.setUserToken(payload);
  //       this.authService.setUser(data);
  //       localStorage.setItem(environment.samlIdpLoginErrorStorageName, '');
  //       this.router.navigate(['/rcm']);
  //     }, (error) => {

  //        this.loginErrorMessage = error.message.replace('GraphQL error: ', '');
  //      });
  //   }
  // }

  getLoginErrorMessage(): string {
    const samlIdpLoginError = localStorage.getItem(environment.samlIdpLoginErrorStorageName);
    if (samlIdpLoginError === null || samlIdpLoginError.match(/^ *$/) !== null) {
        return this.loginErrorMessage;
      }

    return samlIdpLoginError;
  }
}
