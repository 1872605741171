import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RcmStepperService } from '@app/rcm/rcm-stepper/rcm-stepper.service';

@Component({
  selector: 'aar-rcm-stepper',
  templateUrl: './rcm-stepper.component.html',
  styleUrls: ['./rcm-stepper.component.scss']
})
export class RcmStepperComponent implements OnInit {
  @Input() steps: string[];
  @Input() currentSelected: number;
  @Input() hasError: boolean;
  @Input() xs: boolean;
  @Input() clickables: number[];
  @Output('changeStep') changeStep = new EventEmitter();

  constructor(private rcmStepperService: RcmStepperService ) { }

  ngOnInit() { }

  isClickable(index): boolean {
    return this.clickables && this.clickables.indexOf(index) > -1;
  }

  goToStep(index): void {
    if ( this.isClickable(index) ) {
      this.changeStep.emit(index);

      switch (index) {
        case 1:
          this.rcmStepperService.handleChange('SHIPPING OUTBOUND');
          break;
        case 2:
          this.rcmStepperService.handleChange('SUPPLIER RECEIPT');
          break;
        case 3:
          this.rcmStepperService.handleChange('QUOTE PROCESSING');
          break;
        case 4:
          this.rcmStepperService.handleChange('QUOTE APPROVAL');
          break;
        case 6:
          this.rcmStepperService.handleChange('SHIPPING INBOUND');
          break;
        default:
          this.rcmStepperService.handleChange(`STEP ${index}`);
          break;
      }
    }
  }
}
