import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: 'dateLocalisedPipe' })
export class DateLocalisedPipe implements PipeTransform {

    readonly locale:string;

    constructor(@Inject(LOCALE_ID) localeId) { 

        this.locale = localeId.toString();
    }

    transform(value: string | number | Date, useUTC: boolean = false): string {
        return value === null ?
            '' :
            useUTC ?
                moment.utc(value).format('MM/DD/yyyy') :
                formatDate(value, 'MM/dd/yyyy', this.locale);
    }
}