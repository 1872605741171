import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2C3ChartModule } from '@app/shared/directives';

@Component({
  selector: 'aar-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements OnInit {
  @Input('c3ChartConfig') c3ChartConfig: any;
  @Input('title') title: string;
  @Input('showLegend') showLegend: Boolean = true;
  @Input('showXAxisLine') showXAxisLine: Boolean = true;
  @Input('showYAxisLine') showYAxisLine: Boolean = true;

  constructor() {}

  ngOnInit() {}

  getValue(key): string {
    const value = this.c3ChartConfig.data.columns.find(columns => columns[0] === key);

    if (value.length > 2) {
      let multiple = ``;

      for (let i = 1; i < value.length; i++) {
        multiple += `${value[i]}${i !== value.length - 1 ? ', ' : ''}`;
      }

      return multiple;
    }
    return value[1];
  }
}

@NgModule({
  imports: [
    CommonModule,
    Ng2C3ChartModule
  ],
  exports: [ ChartCardComponent ],
  declarations: [ ChartCardComponent ]
})

export class ChartCardModule {}
