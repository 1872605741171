<div class="card-content card-element">

  <ng-template [ngIf]="isEmpty" [ngIfElse]="content">
    <aar-no-activity [message]="noActivityMessage"></aar-no-activity>
  </ng-template>

  <ng-template #content>
    <div tyle="width:100%">

      <ng-template [ngIf]="shippings && shippings.outbound">
        <h4>Outbound Shipping Details</h4>
        <div style="margin-left: 50px" *ngIf="shippings.outbound.length == 0">No Outbound Shipping Details found.</div>
        <div *ngFor="let shipping of shippings.outbound; let itemIndex=index;">
          <mat-accordion hideToggle="true">
            <mat-expansion-panel style="margin-bottom:10px" #obShip>
              <mat-expansion-panel-header #obHeaderShip (click)="obHeaderShip._toggle()"
                style="background-color: rgb(39,40,45); width:90%; margin-left:auto;margin-right:auto; margin: bottom 10px;cursor: auto;">
                <mat-panel-title>
                  <div class="aar-panel-title font-regular" style="width:100%;" *ngIf="obShip.expanded">
                    <aar-rcm-shipping-header-title [shippingStatus]="shipping.shippingStatus"
                      [directionDescription]="shipping.directionDescription" [shippingStage]="shipping.shippingStage" ></aar-rcm-shipping-header-title>
                  </div>

                  <div class="aar-panel-title font-regular" style="width:100%;" *ngIf="!obShip.expanded">
                    <aar-rcm-shipping-header [id]="shipping.id" [shippingCarrier]="shipping?.carrierId"
                      [trackingLink]="shipping.trackingLink" [trackingNumber]="shipping.trackingNumber"
                      [shippingStatus]="shipping.shippingStatus" [dateShipped]="shipping.dateShipped"
                      [dateDelivered]="shipping.dateDelivered" [sequence]="shipping.sequence"
                      [directionId]="shipping.directionId" [shippingReference]="shipping.shippingReference"
                      [address]="shipping.address" [shippingId]="shipping.shippingId"
                      [isPreventFeedUpdate]='shipping.isPreventFeedUpdate'
                      [repairOrderNumber]="repairOrder.repairOrderNumber"
                      [repairOrderLineNumber]="repairOrder.rOLineNumber"
                      [directionDescription]="shipping.directionDescription" [directShip]="shipping.directShip"
                      [shippingStage]="shipping.shippingStage" [panel]="obShip"></aar-rcm-shipping-header>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="margin-bottom:5px" style="min-height: 70px; background-color: rgb(39,40,45)">
                <aar-rcm-shipping-block [id]="shipping.id" [shippingCarrier]="shipping?.carrierId"
                  [trackingLink]="shipping.trackingLink" [trackingNumber]="shipping.trackingNumber"
                  [shippingStatus]="shipping.shippingStatus" [dateShipped]="shipping.dateShipped"
                  [dateDelivered]="shipping.dateDelivered" [sequence]="shipping.sequence"
                  [directionId]="shipping.directionId" [shippingReference]="shipping.shippingReference"
                  [address]="shipping.address" [shippingId]="shipping.shippingId"
                  [isPreventFeedUpdate]='shipping.isPreventFeedUpdate'
                  [repairOrderNumber]="repairOrder.repairOrderNumber" [repairOrderLineNumber]="repairOrder.rOLineNumber"
                  [directionDescription]="shipping.directionDescription" [panel]="obShip">
                </aar-rcm-shipping-block>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

      </ng-template>

      <ng-template [ngIf]="shippings && shippings.inbound">
        <h4>Inbound Shipping Details</h4>
        <div style="margin-left: 50px" *ngIf="shippings.inbound.length == 0">No Inbound Shipping Details found.</div>
        <div *ngFor="let shipping of shippings.inbound; let itemIndex=index;">
          <mat-accordion hideToggle="true">
            <mat-expansion-panel style="margin-bottom:10px" #ibShip>
              <mat-expansion-panel-header #ibHeaderShip (click)="ibHeaderShip._toggle()"
                style="background-color: rgb(39,40,45); width:90%; margin-left:auto;margin-right:auto; margin: bottom 10px;cursor: auto;">
                <mat-panel-title>
                  <div class="aar-panel-title font-regular" style="width:100%;" *ngIf="ibShip.expanded">
                    <aar-rcm-shipping-header-title [shippingStatus]="shipping.shippingStatus"
                      [directionDescription]="shipping.directionDescription"[shippingStage]="shipping.shippingStage"></aar-rcm-shipping-header-title>
                  </div>
                  <div class="aar-panel-title font-regular" style="width:100%;" *ngIf="!ibShip.expanded">
                    <aar-rcm-shipping-header [id]="shipping.id" [shippingCarrier]="shipping?.carrierId"
                      [trackingLink]="shipping.trackingLink" [trackingNumber]="shipping.trackingNumber"
                      [shippingStatus]="shipping.shippingStatus" [dateShipped]="shipping.dateShipped"
                      [dateDelivered]="shipping.dateDelivered" [sequence]="shipping.sequence"
                      [directionId]="shipping.directionId" [shippingReference]="shipping.shippingReference"
                      [address]="shipping.address" [shippingId]="shipping.shippingId"
                      [isPreventFeedUpdate]='shipping.isPreventFeedUpdate'
                      [repairOrderNumber]="repairOrder.repairOrderNumber"
                      [repairOrderLineNumber]="repairOrder.rOLineNumber"
                      [directionDescription]="shipping.directionDescription" [directShip]="shipping.directShip"
                      [shippingStage]="shipping.shippingStage" [panel]="ibShip"></aar-rcm-shipping-header>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="margin-bottom:5px" style="min-height: 70px; background-color: rgb(39,40,45)">
                <aar-rcm-shipping-block [id]="shipping.id" [shippingCarrier]="shipping?.carrierId"
                  [trackingLink]="shipping.trackingLink" [trackingNumber]="shipping.trackingNumber"
                  [shippingStatus]="shipping.shippingStatus" [dateShipped]="shipping.dateShipped"
                  [dateDelivered]="shipping.dateDelivered" [sequence]="shipping.sequence"
                  [directionId]="shipping.directionId" [shippingReference]="shipping.shippingReference"
                  [address]="shipping.address" [shippingId]="shipping.shippingId"
                  [isPreventFeedUpdate]='shipping.isPreventFeedUpdate'
                  [repairOrderNumber]="repairOrder.repairOrderNumber" [repairOrderLineNumber]="repairOrder.rOLineNumber"
                  [directionDescription]="shipping.directionDescription"
                  [panel]="ibShip">
                </aar-rcm-shipping-block>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-template>
    </div>
  </ng-template>
  <aar-loading-local *ngIf="loading" [error]="loadingError"></aar-loading-local>
  <div class="form-footer">
    <div class="form-footer-elem">
      <button mat-flat-button color="primary" (click)="showAddShippingDetails()" *ngIf="showControls">Add Shipping
        Detail</button>
    </div>
  </div>
</div>