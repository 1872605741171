import { getCurrencySymbol, getLocaleCurrencySymbol } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DecimalLocalisedPipe } from "../decimal-pipe/decimal-localised.pipe";

@Pipe({ name: 'currencyLocalisedPipe' })
export class CurrencyLocalisedPipe implements PipeTransform {

    readonly locale:string;
    
    constructor(@Inject(LOCALE_ID) localeId, private decimalLocalisedPipe : DecimalLocalisedPipe) { 

        this.locale = localeId.toString();
    }

    transform(value: number, overrideLocaleCurrency : string=''): string {

      if(!value || !Number(value)){
        value = 0;
      }
      if(overrideLocaleCurrency){
        return getCurrencySymbol(overrideLocaleCurrency, "narrow") + this.decimalLocalisedPipe.transform(value);
      }else{
        return getLocaleCurrencySymbol(this.locale) + this.decimalLocalisedPipe.transform(value);
      }
    }
}