<div class="card__content card__element">
  <div class="aar-card">
    <h3>
    <div (click)="backClick()" class="return-link">
      <mat-icon style="font-size: 44px; width: 44px; height: 44px; margin-left: 1rem;">chevron_left</mat-icon>
      <div>RETURN TO ALL DOCUMENTS</div>
    </div>
    </h3>
    <span style="margin-right: 10px">
    <button mat-flat-button color="primary" (click)="download(document)">Download</button>
    </span>
    <span>
    <button mat-flat-button class="delete-button" (click)="delete(document)" *ngIf="canDelete">Delete</button>
    </span>
    
    <div style="margin-top: 20px;">
    <img [src]="document.document"/>
  </div>

    <div class="form-group">
      <div class="form-control_1">
        <label for="">DOCUMENT TITLE</label>
        <span class="inputvalue">{{document.name}}</span>
      </div>
      <div class="form-control_1">
        <label for="">UPLOADED BY</label>
        <button mat-flat-button class="">{{document.uploader}}</button>
      </div>
      <div class="form-control_1">
        <label for="">UPLOADED</label>
        <span class="inputvalue">{{document.createdAt | timezoneFromUtcLocalizedPipe:'12'}}</span>
        <span class="inputvalue"></span>
      </div>

      <div class="form-control_1">
        <label for="">COMMENTS</label>
        <span class="inputvalue">{{document.comments}}</span>
      </div>
    </div>
    <aar-loading-local *ngIf="loading" [error]="loadingError"></aar-loading-local>
  </div>
</div>
