<div role="alertdialog" class="rcm-upload-document">
    <h2 class="rcm-upload-document-title">Message</h2>
    <div class="rcm-upload-document-body">
      <div class="rcm-upload-document-body-form">
  
        <div class="rcm-upload-document-body-form-row">
          <div class="mr">
            <textarea readonly>{{data.message}}</textarea>
          </div>
          
        </div>
       
      </div>
    </div>
  
    <div class="rcm-upload-document-footer">
   
      <button *ngIf="!data.isRead" mat-flat-button color="primary" class="aar-button" (click)="markRead()">Mark Read</button>
      <button *ngIf="data.isRead" mat-flat-button color="primary" class="aar-button" (click)="markUnRead()">Mark UnRead</button>
      <button mat-button color="primary" (click)="onClose()">Cancel</button>
    </div>
  </div>
  