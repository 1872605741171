<div class ='aar-domo-report aar-page'>
    <div class='content terms-container'>
      <div layout="column" layout-align="center center" layout-fill="layout-fill">
        <p><strong>Terms and Conditions of Use</strong></p>
<p>Welcome to the Airvolution Software of AAR SUPPLY CHAIN, INC. (&ldquo;<strong>AAR</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;), its subsidiaries and affiliate companies have their own legal existence and independent legal status.</p>
<p>Please carefully review these Terms and Conditions of Use and our Privacy Notice (&ldquo;<strong>Terms of Use</strong>&rdquo; or &ldquo;<strong>Terms</strong>&rdquo;). These Terms of Use govern your use, access, and browsing of the websites and online presences that constitutes the Airvolution Software, including any mobile site, blog, app, email, social media, feature, software, product, service, information, or content made available, displayed, or offered by or through AAR (collectively, &ldquo;<strong>Software</strong>&rdquo;). By using this Software, you acknowledge that you understood, accepted, and agreed to be bound by these Terms of Use, whether or not you have read them and whether you are a casual visitor, or licensee. If you do not agree with these Terms, you are not permitted to use or access this Software. The Terms remain subject to the agreement between AAR and you, and to the extent of any conflict, the terms of such agreement take precedence over these Terms. If you have any questions about these Terms, please contact AAR at <a href="mailto:airvolutionsupport@aarcorp.com">airvolutionsupport@aarcorp.com</a>.</p>
<h1>I. Description of Services</h1>
<p>Please feel free to browse the Software, as appropriate. Your access to and use of the information on the Software is subject to these Terms and all applicable laws, including International Traffic in Arms Regulations (ITAR) and other applicable federal regulations. By accessing this Software, you accept, without limitation or qualification, these Terms and acknowledge that it supersedes any other agreement between you and AAR with respect to access and use of this Software.</p>
<h1>II. Changes to the Terms</h1>
<p>We will notify users of any changes to these Terms. Notification will either be made by way of an e-mail or by posting of the changes on the Software. Changes to these Terms will be effective 30 days following notice. Your continued use of the Software after any change has become effective will constitute your acceptance of those changes.</p>
<h1>III. Registration</h1>
<p>We will require you to open an account to access this Software.</p>
<p>By utilizing this Software, you agree to provide AAR with accurate, truthful, complete, and updated information. Failure to do so shall constitute a breach of these Terms of Use, which may result in suspension or termination of your account or your denial from such events or features. We, in our sole discretion, reserve the right to refuse access to the Software. </p>
<p>When using or accessing this Software, you may be asked to provide a username and password. You agree that we may store and use any registration data, including your username and password, you provide for use in maintaining your account. We may require you to periodically change your password.</p>
<p>You agree to use your best efforts to maintain the security and confidentiality of your user name, password, and account (collectively, a &ldquo;<strong>User ID&rdquo;</strong>). You are fully responsible for all activities that occur under your User ID. You may not use the User ID of someone else at any time. You may not sell or transfer your account, or otherwise allow any third party to use your account for any purpose. You agree to notify AAR about any unauthorized use of your User ID or any breach of security related to your use of the Software. You should use particular caution when accessing your account from a public or shared computer or network. </p>
<p>You further agree that AAR shall not be liable for any loss or damage arising out of, or related to, your use of your User ID by you or by any other party, either with or without your knowledge. You may be held liable for any losses incurred by AAR, including its subsidiaries, entities owned, related to or controlled by AAR, its employees, staff, officers, directors, consultants, agents, suppliers, contractors, and representatives, due to someone else's use of your User ID.</p>
<h1>IV. User Content</h1>
<p>Certain features of this Software may allow you to display on this Software materials created by you or others that are unaffiliated with AAR (&ldquo;<strong>User Content</strong>&rdquo;). You understand and agree that you are solely responsible for any material that you, directly or indirectly, submit, post, upload, download, display, transmit, distribute, link, promote, advertise, share with others, or otherwise publish on or through this Software.</p>
<p>By providing User Content to this Software, you grant AAR a nonexclusive, worldwide, perpetual, irrevocable, royalty-free, fully-paid, sublicenseable, and freely transferable right and license to copy, reproduce, publish, display, distribute, broadcast, archive, perform, modify, adapt, create derivative works of, and otherwise use the User Content, in whole or in part, in connection with the operation of the Software.</p>
<p>You understand and agree that we may, but are not obligated to, delete, edit, refuse to post, remove, or block access to any Software content or User Content, in our reasonable discretion, at any time, with or without notice, if we believe the User Content violates these Terms of Use.</p>
<p>You are solely responsible for the User Content that you provide, the consequences of posting your User Content, and your reliance on any User Content found on our Software. AAR and any party involved in creating, producing, delivering, or maintaining this Software are not responsible for the consequences of any User Content. We expressly disclaim responsibility for and liabilities resulting from any information or communications from and between users of this Software.</p>
<h1>V. Third Party Content</h1>
<p>This Software may contain links or references to other Internet sites or products or services of third parties over whom neither we nor our affiliates have any control (&ldquo;<strong>Third Party Content</strong>&rdquo;).</p>
<p>AAR does not verify, and is not responsible for, the accuracy, authenticity, appropriateness, completeness, reliability, safety, or privacy practices of Third Party Content. AAR makes no representation concerning, and is under no obligation to prevent, the downloading, distribution, linking to, or re-posting of Third Party Content. Inclusion of, linking to, or permitting the use of any Third Party Content does not imply our approval, sponsorship, or endorsement thereof. AAR does not make any representations or warranties as to the security of any information (such as confidential and other sensitive information) you might give on any third party Software.</p>
<p>AAR will not in any manner be liable for or responsible for any loss or damages that may arise from your dealings or interaction with third parties, including your use or reliance on any Third Party Content found on our Software. You expressly waive any and all claims you may have against AAR and release AAR from any and all liability arising from or related to the Third Party Content, whether or not authorized by AAR.</p>
<p>If you decide to leave this Software and access or use third party sites, you do so at your own risk and you should be aware that our Terms of Use no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any third party websites to which you navigate from this Software.</p>
<h1>VI. Prohibited Use of the Software</h1>
<p>You agree to not use this Software in the following ways:</p>
<p>(1) for any purpose that is unlawful or prohibited by these Terms;</p>
<p>(2) to conduct yourself in any way that may be construed as threatening, harmful, abusive, harassing, stalking, defamatory, libelous, vulgar, profane, obscene, offensive, objectionable, pornographic, obscene, unlawful, illegal, fraudulent, tortuous, giving rise to civil or criminal liability, invasive or in violation of another&rsquo;s right of privacy or publicity, hateful, bigoted, racially or ethnically offensive, in violation of an applicable local, national or international law, including but not limited to laws related to copyrights, trademarks, patents, or trade secrets, or otherwise objectionable as determined by us in our sole discretion;</p>
<p>(3) to promote information that is known to be, inaccurate, misleading, or false;</p>
<p>(4) to advertise or otherwise solicit funds, goods, or services, such as through contests, sweepstakes, barter, pyramid schemes, or other form of unauthorized advertising or commerce, without our prior written consent;</p>
<p>(5) to express or imply that any statements you make are endorsed by AAR without our specific prior written consent;</p>
<p>(6) to send unsolicited email or other communications, including, without limitation, junk mail, spam, and chain letters;</p>
<p>(7) in a manner that could damage, disable, overburden, or impair our (or our third party providers&rsquo;) server or the networks connected to any our server;</p>
<p>(8) to collect or harvest any information about other users or to attempt to gain unauthorized access to accounts, computer systems, or networks associated with any AAR&rsquo;s servers, data not intended for you, or otherwise violate any security features of this Software, through hacking, password mining, or any other means;</p>
<p>(9) to distribute or facilitate the distribution of viruses, corrupted data, trojan horses, bots, keystroke loggers, worms, time bombs, cancelbots or other computer programming routines or computer code that are intended to and/or actually damage, detrimentally interfere with, surreptitiously intercept or mine, obtain unauthorized access to, scrape or expropriate any system, software, data or personal information; and</p>
<p>(10) to attempt to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to human-perceivable form any of the source code used by AAR in providing this Software; and</p>
<p>(11) adversely affect the availability of Software resources to other users.</p>
<p>Any violation of system or network security may subject you to civil and/or criminal liability.</p>
<h1>VII. Feedback Submission</h1>
<p>We welcome your feedback regarding AAR and this Software. However, any comments, notes, suggestions, review, ideas, suggestions, concepts, services, feature, program, product, software, application, tool, or other communications (&ldquo;<strong>Feedback</strong>&rdquo;) sent to AAR shall be and remain the exclusive property of AAR. Your submission of any such Feedback shall constitute an assignment to AAR of all worldwide rights, titles, and interests in all copyrights and other intellectual property rights in the Feedback. AAR will be entitled to use, reproduce, disclose, publish, and distribute any Feedback material you submit for any purpose whatsoever, without restriction and without compensating you in any way. AAR will be under no obligation to keep your Feedback confidential or to otherwise protect your Feedback.</p>
<p>For this reason, we ask that you not send us any comment that you do not wish to assign to us, including any confidential or proprietary information or any original creative materials such as stories, product ideas, computer code, or artwork.</p>
<h1>VIII. Limitations of Liability</h1>
<p>You acknowledge that your use of this Software is at your sole risk, and that we will not be held liable in any way. Under no circumstance and under no legal or equitable theory, whether in tort, contract, strict liability, or otherwise, shall AAR or any of its employees, OWNERS, SHAREHOLDERS, staff, directors, officers, consultants, agents, suppliers, contractors, or representatives be liable to you or any other person for any direct, indirect, general, special, incidental, compensatory, consequential, or exemplary damages, injury, or losses of any nature arising out of or in connection with the use of this Software. This includes, but is not limited to, bodily injury, emotional distress, damages for loss of profits, goodwill, use, data, personal property, or other intangible losses, accuracy of results, computer failure or malfunction, even if an authorized representative of AAR has been advised of, or should have known, the possibility of such damages, resulting from (i) the use of, or the inability to use, this Software, (II) your communications or dealings with other users of this Software or persons you meet through this Software (Iii) unauthorized access to or alteration of your data transmissions, (iv) statements, representations, or conduct of any third party using this Software, or (v) any other matter relating to this Software. </p>
<h1>IX. Disclaimer of Warranty</h1>
<p>This Software and Service are provided to you on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. We disclaim all warranties and representations of any kind, either express or implied, including but not limited to warranties of merchantability, title, fitness for a particular purpose, custom, trade, quiet enjoyment, system integration, freedom from computer virus, and non-infringement of rights. We make no warranty or representation that any part of the Software will be uninterrupted, error-free, virus-free, safe to download, timely, secure, accurate, complete, adequate, useful, reliable, physically safe, or of any quality or that the defects will be corrected. </p>
<h1>X. Indemnification</h1>
<p>You agree to indemnify, defend, and hold harmless AAR and any other party involved in creating, producing, delivering, or maintaining this Software and each of their respective owners, representatives, subsidiaries, affiliates, officers, directors, employees, agents, shareholders, contractors, advertisers, partners, related parties, licensors, licensees, suppliers, and co-branders from and against all claims, demands, damages, costs, losses, and expenses, including attorneys&rsquo; fees, arising out of or related to (i) your use of this Software, (ii) your breach of these Terms of Use, or (iii) any claim that User Content or third party content, or any reliance thereof, caused damage to your or a third party.</p>
<h1>XI. Use License</h1>
<p>Permission is granted to temporarily download one copy of the content (information or software) on this Software for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: (1) modify or copy the content; (2) remove any copyright or other proprietary notations from the content; (3) use the content for any commercial purpose other than what is expressly authorized by AAR; (4) publish, publicly display, or otherwise distribute the content, including via the Internet, any social media platform or network, or any intranet or extranet side, for any commercial or non-commercial purpose; (5) attempt to decompile or reverse engineer any software contained on this Software; (6) use any data mining, robots, scraping, or similar data gathering or extraction methods to obtain the content; (7) transfer the content to another person or &ldquo;mirror&rdquo; the materials on any other server. No right, title, or interest in any downloaded materials is transferred to you as a result of any such downloading.</p>
<p> This license shall automatically terminate if you violate any of these restrictions. We reserve the right to suspend or deny at any time, in our sole discretion, your access to all or any portion of this Software.</p>
<h1>XII. Intellectual Property</h1>
<p>Unless otherwise explicitly specified, all materials that are included in or are otherwise a part of the Software are copyrights, patents, trademarks, trade dress, and/or other intellectual property owned, controlled, or licensed by AAR, its owners, or by third parties who have licensed their materials to AAR. You may not reproduce or use AAR&rsquo;s trademarks or trade dress without the prior written permission of AAR. Nothing contained on this Software should be understood as granting you a license to use any of the trademarks, trade names, trade dress, service marks, taglines, or logos owned or licensed by AAR or by any third party.</p>
<p>If you believe that your intellectual property has been used in any way that could be considered a copyright infringement or a violation of your intellectual property rights, please contact us in writing at AAR CORP., Attn: General Counsel, 1100 N. Wood Dale Rd, Wood Dale, IL 60161, U.S.A. You agree that, in the event we receive a written demand from a third party alleging that any User Content infringes upon, dilutes, tarnishes, or otherwise violates its trademark or trade secret rights, we may in our sole discretion, remove or disable access to such User Content. In an effort to protect the rights of intellectual property owners, we maintain a policy for the termination, in appropriate circumstances, of user accounts of this Software who repeatedly infringe the copyright and other intellectual property rights of others.</p>
<h1>XIII. Reservation of Rights</h1>
<p>All rights not expressly granted herein are reserved to AAR and its licensors. The use of our rights and property requires our prior written consent. By making this Software available to you, we are not providing you with any implied or express licenses or rights, and you will have no rights to make any commercial use of this Software without prior written consent.</p>
<h1>XIV. Termination and Investigation</h1>
<p>AAR reserves the right to suspend or terminate your use of this Software. You agree that AAR or its representatives may access your account and records on a case-by-case basis to investigate complaints or allegations of abuse, infringement of third party rights, or other unauthorized uses of this Software. AAR does not intend to disclose the existence or occurrence of such an investigation unless required by law, but AAR reserves the right to suspend or terminate your account or your access to this Software immediately, with or without notice to you, and without liability to you, if AAR believes that you have violated any of the Terms of Use, furnished AAR with false or misleading information, or interfered with the use of this Software by others.</p>
<p>YOU WAIVE AND HOLD HARMLESS AAR FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY AAR DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER AAR OR LAW ENFORCEMENT AUTHORITIES.</p>
<h1>XV. Accuracy and Completeness</h1>
<p>Although every effort is made to ensure the accuracy of published information on or through this Software, you understand that the materials appearing on this Software could inadvertently contain technical, typographical, or photographic errors or may expose you to content that you find undesirable, objectionable, or offensive. Neither AAR nor any author or supplier contributing to this Software is responsible to you in any way for any content displayed on this Software, nor for any error, revision, or omission in any information provided or the results obtained from your use of such information.</p>
<p>AAR may make changes to the materials contained on its Software at any time without notice. While we will make every effort to keep content on this Software current, we do not guarantee it is the most up to date information available. For up-to-the-minute information on class changes, contact information, and any other information regarding AAR, its operations, programs, and offers, please contact AAR at <a href="mailto:airvolutionsupport@aarcorp.com">airvolutionsupport@aarcorp.com</a>.</p>
<p>Certain portions of the Software may be subject to additional terms and conditions specified by AAR from time to time. Your use of the Software is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</p>
<h1>XVI. Governing Law</h1>
<p>These Terms shall be governed by and construed under the laws of the State of Illinois, without regard for conflicts of law principles. You agree that jurisdiction over and venue in any legal proceeding directly or indirectly arising out of or related to this Software or the use or access thereof shall be in the state or federal courts located in Illinois. The UN Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act are each expressly disclaimed.</p>
<h1>XVII. Severability/Waiver</h1>
<p>If any provision of these Terms of Use is found to be invalid, void, unlawful, or for any reason unenforceable by any court having competent jurisdiction, then the provision shall be deemed severable from the Terms and shall not affect the validity and enforceability of the remaining provisions, which shall remain in full force and effect. The section headings used herein are for convenience only and shall not affect the interpretation of these Terms of Use.</p>
<p>The failure of either party to assert any right under these Terms of Use will not be considered to be a waiver of that party&rsquo;s right, and the said right will remain in full force and effect. No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such terms and condition or any other term or condition. Neither the course of conduct between the parties nor trade practice shall act to modify the Terms.</p>
<h1>XVIII. Statute of Limitations</h1>
<p>You agree that, regardless of any statute or law to the contrary, any claim or cause of action that you may wish to assert related in any manner to this Software or its services must be filed within one (1) year after such claim or cause arose, or the said claim or cause will be forever barred, without regard to any contrary legislation.</p>
<h1>XIX. Assignment</h1>
<p>We may assign our rights and obligations under these Terms of Use without notice to one or more third parties. In this event, AAR will be relieved of any further obligation. You may not assign these Terms without AAR&rsquo;s prior written consent.</p>
<h1>XX. Privacy Notice</h1>
<p>Please refer to our Privacy Notice, which is incorporated herein by reference. Your assent to these Terms of Use includes your assent to our Privacy Notice.</p>
<h1>XXI. Last Modified Date of these Terms of Use</h1>
<p>June 29, 2022</p>
      </div> 
    </div>
  </div>