<div *ngIf="dialogData.dialogTitle !== 'ERROR' " role="alertdialog" class="common-dialog">
  <h2>{{data.dialogTitle}}</h2>
  <div>
    {{data.dialogMessage}}
  </div>

  <div class="dialog-buttons-list" *ngIf="data.secondarybuttonText !== ''">
    <button mat-flat-button color="primary" class="aar-button" (click)="confirmDialog()">{{data.buttonText}}</button>
    <button mat-flat-button color="primary" class="aar-button" (click)="onClose()">{{data.secondarybuttonText}}</button>
  </div>
</div>

<div  *ngIf="dialogData.dialogTitle == 'ERROR' " role="alertdialog" class="warn-dialog">

  <div class="warn-mess">
    <h5> <span [ngClass]="dialogTitle === 'ERROR' ? 'dialog-width-error' : 'dialog-width-normal'">{{data.dialogTitle}} </span>  {{data.dialogMessage}} </h5>   
  </div>
   <div class="dialog-buttons-list" >
    <button mat-flat-button color="primary" class="aar-button" (click)="onClose()">{{data.secondarybuttonText}}</button>
  </div>
</div>