<div class="rcm-compose-email">
  <h2 class="rcm-compose-email-title">Compose Email</h2>

  <form [formGroup]="composeForm" (ngSubmit)="submitEmail()">
    <div class="rcm-compose-email-body">
      <div class="rcm-compose-email-body-form">

        <div class="rcm-compose-email-body-form-row">
          <mat-form-field class="mr">
            <mat-select placeholder="Template" formControlName="templateId" (selectionChange)="selectTemplate($event.value)">
              <mat-option>Select Template</mat-option>
              <mat-option class="font-regular" *ngFor="let template of templates" [value]="template.templateID">
                {{template.templateName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="mr">
            <mat-select placeholder="Tags" formControlName="tagId" [(ngModel)]="email.tagId">
              <mat-option>Select Tags</mat-option>
              <mat-option class="font-regular" *ngFor="let tag of tags" [value]="tag.iD">
                {{tag.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="mr">
            <mat-select placeholder="Category" formControlName="categoryId" [(ngModel)]="email.categoryId">
              <mat-option>Select Category</mat-option>
              <mat-option class="font-regular" *ngFor="let category of categories" [value]="category.categoryID">
                {{category.categoryName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="rcm-compose-email-body-form-row">
          <mat-form-field class="mid">
            <input matInput placeholder="From" type="email" formControlName="fromEmail" />
          </mat-form-field>

          <mat-form-field class="mid">
            <input matInput placeholder="CC" formControlName="ccEmail" [(ngModel)]="email.ccEmail" />
          </mat-form-field>
        </div>

        <div class="rcm-compose-email-body-form-row">
          <mat-form-field class="mid">
            <input matInput placeholder="To" formControlName="toEmail" [(ngModel)]="email.toEmail" />
          </mat-form-field>

          <mat-form-field class="mid">
            <input matInput placeholder="BCC" formControlName="bccEmail" [(ngModel)]="email.bccEmail" />
          </mat-form-field>
        </div>

        <div class="rcm-compose-email-body-form-row">
          <mat-form-field class="large">
            <input matInput placeholder="Subject" formControlName="subject"  [(ngModel)]="email.subject"/>
          </mat-form-field>
        </div>

        <div class="rcm-compose-email-body-form-row">
          <mat-form-field class="large">
            <textarea matInput placeholder="Enter Message" formControlName="body"  [(ngModel)]="email.body"></textarea>
          </mat-form-field>
        </div>

       <!--<div class="rcm-compose-email-body-form-row" ng-show="false">
          <mat-form-field>
            <mat-select placeholder="Link Document">
              <mat-option value="option" disabled>Select Document</mat-option>
            </mat-select>
          </mat-form-field>
        </div>--> 

      </div>
    </div>
    <aar-loading-local *ngIf="loading" [error]="loadingError"></aar-loading-local>
    <div class="rcm-compose-email-footer">
      <button mat-flat-button color="primary" class="aar-button" type="submit">Send</button>
      <button mat-button color="primary" (click)="onClose()">Cancel</button>
    </div>

  </form>
</div>
