import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RcmStepType } from '@app/core/constants/enums';
import { Subscription } from 'rxjs';
import { RcmStatusStepperService } from '../../rcm-status-stepper.service';

@Component({
  selector: 'aar-rcm-status-stepper',
  templateUrl: './rcm-status-stepper.component.html',
  styleUrls: ['./rcm-status-stepper.component.scss']
})
export class RcmStatusStepperComponent  implements OnInit, OnDestroy {
  
  @Input() hasError: boolean;
  @Input() xs: boolean;
  @Input() clickables: number[];
  @Input() type: RcmStepType;

  constructor(private rcmStatusStepperService: RcmStatusStepperService) { }

  private rcmLogisticStepChangeSubscription: Subscription;
  private rcmQuoteStatusStepChangeSubscription: Subscription;

  statusSteps:string[]=[];
  currentStatusStepSelected:number;

  ngOnInit() {

    if (this.type === RcmStepType.Logistic) {
      this.rcmStatusStepperService.getLogisticStepNames().subscribe((logisticStepNames) => {
        this.statusSteps = logisticStepNames;
      });
    }

    if (this.type === RcmStepType.QuoteStatus) {
      this.rcmStatusStepperService.getQuoteStatusStepNames().subscribe((quoteStepNames) => {
        this.statusSteps = quoteStepNames;
      });
    }

    this.rcmLogisticStepChangeSubscription = this.rcmStatusStepperService.rcmLogisticStepChanged().subscribe((step) => {
      if (this.type === RcmStepType.Logistic) {
        this.currentStatusStepSelected = step - 1;
      }

    });

    this.rcmQuoteStatusStepChangeSubscription = this.rcmStatusStepperService.rcmQuoteStepChanged().subscribe((step) => {
      if (this.type === RcmStepType.QuoteStatus) {
        this.currentStatusStepSelected = step - 1;
      }
    });
  }

  ngOnDestroy() {
    this.rcmLogisticStepChangeSubscription.unsubscribe();
    this.rcmQuoteStatusStepChangeSubscription.unsubscribe();
  }
}
