//https://stackoverflow.com/questions/51709699/document-viewer-to-view-all-types-of-files-in-angular-4

import { Component, OnInit, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'apollo-link';
import { ApiService } from '@app/shared/services/api/api.service';
import {LoadingComponent} from '@app/shared/components/loading/loading.component';
import { SharedService } from '@app/shared/services/shared.service';



@Component({
  selector: 'aar-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})


export class NotificationDialog  {

  public loading = false;
  public  isRead = false;

  public static openDialog(dialog: MatDialog, isRead: boolean, message: string) {


    const dialogRef = dialog.open(NotificationDialog, {
      data: { message: message, isRead : isRead }
    });

    return dialogRef.afterClosed();

  }


  constructor(public dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
    ) {

  }
  markRead() {
     this.isRead = true;
     this.dialogRef.close(this.isRead);
  }
  markUnRead() {
    this.isRead = false;
    this.dialogRef.close(this.isRead);
  }
  onClose() {

    this.dialogRef.close(-1);
  }



}
