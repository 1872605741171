<form [formGroup]="quoteForm" (ngSubmit)="submitCost($event)" *ngIf="comparisonModel">
  <div class="aar-sliding-section">
    <h4>Cost Entry</h4>

    <div class="aar-sliding-form-group clearfix">
      <div class="aar-sliding-form-control3" *ngIf="!isFieldHidden('repairCategory')">
        <label>Unit Category</label>
        <span class="aar-sliding-input-value">{{ comparisonModel.repairCategory ? comparisonModel.repairCategory.value :
          ''}}</span>
      </div>
      <div class="aar-sliding-form-control3" *ngIf="!isFieldHidden('proposedWorkscopeId')">
        <label>Workscope Proposed</label>
        <span class="aar-sliding-input-value">{{displayWorkscope}}</span>
      </div>
    </div>
  </div>

  <div class="aar-sliding-form-bdr">
    <div class="aar-sliding-section aar-sliding-section">
      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0">
          <label>Currency</label>

          <mat-form-field floatLabel="never" class="mat-form-field-select">
            <mat-select formControlName="currency">
              <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                {{currency.name}}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('currency')">{{ getErrorMessage('currency') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0">
          <label>Supplier Cost</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <div class="currencySymbol">
              {{getCurrencySymbology('currency')}}
            </div>
            <input type="number" min="0" matInput formControlName="unitCost" style="color: #ffff;" class="currencySymbol">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('unitCost')">{{ getErrorMessage('unitCost') }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="aar-sliding-section">
    <div class="aar-sliding-form-group">
      <div class="aar-sliding-form-control">
        <label>RAI <span>Optional</span></label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
          <input type="number" min="0" matInput formControlName="rai" style="color: #fff;">
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control">
        <label>SOS <span>optional</span></label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
          <input type="number" min="0" matInput formControlName="sos" style="color: #fff;">
        </mat-form-field>
      </div>

    </div>

    <div class="aar-sliding-form-group">
      <div class="aar-sliding-form-control">
        <label>Total Supplier Cost</label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
          <input type="text" min="0" matInput formControlName="totalCost" readonly style="color: #fff;">
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control">
        <label>Internal Notes</label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
          <textarea type="text" matInput formControlName="internalComments" type="textarea" rows="5"
            style="color: #fff;"></textarea>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('internalComments')">{{ getErrorMessage('internalComments') }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="aar-sliding-form-footer">
    <div class="aar-sliding-form-footer1">
      <button color="primary" mat-flat-button (click)="previousSelected()">Previous</button>
      <button color="primary" mat-flat-button>{{this.btnText}}</button>
    </div>
  </div>
</form>
