<div class="aar-supplier-add-quote aar-page">
  <div class="content">
    <div class="aar-sliding-section new-header-quote">
      <div class="aar-sliding-section-head">
        <mat-icon matSuffix>chevron_left</mat-icon> <h2> RO-123456</h2>
      </div>
      <div class="aar-sliding-section-body">
        <div class="flx-new">
          <div class="edit-box">
            <p>LAST EDITED</p>
            <span>2018-10-17 15:48</span>
          </div>
          <div class="edit-box">
            <p>DATE RECEIVED</p>
            <span>2018-10-17 15:48</span>
          </div>
          <div class="edit-box">
            <p>PART #</p>
            <span>ABCX-567</span>
          </div>
          <div class="edit-box">
            <p>SERIAL #</p>
            <span>12345678</span>
          </div>
        </div>
        <div class="flx-new">
          <div class="aar-panel-group tat ">
            <label class="aar-panel-tat-label header-6">STATUS</label>
            <div class="aar-panel-tat-days-container border-yes">
              <div class="aar-panel-tat-days">Under Evaluation</div>
            </div>
          </div>
          <div class="aar-panel-group tat ">
            <label class="aar-panel-tat-label header-5">TAT</label>
            <div class="aar-panel-tat-days-container">
              <div class="aar-panel-tat-days">19 DAYS NET</div>
              <div class="aar-panel-tat-days">19 DAYS GROSS</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="arr-add-quote-container content">
    <div class="aar-sliding-section">
      <div class="new-quote-full">
          <!--Left section-->
          <div class="aar-sliding-section-dual rt-left">
            <label class="aar-sliding-section-dual-title">Quote Detail</label>

            <div class="aar-sliding-form-group">
              <div class="aar-sliding-form-control">
                <label for="">Date on Quote</label>
                <mat-form-field  class="mat-form-field-date">
                  <input matInput [matDatepicker]="picker1" formControlName="datePosted">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <mat-icon matSuffix>error_outline</mat-icon>
                </mat-form-field>
              </div>
              <div class="aar-sliding-form-control">
                <label for="">Quote Reference <span>optional</span></label>
                <mat-form-field floatLabel="never"  class="mat-form-field-input">
                  <input type="text" matInput>
                </mat-form-field>
              </div>
            </div>
            <div class="aar-sliding-form-group">
              <div class="aar-sliding-form-control aar-sliding-form-control3">
                <label for="">Quote Comment<span>optional</span></label>
                <mat-form-field floatLabel="never" class="mat-form-field-input">
                  <textarea matInput formControlName="findings"  class="mat-text-area"></textarea>
                </mat-form-field>
              </div>
            </div>

            <mat-checkbox class="aar-sliding-form-group__checkbox">Multioption Quote</mat-checkbox>
            <mat-checkbox class="aar-sliding-form-group__checkbox">Exchange Offered</mat-checkbox>

            <label class="aar-sliding-section-dual-title new-rcm-one header-2 add-icon">Additional Details  <mat-icon matSuffix (click)="toggleAdditionalInfo()">add</mat-icon></label>
            <div class="aar-additional-body" *ngIf="showAdditionalInfo"> <!-- Hide this div when clik on plus icon-->
              <mat-checkbox class="aar-sliding-form-group__checkbox full-width">Part Number Discrepancy</mat-checkbox>
              <mat-checkbox class="aar-sliding-form-group__checkbox full-width">Serial Number Discrepancy</mat-checkbox>
              <div class="aar-sliding-form-group mtop-adj">
                <div class="aar-sliding-form-control">
                  <label for="">Quantity</label>
                  <mat-form-field floatLabel="never"  class="mat-form-field-input">
                    <input type="text" matInput>
                  </mat-form-field>
                </div>
                <div class="aar-sliding-form-control">
                  <label for="">Workscope</label>
                  <mat-form-field floatLabel="never" class="mat-form-field-select">
                    <mat-select formControlName="mismatchReasonPnId">
                      <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                        {{mismatchReason.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="aar-sliding-form-control">
                  <label for="">Warranty Disposition</label>
                  <mat-form-field floatLabel="never" class="mat-form-field-select">
                    <mat-select formControlName="mismatchReasonPnId">
                      <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                        {{mismatchReason.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="aar-sliding-form-group">
                  <div class="aar-sliding-form-control">
                    <label for="">Currency</label>
                    <mat-form-field floatLabel="never" class="mat-form-field-select">
                      <mat-select formControlName="mismatchReasonPnId">
                        <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                          {{mismatchReason.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">Total Cost</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">ECD</label>
                    <mat-form-field  class="mat-form-field-date">
                      <input matInput [matDatepicker]="picker4" formControlName="datePosted">
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                    </mat-form-field>
                  </div>
                </div>
                <div class="aar-sliding-form-group">
                  <div class="aar-sliding-form-control">
                    <label for="">RAI Cost</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">SOS Cost</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="aar-sliding-form-group">
                  <div class="aar-sliding-form-control">
                    <label for="">Currency</label>
                    <mat-form-field floatLabel="never" class="mat-form-field-select">
                      <mat-select formControlName="mismatchReasonPnId">
                        <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                          {{mismatchReason.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">MLP</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="aar-sliding-form-group">
                  <div class="aar-sliding-form-control">
                    <label for="">Currency</label>
                    <mat-form-field floatLabel="never" class="mat-form-field-select">
                      <mat-select formControlName="mismatchReasonPnId">
                        <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                          {{mismatchReason.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">FMV</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-group">
                    <div class="aar-sliding-form-control aar-sliding-form-control3">
                      <label for="">Findings</label>
                      <mat-form-field floatLabel="never" class="mat-form-field-input">
                        <textarea matInput formControlName="findings"  class="mat-text-area"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <label class="aar-sliding-section-dual-title new-rcm-one">Quote Option #2</label>
                <div class="aar-sliding-form-group new-arr-new2">
                  <div class="aar-sliding-form-control">
                    <label for="">Workscope</label>
                    <mat-form-field floatLabel="never" class="mat-form-field-select">
                      <mat-select formControlName="mismatchReasonPnId">
                        <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.value">
                          {{mismatchReason.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="aar-sliding-form-control">
                    <label for="">Total Cost</label>
                    <mat-form-field floatLabel="never"  class="mat-form-field-input">
                      <input type="text" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <button type="button" class="btn-additional">+ Add Additional Option</button>
                </div>
              </div>
            </div>
          </div>

          <!--Right section-->
          <div class="aar-sliding-section-dual rt-right">
            <label class="aar-sliding-section-dual-title">Quote Documentation</label>
            <div class="drag-quote-file">
              <p>Drag & drop a PDF or DOCX here to attach <br> or <a href="#">choose a file on your computer</a></p>
            </div>
            <div class="button-up-section">
              <ul>
                <li>Photos <button type="button" class="btn-upload-other">Add File</button></li>
                <li>Exchange <button type="button" class="btn-upload-other">Add File</button></li>
                <li>Other <button type="button" class="btn-upload-other">Add File</button></li>
              </ul>
            </div>
          </div>
      </div>
        <!--Footer section-->
        <div class="aar-sliding-section-footer new-footer-quote">
          <button class="bt-cancel" mat-raised-button color="primary">Cancel</button>
          <button class="bt-submit" mat-raised-button color="primary">Submit</button>
        </div>
    </div>
  </div>
</div>
