export const ROLE_NAMES = {
  CUSTOMER: 'CUSTOMER',
  REPAIR_MANAGER: 'REPAIR_MANAGER',
  SUPPLIER: 'SUPPLIER',
  REPAIR_ADMIN: 'REPAIR_ADMIN'
};

export const PERSONA_NAMES = {
  __DEFAULT: '__DEFAULT',
  BUYER: 'BUYER',
  CAM: 'CAM',
  PROGRAMMANAGER: 'PROGRAMMANAGER',
  WAREHOUSE: 'WAREHOUSE'
};
