<div class="aar-rcm-stepper-container">
  <aar-rcm-stepper-item
    *ngFor="let step of statusSteps; let first = first; let last = last; let index = index;"
    [label]="step"
    [currentSelected]="currentStatusStepSelected"
    [currentPosition]="index"
    [first]="first"
    [last]="last"
    [hasError]="hasError"
    [xs]="xs"
  ></aar-rcm-stepper-item>
</div>