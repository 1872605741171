import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/shared/services/api/api.service';
import { User } from '@app/auth/auth.models';

@Component({
  selector: 'aar-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: User[];

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getUsers()
      .subscribe(users => this.users = users);
  }

}
