<div class="aar-rcm aar-page">
  <ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">
    <div class="content">
      <div class="aar-rcm-cards-container">
        <div class="equalWidth" *ngFor="let card of criticalStatsCards">
          <aar-dash-card type="b" label={{card.label}} [isEnabled]=card.isEnabled [value]="card.value" [active]="isCardActive(card.id)" (click)="filterByStatus(card.id)" [persona]="card.id==this.criticalStatsIdPendingCustomerApproval ? this.currentUser.roleName.toUpperCase() : null"></aar-dash-card>
        </div>
      </div>
      <aar-data-table
        [loading]="loadingROs"
        [title]="'Repair Orders'"
        [data]="repairOrdersAux"
        [columnsSettings]="columnsSettings"
        [selectable]="false"
        [filterSettings]="tableFilterSettings"
        [linkPath]="'/rcm'">
      </aar-data-table>
    </div>
  </ng-template>

  <ng-template #children>
    <router-outlet></router-outlet>
  </ng-template>
</div>
