import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'aar-rcm-stepper-item',
  templateUrl: './rcm-stepper-item.component.html',
  styleUrls: ['./rcm-stepper-item.component.scss']
})
export class RcmStepperItemComponent implements OnInit {
  @Input() label: string;
  @Input() currentSelected: number;
  @Input() currentPosition: number;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() hasError: boolean;
  @Input() xs: boolean;
  @Input() clickable: boolean;

  constructor() { }

  ngOnInit() { }

  getError(): boolean {
    return this.hasError && this.currentSelected === this.currentPosition;
  }

  getState(): string {
    let cls = this.clickable ? 'pointer' : '';

    if ( this.first ) {
      cls = `${cls} aar-rcm-stepper-item-first`;
    } else if ( this.last ) {
      cls = `${cls} aar-rcm-stepper-item-last`;
    }

    if ( this.xs ) {
      cls = `${cls} aar-rcm-stepper-item-xs`;
    }

    if ( this.getError() ) {
      return `${cls} aar-rcm-stepper-item-error`;
    } else {
      if ( this.currentSelected > this.currentPosition ) {
        return `${cls} aar-rcm-stepper-item-complete`;
      }

      if (this.currentSelected === this.currentPosition) {
        return `${cls} aar-rcm-stepper-item-in-process`;
      }

      if (this.currentSelected < this.currentPosition) {
        return `${cls} aar-rcm-stepper-item-incomplete`;
      }
    }
  }
}
