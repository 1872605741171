import { Injectable } from '@angular/core';
import { CurrentStep, CurrentStepQuery, RepairOrderStepNamesQuery} from '@app/shared/services/api/api.models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiRepairOrderQueryService {

  constructor(private apollo: Apollo) { }

  getCurrentStep(repairOrderNumber:string,  repairLineNumber:string) {
    return this.apollo
      .watchQuery<CurrentStepQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query getCurrentStep($repairOrderNumber:String!, $repairLineNumber:String!) {
          currentStep(repairOrderNumber: $repairOrderNumber, repairLineNumber:$repairLineNumber) {
            logisticStep
            quoteStatusStep
          }
        }
       `,
        variables: {
          repairOrderNumber,
          repairLineNumber
        }
      })
      .valueChanges
      .pipe(
        map(result => {
            const currentStep : CurrentStep = { logisticStep: result.data.currentStep.logisticStep,
              quoteStatusStep: result.data.currentStep.quoteStatusStep};
              return currentStep;
        })
      );
  }


  getRepairOrderStepNames() {
    return this.apollo
      .watchQuery<RepairOrderStepNamesQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query getRepairOrderStepNames {
          repairOrderStepNames {
          logisticStepNames {
            title
            sequence
          }
          quoteStatusStepNames{
            title
            sequence
          }
        }
      }
       `
      })
      .valueChanges
      .pipe(
        map(result => result.data)
      );
  }
}
