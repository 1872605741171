import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aar-supplier-add-quote',
  templateUrl: './supplier-add-quote.component.html',
  styleUrls: ['./supplier-add-quote.component.scss']
})
export class SupplierAddQuoteComponent implements OnInit {
  workScopes: any[];
  warranties: any[];
  currencies: any[];

  constructor() { }
  showAdditionalInfo = false;
  ngOnInit() {
    this.workScopes = [];
    this.warranties = [];
    this.currencies = [];
  }

  toggleAdditionalInfo() {
      this.showAdditionalInfo = !this.showAdditionalInfo;
  }

}
