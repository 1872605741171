import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aar-supplier-carriers',
  templateUrl: './supplier-carriers.component.html',
  styleUrls: ['./supplier-carriers.component.scss']
})
export class SupplierCarriersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
