import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/shared/services/api/api.service';
import { CriticalStatRepairOrder, DashboardRepairOrder, RepairOrderLineCriticalStat } from '@app/shared/services/api/api.models';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { User } from '@app/auth/auth.models';
import { Subscription, forkJoin } from 'rxjs';
import { CriticalStatsService } from '@app/shared/services/criticalstats.service';
import { environment } from '@env/environment';
import { ICriticalStatsCard } from '@app/core/models/critical-stats-card';
import { ROLE_NAMES } from '@app/core/constants/persona-constants';
import { CUSTOMER_STATS, REPAIR_MANAGER_STATS, SUPPLIER_STATS } from '@app/core/constants/critical-stats-constants';
import { finalize, take, tap } from 'rxjs/operators';

@Component({
  selector: 'aar-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  repairOrdersApproval: DashboardRepairOrder[];
  repairOrdersForCriticalStats: DashboardRepairOrder[] = [];
  currentUser: User;
  loading: boolean = true;
  domoEmbedUrlDashboardReport = environment.domoEmbedUrlDashboardReport;
  criticalStatsCards: ICriticalStatsCard[];
  externalSubscriptions = new Subscription;

  constructor(
    public route: ActivatedRoute,
    private authService: AuthService,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private criticalStatsService: CriticalStatsService
  ) { }

  ngOnInit() {
    this.externalSubscriptions.add(this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;
    }));

    this.getCurrentUser();
    this.setCriticalStats();
    this.getData();
  }

  ngOnDestroy() {
    this.externalSubscriptions.unsubscribe();
  }

  private getData(): void {

    const operations = forkJoin({
      dashboardRepairOrdersOperation: this.apiService.getDashboardRepairOrders().pipe(take(1), tap(() => this.loadingService.startLoading())),
      criticalStatsOperation: this.apiService.getCriticalStats().pipe(take(1), tap(() => this.loadingService.startLoading())),
    });

    this.externalSubscriptions.add(operations
      .pipe(tap(() => {
        this.loadingService.startLoading()
      }),
        finalize(() => {
          this.loadingService.stopLoading(),
            this.loading = false
        }))
      .subscribe((opReturn) => {

        this.processCriticalStats(opReturn.dashboardRepairOrdersOperation, opReturn.criticalStatsOperation);

      }));
  }

  private processCriticalStats(dashboardRepairOrders:DashboardRepairOrder[], 
    repairOrderLineCriticalStats:RepairOrderLineCriticalStat[]):void{
    const criticalStatsRepairOrders = dashboardRepairOrders.map((csro) => {
      return <CriticalStatRepairOrder>{
        repairOrderLineId: csro.repairOrderLineId,
        repairLineStatusDescription: csro.repairLineStatusDescription
      }
    });

    const statuses =
      this.criticalStatsService.calculateCriticalStats(
        this.currentUser.roleName,
        repairOrderLineCriticalStats,
        criticalStatsRepairOrders);

    this.criticalStatsCards.forEach((card) => {
      card.value = statuses[card.id];
    });
  }

  private setCriticalStats(): void {
    if (this.currentUser.roleName.toUpperCase() === ROLE_NAMES.CUSTOMER) {
      this.criticalStatsCards = CUSTOMER_STATS;
    } else if (this.currentUser.roleName.toUpperCase() === ROLE_NAMES.SUPPLIER) {
      this.criticalStatsCards = SUPPLIER_STATS;
    } else {
      this.criticalStatsCards = REPAIR_MANAGER_STATS;
    }
  }

  private getCurrentUser(): void {
    if (this.authService.isUserLoggedIn()) {
      this.currentUser = this.authService.getLoggedUser();
    }
  }
}