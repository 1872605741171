import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rcmQuoteAllDocumentsPrimary'
})
export class RcmQuoteAllDocumentsPrimaryPipe implements PipeTransform {
  transform(value: any[], isPrimary: boolean): any[] {
    return value.filter((x: any) => x.primary === isPrimary);
  }
}
