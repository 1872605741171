<div class="aar-notifications aar-page">
    <div class="aar-notifications-header">
      <h2 class="aar-notifications-title">Notifications</h2>
      <aar-toggle-group [value]="0" (select)="toggleNotificationStatus($event)" [items]="toggleButton"></aar-toggle-group>
  </div>

  <form #filtersForm="ngForm" novalidate>
      <div class="aar-notifications-filters">

        <mat-form-field floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Flag">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let flag of flags" (onSelectionChange)="filterFlags(flag.value)" [value]="flag.value">
              {{flag.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Type" name="type" ngModel>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let type of types" [value]="type.value" (onSelectionChange)="filterTypes(type.value)">
              {{type.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Date Range">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange.value">
              {{dateRange.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        

      </div>
    </form>

    <aar-notification-data-table class="arr-notification-list"
    [data]="tableData"
    [columnsSettings]="columnsSettings"
    (clickItem) = "handleReadStatus($event)"
  >
  </aar-notification-data-table>


</div>
