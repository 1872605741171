import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/shared/services/api/api.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { Supplier } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {
  suppliers: Supplier[];
  columnsSettings = [
    { name: 'name', header: 'Supplier Name'},
    { name: 'primaryContact', header: 'Primary Contact'},
    { name: 'mainPhone', header: 'Main Phone'},
    { name: 'location', header: 'Location'},
    { name: 'totalContacts', header: 'Total Contacts'},
    { name: 'openButton', header: ''}
  ];

  constructor(public route: ActivatedRoute, private apiService: ApiService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.startLoading();
    this.apiService.getSuppliers()
      .subscribe(suppliers => {
        this.fillSuppliers(suppliers);
        this.loadingService.stopLoading();
      }, err => this.loadingService.loadingError());
  }

  fillSuppliers(suppliers: Supplier[]): void {
    this.suppliers = [];

    suppliers.map(supplier => {
      const newSupplier = {
        id: supplier.id,
        name: supplier.name,
        location: supplier.location,
        avatar: 'assets/images/fpo/suppliers/' + supplier.id + '.png',
        primaryContact: {
          name: 'First Name',
          email: 'contact@email.com'
        },
        mainPhone: '555-555-5555',
        totalContacts: 3
      };
      this.suppliers.push(Object.create(newSupplier));
    });
  }

  openSupplier(supplier: Supplier): void {
    alert(`Supplier -> ${supplier.name} <- selected`);
  }
}
