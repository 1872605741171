import {  Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonContact, SupplierElement } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SharedService } from '@app/shared/services/shared.service';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Subscription, combineLatest } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { AddEditContactDialogComponent } from '../add-edit-contact-dialog/add-edit-contact-dialog.component';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { PERSONA_NAMES } from '@app/core/constants/persona-constants';

@Component({
  selector: 'aar-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerContactsComponent implements OnInit ,OnChanges,OnDestroy{
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  customerContacts: CommonContact[] = [];
  internalContacts: CommonContact[] = [];
  ContactsList: any = [];
  masterCustomersList: any = [];
  columnsSettingsHeader = [
    { name: 'firstName', header: 'First Name' },
    { name: 'lastName', header: 'Last Name' },
    { name: 'title', header: 'Title' },
    { name: 'emailAddress', header: 'Email Address' },
    { name: 'mainPhoneNumber', header: 'Main Phone Number' },
    { name: 'alternatePhoneNumber', header: 'Alternate Phone Number' },
    { name: 'comments', header: '' },
    { name: 'action', header: '' }
  ];

  displayedColumnsRow = this.columnsSettingsHeader.map(item => item.name);
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;
  searchKeyword: string;
  initialSort = 'endCustomerName';
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<SupplierElement>;
  contactTypeId: number;
  companyId: number;
  contact: CommonContact = new CommonContact();
  isInfoPopUp: any;
  isPrimaryContactExists: any;
  subScribe: any = Subscription;
  apiCallsSubscription: Subscription;
  endCustomerID:any;
  isContactEditable: boolean;

  caseInsensitiveSorting = (data: any, sortHeaderId: string): string => {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }
    return data[sortHeaderId];
  }

  public setSort(id: string, start?: 'asc' | 'desc') {
    if (!id) { return; }
    start = start || 'asc';
    const matSort = this.sort;
    const disableClear = false;
    // Reset sort state so that "start" is the first sort direction
    matSort.sort({ id: null, start, disableClear });
    matSort.sort({ id, start, disableClear });
    // Workaround due to Angular bug with the sort arrow not updating: https://github.com/angular/components/issues/10242
    const sortHeader: MatSortHeader = matSort.sortables.get(this.sort.active) as MatSortHeader;
    if (sortHeader != null) { sortHeader._setAnimationTransitionState({ fromState: sortHeader._arrowDirection, toState: 'active' }); }
  }
  constructor(
    private loadingService: LoadingService,
    private sharedService: SharedService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private authService: AuthService) {
     
  }

  ngOnInit() {
    this.loadingService.startLoading();
    this.callApi(2);
    this.apiCallsSubscription = this.sharedService.getSearchKeyWord().subscribe((message) => {
      this.searchKeyword = message;
      this.doFilter(message);
    });

    this.isContactEditable = this.authService.getLoggedUser().personaName === PERSONA_NAMES.CAM;
  }

  ngOnDestroy() {
    if (this.apiCallsSubscription !== undefined) {
      this.apiCallsSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.masterCustomersList);
    if (!this.sort) { this.sort = new MatSort(); }
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.caseInsensitiveSorting;
    this.doFilter(this.searchKeyword);
    this.dataSource.paginator = this.paginator;
    this.setSort(this.initialSort, 'asc');
  }


  callApi(id: any) {
     const getSupplierApi = this.apiService.getEndCustomers();
    const getContactListApi = this.apiService.GetContactByContactTypeId(id);
    this.subScribe = combineLatest([getSupplierApi, getContactListApi]).subscribe((result: any) => {
      this.customerContacts = result[0].endCustomers;       
      this.ContactsList = result[1];     
      this.createCustomerData();
      this.subScribe.unsubscribe()
    }, (error: any) => {
      this.loadingService.loadingError();
    }
    );
  }


  createCustomerData() {
    this.customerContacts.forEach((s: any) => {
      const customerData: any = [];
      let isPrimaryDetail: any = '';
      this.ContactsList.forEach((c: any) => {
        if (s.endCustomerID === c.endCustomerID) {
          customerData.push(c);
        }
      })

      const isPrimaryObj = customerData.find(d => {
        return d.isPrimaryContact == true
      })

      if (isPrimaryObj !== undefined) {
        isPrimaryDetail = isPrimaryObj
      }

      const reqObj = {
        ...s, contactList: customerData, primaryDetail: isPrimaryDetail,
      };


      customerData.sort((a, b) => {
        if (a.isPrimaryContact !== b.isPrimaryContact) {
          return b.isPrimaryContact - a.isPrimaryContact
        }
        return a.lastName.localeCompare(b.lastName);
      })
      reqObj['isShowContactList'] = true;
      this.masterCustomersList.push(reqObj);   
    })


    this.ngOnChanges();
    setTimeout(() => {    
      this.table.renderRows();
      this.loadingService.stopLoading();
    }, 50);
  }

  columnsToDisplay = ['endCustomerName', 'primaryContect', 'mainPhoneNumber', 'addressn', 'totalCount'];
  columnsToDisplayWithExpand = ['endCustomerName', 'expand', 'primaryContect', 'mainPhoneNumber', 'address', 'totalCount'];
  expandedElement: SupplierElement | null;




  doFilter = (value: string) => {
    const filterValue = value === undefined ? value : value.trim().toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  showAddContactDetailsForm(data: any, element?: any, toAdd?: number) {
    // TO-DO: if there are no contacts at all, how do we determine the contact type & company IDs!
  
    const firstRecord = data[0];
    let supplierId: number;
    let contactID: number;
    let supplierName: string;
    if (firstRecord !== undefined) {
      this.contactTypeId = firstRecord.contactTypeID;
      this.companyId = firstRecord.companyID;
      supplierId = null;
      this.endCustomerID = firstRecord.endCustomerID;
    } else {
      this.contactTypeId = 2;
      this.companyId = null;
      supplierId = null;
       this.endCustomerID = element.endCustomerID;
    }
    contactID = toAdd === 0 ? 0 : firstRecord.contactID;
    this.isPrimaryContactExists = element.contactList && element.contactList.some(item => item.isPrimaryContact);
    supplierName = element.endCustomerName;
    const newContact: CommonContact = {
      contactID: contactID,
      contactTypeID: this.contactTypeId,
      alternatePhoneNumber: null,
      comments: null,
      companyID: this.companyId,
      emailAddress: null,
      endCustomerID: this.endCustomerID,
      firstName: null,
      lastName: null,
      mainPhoneNumber: null,
      title: null,
      isPrimaryContact: !this.isPrimaryContactExists,
      supplierID: supplierId,
    };
    this.contact = newContact;    
    this.showMaintainContactDetailsForm(supplierName, element);
  }

  showUpdateContactDetailsForm(data: any, element?: any, isInfo?: any) {
    const supplierId = this.masterCustomersList.filter(x => x.endCustomerID=== element.endCustomerID);
    const supplierName = supplierId[0].endCustomerName;
    this.contact = element;
    this.isInfoPopUp = isInfo;
    this.showMaintainContactDetailsForm(supplierName, element);
  }

  showMaintainContactDetailsForm(supplierName?: any, element: any = {}) {   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      contact: this.contact,
      name: supplierName,
      isInfoPopUp: this.isInfoPopUp,
      isCustomer:true
    };
    this.openEditDialog(dialogConfig, element);
  }

  public openEditDialog(dialogConfig: MatDialogConfig, element: any = {}) {
    const dialogRef = this.dialog.open(AddEditContactDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.contact = data as any;         
          if (this.contact.contactID > 0) {
          this.updateContact(element);          
          } else {
             this.createContact(element);             
          }
        }
      }
    );
  }

  createContact(element: any = {}) {
    this.loadingService.startLoading();  
    this.apiService.CreateContact(this.apiService.removeTypename(this.contact))
      .subscribe((result: any) => {        
        if (result.isPrimaryContact) {
          let getSupplier = this.masterCustomersList.find(x => {
            return x.endCustomerID == result.endCustomerID
          });
          getSupplier.primaryDetail = result;
        }
       
        if (result !== null) {
           if (result.isPrimaryContact && !this.isPrimaryContactExists) {
            let allContact = element.contactList;          
            this.updateOtherPrimaryContact(allContact, result);
            if (result.isPrimaryContact) {
              element.contactList.forEach(item => {
                item.isPrimaryContact = false;
              });
            }
            element.contactList.push(result);           
            this.sortContactList(element.contactList)
            this.table.renderRows();
            this.loadingService.stopLoading();
            this.ngOnChanges();
          }
          else if (!result.isPrimaryContact && this.isPrimaryContactExists) {
            element.contactList.push(result);            
            this.sortContactList(element.contactList);
            this.loadingService.stopLoading();
            this.table.renderRows();  
            this.ngOnChanges();
          } else if (result.isPrimaryContact && this.isPrimaryContactExists) {            
            let allContact = element.contactList;           
            this.updateOtherPrimaryContact(allContact, result);
            if (result.isPrimaryContact) {
              element.contactList.forEach(item => {
                item.isPrimaryContact = false;
              });
            }
            element.contactList.push(result);
            this.sortContactList(element.contactList);
            this.table.renderRows();
            this.loadingService.stopLoading();
            this.ngOnChanges();
          }
        }
        element['isShowContactList'] = false;
        setTimeout(() => {
          element['isShowContactList'] = true;
        }, 50);
        setTimeout(() => {
          this.table.renderRows();
          this.loadingService.stopLoading();
        }, 50);
      }, err => this.loadingService.loadingError());
  }

  updateContact(element: any = {}) {
    this.loadingService.startLoading();
    const { isShowContactList, ...newData } = this.contact as any;
    this.apiService.UpdateContact(this.apiService.removeTypename(newData))
      .subscribe(result => {
        
        if (result !== null) {
          const oldContactList = this.masterCustomersList.filter(res => {
            return res.endCustomerID == result.endCustomerID;
          });          
          const removeCurrentUpdated = oldContactList[0].contactList.filter(res => {
            return res.contactID !== result.contactID;
          });
            if (result.isPrimaryContact) {           
            this.updateOtherPrimaryContact(removeCurrentUpdated, result);
            if (result.isPrimaryContact) {
              oldContactList[0].contactList.forEach(item => {
                item.isPrimaryContact = false;
              });
            }
            let getSupplier = this.masterCustomersList.find(x => {
              return x.endCustomerID == result.endCustomerID
            });          
            getSupplier.primaryDetail = result;
            const changedIndex = oldContactList[0].contactList.findIndex(x => x.contactID === result.contactID)
            oldContactList[0].contactList[changedIndex] = result;             
            
            this.sortContactList(oldContactList[0].contactList) ;            
            this.table.renderRows(); 
            this.loadingService.stopLoading();
            this.ngOnChanges();
          }
          else {
            this.loadingService.stopLoading();
            this.table.renderRows();
          }

          element['isShowContactList'] = false;
          setTimeout(() => {
            element['isShowContactList'] = true;            
          }, 50);
        }

      }, err => this.loadingService.loadingError());
  }

  hasComments(comments: string): boolean {
    return comments !== undefined && comments !== null && comments !== '';
  }

  getEmailLink(email: any) {
    const mailToUrl = `mailto:${email}`;
    return this.sanitizer.bypassSecurityTrustUrl(mailToUrl);
  }

  updateOtherPrimaryContact(contactList: any, result: any) {    
    this.loadingService.startLoading();  
    const oldContactList = contactList.filter(res => {
      return res.contactID !== result.contactID && res.isPrimaryContact === true;
    });

      oldContactList.forEach(res => {
        const { isPrimaryContact, ...newData } = res;
        const resultData = { isPrimaryContact: false, ...newData };
        this.apiService.UpdateContact(this.apiService.removeTypename(resultData))
          .subscribe(result => {
            if (result !== null) {
              this.ngOnChanges();
            }
          }, err => this.loadingService.loadingError());
      })

    setTimeout(() => {
      this.table.renderRows();
      this.loadingService.stopLoading();
    }, 100);

  }

  deleteContact(contactID: number, rowData?: any) {
    if (rowData.isPrimaryContact) {
      CommonDialogComponent.openDialog(this.dialog, 'ERROR', ': Primary Contact required. You must either: (1) add a new contact as the Primary Contact; or (2) change an existing contact to the Primary Contact before you can proceed.', '', 'OK');
    }
    else {
      const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to delete this contact?', 'Yes', 'No');
      results.subscribe(shouldConfirm => {
        if (shouldConfirm) {
          this.loadingService.startLoading();
          const deletedSupportData = this.masterCustomersList.filter(data => {
            return data.endCustomerID === rowData.endCustomerID
          })
          this.apiService.DeleteContactByContactId(contactID)
            .subscribe(result => {
              if (result) {
                const cList = deletedSupportData[0].contactList;
                const index = cList.findIndex(x => x.contactID === contactID);
                cList.splice(index, 1);     
                this.ngOnChanges();
                setTimeout(() => {
                  this.getCustomerContact(cList.endCustomerID)
                  this.table.renderRows();
                  this.loadingService.stopLoading();
                }, 100);
              }
            }, err => this.loadingService.loadingError());
        }
      });
    }
  }

  sortContactList(data: any) {
    const sortData = data.sort((a, b) => {
      if (a.isPrimaryContact !== b.isPrimaryContact) {
        return b.isPrimaryContact - a.isPrimaryContact
      }
      return a.lastName.localeCompare(b.lastName);
    })
    return sortData
  }

  getCustomerContact(element: any){
    if(element) {
      const customers = this.masterCustomersList.find(x => x.endCustomerID === element.endCustomerID);
      if(customers && customers.contactList) {
        return customers.contactList.length;
      }
      return 0;
    }
  }
}

