import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { ActivityType } from '@app/core/constants/enums';
import { DateLocalisedPipe } from '@app/shared/custom-pipe/date-pipe/date-localised.pipe';
import { RepairOrderActivity } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { ActivityEvent } from '@app/shared/services/rcm/activity/activity-event';
import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { Subscription } from 'rxjs';
import { finalize,switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'aar-rcm-activity',
  templateUrl: './rcm-activity.component.html',
  styleUrls: ['./rcm-activity.component.scss'],
})
export class RcmActivityComponent implements OnInit, OnDestroy {
  @Input('repairOrderNumber') repairOrderNumber: string;
  @Input('repairOrderLineNumber') repairOrderLineNumber: string;

  notifyMe: boolean = false;
  activityFilters: any[];
  selectedActivityFilter = '0';
  filteredActivities: RepairOrderActivity[] = [];
  loading: boolean = false;
  displayedColumns = ['description', 'displayDate'];
  title: string = 'Activities';

  private externalSubscriptions = new Subscription;
  private personaService: PersonaService;
  private statusToHide: ActivityType[] = [];

  constructor(
    private authService: AuthService,
    private activityService: ActivityService,
    private apiService: ApiService,
    private dateLocalizedPipe: DateLocalisedPipe) {
    this.personaService = new PersonaService(
      this.authService.getLoggedUser().personaName
    );
  }

  ngOnInit() {
    this.subscribeToActivityEvents();
    this.statusToHide = this.personaService.getHiddenActivityStatus(
      ComponentType.RcmActivity, this.authService.getLoggedUser().roleName
    );

    this.activityFilters = [
      {
        name: 'Oldest',
        value: '0',
        text: 'Oldest to Newest',
      },
      {
        name: 'Newest',
        value: '1',
        text: 'Newest to Oldest',
      },
    ];

  }

  ngOnDestroy(): void {
    this.externalSubscriptions.unsubscribe();
  }

  sortActivityByDate() {

    const newestToOldestFunc = () => {
      this.filteredActivities = this.filteredActivities.sort(
        (a, b) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
      );
    };

    newestToOldestFunc();
    let nullArray: RepairOrderActivity[] = [];
    const idx = this.filteredActivities.findIndex(a => !a.activityDate);
    if (idx > -1) {
      nullArray = this.filteredActivities.splice(idx);
    }

    if (this.selectedActivityFilter === "0") {
      this.filteredActivities = this.filteredActivities.sort(
        (a, b) => new Date(a.activityDate).getTime() - new Date(b.activityDate).getTime()
      );
    } else {
      newestToOldestFunc();
    }

    this.filteredActivities.push(...nullArray);
  }

  showIcon(activity: RepairOrderActivity): boolean {
    return ![ActivityType.ROCreated, ActivityType.ROClosed, ActivityType.ROCanceled].includes(activity.activityType.activityTypeID);
  }

  private subscribeToActivityEvents(): void {

    const activityChangedOp = this.activityService.rcmActivityChange$.pipe(tap(()=>this.loading = true),
      switchMap((activity: ActivityEvent) => {
        return this.apiService.getRepairOrderActivity(
          activity.repairOrderNumber,
          activity.repairLineNumber).
          pipe(take(1),
            finalize(() => this.loading = false));
      }));

    this.externalSubscriptions.add(activityChangedOp.subscribe((activityItems) => {
      this.loading = true;
      this.filteredActivities = [];
      activityItems.forEach(activityItem => {
        var activity: RepairOrderActivity = {
          activityDate: activityItem.activityDate,
          displayDate: this.dateLocalizedPipe.transform(activityItem.activityDate),
          activityType: activityItem.activityType,
          userId: activityItem.userId,
          description: activityItem.description
        }
        this.filteredActivities.push(activity);
      });
      this.filteredActivities = this.filteredActivities.filter(x => !this.statusToHide.includes(x.activityType.activityTypeID));
      this.sortActivityByDate();
    }));
  }
}