import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { QuoteLine, ShippingType } from './api/api.models';
import { UpdateQuoteLineInput } from '../services/api/api.models';

@Injectable()
export class SharedService {

  private subject = new Subject<string>();
  private searchString = new Subject<string>();
  private objectSubject = new Subject<any>();
  private deletedShippingSubject = new Subject<ShippingType>();
  private updatedShippingSubject = new Subject<ShippingType>();
  private selectedQuoteLine : QuoteLine = null;
  private totalQuoteValue: string = '0';
  private outboundNumberUpdatedSubject = new Subject<string>();
  private selectedRepairOrderHasWarrantySubject = new Subject<boolean>();

  private quoteLineSource = new BehaviorSubject<QuoteLine>(this.selectedQuoteLine);

  quoteLineEmitted = this.quoteLineSource.asObservable();

  selectedRepairOrderHasWarranty$ = this.selectedRepairOrderHasWarrantySubject.asObservable();
  private selectedRepairOrderHasWarranty: boolean;

  private masterOptionQuoteToCustomerDate = new Subject<Date>();

  sendMessage(message: string) {
    //console.log(message);
    this.subject.next(message);
    //console.log(message);
  }

  search(keyword : string)
  {
      this.searchString.next(keyword);
  }

  getSearchKeyWord(): Observable<string> {
    return this.searchString.asObservable();
   }

  sendObject(payload: any) {
    //console.log(JSON.stringify(payload));
    this.objectSubject.next(payload);
    //console.log(payload);
  }

  setSelectedQuoteLine(input :QuoteLine )
  {
    this.selectedQuoteLine = input;
    //localStorage.setItem('selectedQuote',JSON.stringify(input));

    this.quoteLineSource.next(this.selectedQuoteLine);
  }

  checkSerialNumberPublish(input: string)
  {
    this.outboundNumberUpdatedSubject.next(input);
  }

  checkSerialNumberChanged() : Observable<string>
  {
    return this.outboundNumberUpdatedSubject.asObservable();
  }

  getTotalQuoteValue() : string {
    return this.totalQuoteValue;
  }

  getSelectedQuoteLine() : QuoteLine
  {
    //this.selectedQuoteLine = JSON.parse(localStorage.getItem("selectedQuote"));

    return this.selectedQuoteLine;
  }

  sendDeletedShipping(payload: ShippingType) {
    this.deletedShippingSubject.next(payload);
  }

  sendUpdatedShipping(payload: ShippingType) {
    this.updatedShippingSubject.next(payload);
  }


  clearMessage() {
      this.subject.next();
  }
  getObject(): Observable<any> {
    return this.objectSubject.asObservable();
  }
  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
  getDeletedShipping(): Observable<any> {
    return this.deletedShippingSubject.asObservable();
  }

  getUpdatedShipping(): Observable<any> {
    return this.updatedShippingSubject.asObservable();
  }
  constructor() { }

  dateFormatter(sourceDate: any): string | undefined {

    if(!sourceDate) return undefined;

    let sourceDateString = sourceDate.toString();
    let arr = sourceDateString.split(' ');

    //If the value hasn't been updated it's still a string so return it.
    //Note - Split returns the whole string in a single element if the split character isn't present
    if(arr.length <= 2) return sourceDate;

    let month = arr[1];
    let day = arr[2];
    let year = arr[3]
    let time = arr[4]

    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let monthNumber = (months.indexOf(month) + 1).toString().padStart(2, "0");

    let formattedDateString = `${year}-${monthNumber}-${day} ${time}`;

    return formattedDateString;

  }

  preserveQuoteDates(quoteUpdateModel: UpdateQuoteLineInput): UpdateQuoteLineInput{

    quoteUpdateModel.authorizedOnSupplierDate = this.dateFormatter(quoteUpdateModel.authorizedOnSupplierDate);
    quoteUpdateModel.canceledDate = this.dateFormatter(quoteUpdateModel.canceledDate);
    quoteUpdateModel.customerApprovedDate = this.dateFormatter(quoteUpdateModel.customerApprovedDate);
    quoteUpdateModel.dateReceived = this.dateFormatter(quoteUpdateModel.dateReceived);
    quoteUpdateModel.processedDate = this.dateFormatter(quoteUpdateModel.processedDate);
    quoteUpdateModel.customerApprovedDate = this.dateFormatter(quoteUpdateModel.customerApprovedDate);
    quoteUpdateModel.quotedToCustomerDate = this.dateFormatter(quoteUpdateModel.quotedToCustomerDate);
    quoteUpdateModel.createdDate = this.dateFormatter(quoteUpdateModel.createdDate);
    quoteUpdateModel.datePosted = this.dateFormatter(quoteUpdateModel.datePosted);

    return quoteUpdateModel;

  }

  setSelectedRepairOrderHasWarranty(hasWarranty: boolean) {
    this.selectedRepairOrderHasWarranty = hasWarranty;
    this.selectedRepairOrderHasWarrantySubject.next(hasWarranty);
  }

  getSelectedRepairOrderHasWarranty(): boolean {
    return this.selectedRepairOrderHasWarranty;
  }

  setMasterOptionQuoteToCustomerDate(quoteToCustomerDate: Date){
    this.masterOptionQuoteToCustomerDate.next(quoteToCustomerDate);
  }

  getEmittedMasterOptionQuoteToCustomerDate(): Observable<any> {
    return this.masterOptionQuoteToCustomerDate.asObservable();
   }

}
