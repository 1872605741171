<div class="aar-suppliers aar-page">
  <ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">
    <div class="content">
      <aar-data-table
        title="Active Suppliers"
        [data]="suppliers"
        [columnsSettings]="columnsSettings"
        [selectable]="true"
        [showAvatar]="false"
        [openButton]="true"
        (clickItem)="openSupplier($event)"
      ></aar-data-table>
    </div>
  </ng-template>

  <ng-template #children>
    <router-outlet></router-outlet>
  </ng-template>
</div>
