import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToYesNo'
})
export class BooleanToYesNoPipe implements PipeTransform {

  public transform(value: any): any {
    return value ? 'Yes' : 'No';
  }

}
