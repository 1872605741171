import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rcmQuoteAllDocumentsSort'
})
export class RcmQuoteAllDocumentsSortPipe implements PipeTransform {
  transform(value: any[], sortBy: string): any[] {
    switch (sortBy) {
      case 'size':
        // @ts-ignore
        return value.sort((a, b) => a[sortBy] - b[sortBy]);
      case 'name':
      case 'type':
      case 'tag':
        return value.sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
      case 'date':
        // @ts-ignore
        return value.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      default:
        return value;
    }
  }
}
