<div class="title-section" *ngIf="title">
  <h4>{{ title }}</h4>
</div>

<div [hidden]="!loading">
  <div class="loading-container">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
      [value]="50"
      [diameter]="70"
      [strokeWidth]="5"
    ></mat-progress-spinner>
  </div>
</div>

<div [hidden]="loading">
  <aar-table-filter *ngIf="filterSettings" [settings]="filterSettings" [enableSearch]="enableROSearch"></aar-table-filter>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="select" *ngIf="selectable">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of columnsSettings" matColumnDef="{{column.name}}">

      <th mat-header-cell *matHeaderCellDef mat-sort-header
          [class.highlight-error]="column.class === 'highlight-error'"> {{column.header}} </th>

      <td mat-cell *matCellDef="let element" [ngClass]="isNotification() && element.read ? 'read-text' : ''">

        <div class="cell-block" *ngIf="column.type == 'notify'">
          <span [ngClass]="column.class" [class]="element[column.name] ? 'bullet-read' : 'bullet'"></span>
        </div>

        <div class="cell-block" *ngIf="!column.type || column.type == 'content-highlight'">
          <a class="cell-link" *ngIf="element.repairOrderNumber && column.name !== 'select'"
             [routerLink]="[linkPath, element.repairOrderNumber]"></a>

          <span
            [ngClass]="column.type == 'content-highlight' ? column.mappings[element[column.name]] : column.class"
            *ngIf="column.name !== 'status' && column.name !== 'repairOrderStatusName' &&
            column.name !== 'requestedWorkScope' && column.name !== 'workScopeName' &&
            column.name !== 'priority' && column.name !== 'priorityName' &&
            column.name !== 'repairOrder' &&
            column.name !== 'historicalPricingList' && column.name !== 'total' &&
            column.name !== 'teamMember' && !element.warning && column.name !== 'primaryContact' &&
            column.name !== 'name' && column.name !== 'lineRequestedWorkScope' &&
            column.name !== 'lineRepairCategory' && column.name !== 'lineStatus' &&
            column.name !== 'supplierRONumber' && column.name !== 'supplierProvider' && column.name !== 'supplierTAT'"
          >
            {{element[column.name]}}
          </span>

          <span
            class="highlight-error"
            [ngClass]="column.type == 'content-highlight' ? column.mappings[element[column.name]] : column.class"
            *ngIf="column.name !== 'status' && column.name !== 'repairOrderStatusName' &&
            column.name !== 'requestedWorkScope' && column.name !== 'workScopeName' &&
            column.name !== 'priority' && column.name !== 'priorityName' &&
            column.name !== 'repairOrder' &&
            column.name !== 'historicalPricingList' && column.name !== 'total' &&
            column.name !== 'teamMember' && element.warning"
          >
            {{element[column.name]}}
          </span>

          <span
            [ngClass]="column.type == 'content-highlight' ? column.mappings[priorities[element.priority || element.priorityName]] : column.class"
            *ngIf="column.name == 'priority' || column.name == 'priorityName'"
          >
            {{capitalizeText(priorities[element.priorityName || element.priority])}}
          </span>

          <span *ngIf="column.name == 'repairOrder' && column.header == 'Part Number'">
            {{element.repairOrder.partNumber}}
          </span>

          <span *ngIf="(column.name == 'repairOrder') && column.header == 'Status'">
            {{element.repairOrder.repairOrderStatusName}}
          </span>

          <span
            [ngClass]="column.type == 'content-highlight' ? column.mappings[statuses[element.status || element.repairOrderStatusName]] : column.class"
            *ngIf="column.name == 'status' || column.name == 'repairOrderStatusName'"
          >
            {{alignStatus(statuses[element.repairOrderStatusName || element.status])}}
          </span>

          <span
            [ngClass]="column.type == 'content-highlight' ? column.mappings[workscopes[element.requestedWorkScope || element.workScopeName]] : column.class"
            *ngIf="column.name == 'requestedWorkScope' || column.name == 'workScopeName'"
          >
            {{capitalizeText(workscopes[element.requestedWorkScope || element.workScopeName])}}
          </span>

          <span *ngIf="column.name == 'historicalPricingList'">
            {{element.historicalPricingList && element.historicalPricingList.length ? element.historicalPricingList[element.historicalPricingList.length - 1].cost : '--.--'}}
          </span>

          <span *ngIf="column.name == 'total'">
            <span class="highlight-brand">{{element.total.value}}</span>
            &nbsp;
            <span class="highlight-percent">{{element.total.percent ? element.total.percent + '%' : ''}}</span>
          </span>

          <span *ngIf="column.name == 'teamMember'">
            {{element.teamMember}}
            <br>
            <small class="highlight-percent">{{element.observation}}</small>
          </span>

          <span *ngIf="column.name == 'primaryContact'">
            {{element.primaryContact.name}}
            <br>
            <span class="highlight-brand">{{element.primaryContact.email}}</span>
          </span>

          <span class="aar-table-avatar-display" *ngIf="column.name == 'name'">
            <img *ngIf="showAvatar" [src]="element.avatar" alt="avatar"/>
            {{element.name}}
          </span>

          <span *ngIf="column.name == 'openButton'">
            <button mat-flat-button color="primary" (click)="selectItem(element, openButton)">Open</button>
          </span>

          <span *ngIf="column.name == 'lineRequestedWorkScope'">
            {{capitalizeText(element.requestedWorkScope.name)}}
          </span>

          <span *ngIf="column.name == 'lineRepairCategory'">
            {{capitalizeText(element.repairCategory.name)}}
          </span>

          <span *ngIf="column.name == 'lineStatus'">
            {{capitalizeText(element.status.name)}}
          </span>

          <span *ngIf="column.name == 'supplierRONumber'">
            {{element.repairOrderNumber}}
            <br>
            <small class="highlight-percent">Part #: {{element.partNumber}}</small>
          </span>

          <span *ngIf="column.name == 'supplierProvider'">
            <!--{{element.repairOrderNumber}}-->
            Customer/Carrier name
            <br>
            <small class="highlight-percent">Serial #: {{element.serialNumber}}</small>
          </span>

          <span *ngIf="column.name == 'supplierTAT'">
            <span class="highlight-percent tat-title">TAT</span>
            <span class="tat-container">{{calcTATAverage(element)}}</span>
          </span>

        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [hidden]="!showHeader"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns;"
      [class.highlight]="highlightSelection && selection.isSelected(row)"
      [class.highlight-hover]="highlightHover" [class.tall-row]="oversizeRow"
      (click)="selectItem(row, !openButton)"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="showPagination"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  ></mat-paginator>

</div>
