<form class="filter-table-form" [formGroup]="filterForm" novalidate>
  <div>
    <div class="aar-table-filters-container">
      <mat-form-field class="search" *ngIf="enableSearch" floatLabel="never">
        <mat-label>Search</mat-label>
        <input type="text" matInput formControlName="searchQuery">
      </mat-form-field>
      <div *ngFor="let column of settings; let columnIndex = index">
        <!-- TODO: Get back search field after DEMO -->
        <!-- <div *ngIf="column.type == 'dropdown'; else renderSearchField"> -->
          <mat-form-field floatLabel="never" class="aar-filter-select">
            <mat-select placeholder="{{ settings[columnIndex].placeholder }}" formControlName="{{column.name}}">
              <mat-option>Clear</mat-option>
              <mat-option
                *ngFor="let option of filterOptions(settings[columnIndex].options); let optionIndex = index"
                value="{{ option.id || option.value }}"
              >
                {{ option.name || option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        <!-- </div> -->
        <!-- <ng-template #renderSearchField>
          <mat-form-field floatLabel="never" class="aar-filter-input">
            <input matInput [placeholder]="column.placeholder" [formControlName]="column.name"/>
          </mat-form-field>
        </ng-template> -->
      </div>
    </div>
  </div>
</form>
