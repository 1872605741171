import { Component } from '@angular/core';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'aar-status-report',
  templateUrl: './status-report.component.html',
  styleUrls: ['./status-report.component.scss']
})


export class StatusReportComponent {

  domoEmbedUrlStatusReport = environment.domoEmbedUrlStatusReport;

  constructor(public authService: AuthService) {}
}
