import { DatevalidationService } from './../../../shared/services/datevalidation.service';
import { QuoteStatusIdType, RepairCategory, Status, WorkScope } from './../../../shared/services/api/api.models';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { getFormFielErrorMessage, isEmpty } from '@app/common/utility';
import { ActivityInputType, QuoteLine, QuoteWarrantyStatus, RepairOrder, UpdateQuoteLineInput } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { SharedService } from '@app/shared/services/shared.service';
import { Console } from 'console';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import * as moment from 'moment';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { QuoteService } from '@app/shared/services/rcm/quote/quote.service';
import { PERSONA_NAMES } from '@app/core/constants/persona-constants';
import { RcmStatusStepperService } from '@app/rcm/rcm-stepper/rcm-status-stepper.service';
import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';

@Component({
  selector: 'aar-rcm-review-workscope-comparison',
  templateUrl: './rcm-review-workscope-comparison.component.html',
  styleUrls: ['./rcm-review-workscope-comparison.component.scss']
})
export class RcmReviewWorkscopeComparisonComponent implements OnInit, OnDestroy {
  @Input('quoteLine') quoteLine: UpdateQuoteLineInput;
  @Input('repairOrder') repairOrder: RepairOrder;
  @Output('stepForward') stepForward = new EventEmitter();
  @Output('updateQuoteLine') updateQuoteLine = new EventEmitter();

  quoteForm: UntypedFormGroup;
  workScopes: WorkScope[] = [];
  repairCategories: any[];
  partNumbers: any[];
  mismatchReasons: any[];
  comparisonModel: QuoteLine;
  QuoteProcessingUpdate: UpdateQuoteLineInput;
  subscription: Subscription;
  outboundSerialNumberChangedSubscription: Subscription;
  message: string;
  keywords = '';
  quoteDoc: any;
  activity: ActivityInputType;
  filteredPartNumbers: Observable<any[]>;
  filteredSerialNumberReceived: Observable<any[]>;
  fieldsToHide: string[] = [];
  repairCategoryLookup: RepairCategory[] = [];
  quoteWarrantyStatuses: QuoteWarrantyStatus[] = [];

  btnText: string = "Submit";
  nonEditableFields: string[] = [];
  personaService: PersonaService;

  readonly masterOptionControlName = 'masterOption';
  readonly dateOnQuoteControlName = 'datePosted';
  readonly dateReceivedOnQuoteControlName = 'dateReceived';
  readonly quoteWarrantyStatusControlName = 'quoteWarrantyStatus';
  readonly supplierRefControlName = 'supplierRef';
  readonly partNumberReceivedControlName = 'partNumberReceived';
  readonly mismatchReasonPnIdControlName = 'mismatchReasonPnId';
  readonly serialNumberReceivedControlName = 'serialNumberReceived';
  readonly mismatchReasonSnIdControlName = 'mismatchReasonSnId';
  readonly qtyReceivedControlName = 'qtyReceived';
  readonly repairCategoryIdControlName = 'repairCategoryId';
  readonly proposedWorkScopeIdControlName = 'proposedWorkScopeId';
  readonly quotedTatControlName = 'quotedTat';
  readonly findingsControlName = 'findings';

  readonly nonCreateStatusFieldGroup: string[] = [this.dateOnQuoteControlName, this.dateReceivedOnQuoteControlName,
  this.quoteWarrantyStatusControlName, this.supplierRefControlName, this.partNumberReceivedControlName,
  this.mismatchReasonPnIdControlName, this.serialNumberReceivedControlName, this.mismatchReasonSnIdControlName,
  this.qtyReceivedControlName, this.repairCategoryIdControlName, this.proposedWorkScopeIdControlName,
  this.quotedTatControlName, this.findingsControlName];


  currentSupplierCost: number = 0;
  outboundSerialNumberValue: string = "";
  rolOutboundSn: string = '';

  selectedRepairOrderHasWarranty = false;
  private selectedRepairOrderHasWarrantySubscription: Subscription;

  constructor(private apiService: ApiService, private authService: AuthService,
    private sharedService: SharedService, private snackBar: MatSnackBar,
    private datalookupService: DataLookupService, private validateDateService: DatevalidationService,
    private quoteService: QuoteService,
    private statusStepperService: RcmStatusStepperService,
    private activityService: ActivityService) {

    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);

  }

  ngOnDestroy(): void {
    this.selectedRepairOrderHasWarrantySubscription.unsubscribe();
    this.outboundSerialNumberChangedSubscription.unsubscribe();
  }

  setButtonTextFunctionality(quoteLineStatus: string) {
    if (quoteLineStatus.toUpperCase() !== "CREATED") {
      this.btnText = "Next";

      this.effectRoEdit(quoteLineStatus);
    } else {
      this.btnText = "Submit";
    }
  }

  effectRoEdit(quoteLineStatus: string): void {
    // enable or disable fields based on roEdit flag
    if (quoteLineStatus.toUpperCase() === "CREATED") {
      Object.keys(this.quoteForm.controls).forEach(key => {
        // skip non editable fields
        if (this.nonEditableFields.includes(key)) {
          this.quoteForm.get(key).enable();
        }
      });
    } else {
      Object.keys(this.quoteForm.controls).forEach(key => {
        if (this.nonEditableFields.includes(key)) {
          this.quoteForm.get(key).disable();
        }
      });
    }
  }

  extractQuoteDetails(): void {

    this.apiService.extractQuotelineDetails(this.quoteLine.attachedQuoteID).subscribe(result => {

      this.comparisonModel = result;
      this.initializeInputs();

      this.apiService.getDocumentById(this.quoteLine.attachedQuoteID).subscribe(doc => {
        this.quoteDoc = doc;
        this.sharedService.sendObject(this.quoteDoc);

      });
      this.openSnackBar('OCR/Text Capture Successful');
    },
      error => {

        this.openSnackBar('Failed to capture text using OCR/Text Capture');
      });
  }

  private setQuoteWarrantyStatusState(): void {
    const hasWarranty = this.sharedService.getSelectedRepairOrderHasWarranty();
    this.selectedRepairOrderHasWarranty = hasWarranty;
    
    const isQuoteAtCreatedState = this.comparisonModel.quote.quoteStatusName === 'Created';
    const canEdit = hasWarranty && isQuoteAtCreatedState;

    if(isQuoteAtCreatedState && !hasWarranty){
      this.quoteForm.controls[this.quoteWarrantyStatusControlName].setValue(null);
    }

    if (canEdit) {
      this.quoteForm.controls[this.quoteWarrantyStatusControlName].enable();
    } else {
      this.quoteForm.controls[this.quoteWarrantyStatusControlName].disable();
    }
  }

  ngOnInit() {

    // Fields to hide or remove
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMReviewWorkscopeComparison);
    this.nonEditableFields = this.personaService.getNonEditableFields(ComponentType.RCMReviewWorkscopeComparison);

    this.subscribeToExternalEvents();

    this.mismatchReasons = this.datalookupService.cachedMismatchReasonsForQuoteSerialNumberChanged();
    this.quoteWarrantyStatuses = this.datalookupService.cachedQuoteWarrantyStatuses;
    this.repairCategoryLookup = this.datalookupService.cachedRepairCategories;
    this.apiService.getPartNumbers().subscribe(result => this.partNumbers = result);
    this.workScopes = this.datalookupService.cachedWorkscopes;

    this.quoteForm = new UntypedFormGroup({
      partNumberReceived: new UntypedFormControl('', [Validators.required]),
      datePosted: new UntypedFormControl('', [Validators.required]),
      dateReceived: new UntypedFormControl('', [Validators.required]),
      mismatchReasonPnId: new UntypedFormControl('',),
      serialNumberReceived: new UntypedFormControl(''),
      mismatchReasonSnId: new UntypedFormControl(null),
      qtyReceived: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      repairCategoryId: new UntypedFormControl('', [Validators.required]),
      proposedWorkScopeId: new UntypedFormControl('Other', [Validators.required]),
      supplierRef: new UntypedFormControl('', [Validators.required]),
      quotedTat: new UntypedFormControl(0),
      findings: new UntypedFormControl(null),
      masterOption: new UntypedFormControl(false),
      quoteWarrantyStatus: new UntypedFormControl('', [Validators.required]),
    });

    this.quoteForm.controls[this.masterOptionControlName].valueChanges.subscribe(() => {
      if (this.quoteForm.controls[this.masterOptionControlName].value !== this.comparisonModel.quote.masterOption && this.comparisonModel.quote.quoteStatusName !== "Created") {
        this.btnText = "Submit";
      } else if (this.quoteForm.controls[this.masterOptionControlName].value === this.comparisonModel.quote.masterOption && this.comparisonModel.quote.quoteStatusName !== "Created") {
        this.btnText = "Next";
      }
    });

    this.subscribeToValueChangeObservables();

    //this.quoteLine.partNumberReceived = 'This is a partnumber';
    this.populateQuoteInfo();

    this.filteredPartNumbers = this.quoteForm.controls["partNumberReceived"].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.filteredSerialNumberReceived = this.quoteForm.controls[this.serialNumberReceivedControlName].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.quoteForm.controls[this.dateOnQuoteControlName].valueChanges.subscribe((changedValue) => {
      const resp = this.validateDateService.validateDateIsBeforeOrEqualToDate(changedValue, new Date());

      if (resp) {
        this.getFormControl(this.dateOnQuoteControlName).setErrors(null);
      } else {
        this.getFormControl(this.dateOnQuoteControlName).setErrors({ 'invalid': true });
      }
    });

    this.quoteForm.controls[this.dateReceivedOnQuoteControlName].valueChanges.subscribe(() => {
      let date = this.getFormControl(this.dateReceivedOnQuoteControlName).value;
      const resp = this.validateDateService.validateDateIsBeforeOrEqualToDate(date, new Date());

      if (resp) {
        this.getFormControl(this.dateReceivedOnQuoteControlName).setErrors(null);
      } else {
        this.getFormControl(this.dateReceivedOnQuoteControlName).setErrors({ 'invalid': true });
      }
    });

  }

  getFormControl(controlName: string): UntypedFormControl {
    return this.quoteForm.get(controlName) as UntypedFormControl;
  }

  private subscribeToExternalEvents(): void {
    this.selectedRepairOrderHasWarrantySubscription = this.sharedService.selectedRepairOrderHasWarranty$.subscribe(() => {
      this.setQuoteWarrantyStatusState();
    });

    this.subscription = this.sharedService.getMessage().subscribe(message => {

      this.message = message;
      if (message == 'SaveStep0') {
        if (this.checkFormValid()) {

          this.initializeUpdate();

          this.openSnackBar('Saving...');
          this.apiService.updateQuoteLine(this.QuoteProcessingUpdate).subscribe(result => {
            this.openSnackBar('Comparison values successfully Saved.');

            this.sharedService.sendMessage('ShowQuoteProcessingList');

          },
            error => {
              this.openSnackBar('Saving Failed');
              //console.log(JSON.stringify(this.QuoteProcessingUpdate));
              //console.log(error);
            });

        } else {
          this.openSnackBar('Please complete all mandatory fields.');
        }
      }

    });

    this.outboundSerialNumberChangedSubscription =
      this.sharedService.checkSerialNumberChanged().subscribe((rolObSerialNumber) => {
        this.rolOutboundSn = rolObSerialNumber;
        const quoteObSerialNumber = this.quoteForm.controls[this.serialNumberReceivedControlName].value;
        if (quoteObSerialNumber !== rolObSerialNumber) {
          this.rolSerialNumberMatchesQuoteSerialNumber(quoteObSerialNumber, rolObSerialNumber);
        }else{
          this.setMismatchReasonSnIdAccess(false);
        }
      },
        error => {
          this.openSnackBar('Failed to compare Outbound Serial Number to Quote Serial Number');
        });
  }

  private subscribeToValueChangeObservables(): void {
    this.getFormControl(this.qtyReceivedControlName).valueChanges.subscribe((qtyChange) => {
      this.calculateAndFormatTotalSupplierCost(qtyChange);
    });

    this.getFormControl(this.masterOptionControlName).valueChanges.subscribe((masterChange) => {
      if (masterChange) {
        this.quoteService.formatValueAndCurrency(this.comparisonModel.unitCost, this.getFormControl(this.qtyReceivedControlName).value, this.quoteLine.currency, true);
      } else {
        if (this.quoteService.originalMasterQuoteId === this.comparisonModel.id) {
          this.quoteService.formatValueAndCurrency(0, 0, this.quoteLine.currency, true);
        } else {
          this.quoteService.resetOriginalMasterValueAndCurrency();
        }
      }
    })

    this.quoteForm.controls[this.serialNumberReceivedControlName].valueChanges.subscribe((quotedSnChange) => {

      const mismatchReasonControl = this.quoteForm.controls[this.mismatchReasonSnIdControlName];

      if (isEmpty(quotedSnChange)) {
        mismatchReasonControl.setValue(null);
        mismatchReasonControl.disable();
      } else {
        this.rolSerialNumberMatchesQuoteSerialNumber(this.rolOutboundSn, quotedSnChange);
      }
    });
  }

  private calculateAndFormatTotalSupplierCost(quantityReceived: number): void {
    this.quoteService.formatValueAndCurrency(this.comparisonModel.unitCost, quantityReceived,
      this.quoteLine.currency,
      this.getFormControl(this.masterOptionControlName).value);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.partNumbers != undefined) {
      return this.partNumbers.filter(option => option.partNumber.toLowerCase().includes(filterValue));
    }
    else {
      return null;
    }
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  detectKeyWords() {

    this.apiService.extractKeywords(this.comparisonModel.id).subscribe(result => {
      this.keywords = result;
      this.openSnackBar('Keywords successfully extracted');
    },
      error => {
        this.openSnackBar('Keywords extraction failed. Please try again later');
      }
    );
  }

  initializeInputs() {

    this.rolOutboundSn = this.comparisonModel.outboundSerialNumber;
    this.quoteForm.controls['masterOption'].setValue(this.comparisonModel.quote.masterOption);

    //Calculate and persist
    this.currentSupplierCost = this.comparisonModel.totalCost / this.comparisonModel.qTYRceived;

    // disable part number field after populating data
    this.quoteForm.controls['partNumberReceived'].setValue(this.repairOrder.partNumber);
    this.quoteForm.controls['partNumberReceived'].disable({ onlySelf: true });
    this.quoteForm.controls['repairCategoryId'].setValue(this.comparisonModel.repairCategory.repairCategoryId);
    this.quoteForm.controls[this.serialNumberReceivedControlName].setValue(this.comparisonModel.serialNumberReceived);
    this.quoteForm.controls[this.mismatchReasonSnIdControlName].setValue(this.comparisonModel.mismatchReasonSnId);
    this.quoteForm.controls[this.quoteWarrantyStatusControlName].setValue(this.comparisonModel.quoteWarrantyStatus ? this.comparisonModel.quoteWarrantyStatus.id : null);
    this.getFormControl(this.proposedWorkScopeIdControlName).setValue(this.comparisonModel.proposedWorkscopeId);

    this.getFormControl(this.dateOnQuoteControlName).setValue(moment(this.comparisonModel.quote.datePosted).utc(true));
    this.getFormControl(this.dateReceivedOnQuoteControlName).setValue(moment(this.comparisonModel.quote.dateReceived).utc(true));

    this.setQuoteWarrantyStatusState();

    this.setButtonTextFunctionality(this.comparisonModel.quote.quoteStatusName);
    this.disableNonCreateStatusFields();

    this.calculateAndFormatTotalSupplierCost(this.quoteLine.qtyRceived);

  }

  private disableNonCreateStatusFields() {
    if (this.comparisonModel.quote.quoteStatus.id !== QuoteStatusIdType.Created) {
      this.nonCreateStatusFieldGroup.forEach((fgItem) => {
        this.getFormControl(fgItem).disable();
      });
    }
  }

  populateQuoteInfo() {
    //Here
    if (this.sharedService.getSelectedQuoteLine() === null || this.sharedService.getSelectedQuoteLine() === undefined) {
      this.apiService.getPendngQuoteLineStagging(this.quoteLine.roNumber, this.quoteLine.roLineNumber).subscribe(result => {
        this.comparisonModel = result[0];
        this.initializeInputs();
        this.sharedService.setSelectedQuoteLine(this.comparisonModel);
        this.openSnackBar('Comparison values successfully loaded');
      }, error => {

        this.openSnackBar('Error Loading Comparison State, Please try again later.');
      });
    }
    else {
      let rcmQuoteId = this.sharedService.getSelectedQuoteLine().quote.rCMQuoteId;

      this.apiService.getQuotesLinesById(rcmQuoteId).subscribe(result => {
        this.comparisonModel = result[0];
        this.initializeInputs();
        this.sharedService.setSelectedQuoteLine(this.comparisonModel);
        this.openSnackBar('Comparison values successfully loaded');
      }, error => {

        this.openSnackBar('Error Loading Comparison State, Please try again later.');
      });
    }
  }

  checkFormValid(): boolean {
    //Since we are the STATUS of each control whiles still applying Validators
    //The Status Property of the controls would read - VALID, INVALID, PENDING OR DISABLED
    //Thus the need for the second Leg of the form Validation

    let result = this.quoteForm.get('datePosted').valid &&
      this.quoteForm.get('dateReceived').valid &&
      this.quoteForm.get(this.serialNumberReceivedControlName).valid &&
      this.quoteForm.get(this.mismatchReasonSnIdControlName).valid &&
      this.quoteForm.get('qtyReceived').valid &&
      this.quoteForm.get('repairCategoryId').valid &&
      this.quoteForm.get('proposedWorkScopeId').valid ||
      this.quoteForm.get('datePosted').valid &&
      this.quoteForm.get('dateReceived').valid &&
      this.quoteForm.get(this.serialNumberReceivedControlName).valid &&
      this.quoteForm.get(this.mismatchReasonSnIdControlName).disabled &&
      this.quoteForm.get('qtyReceived').valid &&
      this.quoteForm.get('repairCategoryId').valid &&
      this.quoteForm.get('proposedWorkScopeId').valid ||
      this.quoteForm.get('datePosted').disabled &&
      this.quoteForm.get('dateReceived').disabled &&
      this.quoteForm.get(this.serialNumberReceivedControlName).disabled &&
      this.quoteForm.get(this.mismatchReasonSnIdControlName).valid &&
      this.quoteForm.get('qtyReceived').disabled &&
      this.quoteForm.get('repairCategoryId').disabled &&
      this.quoteForm.get('proposedWorkScopeId').disabled ||
      this.quoteForm.get('datePosted').disabled &&
      this.quoteForm.get('dateReceived').disabled &&
      this.quoteForm.get(this.serialNumberReceivedControlName).disabled &&
      this.quoteForm.get(this.mismatchReasonSnIdControlName).disabled &&
      this.quoteForm.get('qtyReceived').disabled &&
      this.quoteForm.get('repairCategoryId').disabled &&
      this.quoteForm.get('proposedWorkScopeId').disabled;

    if (this.authService.getLoggedUser().personaName === PERSONA_NAMES.BUYER) {
      result = result && (this.quoteForm.get(this.supplierRefControlName).valid || this.quoteForm.get(this.supplierRefControlName).disabled);
    }

    const isWarrantyStatusValid = this.quoteForm.get(this.quoteWarrantyStatusControlName).disabled ||
      !this.quoteForm.get(this.quoteWarrantyStatusControlName).disabled && this.quoteForm.get(this.quoteWarrantyStatusControlName).valid;

    result = result && isWarrantyStatusValid;

    return result;
  }

  submitReview(event): void {
    if (this.btnText === "Next") {
      this.stepForward.emit();
    } else {
      if (this.checkFormValid()) {
        this.initializeUpdate();

        this.apiService.updateQuoteLine(this.QuoteProcessingUpdate).subscribe(result => {
          this.statusStepperService.currentStepChange(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          this.activityService.activityChangePublish(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          
          this.openSnackBar('Comparison values successfully updated.');
          this.stepForward.emit();
        },
          error => {

            this.openSnackBar('Error Submitting Quote.  Please try again later.');
            console.log(error);
          });
      } else {

      }
    }
  }

  initializeUpdate(): void {

    //These fields have been cross checked and are currently (2022-09-16) in use in
    //the RepairOrderDetailsService.UpdateQuoteLineStagging method.
    //
    //The service will currently save all fields
    //**Including fields that are not directly altered by this component**
    //Until the service is altered these fields are included in the update under the
    //comments 'Preserve fields'
    //
    //Note: There are duplicates that will need refactoring later

    this.QuoteProcessingUpdate = new UpdateQuoteLineInput();

    //Quote
    //Changeable fields
    this.QuoteProcessingUpdate.masterOption = this.quoteForm.controls['masterOption'].value;
    this.QuoteProcessingUpdate.datePosted = this.quoteForm.controls['datePosted'].value;
    this.QuoteProcessingUpdate.dateReceived = this.quoteForm.controls['dateReceived'].value;
    this.QuoteProcessingUpdate.supplierReference = this.quoteForm.controls['supplierRef'].value;
    this.QuoteProcessingUpdate.vendName = '';
    this.QuoteProcessingUpdate.vendAccount = '';

    //Preserve fields
    this.QuoteProcessingUpdate.internalComments = this.comparisonModel.quote.internalComments;

    //QuoteLine
    //Changeable fields
    this.QuoteProcessingUpdate.qtyRceived = this.quoteForm.controls['qtyReceived'].value;
    this.QuoteProcessingUpdate.warrantyStatusId = this.getFormControl(this.quoteWarrantyStatusControlName).value;
    this.QuoteProcessingUpdate.mismatchReasonSnId = this.quoteForm.controls[this.mismatchReasonSnIdControlName].value;
    this.QuoteProcessingUpdate.partNumberReceived = this.quoteForm.controls['partNumberReceived'].value;
    this.QuoteProcessingUpdate.repairCategoryId = this.quoteForm.controls['repairCategoryId'].value;
    this.QuoteProcessingUpdate.mismatchReasonPnId = this.quoteForm.controls['mismatchReasonPnId'].value;
    this.QuoteProcessingUpdate.findings = this.quoteForm.controls['findings'].value;
    this.QuoteProcessingUpdate.mismatchReasonPn = this.quoteForm.controls['mismatchReasonPnId'].value;
    this.QuoteProcessingUpdate.proposedWorkscopeId = this.quoteForm.controls[this.proposedWorkScopeIdControlName].value;
    this.QuoteProcessingUpdate.quotedTat = this.quoteForm.controls['quotedTat'].value;
    this.QuoteProcessingUpdate.serialNumberReceived = this.quoteForm.controls[this.serialNumberReceivedControlName].value;
    this.QuoteProcessingUpdate.mismatchReasonSn = this.quoteForm.controls[this.mismatchReasonSnIdControlName].value;
    this.QuoteProcessingUpdate.unitId = '';
    this.QuoteProcessingUpdate.nff = '';
    this.QuoteProcessingUpdate.webServiceDescription = '';
    this.QuoteProcessingUpdate.quoteLineStatus = '';

    //Preserve fields
    this.QuoteProcessingUpdate.id = this.comparisonModel.id;
    this.QuoteProcessingUpdate.rai = this.comparisonModel.rai;
    this.QuoteProcessingUpdate.sos = this.comparisonModel.sos;
    this.QuoteProcessingUpdate.nupCurrency = this.comparisonModel.nupCurrency;
    this.QuoteProcessingUpdate.quoteId = this.comparisonModel.quote.rCMQuoteId;
    this.QuoteProcessingUpdate.quoteOrderLineNum = this.comparisonModel.quoteOrderLineNum;
    this.QuoteProcessingUpdate.updatedAt = new Date();
    this.QuoteProcessingUpdate.nupPercent = this.comparisonModel.nupPercent;
    this.QuoteProcessingUpdate.currency = this.comparisonModel.currencyType.id;
    this.QuoteProcessingUpdate.mV = this.comparisonModel.mV;
    this.QuoteProcessingUpdate.nUP = this.comparisonModel.nUP;
    this.QuoteProcessingUpdate.mvPercent = this.comparisonModel.mvPercent;
    this.QuoteProcessingUpdate.laborCost = this.comparisonModel.laborCost;
    this.QuoteProcessingUpdate.roLineNumber = this.comparisonModel.quote.rOLineNumber;
    this.QuoteProcessingUpdate.roNumber = this.comparisonModel.quote.rONumber;
    this.QuoteProcessingUpdate.mvCurrency = this.comparisonModel.mvCurrency;
    this.QuoteProcessingUpdate.totalCost = this.comparisonModel.totalCost;
    this.QuoteProcessingUpdate.unitCost = this.comparisonModel.unitCost;
    this.QuoteProcessingUpdate.material = this.comparisonModel.material;
    this.QuoteProcessingUpdate.miscellaneous = this.comparisonModel.miscellaneous;

    this.QuoteProcessingUpdate.onlyUpdateStatus = false;
    this.sharedService.preserveQuoteDates(this.QuoteProcessingUpdate);
  }

  hasError(control: string): boolean {
    return !this.quoteForm.controls[control].valid;
  }

  getErrorMessage(control: string): string {
    return getFormFielErrorMessage(this.quoteForm, control);
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  private rolSerialNumberMatchesQuoteSerialNumber(rolSn: string, quoteSn: string): void {

    let edit: boolean = false;

    let rsn = rolSn.split(',');
    let qsn = quoteSn.split(',');

    if (rsn.length !== qsn.length) {
      edit = true;
    } else {
      rsn.forEach((item, index) => {
        if (item.trim() !== qsn[index].trim()) {
          edit = true;
          return;
        }
      });
    }
    this.setMismatchReasonSnIdAccess(edit);
  }

  private setMismatchReasonSnIdAccess(edited: boolean) {
    const mismatchReasonSnIdControl = this.quoteForm.controls[this.mismatchReasonSnIdControlName];
    if (!edited) {
      mismatchReasonSnIdControl.setValue(null);
      mismatchReasonSnIdControl.disable();
      mismatchReasonSnIdControl.clearValidators();
      mismatchReasonSnIdControl.updateValueAndValidity();
    } else {
      mismatchReasonSnIdControl.enable();
      mismatchReasonSnIdControl.setValidators(Validators.required);
      mismatchReasonSnIdControl.updateValueAndValidity();
    }
  }
}