import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { AppMaterialModule } from '@app/app-material.module';
import { SharedModule } from '@app/shared.module';

import {
  DataTableModule,
  DashCardModule,
  TertiaryCardModule,
  ChartCardModule,
  ToggleGroupModule,
  ApprovalDashCardModule
} from '@app/shared/components';

import { DashboardComponent } from './dashboard.component';


@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    DataTableModule,
    DashCardModule,
    TertiaryCardModule,
    ChartCardModule,
    ToggleGroupModule,
    ApprovalDashCardModule,
    SharedModule
  ],
  providers: [],
  declarations: [
    DashboardComponent,
  ]
})
export class DashboardModule { }
