import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'aar-rcm-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent {
  dialogTitle:string;
  dialogMessage:string;
  buttonText:string;
  secondarybuttonText:string;
  public loading = false;
  public status = false;
  public dialogData:any ;

  public static openDialog(dialog: MatDialog, dialogTitle:string,dialogMessage: string,buttonText:string,secondarybuttonText:string) {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {dialogTitle:dialogTitle, dialogMessage: dialogMessage,secondarybuttonText:secondarybuttonText ,buttonText:buttonText,} 
    });
    return dialogRef.afterClosed();
  }

  constructor(public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
    ) {
      this.dialogData = data;
  }

  confirmDialog() {
    this.status = true;
    this.dialogRef.close(this.status);  
  }

  onClose() {
    this.status = false;
    this.dialogRef.close(this.status);
  }
}