<div class="aar-my-account aar-page">
  <div class="content">

    <ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">
      <div class="aar-new-container">
        <div class="aar-new-leftside">
          <div class="leftside-top">
            <div class="left-acc">
              <div class="img-left">
                <div class="initials">
                  {{firstname}} {{lastname}}
                </div>
              </div>
              <div class="content-left">
                <h2>{{currentUser.name}}</h2>
                <p>{{currentUser.email}}</p>
              </div>
            </div>
            <div class="right-acc">
              <div class="right-acc-sub">
                <h4>LAST LOGIN</h4>
                <p>{{currentUser.lastLoggedIn}}</p>
              </div>
              <div class="right-acc-sub">
                <h4>TITLE</h4>
                <p>{{currentUser.roleName}}{{currentUser.personaName !== undefined ? ' / ' + currentUser.personaName :
                  '' }}</p>
              </div>
            </div>
          </div>
          <div class="leftside-body">

            <mat-card>
              <div class="card-title">
              <h4>Timezone</h4>
              <div class="spacer-line"></div>
                    <p>Set your timezone preferences</p>
                    <div class="aar-sliding-form-group gp-small">
                      <div class="aar-sliding-form-control">
                        <mat-form-field floatLabel="never" class="mat-form-field-select">
                          <mat-select>
                            <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">
                              {{timeZone.label}}
                            </mat-option>
                          </mat-select>
                          <mat-icon matSuffix>error_outline</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
              </div>
            </mat-card>



            <!-- Commented under 30896 - Temporarily replaced tab with card pending final design
             <mat-tab-group [selectedIndex]="selectedTabsIndex">
              <mat-tab label="General">
                <div class="tabaccount-body">
                  <div class="tabcontent-list">
                    <h4>Timezone</h4>
                    <p>Set your timezone preferences</p>
                    <div class="aar-sliding-form-group gp-small">
                      <div class="aar-sliding-form-control">
                        <mat-form-field floatLabel="never" class="mat-form-field-select">
                          <mat-select>
                            <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">
                              {{timeZone.label}}
                            </mat-option>
                          </mat-select>
                          <mat-icon matSuffix>error_outline</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="tabcontent-list" *ngIf="showPassSection">
                    <h4>Password</h4>
                    <p>Manage your password</p>
                    <button type="button" (click)="showChangePassModal()" class="mat-flat-button mat-primary">CHANGE
                      PASSWORD</button>
                  </div>
                </div>
              </mat-tab> -->

              <!-- Commented under 40896
               <mat-tab label="Notification Settings" [disabled]="true">
                <div class="tabaccount-body">
                  <div class="tabcontent-list border-0">
                    <h4>Notifications</h4>
                    <p>Set your notification preferences</p>
                    <div class="notification-data">
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width:30%">Off/On</th>
                            <th style="width:40%">Notification</th>
                            <th>Notification Frequency</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let subscription of subscriptions">
                            <td>
                              <mat-slide-toggle [(ngModel)]="subscription.isSubscribed"
                                (change)="changeSubscription(subscription)"></mat-slide-toggle>
                            </td>
                            <td>
                              {{subscription.subscriptionName}}
                            </td>
                            <td>
                              <div class="aar-sliding-form-group gp-small">
                                <div class="aar-sliding-form-control">
                                  <mat-form-field floatLabel="never" class="mat-form-field-select">
                                    <mat-select [value]="subscription.frequencyId" id="{{subscription.triggerId}}"
                                      (selectionChange)="changeFrequency($event)">
                                      <mat-option *ngFor="let frequency of frequencies" [value]="frequency.frequencyID">
                                        {{frequency.freqencyName}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>error_outline</mat-icon>
                                  </mat-form-field>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-tab> -->

            <!-- </mat-tab-group> -->
          </div>
        </div>
        <div>
        </div>
      </div>
    </ng-template>
    <ng-template #children>
      <router-outlet></router-outlet>
    </ng-template>
  </div>
</div>

<!--<ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">-->
<!--<h2>This Airvolution feature has not been activated.</h2>-->
<!--<p>This Airvolution feature has not been activated for this account. Please contact your AAR representative for more information.</p>-->
<!--<a routerLink="/my-account/users/">See users</a>-->
<!--</ng-template>-->