<div class="aar-chart-card" [class.c3-hide-x-axis-line]="!showXAxisLine" [class.c3-hide-y-axis-line]="!showYAxisLine">
  <div class="aar-chart-card-content">
    <h4 *ngIf="title"> {{title}} <i class="icon-more"></i> </h4>
    <div class="aar-chart-card-graph">
      <ul class="aar-chart-card-legend" *ngIf="showLegend">
        <li *ngFor="let legend of c3ChartConfig.data.colors | keyvalue">
          <span>
            <span class="aar-chart-card-legend-bullet" [ngStyle]="{'background-color': legend.value}"></span>
            {{legend.key}}
          </span>
          <span class="aar-chart-card-legend-value">{{getValue(legend.key)}}</span>
        </li>
      </ul>

      <div aarNg2C3Chart [c3ChartConfig]="c3ChartConfig"></div>
    </div>
  </div>
</div>
