<div class="aar-dash-card" [ngClass]="{'type-a': type == 'a', 'type-b': type == 'b', 'alert': isAlert, 'enabled': isEnabled, 'active': active}">
  <div class="aar-card-container">
    <h5 class="aar-card-label"> {{label}}</h5>
    <span class="aar-card-value display-1" [ngClass]="{'highlight display-2': highlight, 'colorCoded' : getHighlight(), 'aog-color':setAogColor(), 'past-due-color':setPastDueColor() }">
      {{value || 0}}
      <span *ngIf="highlightText" class="header-5">
        {{highlightText}}
      </span>
    </span>
  </div>
  <a *ngIf="goTo && !filterBy" [routerLink]="[goTo]" class="aar-card-link"></a>
  <a *ngIf="goTo && filterBy" [routerLink]="[goTo]" [queryParams] = "{filterBy: filterBy }" class="aar-card-link"></a>
  <a *ngIf="goTo && showOnly" [routerLink]="[goTo]" [queryParams] = "{showOnly: showOnly }" class="aar-card-link"></a>
  <a *ngIf="menuOptions" class="aar-card-menu-trigger"><mat-icon svgIcon="more" class="icon-more"></mat-icon></a>
  <!-- TODO: Pending design -->
  <!-- <div class="aar-card-menu"></div> -->
</div>
