import { Injectable } from '@angular/core';
import { AAREventSourceGeneric } from '../../event-operation/event-source-generic';
import { DocumentUpdate } from './event-model/document-update';

@Injectable({
  providedIn: 'root'
})
export class DocumentEventService {
  documentUpdated = new AAREventSourceGeneric<DocumentUpdate>();
}
