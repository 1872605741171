import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'apollo-link';

@Component({
  selector: 'aar-rcm-export',
  templateUrl: './rcm-export.component.html',
  styleUrls: ['./rcm-export.component.scss']
})
export class RcmExportComponent implements OnInit {
  option = 'option';

  public static openDialog(dialog: MatDialog) {
    const dialogRef = dialog.open(RcmExportComponent);
    return dialogRef.afterClosed(); // .subscribe(result => { });
  }


  constructor(public dialogRef: MatDialogRef<RcmExportComponent>) { }

  ngOnInit() { }

  onClose() {
    this.dialogRef.close();
  }

}
