import { QuoteStatusIdType } from "./../../../shared/services/api/api.models";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "@app/auth/services/auth/auth.service";
import { getFormFielErrorMessage } from "@app/common/utility";
import { QuoteLine, UpdateQuoteLineInput, } from "@app/shared/services/api/api.models";
import { SharedService } from "@app/shared/services/shared.service";
import * as moment from "moment";
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { Subscription } from "rxjs";
import { DatevalidationService } from "@app/shared/services/datevalidation.service";
import { User } from '@app/auth/auth.models';
import { CommonDialogComponent } from "../../../shared/components/common-dialog/common-dialog.component";
import { PERSONA_NAMES } from "@app/core/constants/persona-constants";


@Component({
  selector: "aar-rcm-review-workscope-status",
  templateUrl: "./rcm-review-workscope-status.component.html",
  styleUrls: ["./rcm-review-workscope-status.component.scss"]
})
export class RcmReviewWorkscopeStatusComponent implements OnInit, OnDestroy {

  @Input('preventApprovalState') set setPreventApprovalState(value: QuoteStatusIdType | undefined) {
    this.preventApprovalState = value;
  }
  @Output("updateQuoteLine") updateQuoteLine = new EventEmitter();
  @Output() callUpdatedQuoteLine = new EventEmitter<boolean>();
  @Output("previous") previous = new EventEmitter();

  statusForm: UntypedFormGroup;
  private quoteLine: QuoteLine;
  private preventApprovalState: QuoteStatusIdType;
  private user: User;
  private messageSubscription: Subscription;
  private quoteProcessingSubscription: Subscription;

  readonly authorizedOnSupplierDateCtrlName = "authorizedOnSupplierDate";
  readonly authorizedOnSupplierByCtrlName = "authorizedOnSupplierByUser";
  readonly authorizedOnSupplierCtrlName = "authorizedOnSupplier";
  private nonEditableFields: string[] = [];
  private fieldsToHide: string[] = [];
  private personaService: PersonaService;


  readonly canceledByCtrlName = "canceledByUser";
  readonly canceledDateCtrlName = "canceledDate";
  readonly cancelationReasonCtrlName = "cancelationReason";
  readonly canceledCtrlName = "canceled";

  readonly createdDateCtrlName = 'createdDate';
  readonly createdByCtrlName = 'createdByUser';

  readonly customerApprovedDateCtrlName = "customerApprovedDate";
  readonly customerApprovedByCtrlName = "customerApprovedByUser";
  readonly customerApprovedCtrlName = "customerApproved";

  readonly processedByCtrlName = 'processedByUser';
  readonly processedDateCtrlName = 'processedDate';

  readonly quotedToCustomerDateCtrlName = "quotedToCustomerDate";
  readonly quotedToCustomerByCtrlName = "quotedToCustomerByUser";
  readonly quotedToCustomerCtrlName = "quotedToCustomer";

  readonly noApprovalRequiredCtrlName = 'noApprovalRequired';
  readonly noApprovalRequiredDateCtrlName = 'noApprovalRequiredDate';
  readonly noApprovalRequiredByCtrlName = 'noApprovalRequiredByUser';


  private readonly authorizedOnSupplierGroup: string[] = [this.authorizedOnSupplierDateCtrlName, this.authorizedOnSupplierByCtrlName];
  private readonly canceledGroup: string[] = [this.canceledDateCtrlName, this.canceledByCtrlName, this.cancelationReasonCtrlName];
  private readonly createdGroup: string[] = [this.createdDateCtrlName, this.createdByCtrlName];
  private readonly customerApprovedGroup: string[] = [this.customerApprovedDateCtrlName, this.customerApprovedByCtrlName];
  private readonly processedByGroup: string[] = [this.processedDateCtrlName, this.processedByCtrlName];
  private readonly quotedToCustomerGroup: string[] = [this.quotedToCustomerDateCtrlName, this.quotedToCustomerByCtrlName];
  private readonly noApprovalRequiredGroup: string[] = [this.noApprovalRequiredByCtrlName, this.noApprovalRequiredDateCtrlName];
  private readonly allGroups: string[][] = [this.authorizedOnSupplierGroup, this.canceledGroup, this.createdGroup,
  this.customerApprovedGroup, this.processedByGroup, this.quotedToCustomerGroup, this.noApprovalRequiredGroup];

  private readonly allCheckboxes: string[] = [this.canceledCtrlName, this.quotedToCustomerCtrlName,
  this.customerApprovedCtrlName, this.authorizedOnSupplierCtrlName, this.noApprovalRequiredCtrlName];

  private readonly preventAuthorizeMessage = 'A quote is already at Approved state';
  private readonly preventAuthorizedOnSupplierMessage = 'A quote is already at Authorized On Supplier state';

  private readonly approvalLevelCustomerExclusions = [
    this.quotedToCustomerCtrlName, this.quotedToCustomerDateCtrlName, this.quotedToCustomerByCtrlName,
    this.authorizedOnSupplierCtrlName, this.authorizedOnSupplierDateCtrlName, this.authorizedOnSupplierByCtrlName
  ];

  readonly dateControlNames = [
    this.createdDateCtrlName,
    this.processedDateCtrlName,
    this.noApprovalRequiredDateCtrlName,
    this.quotedToCustomerDateCtrlName,
    this.customerApprovedDateCtrlName,
    this.authorizedOnSupplierDateCtrlName,
    this.canceledDateCtrlName
  ];

  private originalQuoteStatusType: QuoteStatusIdType;
  private selectedQuoteStatusType: QuoteStatusIdType;

  private isQuoteAtNoApprovalRequiredState: boolean = false;

  QuoteStatusIdType = QuoteStatusIdType;

  submitted: boolean = true;
  cancelationReasonHasValue: boolean = false;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private changeDectector: ChangeDetectorRef,
    private dateValidator: DatevalidationService,
    private dialog: MatDialog) {
      this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
    }

  ngOnInit() {
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMReviewWorkscopeStatus);
    this.nonEditableFields = this.personaService.getNonEditableFields(ComponentType.RCMReviewWorkscopeStatus);
    this.subscribeToMessageService();
    this.subscribeToQuoteProcessing();
    this.user = this.authService.getLoggedUser();
    this.subscribeToDateFieldChanges();
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.quoteProcessingSubscription.unsubscribe();
  }

  private subscribeToDateFieldChanges() {
    this.dateControlNames.forEach((element) => {
      this.statusForm.controls[element].valueChanges.subscribe((changedDateValue) => {
        let resp = this.dateValidator.validateDateIsBeforeOrEqualToDate(changedDateValue, new Date());
        if (element === this.noApprovalRequiredDateCtrlName) {
          if (this.selectedQuoteStatusType === QuoteStatusIdType.Processed && !this.isQuoteAtNoApprovalRequiredState) {
            resp = this.dateValidator.validateDateIsBetweenDatesInclusive(changedDateValue, this.quoteLine.quote.processedDate, new Date());
          }
        }
        if (resp) {
          this.getFormControl(element).setErrors(null);
        } else {
          this.getFormControl(element).setErrors({ 'invalid': true });
        }
      });
    });
  }

  //User has selected a different quote
  private initialiseComponent() {
    this.createStatusForm();

    this.originalQuoteStatusType = this.quoteLine.quote.quoteStatus.id as QuoteStatusIdType;
    if (this.quoteLine.quote.noApprovalRequiredBy) {
      this.isQuoteAtNoApprovalRequiredState = true;
    } else {
      this.isQuoteAtNoApprovalRequiredState = false;
    }

    this.resetForm();
  }

  private subscribeToQuoteProcessing() {
    this.quoteProcessingSubscription = this.sharedService.quoteLineEmitted.subscribe((quoteLineEmitted) => {
      if (quoteLineEmitted) {
        this.quoteLine = quoteLineEmitted;
        this.initialiseComponent();
      }
    });
  }

  private subscribeToMessageService() {
    this.messageSubscription = this.sharedService.getMessage().subscribe((message) => {
      if (message === "QuoteStatusDatesUpdated") {
        //this.sharedService.sendMessage("ShowQuoteProcessingList");
        this.initialiseComponent();
      }
    });
  }

  private setAllGroupsToDisabled() {
    this.allGroups.forEach(group => {
      this.toggleControlGroup(true, group, false);
    });
  }

  private setAllCheckboxesToDisabled(checkboxToExclude: string | undefined) {
    this.allCheckboxes.forEach((cb) => {
      if (cb) {
        if (cb != checkboxToExclude) {
          this.changeDectector.detectChanges();
          this.getFormControl(cb).disable();
        }
      }
    });
  }

  //Operation logic:
  //If initialising then the check boxes are set to the selectable options for the current status
  //otherwise the user has selected an available option and all check boxes
  //apart from the selected option are then disabled
  private setStatusCheckboxes(initialize: boolean, statusType: QuoteStatusIdType) {

    this.setAllGroupsToDisabled();

    if (initialize) {
      this.setAllCheckboxesToDisabled(undefined);
    }

    let controlsToEnable: string[] = [this.canceledCtrlName];

    switch (statusType) {
      case QuoteStatusIdType.Processed:
        if (initialize) {
          if (this.isQuoteAtNoApprovalRequiredState) {
            controlsToEnable.push(this.authorizedOnSupplierCtrlName);
          } else {
            controlsToEnable.push(this.noApprovalRequiredCtrlName, this.quotedToCustomerCtrlName, this.authorizedOnSupplierCtrlName);
          }
        } else {
          this.setAllCheckboxesToDisabled(this.noApprovalRequiredCtrlName);
        }
        break;
      case QuoteStatusIdType.Canceled:
        if (!initialize) {
          this.setAllCheckboxesToDisabled(this.canceledCtrlName);
        }
        break;
      case QuoteStatusIdType.SentForAuthorization:
        if (initialize) {
          if (!this.preventApprovalState) {
            controlsToEnable.push(this.customerApprovedCtrlName)
          }
        } else {
          this.setAllCheckboxesToDisabled(this.quotedToCustomerCtrlName);
        }
        break;
      case QuoteStatusIdType.Authorized:
        if (initialize) {
          controlsToEnable.push(this.authorizedOnSupplierCtrlName);
        } else {
          this.setAllCheckboxesToDisabled(this.customerApprovedCtrlName);
        }
        break;
      case QuoteStatusIdType.AuthorizedOnSupplier:
        if (!initialize) {
          this.setAllCheckboxesToDisabled(this.authorizedOnSupplierCtrlName);
        }
        break;
    }

    if (initialize) {
      controlsToEnable.forEach((controlName) => {
        this.changeDectector.detectChanges();
        this.statusForm.controls[controlName].enable()
      });
    };
    
  }

  private toggleControlGroup(initialise: boolean, controlGroup: string[], enable: boolean) {
    controlGroup.forEach((groupItem) => {
      let ctrl = this.getFormControl(groupItem);
      if (enable) {
        if (groupItem.includes('Date')) {
          ctrl.setValue(moment().format("yyyy-MM-DD"));         
          ctrl.setValidators([Validators.required]);
          this.changeDectector.detectChanges();
          ctrl.enable();
        } else if (groupItem.includes("ByUser")) {
          ctrl.setValue(this.user.userName);
          ctrl.setValidators([Validators.required, Validators.maxLength(255)]);
        } else if (groupItem.includes("cancelationReason") && this.submitted === false) {
          ctrl.setValidators([Validators.maxLength(100)]);
          this.changeDectector.detectChanges();
          ctrl.enable();
        } else {
          ctrl.setValidators([Validators.required, Validators.maxLength(100)]);
          this.changeDectector.detectChanges();
          ctrl.enable();
        }
        ctrl.updateValueAndValidity();
      }
      else {
        if (!initialise) {
          ctrl.clearValidators();
          ctrl.setValue(undefined);
          ctrl.updateValueAndValidity();
          this.changeDectector.detectChanges();
          ctrl.disable();
          this.selectedQuoteStatusType = undefined;
        }
      }
    });
  }

  private getFormControl(controlName: string): UntypedFormControl {
    return this.statusForm.get(controlName) as UntypedFormControl;
  }

  private createStatusForm() {

    this.statusForm = this.fb.group({
      createdDate: [{ value: moment(this.quoteLine.quote.createdDate).utc(true), disabled: true }],
      createdByUser: [{ value: this.quoteLine.quote.createdByUserName, disabled: true }],
      processedDate: [{ value: moment(this.quoteLine.quote.processedDate).utc(true), disabled: true }],
      processedByUser: [{ value: this.quoteLine.quote.processedBy, disabled: true }],
      quotedToCustomerDate: [{ value: moment(this.quoteLine.quote.quotedToCustomerDate).utc(true), disabled: true }],
      quotedToCustomerByUser: [{ value: this.quoteLine.quote.quotedToCustomerBy, disabled: true }],
      quotedToCustomer: [{ value: this.quoteLine.quote.quotedToCustomerDate !== null ? true : false, disabled: false }],
      customerApprovedDate: [{ value: moment(this.quoteLine.quote.customerApprovedDate).utc(true), disabled: true }],
      customerApprovedByUser: [{ value: this.quoteLine.quote.customerApprovedBy, disabled: true }],
      customerApproved: [{ value: this.quoteLine.quote.customerApprovedDate !== null, disabled: false }],
      authorizedOnSupplierDate: [{ value: moment(this.quoteLine.quote.authorizedOnSupplierDate).utc(true), disabled: true }],
      authorizedOnSupplierByUser: [{ value: this.quoteLine.quote.authorizedOnSupplierBy, disabled: true }],
      authorizedOnSupplier: [{ value: this.quoteLine.quote.authorizedOnSupplierDate !== null, disabled: false }],
      canceledDate: [{ value: moment(this.quoteLine.quote.canceledDate).utc(true), disabled: true }],
      canceledByUser: [{ value: this.quoteLine.quote.canceledBy, disabled: true }],
      canceled: [{ value: this.quoteLine.quote.canceledDate !== null, disabled: false }],
      cancelationReason: [{ value: this.quoteLine.quote.cancelationReason, disabled: true }, []],
      noApprovalRequired: [{ value: this.quoteLine.quote.noApprovalRequiredDate ? true : false, disabled: false }, []],
      noApprovalRequiredDate: [{ value: this.quoteLine.quote.noApprovalRequiredDate, disabled: true }, []],
      noApprovalRequiredByUser: [{ value: this.quoteLine.quote.noApprovalRequiredBy, disabled: true }, []]
    });

  }

  private showPreventAuthorisationMessage() {
    if (!this.selectedQuoteStatusType) {
      if (this.preventApprovalState === QuoteStatusIdType.Authorized) {
        this.openSnackBar(this.preventAuthorizeMessage);
      }
      if (this.preventApprovalState === QuoteStatusIdType.AuthorizedOnSupplier) {
        this.openSnackBar(this.preventAuthorizedOnSupplierMessage);
      }
    }
  }

  onCustomerApprovedClick(event: PointerEvent) {
    if (this.preventApprovalState && this.originalQuoteStatusType !== QuoteStatusIdType.Canceled &&
      this.originalQuoteStatusType != QuoteStatusIdType.Authorized &&
      this.originalQuoteStatusType != QuoteStatusIdType.AuthorizedOnSupplier) {
      this.showPreventAuthorisationMessage();
      event.preventDefault();
    }
  }

  onAuthorizedOnSupplierClick(event: PointerEvent) {
    if (this.originalQuoteStatusType === QuoteStatusIdType.Canceled ||
      this.originalQuoteStatusType === QuoteStatusIdType.AuthorizedOnSupplier) {
      event.preventDefault();
    } else {
      if (this.preventApprovalState) {
        this.showPreventAuthorisationMessage();
        event.preventDefault();
      } else {
        if (!this.quoteLine.quote.masterOption) {
          this.openSnackBar(
            "Please set the quote to Master to continue with the status change");

          event.preventDefault();
        }
      }
    }
  }

  onStatusCheckboxChange(event: MatCheckboxChange) {

    let quoteStatusType: QuoteStatusIdType = +event.source.name as QuoteStatusIdType;

    if (event.checked) {
      this.selectedQuoteStatusType = quoteStatusType;
      this.setStatusCheckboxes(false, quoteStatusType);

      switch (quoteStatusType) {
        case QuoteStatusIdType.Processed:
          this.toggleControlGroup(false, this.noApprovalRequiredGroup, event.checked);
          break;
        case QuoteStatusIdType.Authorized:
          this.toggleControlGroup(false, this.customerApprovedGroup, event.checked);
          break;
        case QuoteStatusIdType.AuthorizedOnSupplier:
          this.toggleControlGroup(false, this.authorizedOnSupplierGroup, event.checked);
          break;
        case QuoteStatusIdType.Canceled:
          this.toggleControlGroup(false, this.canceledGroup, event.checked);
          break;
        case QuoteStatusIdType.SentForAuthorization:
          this.toggleControlGroup(false, this.quotedToCustomerGroup, event.checked);
          break;
      }
    } else {
      this.resetForm();
    }

  }

  updateSelectedQuoteLine(quoteLine: QuoteLine): void {
    this.quoteLine = quoteLine;
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000,
    });
  }

  submit(): void {
    this.submitted = true;
    const confirmationPersonas = [PERSONA_NAMES.BUYER, PERSONA_NAMES.CAM];
    if (confirmationPersonas.includes(this.user.personaName.toUpperCase())) {
      this.confirmAndSaveQuote();
    } else {
      this.saveChanges();
    }
  }

  private confirmAndSaveQuote() {
    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to change the status of this quote?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {

      if (shouldConfirm === true) {
        this.saveChanges();
      } else {
        this.submitted = false;
        this.cancelationReasonHasValue = false;
        this.resetForm();
      }
    });
  }

  private saveChanges() {
    if (this.selectedQuoteStatusType) {
      const quoteStatus: UpdateQuoteLineInput = {
        onlyUpdateStatus: true,
        roNumber: this.quoteLine.quote.rONumber,
        quoteId: this.quoteLine.quote.rCMQuoteId,
        quoteStatusId: this.selectedQuoteStatusType,
        repairCategoryId: this.quoteLine.repairCategory.repairCategoryId,
        masterOption: this.quoteLine.quote.masterOption
      };

      switch (this.selectedQuoteStatusType) {
        case QuoteStatusIdType.Processed:
          quoteStatus.noApprovalRequiredDate = this.getFormControl(this.noApprovalRequiredDateCtrlName).value as Date;
          break;
        case QuoteStatusIdType.Authorized:
          quoteStatus.customerApprovedDate = this.getFormControl(this.customerApprovedDateCtrlName).value as Date;
          break;
        case QuoteStatusIdType.AuthorizedOnSupplier:
          quoteStatus.authorizedOnSupplierDate = this.getFormControl(this.authorizedOnSupplierDateCtrlName).value as Date;
          break;
        case QuoteStatusIdType.Canceled:
          quoteStatus.canceledDate = this.getFormControl(this.canceledDateCtrlName).value as Date;
          quoteStatus.cancelationReason = this.getFormControl(this.cancelationReasonCtrlName).value;
          break;
        case QuoteStatusIdType.SentForAuthorization:
          quoteStatus.quotedToCustomerDate = this.getFormControl(this.quotedToCustomerDateCtrlName).value as Date;
          break;
      }

      delete quoteStatus.attachedQuoteID;
      this.updateQuoteLine.emit(quoteStatus);
      this.callUpdatedQuoteLine.emit(true);

    }
  }

  //Called from QuoteProcessing parent component
  reset() {
    this.statusForm.reset();
  }

  resetForm() {

    if (this.selectedQuoteStatusType) {

      switch (this.selectedQuoteStatusType) {
        case QuoteStatusIdType.Processed:
          this.toggleControlGroup(false, this.noApprovalRequiredGroup, false);
          this.getFormControl(this.noApprovalRequiredCtrlName).setValue(undefined);
          break;
        case QuoteStatusIdType.Authorized:
          this.toggleControlGroup(false, this.customerApprovedGroup, false);
          this.getFormControl(this.customerApprovedCtrlName).setValue(undefined);
          break;
        case QuoteStatusIdType.AuthorizedOnSupplier:
          this.toggleControlGroup(false, this.authorizedOnSupplierGroup, false);
          this.getFormControl(this.authorizedOnSupplierCtrlName).setValue(undefined);
          break;
        case QuoteStatusIdType.Canceled:
          this.toggleControlGroup(false, this.canceledGroup, false);
          this.getFormControl(this.canceledCtrlName).setValue(undefined);
          this.getFormControl(this.cancelationReasonCtrlName).setValue(undefined);
          break;
        case QuoteStatusIdType.SentForAuthorization:
          this.toggleControlGroup(false, this.quotedToCustomerGroup, false);
          this.getFormControl(this.quotedToCustomerCtrlName).setValue(undefined);
          break;
      }
    }

    this.setStatusCheckboxes(true, this.originalQuoteStatusType);
    this.changeDectector.detectChanges();
    this.personaService.effectFieldDisables(this.statusForm, this.nonEditableFields);
    this.selectedQuoteStatusType = undefined;
    this.statusForm.markAsUntouched();
    this.statusForm.markAsPristine();
    this.statusForm.updateValueAndValidity();
  }
  cancelationReasonUpdate() {
    if (this.statusForm.value.cancelationReason !== undefined && this.statusForm.value.cancelationReason.length > 0) {
      this.cancelationReasonHasValue = true;
    } else {
      this.cancelationReasonHasValue = false;
    }
  }

  cancelationReasonBlur() {
    if (this.cancelationReasonHasValue === false) {
      this.getFormControl(this.cancelationReasonCtrlName).setValidators([Validators.required]);
      this.getFormControl(this.cancelationReasonCtrlName).updateValueAndValidity();
    }
  }

  hasError(control: string): boolean {
    return !this.statusForm.controls[control].valid;
  }

  getErrorMessage(control: string): string {
    return getFormFielErrorMessage(this.statusForm, control);
  }

  disableSubmit(): boolean {
    return this.statusForm.invalid || !this.statusForm.dirty || (this.getFormControl(this.cancelationReasonCtrlName).enabled && !this.cancelationReasonHasValue);
  }

  disableCancel(): boolean {
    return this.originalQuoteStatusType === QuoteStatusIdType.Canceled ||
      !this.statusForm.dirty;
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  isFieldNonEditable(fieldName: string): boolean {
    return this.nonEditableFields.includes(fieldName);
  }
}
