import { Injectable } from '@angular/core';
import { Breadcrumb } from '@app/core/models/breadcrumb.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: Breadcrumb[];
  public breadcrumbsSource: Subject<Breadcrumb[]>;
  public breadcrumbsChanged$: Observable<Breadcrumb[]>;
  public breadcrumbsAction: Subject<string>;
  public breadcrumbsAction$: Observable<string>;

  constructor() {
    this.breadcrumbs = [];
    this.breadcrumbsSource = new Subject<Breadcrumb[]>();
    this.breadcrumbsChanged$ = this.breadcrumbsSource.asObservable();
    this.breadcrumbsAction = new Subject<string>();
    this.breadcrumbsAction$ = this.breadcrumbsAction.asObservable();
  }

  // Store the breadcrumbs of the current route
  public store(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs = breadcrumbs;
    this.breadcrumbsSource.next(this.breadcrumbs);
  }

  // Return the breadcrumbs
  public get() {
    return this.breadcrumbsChanged$;
  }

  // Return an action
  public actionEvent(action: string) {
    this.breadcrumbsAction.next(action);
  }
}
