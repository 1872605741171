<div *ngIf="authService.isUserInCompanyWithModule('CostControlResults')" class="sidebar-wrapper">

  <div class="title-section">
    <h4>Cost Control Results</h4>
  </div>

  <div class="content-row mt-3">
    <div class="content-col">
      <p class="text-muted">PART NUMBER</p>
      <p class="f-14">751A0000-07</p>
    </div>
    <div class="content-col">
      <p class="text-muted">WORKSCOPE</p>
      <p class="f-14">{{tableData[0]?.workScopeName}}</p>
    </div>
  </div>

  <div class="content-row">
    <div class="content-col">
      <p class="text-muted">SUPPLIER </p>
      <p class="f-14">{{tableData[0]?.supplier}}</p>
    </div>
    <div class="content-col">
      <p class="text-muted">COUNTRY</p>
      <p class="f-14">{{tableData[0]?.country}}</p>
    </div>
  </div>

  <div class="content-row">
    <div class="content-col">
      <p class="text-muted">DATE RANGE</p>
      <p class="f-14">{{dateRangeF}} - {{dateRangeL}}</p>
    </div>
  </div>

  <div class="hr"></div>

  <div class="title-section">
    <h4>Cost Over Time</h4>
  </div>

  <aar-chart-card [c3ChartConfig]="c3ChartConfigBar" [showLegend]="false"></aar-chart-card>

  <div class="hr"></div>

  <div class="gradient-container">
    <div class="text-right">LOW <br> ${{costL}}</div>
    <div class="gradient-box">
      <div class="scale" style="left:30%">Quoted <br> price </div>
    </div>
    <div>HIGH <br> ${{costH}}</div>
  </div>

  <div class="content-row align-center">
    <div class="content-col text-muted">
      HISTORICAL AVERAGE COST
    </div>
    <div class="content-col text-right f-18">
      ${{((costH + costL)/2)}}
    </div>
  </div>

  <div class="hr"></div>

  <div class="content-row align-center">
    <div class="content-col text-muted">
      QUOTED
    </div>
    <div class="content-col text-right f-18">
      <p class="text-red f-18 m-0"> $2890.89</p>

    </div>
  </div>
  <p class="text-right  text-muted"><span class="text-red"> +38% </span> the Average Historical Cost</p>

  <div>
    <button mat-flat-button>Compose Email</button>
  </div>
</div>
