import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { ShippingDirection } from '@app/core/constants/enums';
import { RcmStatusStepperService } from '@app/rcm/rcm-stepper/rcm-status-stepper.service';
import { ShippingInputType, ShippingType, User } from '@app/shared/services/api/api.models';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { SharedService } from '@app/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aar-rcm-shipping-header',
  templateUrl: './rcm-shipping-header.component.html',
  styleUrls: ['./rcm-shipping-header.component.scss']
})
export class RcmShippingHeaderComponent {
  @Input('directionDescription') set setDirectionDescription(value){
    this.directionDescription = value ? value : 'New Shipping Record';
  }
  @Input('shippingStatus') shippingStatus: string;
  @Input('dateShipped') dateShipped;
  @Input('dateDelivered') dateDelivered;
  @Input('shippingLogo') shippingLogo = '';
  @Input('urlNumber') urlNumber = '';
  @Input('trackingNumber') trackingNumber = '';
  @Input('trackingLink') trackingLink = '';
  @Input('shippingCarrier') shippingCarrier : number = null;
  @Input('address') address;
  @Input('directionId') directionId;
  @Input('sequence') sequence;
  @Input('shippingReference') shippingReference;
  @Input('id') id = null;
  @Input('shippingId') shippingId;
  @Input('isPreventFeedUpdate') isPreventFeedUpdate;
  @Input('repairOrderNumber') repairOrderNumber;
  @Input('repairOrderLineNumber') repairOrderLineNumber;
  @Input('directShip') set SetDirectShip(value) {
    this.directShip = value;
  };
  @Input('shippingStage') shippingStage:string;
  @Input('panel') panel: MatExpansionPanel;

  direction: ShippingDirection;
  directShip: boolean;
  directShipSubscription: Subscription;
  directionDescription:string;
  private personaService: PersonaService;
  private user: User;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService) {
    this.user = authService.getLoggedUser();
    this.personaService = new PersonaService(this.user.personaName)
  }

  private updateShipping(id) {
    const shippingUpdate = this.createUpdateObject(id);
    this.sharedService.sendUpdatedShipping(shippingUpdate);
  };

  private delete(id:number) {
    var result = confirm("Are you sure you want to delete?");
    if (result == true) {
      const shippingDelete = this.createUpdateObject(id);
        this.sharedService.sendDeletedShipping(shippingDelete);
    }
  }

  private createUpdateObject(id:number):ShippingType{
    const shippingUpdate : ShippingType = {
      id: id,
      dateDelivered: this.dateDelivered,
      shippingId: this.shippingId,
      shippingStatus: this.shippingStatus,
      dateShipped: this.dateShipped,
      directionId: this.directionId,
      address: this.address,
      carrierId: this.shippingCarrier,
      trackingLink: this.trackingLink,
      trackingNumber: this.trackingNumber,
      shippingReference: this.shippingReference,
      rOLineNumber: null,
      rONumber: null,
      rOLNumber: null,
      isPreventFeedUpdate: this.isPreventFeedUpdate,
      sequence:this.sequence
    };

    return shippingUpdate;
  }

  private isRestrictedPersona(): boolean {
    return this.personaService.hideShippingUpdates();
  }

  private isRestrictedRole(): boolean {
    return this.personaService.shippingInfoUpdateRoles().includes(this.user.roleName);
  }

  onUpdateClick(event: Event) {
    this.updateShipping(this.id);
    event.stopPropagation();
  }

  onDeleteClick(event: Event) {
    this.delete(this.id);
    event.stopPropagation();
  }

  onOpenClick():void{
    this.panel.open();
  }

  showDelete(): boolean {
    if (!this.isRestrictedRole()) {
      if (!this.isRestrictedPersona()) {
        return true;
      }
    }
    return false;
  }

  showUpdate(): boolean {
    if (!this.isRestrictedPersona()) {
      return true;
    }
    return false;
  }
}