<div class="rcm-add-shipment">
  <h2 class="rcm-add-shipment-title">{{this.title}}</h2>

  <div class="rcm-add-shipment-body">
    <div class="rcm-add-shipment-body-form" [formGroup]="shippingForm">

      <div class="rcm-add-shipment-body-form-row" mat-dialog-content [formGroup]="shippingForm">
        <mat-form-field class="mid">
          <mat-select placeholder="Shipping Direction" formControlName="directionId">
            <mat-option value="" disabled>Select Direction</mat-option>
            <mat-option value="1">
              Outbound
            </mat-option>
            <mat-option value="2">
              Inbound
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mid">
          <input matInput placeholder="Tracking Number" value="" type="text" formControlName="trackingNumber" />
        </mat-form-field>
        <mat-form-field class="mid">
          <input matInput placeholder="Tracking Link" value="" type="text" formControlName="trackingLink" />
        </mat-form-field>

        <mat-form-field class="mid">
          <input matInput placeholder="Date Shipped"
            [formControlName]="dateShippedCtlName" [matDatepicker]="dateShippedPicker" [max]="today" readonly>
          <mat-datepicker-toggle matSuffix [for]="dateShippedPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateShippedPicker></mat-datepicker>
          <mat-error *ngIf="hasError(shippingForm, 'dateShipped')">{{
            getErrorMessage(shippingForm, 'dateShipped') }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mid">
          <input matInput placeholder="Date Delivered"
            [formControlName]="dateDeliveredCtlName" [matDatepicker]="dateDeliveredPicker" [max]="today" readonly>
          <mat-datepicker-toggle matSuffix [for]="dateDeliveredPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateDeliveredPicker></mat-datepicker>
          <mat-error *ngIf="hasError(shippingForm, 'dateDelivered')">{{
            getErrorMessage(shippingForm, 'dateDelivered') }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mid">
          <mat-select placeholder="Carrier" formControlName="carrierId">
            <mat-option value="">Select Carrier</mat-option>
            <mat-option *ngFor="let carrier of carriers" value="{{carrier.id}}">
              {{carrier.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="mid">
          <input matInput placeholder="Shipping Reference" value="" type="text" formControlName="shippingReference" />
        </mat-form-field>

         <!-- Commented under #40361
        <div class="rcm-add-shipment-toggle">
          <div aar-label>Prevent Feed Update</div>
          <div class="mid">
            <aar-toggle controlName="isPreventFeedUpdate" [group]="shippingForm"></aar-toggle>
          </div>
        </div>
      -->

        <br>

        <mat-form-field class="mid" *ngIf="false">
          <mat-select placeholder="Shipping Status" formControlName="shippingStatus">
            <mat-option value="" disabled>Select Status</mat-option>
            <mat-option *ngFor="let shippingStatus of shippingStatuses" value="{{shippingStatus.shippingStatusId}}">
              {{shippingStatus.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mid">
          <textarea matInput placeholder="Address" name="questionText" cols="35" rows="3" formControlName="address"
            readonly></textarea>
        </mat-form-field>
      </div>


    </div>
  </div>

  <div class="rcm-add-shipment-footer">
    <button mat-flat-button color="primary" class="aar-button" [disabled]="shippingForm.invalid || !shippingForm.dirty"
      (click)="save()">Add</button>
    <button mat-button color="primary" (click)="onClose()">Cancel</button>
  </div>
</div>