import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Email, RepairOrder } from '@app/shared/services/api/api.models';


@Component({
  selector: 'aar-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})

export class EmailListComponent {
  @Input('messages') messages: Email[];
  @Input('isEmpty') isEmpty: boolean;
  @Input('repairOrder') repairOrder: RepairOrder;
  noActivityMessage = 'No Activity Yet';


  constructor(
    public dialog: MatDialog) { }

  
  openAddCommunicationDialog(): void {
    // const dialogRef = this.dialog.open(RcmAddCommunicationComponent);
    // dialogRef.afterClosed().subscribe(result => { });
  }

  openEmailDialog(): void {

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = { serviceOrderId: this.repairOrder.repairOrderNumber };
    // const dialogRef = this.dialog.open(RcmCommunicationEmailComponent, dialogConfig);
    //  dialogRef.afterClosed().subscribe(result => { });
  }
  openEmailDialog2(): void {

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = { serviceOrderId: this.repairOrder.repairOrderNumber };
    // const dialogRef = this.dialog.open(RcmCommunicationEmailComponent, dialogConfig);
    //  dialogRef.afterClosed().subscribe(result => { });
  }
}
