<div class="aar-sliding-section">
  <h4>Review Workscope</h4>

  <form [formGroup]="quoteForm" (ngSubmit)="submitReview($event)" *ngIf="comparisonModel" >
    <div class="aar-sliding-form-group"  *ngIf="!isFieldHidden('masterOption')">
      <div class="aar-sliding-form-control">
          <aar-toggle [group]="quoteForm" controlName="masterOption" >
            <div aar-label>Master Option</div>
          </aar-toggle>
      </div>
      <div class="aar-sliding-form-control spacer">&nbsp;</div>
      <div class="aar-sliding-form-control spacer">&nbsp;</div>

      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('supplierRef')">
        <label for="">Supplier Ref</label>
        <mat-form-field class="mat-form-field-input">
          <input type="text" [(ngModel)] ="comparisonModel.quote.supplierReference" formControlName="supplierRef" matInput>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('supplierRef')">{{getErrorMessage('supplierRef')}}</mat-error>
        </mat-form-field>
      </div>


      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('datePosted')">
        <label for="">Date on Quote</label>
        <mat-form-field  class="mat-form-field-date">
          <input matInput [matDatepicker]="picker1" formControlName="datePosted">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('datePosted')">{{getErrorMessage('datePosted')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('dateReceived')">
        <label for="">Date Quote Received</label>
        <mat-form-field  class="mat-form-field-date">
          <input matInput [matDatepicker]="picker2" formControlName="dateReceived">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('dateReceived')">{{ getErrorMessage('dateReceived') }}</mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="aar-sliding-form-group" *ngIf="!isFieldHidden('partNumberReceived')">
      <div class="aar-sliding-form-control">
        <label for="">Part Number Received</label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput formControlName="partNumberReceived">
            <!-- <input type="text" [(ngModel)]="comparisonModel.partNumberReceived" matInput formControlName="partNumberReceived" [matAutocomplete]="autoPart"> -->
            <!-- <mat-autocomplete #autoPart="matAutocomplete" floatLabel="never" class="mat-form-field-autocomplete">
              <mat-option *ngFor="let partNumber of filteredPartNumbers | async" [value]="partNumber.partNumber">{{partNumber.partNumber}}</mat-option>
            </mat-autocomplete> -->
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('partNumberReceived')">{{ getErrorMessage('partNumberReceived') }}</mat-error>
        </mat-form-field>



      </div>

      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('mismatchReasonPnId')">
        <label for="">Reason for PN Mismatch</label>
        <mat-form-field floatLabel="never" class="mat-form-field-select">
         <!-- <mat-select [(ngModel)]="comparisonModel.mismatchReasonPnId" formControlName="mismatchReasonPnId">
            <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.label">
              {{mismatchReason.label}}


            </mat-option>
          </mat-select> -->
            <mat-select [(ngModel)]="comparisonModel.mismatchReasonPnId" formControlName="mismatchReasonPnId">

            <mat-option value ="Exchange Provided">
              Exchange Provided
            </mat-option>
            <mat-option value ="Modification">
              Modification
            </mat-option>
            <mat-option value ="Unit misidentified">
              Unit misidentified
            </mat-option>
            <mat-option value ="Other">
              Other
            </mat-option>
            <mat-option value ="N/A">
              N/A
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('mismatchReasonPnId')">{{ getErrorMessage('mismatchReasonPnId') }}</mat-error>
        </mat-form-field>

      </div>
    </div>

    <div class="aar-sliding-form-group" *ngIf="!isFieldHidden(serialNumberReceivedControlName)">
      <div class="aar-sliding-form-control">
        <label for="">Quoted Serial Number</label>
        <mat-form-field floatLabel="never"  class="mat-form-field-input">
          <input type="text"  matInput [formControlName]="serialNumberReceivedControlName" [matAutocomplete]="autoSerial">
          <mat-autocomplete #autoSerial="matAutocomplete" floatLabel="never" class="mat-form-field-autocomplete">
            <mat-option *ngFor="let partNumber of filteredSerialNumberReceived | async" [value]="partNumber.partNumber">{{partNumber.partNumber}}</mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError(serialNumberReceivedControlName)">{{ getErrorMessage(serialNumberReceivedControlName) }}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden(mismatchReasonSnIdControlName)">
        <label for="">Quoted Serial Number Change Reason</label>

        <mat-form-field floatLabel="never"  class="mat-form-field-select">
          <mat-select [formControlName]="mismatchReasonSnIdControlName" >
            <mat-option *ngFor="let mismatchReason of mismatchReasons" [value]="mismatchReason.reason">
              {{mismatchReason.reasonValue}}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError(mismatchReasonSnIdControlName)">{{ getErrorMessage(mismatchReasonSnIdControlName) }}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control aar-sliding-form-control2" *ngIf="!isFieldHidden('qtyReceived')">
        <label for="">Quantity Received</label>
        <mat-form-field floatLabel="never"  class="mat-form-field-input">
          <input type="number" min="0" [(ngModel)]="comparisonModel.qTYRceived" matInput formControlName="qtyReceived" aarInputWholeNumber>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('qtyReceived')">{{ getErrorMessage('qtyReceived') }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="aar-sliding-form-group" *ngIf="!isFieldHidden('repairCategoryId')">
      <div class="aar-sliding-form-control">
        <label for="">Unit Category</label>
        <mat-form-field floatLabel="never" class="mat-form-field-select">
            <mat-select formControlName="repairCategoryId">
              <mat-option *ngFor="let category of repairCategoryLookup" [value] ="category.repairCategoryId">
                {{category.value}}
                </mat-option>
                </mat-select>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('repairCategoryId')">{{ getErrorMessage('repairCategoryId') }}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control">
        <label for="">Workscope Proposed</label>
       <mat-form-field floatLabel="never" class="mat-form-field-select">
          <mat-select [formControlName]="proposedWorkScopeIdControlName">
            <mat-option *ngFor="let workScope of workScopes" [value]="workScope.id">
              {{workScope.name}}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('proposedWorkScopeId')">{{ getErrorMessage('proposedWorkScopeId') }}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('quoteWarrantyStatus')">
        <label for="">Quoted Warranty Status</label>
        <mat-form-field floatLabel="never" class="mat-form-field-select">
          <mat-select [formControlName]="quoteWarrantyStatusControlName">
            <mat-option *ngFor="let status of quoteWarrantyStatuses" [value] ="status.id">
              {{status.quoteWarrantyStatusID + ' (' + status.reason + ')'}}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>error_outline</mat-icon>
          <mat-error *ngIf="hasError('quoteWarrantyStatus')">{{ getErrorMessage('quoteWarrantyStatus') }}</mat-error>
        </mat-form-field>
      </div>

      <div class="aar-sliding-form-control"  *ngIf="!isFieldHidden('quotedTat')">
        <label for="">Quoted TAT<span>optional</span></label>
        <mat-form-field class="mat-form-field-input">
          <input type="number" min="0" matInput [(ngModel)]="quoteLine.quotedTat" formControlName="quotedTat" aarInputWholeNumber>
        </mat-form-field>
      </div>
    </div>

    <div class="aar-sliding-form-group"  *ngIf="!isFieldHidden('findings')">
      <div class="aar-sliding-form-control aar-sliding-form-control3">
        <label for="">Quote Findings<span>optional</span></label>
        <mat-form-field floatLabel="never" class="mat-form-field-input">
          <textarea matInput formControlName="findings" [(ngModel)]="comparisonModel.findings"   class="mat-text-area">


          </textarea>
        </mat-form-field>
      </div>

    </div>

    <div class="aar-sliding-form-group">

      <div *ngIf="keywords !=''" class="aar-sliding-form-control aar-sliding-form-control4">
        <button type="button" (click)="detectKeyWords()" mat-flat-button>Detect Keywords</button>

      </div>

    </div>
      <div class="aar-sliding-form-control aar-sliding-form-control4" *ngIf="keywords !=''">
        <div class="jumbotron" style="color:lightskyblue"><h3>Detected keywords:</h3></div>
        <span class="jumbotron">{{keywords}}</span>

      </div>

    <div class="aar-sliding-form-footer">
      <div class="aar-sliding-form-footer1">
        <button color="primary" mat-flat-button>{{this.btnText}}</button>
        <span>
            <!--<button type="button" title="Click to extract text using OCR" (click)="extractQuoteDetails()" mat-flat-button>
              <i class="material-icons">insert_link</i> RO-Quote-Active.pdf
          </button>-->
        </span>
      </div>
    </div>
  </form>
</div>


