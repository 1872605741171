import { Component, NgModule, OnInit, Inject } from '@angular/core';
import { ViewChild, ElementRef} from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import {ApiService} from '@app/shared/services/api/api.service';
import { SharedService } from '@app/shared/services/shared.service';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { User } from '@app/auth/auth.models';
import { UntypedFormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';

@Component({
    selector: 'change-pass',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.scss']
  })
  export class ChangePasswordComponent {
  
    currentPassword = new UntypedFormControl('', [Validators.required]);
    newPassword = new UntypedFormControl('', [Validators.required]);
    repeatPassword = new UntypedFormControl('', [Validators.required]);
    notEqual = false;
  
    constructor(
      public dialogRef: MatDialogRef<ChangePasswordComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {}
  
    cancel(): void {
      this.dialogRef.close();
    }
  
    get invalid() {
      this.notEqual = this.newPassword.value !== this.repeatPassword.value;
  
      return (this.currentPassword.invalid && this.currentPassword.touched)
      || (this.newPassword.invalid && this.newPassword.touched)
      || this.notEqual;
    }
  
    apply(): void {
  
      if (this.data.type === 'login') {
        this.dialogRef.close(true);
        return;
      }
  
      if (this.invalid) { return; }
  
      this.dialogRef.close({
        currentPassword: this.currentPassword.value,
        newPassword: this.newPassword.value
      });
    }
  
  }

  @NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatAutocompleteModule
      ],
    exports: [ ChangePasswordComponent ],
    declarations: [ ChangePasswordComponent ]
  })
  
  export class AccountManagementModule {}