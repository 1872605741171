import { RepairOrderLineStatus } from './../../common/fragments';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { User } from '@app/auth/auth.models';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { CRITICAL_STATS_IDS, CRITICAL_STATS_CARD_LABELS } from '@app/core/constants/critical-stats-constants';
import { PERSONA_NAMES, ROLE_NAMES } from '@app/core/constants/persona-constants';
import { ICriticalStatsCard } from '@app/core/models/critical-stats-card';
import { DataTableService } from '@app/shared/components/data-table/data-table.service';
import { RcmRepairOrder, RepairOrder, RepairOrderLineCriticalStat } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { CriticalStatsService } from '@app/shared/services/criticalstats.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { RcmService } from '@app/shared/services/rcm/rcm.service';
import { SharedService } from '@app/shared/services/shared.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { isEmpty } from '@app/common/utility';
import { REPAIRORDERLINE_STATUS_DESCRIPTIONS } from '@app/core/constants/globalConstants';
import { Subscription } from 'rxjs';
import { RepairOrderUpdate } from '@app/shared/services/rcm/event/event-service/repairorder/event-model/repairorder-update';
import { RepairOrderEventService } from '@app/shared/services/rcm/event/event-service/repairorder/repairorder-event.service';

@Component({
  selector: 'aar-rcm-history',
  templateUrl: './rcm-history.component.html',
  styleUrls: ['./rcm-history.component.scss']
})

export class RcmHistoryComponent implements OnInit, OnDestroy {

  repairOrders: RcmRepairOrder[] = [];
  repairOrdersAux: RcmRepairOrder[] = [];
  repairOrdersCardFilter: RcmRepairOrder[] = [];
  tableFilterSettings: any[];
  loadingROs: boolean;
  assignedUsers: User[];
  customerList: any[] = [];
  criticalstats : RepairOrderLineCriticalStat[] =[];
  buyerCodeList: any[] = [];
  userList: any[] = [];
  filterConfig = {
    active: false,
    filter: null
  };
  showOnly:string;

  currentUser: User;
  columnsSettings = [];
  /* Commented under #40361
  directShip = [];
  */
  filters = {}

  criticalStatsIdClosed = CRITICAL_STATS_IDS.RO_CLOSED;
  criticalStatsIdClosedDescription = REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderClosed;
  criticalStatsIdCanceled = CRITICAL_STATS_IDS.RO_CANCELED;
  criticalStatsIdCanceledDescription = REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled;

  repairManagerStatsCards: ICriticalStatsCard[] = [
    { id: CRITICAL_STATS_IDS.RO_CLOSED, label: CRITICAL_STATS_CARD_LABELS.RO_CLOSED, isEnabled: true, value: 0 },
    { id: CRITICAL_STATS_IDS.RO_CANCELED, label: CRITICAL_STATS_CARD_LABELS.RO_CANCELED, isEnabled: true, value: 0 },
  ];

  criticalStatsCards: ICriticalStatsCard[];
  private personaService: PersonaService;
  
  private authServiceUserSubscription$: Subscription;
  private filterSelectedSubscription$: Subscription;
  private getCriticalStatsSubscription$: Subscription;
  private getRcmRepairOrdersSubscription$: Subscription;
  private routerEventsSubscription$: Subscription;
  private routeQueryParamsSubscription$: Subscription;
  private sharedServiceGetObjectSubscription$: Subscription;
  private sharedServiceGetRepairOrdersFiltersSubscription$: Subscription;
  private sharedServiceGetSearchKeywordSubscription$: Subscription;
  private externalSubscriptions$ = new Subscription;

  searchKeyword:string = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private rcmService: RcmService,
    private criticalStatsService: CriticalStatsService,
    private sharedService: SharedService,
    private dataTableService: DataTableService,
    private repairOrderEventService: RepairOrderEventService) {
      const user = this.authService.getLoggedUser();
      this.personaService = new PersonaService(user.personaName);
   }

  ngOnInit() {
    this.routerEventsSubscription$ = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && val.url === '/rcm') {
        let ros = this.repairOrdersAux;

        this.repairOrdersAux = [];
        setTimeout(() => {
          this.repairOrdersAux = ros;
          ros = null;
        });
      }
    });

    this.filterSelectedSubscription$ = this.rcmService.filterSelected$.subscribe(filterBy => this.filterByStatus(filterBy));
    this.routeQueryParamsSubscription$ = this.route.queryParams.subscribe(params => {
      this.showOnly = params.showOnly
      if (params.filterBy) {
        this.filterByStatus(params.filterBy);
      }
    });

    // TODO This subscription is for fixing the error with table, otherwise will show all ROs without pagination
    this.getCurrentUser();
    this.setGridHeaders();
    this.getRepairOrders();


    this.criticalStatsCards = this.repairManagerStatsCards;

    this.loadingService.startLoading();

    this.sharedServiceGetSearchKeywordSubscription$ = this.sharedService.getSearchKeyWord().subscribe((message) => {
      this.searchKeyword = message;

      this.filterByKeyWord();
      this.filterByDropdownFilters();
      this.calculateCriticalStats(this.repairOrdersAux);
      this.filterByCards();
    });
    this.sharedServiceGetObjectSubscription$ = this.sharedService.getObject().subscribe((filters) => {
      this.filters = filters;
      this.filterByKeyWord();
      this.filterByDropdownFilters();
      this.calculateCriticalStats(this.repairOrdersAux);
      this.filterByCards();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeFromExternalEvents();
    if (this.authServiceUserSubscription$ !== undefined) {
      this.authServiceUserSubscription$.unsubscribe();
    }
    if (this.filterSelectedSubscription$ !== undefined) {
      this.filterSelectedSubscription$.unsubscribe();
    }
    if (this.getCriticalStatsSubscription$ !== undefined) {
      this.getCriticalStatsSubscription$.unsubscribe();
    }
    if (this.getRcmRepairOrdersSubscription$ !== undefined) {
      this.getRcmRepairOrdersSubscription$.unsubscribe();
    }
    if (this.routerEventsSubscription$ !== undefined) {
      this.routerEventsSubscription$.unsubscribe();
    }
    if (this.routeQueryParamsSubscription$ !== undefined) {
      this.routeQueryParamsSubscription$.unsubscribe();
    }
    if (this.sharedServiceGetObjectSubscription$ !== undefined) {
      this.sharedServiceGetObjectSubscription$.unsubscribe();
    }
    if (this.sharedServiceGetRepairOrdersFiltersSubscription$ !== undefined) {
      this.sharedServiceGetRepairOrdersFiltersSubscription$.unsubscribe();
    }
    if (this.sharedServiceGetSearchKeywordSubscription$ !== undefined) {
      this.sharedServiceGetSearchKeywordSubscription$.unsubscribe();
    }
  }

  private unsubscribeFromExternalEvents():void{
    this.externalSubscriptions$.unsubscribe();
  }

  getCurrentUser(): void {
    if (this.authService.isUserLoggedIn())
      this.currentUser = this.authService.getLoggedUser();

    this.authServiceUserSubscription$ = this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  setGridHeaders():void {
    if (this.currentUser.roleName.toUpperCase() === ROLE_NAMES.SUPPLIER) {
      this.columnsSettings = [
        /* Commented under #40361
        { name: "poNumber", header: "Purchase Order" },
         */
        { name: "partNumber", header: "Part Number" },
        { name: "serialNumber", header: "Serial Number" },
        { name: "supplierName", header: "Supplier" },
        { name: "repairLineStatus", header: "Status" }
      ];
    }else{
      this.columnsSettings = this.personaService.getDataGridColumms(ComponentType.RCMGridHistorical);
    }
  }

  getRepairOrders(): void {
    this.getCurrentUser();
    this.getRcmRepairOrdersSubscription$ = this.apiService.getRcmRepairOrders()
      .subscribe(repairOrders => {
        this.loadingService.stopLoading();
        this.repairOrders = repairOrders.map((order) => ({
          ...order,
          buyerName: (order.buyerName || '').toUpperCase(),
          customerAccountManager: (order.repairOrderLine.customerAccountManager || '').toUpperCase(),
          /* Commented under #40361
          directShip: order.repairOrderLine.directShip ? "YES" : "NO",
          */
          repairLineStatus: (order.repairOrderLine.repairLineStatus || '').toUpperCase(),
          partNumber:order.repairOrderLine.partNumber,
          serialNumber:order.repairOrderLine.serialNumber
        })) as any;
        this.loadingROs = true;
        this.repairOrdersAux = this.repairOrders.filter(x=> x.repairOrderLine.repairLineStatusDescription === this.criticalStatsIdClosedDescription);
        this.calculateCriticalStats(this.repairOrders);
        this.loadingROs = false;
      }, err => {
        this.loadingService.loadingError();
      });
  }

  calculateCriticalStats(repairOrders: RcmRepairOrder[]) : void{
    this.getCriticalStatsSubscription$ = this.apiService.getCriticalStats()
    .subscribe(criticalstats => {
      this.criticalstats = criticalstats;
      const statuses = this.criticalStatsService.calculateHistoricCriticalStats(this.currentUser.roleName, criticalstats, repairOrders);
      this.criticalStatsCards.forEach(card => {
        card.value = statuses[card.id];
      });

      this.filterByCards();
      this.getRepairOrdersFilters();
    }, err => {
      console.log('Critical Stats Calculation error : ' + err);
    });

  }

  getRepairOrdersFilters(): void {
    this.sharedServiceGetRepairOrdersFiltersSubscription$ = this.apiService.getRepairOrdersFilters().subscribe(filterData => {
      if (this.currentUser && this.currentUser.roleName === ROLE_NAMES.CUSTOMER) {
        this.tableFilterSettings = [
          {
            name: 'repairLineStatus',
            type: 'dropdown',
            placeholder: 'Status',
            options: this.convertFilters(filterData.repairOrderStatuses)
          }
        ];
      }
      else if (this.currentUser && this.currentUser.roleName === ROLE_NAMES.REPAIR_MANAGER) {
        this.tableFilterSettings = [
          {
            name: 'repairLineStatus',
            type: 'dropdown',
            placeholder: 'Status',
            options: []
          },
          {
            name: "supplierName",
            type: "dropdown",
            placeholder: "Supplier",
            options: []
          },
          {
            name: "buyerName",
            type: "dropdown",
            placeholder: "Buyer",
            options: []
          },
          {
            name: 'customerAccountName',
            type: 'dropdown',
            placeholder: 'Customer',
            options: []
          },
          {
            name: "customerAccountManager",
            type: "dropdown",
            placeholder: "CAM",
            options: []
          }
          /* Commented under #40361
          {
            name: "directShip",
            type: "dropdown",
            placeholder: "Direct Ship",
            options: []
          }*/

        ];
      }
    });
  }

  convertFilters(filters): any[] {
    try {
      const regex = /_/g;
      return filters.map(filter => {
        if (filter.name !== undefined) {
          return {
            label: filter.name.replace(regex, ' '),
            value: filter.name
          };
        }
        else {
          return {
            label: "",
            value: ""
          };
        }
      });
    }
    catch (e) {

    }
  }

  filterByStatus(filter: string = null): void {
    (filter == null) ?
      this.filterConfig.filter = null :
      this.filterConfig.filter = filter;

    this.filterConfig.active = true;

    this.filterByKeyWord();
    this.filterByDropdownFilters()
    this.filterByCards();

  }


  filterByDropdownFilters() {
    Object.keys(this.filters).map(key => {
      if (!isEmpty(this.filters[key])) {
        this.repairOrdersAux = this.repairOrdersAux.filter( order => order[key] ===  this.filters[key] )
      }
    })
  }

  filterByKeyWord() {
    const message =  this.searchKeyword || ''
    this.repairOrdersAux = this.repairOrders.filter(x => x.repairOrderLine.repairLineStatusDescription === this.criticalStatsIdClosedDescription ||
      x.repairOrderLine.repairLineStatusDescription === this.criticalStatsIdCanceledDescription);

    this.repairOrdersAux = this.repairOrdersAux.filter(
      (x) =>
        (x.repairOrderNumber &&
          x.repairOrderNumber
            .toLowerCase()
            .includes(message.toLowerCase())) ||
        (x.repairOrderLine.partNumber &&
          x.repairOrderLine.partNumber.toLowerCase().includes(message.toLowerCase()))
        ||
        (x.supplierName &&
          x.supplierName.toLowerCase().includes(message.toLowerCase()))
          ||
          /* Commented under #40361
        (x.poNumber &&
          x.poNumber.toLowerCase().includes(message.toLowerCase()))
          
          ||
          */
        (x.repairOrderLine.serialNumber &&
          x.repairOrderLine.serialNumber.toLowerCase().includes(message.toLowerCase()))
          ||
        (x.repairOrderLine.customerReference &&
          x.repairOrderLine.customerReference.toLowerCase().includes(message.toLowerCase()))
    );

  }

  filterByCards(): void {
    if (this.filterConfig.active && this.filterConfig.filter !== null) {
        this.repairOrdersAux = (this.filterConfig.filter === this.criticalStatsIdClosed) ?
          this.repairOrdersAux.filter((ro) => this.criticalStatsIdClosedDescription.includes(ro.repairOrderLine.repairLineStatusDescription)) :
          this.repairOrdersAux.filter((ro) => this.criticalStatsIdCanceledDescription.includes(ro.repairOrderLine.repairLineStatusDescription));
    }else{
      this.filterConfig.active = true;
      this.filterConfig.filter = this.criticalStatsIdClosed;
    }

    this.dataTableService.updateTable(this.filters);
  }

  isCardActive(cardId: string): boolean
  {
    return this.filterConfig.active && this.filterConfig.filter === cardId;
  }
}
