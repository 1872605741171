<!-- TODO: Create component for aar-timeline-item element -->
<ul class="aar-timeline">
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon">
      <mat-icon color="primary" svgIcon="arrow-right"></mat-icon>
    </span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
  <li class="aar-timeline-item">
    <p class="aar-timeline-text">Some information about this activity</p>
    <span class="aar-timeline-date">15 mins ago</span>
    <span class="aar-timeline-icon"></span>
  </li>
</ul>
