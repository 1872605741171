import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '@app/shared/services/api/api.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { SharedService } from '@app/shared/services/shared.service';
import {LoadingComponent} from '@app/shared/components/loading/loading.component';
import { User } from '@app/auth/auth.models';
import {
  EmailCategories,
  EmailMetadataInput,
  EmailTags,
  EmailTemplate
} from '@app/shared/services/api/api.models';
import { EmailTemplates, EmailTemplatesCategories, EmailTemplatesTags, EmailTemplatesDetails } from '@app/core/constants/EmailTemplates';

@Component({
  selector: 'aar-rcm-communication-email',
  templateUrl: './rcm-communication-email.component.html',
  styleUrls: ['./rcm-communication-email.component.scss']
})
export class RcmCommunicationEmailComponent implements OnInit {
  composeForm: UntypedFormGroup;
  templates: EmailTemplate[] = EmailTemplates;
  tags: EmailTags[] = EmailTemplatesTags;
  categories: EmailCategories[] = EmailTemplatesCategories;
  email: EmailMetadataInput;
  user: User;
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<RcmCommunicationEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private sharedServices: SharedService,
    private apiService: ApiService,
    private authService: AuthService
  ) {
   }

  ngOnInit() {
   // this.apiService.getEmailTemplates().subscribe(templates => this.templates = templates);
   // this.apiService.getEmailTags().subscribe(tags => this.tags = tags);
  //  this.apiService.getEmailCategories().subscribe(categories => this.categories = categories);
    this.user = this.authService.getLoggedUser();

    this.composeForm = new UntypedFormGroup({
      templateId: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required] ),
      tagId: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required] ),
      categoryId: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required] ),
      fromEmail: new UntypedFormControl( {value: this.user.email, disabled: false}, [Validators.required, Validators.email] ),
      ccEmail: new UntypedFormControl( {value
        : '', disabled: false}, [ Validators.email] ),
      toEmail: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required, Validators.email] ),
      bccEmail: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.email] ),
      subject: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required] ),
      body: new UntypedFormControl( {value
        : '', disabled: false}, [Validators.required] ),
    });

    this.email = {
      tagId: 0,
      priority: 'High',
      ccEmail: '',
      markAsRead: 'No',
      emailImportLogUnreviewed: 'false',
      toEmail: '',
      sensitivity: 'Normal',
      bccEmail: '',
      subject: '',
      mailboxEmailAddress: '',
      fromEmail: '',
      categoryId: '',
      templateId: '',
      priorityId: '',
      requireReply: 'true',
      body: '',
      serviceOrderId: this.data.serviceOrderId,
      emailImportLogFailed: 'false',
      importance: 'Low',
      customerId: '',
      isBodyHTML: 'true',
      senderUserId: this.user.id,
      rolNumber: this.data.serviceOrderId.toString()
    };
  }

  onClose() {
    this.dialogRef.close();
  }

  submitEmail(): void {
    this.loading = true;
    if (this.composeForm.valid) {
      this.email.templateId = this.composeForm.controls['templateId'].value.toString();
      // this.email.tagId = this.composeForm.controls['tagId'].value;
      this.email.categoryId = this.composeForm.controls['categoryId'].value.toString();
      this.email.fromEmail = this.composeForm.controls['fromEmail'].value;
      this.email.ccEmail = this.composeForm.controls['ccEmail'].value;
      this.email.toEmail = this.composeForm.controls['toEmail'].value;
      this.email.bccEmail = this.composeForm.controls['bccEmail'].value;
      this.email.subject = this.composeForm.controls['subject'].value;
      this.email.body = this.composeForm.controls['body'].value;

      //console.log(JSON.stringify(this.email));

      this.apiService.sendCommunicationEmail(this.email)

        .subscribe(sentEmail => {
            this.loading = false;
            this.sendMessage();
            this.onClose();
            this.openSnackBar('Email sent successfully.');
          },
            err => {
               this.openSnackBar('Email not sent successfully.');
               this.loading = false;
               this.onClose();

        });
          //  ;
    }
  }
  sendMessage(): void {
    // send message to subscribers via observable subject
    this.sharedServices.sendMessage('EmailSent');
   }
  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  selectTemplate(value) {
    this.email = EmailTemplatesDetails[value];
    this.email.subject = this.email.subject.replace('{XXXXX}', this.data.serviceOrderId);
    this.email.body = this.email.body.replace('{RO_URL}', document.location.href);
    this.email.serviceOrderId = this.data.serviceOrderId;
    this.email.senderUserId = this.user.id;
    this.email.rolNumber = this.data.serviceOrderId;
  }
}
