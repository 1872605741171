import { EmailTemplate, EmailCategories, EmailTags, EmailMetadataInput } from '@app/shared/services/api/api.models';

export const EmailTemplates: EmailTemplate[] = [
    {
        iD: 0,
        templateID: '0',
        templateName: 'Urgent Requote'
    },
    {
        iD: 1,
        templateID: '1',
        templateName: 'Warranty Review'
    },
    {
        iD: 2,
        templateID: '2',
        templateName: 'Supplier Approval'
    },
    {
        iD: 3,
        templateID: '3',
        templateName: 'Expedite Alert'
    }
];

export const EmailTemplatesTags: EmailTags[] = [
    {iD: 1, name: 'Requote'},
    {iD: 2, name: 'Warranty'},
    {iD: 3, name: 'Approval'},
    {iD: 4, name: 'Expedite'},
];

export const EmailTemplatesCategories: EmailCategories[] = [
    {iD: 1, categoryID: '1', categoryName: 'Supplier'},
    {iD: 2, categoryID: '2', categoryName: 'Internal'},
    {iD: 3, categoryID: '3', categoryName: 'Requote'},
];

export const EmailTemplatesDetails: EmailMetadataInput[] = [{
    tagId: 1,
    categoryId: '1',
    priority: 'High',
    ccEmail: '',
    toEmail: 'test@test.com',
    bccEmail: '',
    subject: 'URGENT: Updated Quote Needed Re: RO-{XXXXX}',
    body: `Hello,
I hope you can help me in regards to this RO (see subject line). It's an urgent need, so please supply an updated quote as soon as possible.
{RO_URL}`,
    markAsRead: 'No',
    emailImportLogUnreviewed: 'No',
    sensitivity: 'Normal',
    mailboxEmailAddress: '',
    fromEmail: '',
    templateId: '',
    priorityId: '',
    requireReply: 'Yes',
    serviceOrderId: '0',
    emailImportLogFailed: 'No',
    importance: 'Low',
    customerId: '',
    isBodyHTML: 'Yes',
    senderUserId: 0,
    rolNumber: '0'
},

{
    tagId: 2,
    categoryId: '2',
    priority: 'High',
    ccEmail: '',
    toEmail: 'warrantyspecialist@aarcorp.com',
    bccEmail: '',
    subject: 'Warranty Review Request: RO-{XXXXX}',
    body: `Hello,
Please review the following RO and provide feedback on its warranty status. Please respond to this thread with any additional questions related to this matter or with a final response/resolution once you complete your initial review.
Thanks and have a great day.
Sent from Airvolution {RO_URL}.`,
    markAsRead: 'No',
    emailImportLogUnreviewed: 'No',
    sensitivity: 'Normal',
    mailboxEmailAddress: '',
    fromEmail: '',
    templateId: '',
    priorityId: '',
    requireReply: 'Yes',
    serviceOrderId: '0',
    emailImportLogFailed: 'No',
    importance: 'Low',
    customerId: '',
    isBodyHTML: 'Yes',
    senderUserId: 0,
    rolNumber: '0'
},

{
    tagId: 3,
    categoryId: '1',
    priority: 'High',
    ccEmail: '',
    toEmail: 'supplier@supplier.com',
    bccEmail: '',
    subject: 'RO-{XXXXX} Approved',
    body: `This RO has been approved. Please begin work immediately. Reply to this thread with any additional questions or to provide an Estimated Completion Date if you have not already done so.
{RO_URL}`,
    markAsRead: 'No',
    emailImportLogUnreviewed: 'No',
    sensitivity: 'Normal',
    mailboxEmailAddress: '',
    fromEmail: '',
    templateId: '',
    priorityId: '',
    requireReply: 'Yes',
    serviceOrderId: '0',
    emailImportLogFailed: 'No',
    importance: 'Low',
    customerId: '',
    isBodyHTML: 'Yes',
    senderUserId: 0,
    rolNumber: '0'
},

{
    tagId: 4,
    categoryId: '1',
    priority: 'High',
    ccEmail: '',
    toEmail: 'supplier@supplier.com',
    bccEmail: '',
    subject: 'RO-{XXXXX} - Status Changed',
    body: `The above referenced RO has been marked as urgent/high priority. Please expedite and follow up with any updates to SPT or ECD.
Thank you.
{RO_URL}`,
    markAsRead: 'No',
    emailImportLogUnreviewed: 'No',
    sensitivity: 'Normal',
    mailboxEmailAddress: '',
    fromEmail: '',
    templateId: '',
    priorityId: '',
    requireReply: 'Yes',
    serviceOrderId: '0',
    emailImportLogFailed: 'No',
    importance: 'Low',
    customerId: '',
    isBodyHTML: 'Yes',
    senderUserId: 0,
    rolNumber: '0'
}
];
