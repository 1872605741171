import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentQueryApiService {

  constructor(
    private apollo: Apollo,
    private authService: AuthService
    ) { }

  getAllRepairOrderLinesAssociatedToDocuments(): Observable<number[]> {
    const companyIdRef = this.authService.getLoggedUser().companyID;
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only',
        query: gql`
        query
        {
          repairOrderLinesAssociatedToDocuments(companyIdRef:${companyIdRef}) {             
            repairOrderLineIds
        }
      }
      `,
      })
      .valueChanges
      .pipe(map(result => result.data.repairOrderLinesAssociatedToDocuments.repairOrderLineIds));
  }
}
