import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { AuthPayload } from '@app/auth/auth.models';
import { PERSONA_NAMES } from '@app/core/constants/persona-constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginAcceptance, TermsConditionsPrivacyAcceptanceComponent } from './terms-conditions-privacy-acceptance.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'aar-login-saml-idp',
  templateUrl: './login-saml-idp.component.html',
  styleUrls: ['./login-saml-idp.component.scss']
})
export class LoginSamlIdpComponent implements OnInit {
  userToken: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  displayHomePage(payload: AuthPayload) {
    if (payload.user.personaName.toUpperCase() !== PERSONA_NAMES.WAREHOUSE) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/rcm']);
    }
  }

  private repairCycleCompanyDetails(authPayload:AuthPayload ):Observable<AuthPayload>{
    
    this.authService.setUserToken(authPayload);
    this.authService.setUser(authPayload.user);
    localStorage.setItem(environment.samlIdpLoginErrorStorageName, '');
    const repairCycleCompanyId = this.authService.getCompanyDetails();

    if(repairCycleCompanyId){
      if(repairCycleCompanyId.companyIdRef){
        if(repairCycleCompanyId.companyIdRef === authPayload.user.companyID){
          return of(authPayload);
        }
      }
    }
    return this.authService.getRepairCycleCompanyId().pipe(map((repairCycleCompany) => {
      authPayload.user.repairCycleCompanyId = repairCycleCompany.companyId;
      this.authService.setUserToken(authPayload);
      this.authService.setUser(authPayload.user);
      this.authService.setCompanyDetails(repairCycleCompany);
      return authPayload;
    }));
  }
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.userToken = params['token'];
      console.log(`params['token']=${params['token']}`);
    });

    const splitUserToken = this.decryptUserToken(this.userToken);
    // console.log(`splitUserToken=${splitUserToken}`);

    this.authService.login(splitUserToken[0], splitUserToken[1])
        .subscribe((data: User) => {
          const payload: AuthPayload = {
            user: data,
            token: data.token
          };

          const loginAcceptance: LoginAcceptance = {
            userId: data.id,
            hasAcceptedPrivacyPolicy: data.hasAcceptedPrivacyPolicy,
            hasAcceptedTermsAndConditions: data.hasAcceptedTermsAndConditions
          };

          if (!loginAcceptance.hasAcceptedTermsAndConditions || !loginAcceptance.hasAcceptedPrivacyPolicy ) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = loginAcceptance;
            dialogConfig.backdropClass = '';
            const dialogRef = this.dialog.open(TermsConditionsPrivacyAcceptanceComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(
              popupData => {
                if (popupData) {
                  loginAcceptance.hasAcceptedPrivacyPolicy = popupData.hasAcceptedPrivacyPolicy;
                  loginAcceptance.hasAcceptedTermsAndConditions = popupData.hasAcceptedTermsAndConditions;
                }

                if (loginAcceptance.hasAcceptedPrivacyPolicy && loginAcceptance.hasAcceptedTermsAndConditions) {
                  this.repairCycleCompanyDetails(payload).subscribe((authPayload:AuthPayload)=>{
                    this.displayHomePage(authPayload);
                  });
                } else {
                  localStorage.setItem(environment.samlIdpLoginErrorStorageName, 'SAML Idp-Initiated error: invalid login.');
                  this.authService.processLogout();
                  this.router.navigate(['/login'])
                }
              }
            );
          } else {
            this.repairCycleCompanyDetails(payload).subscribe((authPayload:AuthPayload)=>{
              this.displayHomePage(authPayload);
            });
          }
        }, (error) => {
          localStorage.setItem(environment.samlIdpLoginErrorStorageName, 'SAML Idp-Initiated error: invalid login.');
          this.router.navigate(['/login']);
         });
  }

  decryptUserToken(userToken: string) {
    try {
      const iv = CryptoJS.enc.Hex.parse('2ea861281c9bc5b3db265faf0fec5a2b');
      const Pass = CryptoJS.enc.Utf8.parse('PSuF7e&nPtc*djy^EjqsY%HfNk1uAN#');
      const Salt = CryptoJS.enc.Utf8.parse('5qt14.!J)w1o0mUCruUE');
      const key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
      const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(atob(userToken).substring(1))});
      const decryptedStr = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations,
        { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });

      const decrypted = decryptedStr.toString(CryptoJS.enc.Utf8);
      // console.log(`decrypted=${decrypted}`);
      return decrypted.split('|', 2);
    } catch {
      localStorage.setItem(environment.samlIdpLoginErrorStorageName, 'SAML Idp-Initiated error: invalid token.');
      this.router.navigate(['/login']);
    }
  }

}
