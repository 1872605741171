import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration } from 'c3';
import { AuthService } from '@app/auth/services/auth/auth.service';

@Component({
  selector: 'aar-cost-control-results',
  templateUrl: './cost-control-results.component.html',
  styleUrls: ['./cost-control-results.component.scss']
})
export class CostControlResultsComponent implements OnInit, OnChanges {

  @Input('plotDates') plotDates = [];
  @Input('plotCosts') plotCosts = [];
  @Input('tableData') tableData: any[] = [];

  dateRangeF = '2018';
  dateRangeL = '2019';

  costL = 1;
  costH = 1;
  
  constructor(public authService: AuthService) {}
  
  c3ChartConfigBar: ChartConfiguration = {
    data: {
      columns: [
        ['data1'].concat(this.plotCosts),
      ],
      types: {
        data1: 'area',
      },
      colors: {
        'data1': '#83CBF1',
      },
    },
    grid: {
        x: {
            show: false
        },
        y: {
            show: true
        }
    },
    legend: {
      hide: true,
    },
    axis: {
      x: {show: true},
      y: {show: true}
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plotCosts || changes.plotDates) {
      let plotDates = JSON.parse(JSON.stringify(this.plotDates));
      let plotCosts = JSON.parse(JSON.stringify(this.plotCosts));
      if (changes.plotCosts) {
        plotCosts = JSON.parse(JSON.stringify(changes.plotCosts.currentValue));
      }

      if (changes.plotDates) {
        plotDates = JSON.parse(JSON.stringify(changes.plotDates.currentValue));
      }
      if (plotDates.length > 0) {
        this.dateRangeF = plotDates[0];
        this.dateRangeL = plotDates[plotDates.length - 1];
      }

      if (plotCosts.length > 0) {
        this.costL = Math.min(...plotCosts);
        this.costH = Math.max(...plotCosts);
      }

      this.c3ChartConfigBar = {
        data: {
          x : 'x',
          columns: [
            ['x'].concat(plotDates),
            ['data1'].concat(plotCosts),
          ],
          types: {
            data1: 'area',
          },
          colors: {
            'data1': '#83CBF1',
          },
        },
        grid: {
            x: {
                show: false
            },
            y: {
                show: true
            }
        },
        legend: {
          hide: true,
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              rotate: 60,
              multiline: false
          },
            show: true},
          y: {show: true}
        }
      };
    }
  }


  ngOnInit() {
  }

}
