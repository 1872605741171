import { formatNumber } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'decimalLocalisedPipe' })
export class DecimalLocalisedPipe implements PipeTransform {

    readonly locale:string;

    constructor(@Inject(LOCALE_ID) localeId) { 

        this.locale = localeId.toString();
    }

    transform(value: string | number): string {
        value = Number(value);

        if (isNaN(value)) {
            return `Argument ${value} is not an acceptable value`;
        }
        
        return formatNumber(value, this.locale, '1.2-2');
    }
}