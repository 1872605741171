<div class="aar-dashboard aar-page">
  <div class="content">

    <ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">
      <ng-template [ngIf]="!loading">

        <div class="aar-dash-cards-container">
          <div class="equalWidth" *ngFor="let card of criticalStatsCards">
            <aar-dash-card type="b" [criticalStatId]="card.id" label={{card.label}} [isEnabled]="true"
              [value]="card.value" goTo="/rcm" [filterBy]="card.id"></aar-dash-card>
          </div>
        </div>

        <ng-template [ngIf]="currentUser.roleName === 'REPAIR_ADMIN'">
          <div class="aar-dashboard-tables-container">
            <div class="aar-stacked-columns">
              <div class="iframeContainer" [innerHTML]="domoEmbedUrlDashboardReport | safe: 'html'"></div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="currentUser.roleName === 'REPAIR_MANAGER'">
          <div class="aar-dashboard-tables-container">
            <div class="aar-stacked-columns">
              <div class="iframeContainer" [innerHTML]="domoEmbedUrlDashboardReport | safe: 'html'"></div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="currentUser.roleName !== 'REPAIR_ADMIN' && currentUser.roleName !== 'REPAIR_MANAGER'">
          <h2>This Airvolution feature has not been activated.</h2>
          <p>This Airvolution feature has not been activated for this account. Please contact your AAR representative
            for more information.</p>
        </div>

      </ng-template>
    </ng-template>

    <ng-template #children>
      <router-outlet></router-outlet>
    </ng-template>
  </div>
</div>