import { Injectable } from '@angular/core';
import { CriticalStatNames, CriticalStatRepairOrder, CriticalStats, RcmRepairOrder, RepairOrderLineCriticalStat } from './api/api.models';
import { HelperService } from './helper.service';
import { REPAIRORDERLINE_STATUS_DESCRIPTIONS } from '@app/core/constants/globalConstants';
import { CRITICAL_STATS_IDS } from '@app/core/constants/critical-stats-constants';

@Injectable()
export class CriticalStatsService {

      statuses : CriticalStatNames = {
        NEW_RO: 0,
        UNIT_SHIPPED_OUTBOUND: 0,
        SUPPLIER_RECEIPT: 0,
        QUOTE_PROCESSING: 0,
        QUOTE_APPROVED: 0,
        QUOTE_REVIEW: 0,
        IN_FINAL_REPAIR: 0,
        RECEIPT_INSPECTION: 0,
        UNIT_SHIPPED_INBOUND: 0,
        REPAIR_IN_PROGRESS: 0,
        RO_CLOSED: 0,
        ALL_RO: 0,
        SUPPLIER_EVALUATION: 0,
        PENDING_CUSTOMER_APPROVAL: 0,
        APPROVAL_REQUIRED: 0,
        PENDING_INTERNAL_AUTHORIZATION: 0,
        REQUIRES_S0: 0,
        PENDING_CUSTOMER_SHIPMENT: 0,
        AOG: 0,
        PAST_DUE: 0,
        RO_CANCELED: 0,
        PENDING_APPROVAL: 0
      };

      statsUsingOpenOrders = [
        CRITICAL_STATS_IDS.SUPPLIER_EVALUATION,
        CRITICAL_STATS_IDS.PAST_DUE,
        CRITICAL_STATS_IDS.AOG
      ];
      statsUsingOpenAndClosedOrders = [
        CRITICAL_STATS_IDS.REQUIRES_S0,
        CRITICAL_STATS_IDS.UNIT_SHIPPED_INBOUND,
        CRITICAL_STATS_IDS.UNIT_SHIPPED_OUTBOUND
      ];

      criticalStatsIdClosedDescription = REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderClosed;
      criticalStatsIdCanceledDescription = REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled;

    /**
     * Resets all of the CriticalStats statuses to 0
     */
    resetCriticalStats() {
      HelperService.setObjectProperties(this.statuses, 0);
    }

    private IncrementCriticalStatValue(curStat: RepairOrderLineCriticalStat, repairOrders: CriticalStatRepairOrder[]) {
      if (repairOrders.map( x => x.repairOrderLineId).includes(curStat.repairOrderLineId)) {
        this.statuses[curStat.criticalStats.name]++;
      }
    }

    // NOTE: The repairOrders parameter requires all orders (open, closed & cancelled) with, if applicable)
    // specific filters applied (keyword search & drop-down data filters)
    calculateCriticalStats(roleName: string, stats: RepairOrderLineCriticalStat[], repairOrders: CriticalStatRepairOrder[]): CriticalStatNames {
      this.resetCriticalStats(); // Reset the critical stats all back to 0 before calculating them
      if (repairOrders.length === 0 ) {
          return this.statuses;
      }

      const openOrders = repairOrders.filter(x =>
        x.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled &&
        x.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderClosed);
      const openAndClosedOrders = repairOrders.filter(x =>
        x.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled);

      stats.map((curStat) => {
        if (this.statsUsingOpenOrders.includes(curStat.criticalStats.name)) {
          this.IncrementCriticalStatValue(curStat, openOrders);
        } else if (this.statsUsingOpenAndClosedOrders.includes(curStat.criticalStats.name)) {
          this.IncrementCriticalStatValue(curStat, openAndClosedOrders);
        } else {
          this.IncrementCriticalStatValue(curStat, repairOrders);
        }
      });

      this.statuses.ALL_RO = openOrders.length;

      return this.statuses;
    }

   calculateHistoricCriticalStats(roleName: string, stats : RepairOrderLineCriticalStat[], repairOrders : RcmRepairOrder[]) : CriticalStatNames {
    this.resetCriticalStats(); // Reset the critical stats all back to 0 before calculating them
    if (repairOrders.length == 0)
        return this.statuses;

    stats.map(curStat => {
      if (repairOrders.map( x => x.repairOrderLineId).includes(curStat.repairOrderLineId)) {
        this.statuses[curStat.criticalStats.name]++;
      }
    })

    this.statuses.RO_CLOSED = repairOrders.filter(x=> x.repairOrderLine.repairLineStatusDescription === this.criticalStatsIdClosedDescription).length;
    this.statuses.RO_CANCELED = repairOrders.filter(x=> x.repairOrderLine.repairLineStatusDescription === this.criticalStatsIdCanceledDescription).length;

    return this.statuses;
   }

}
