import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { AppMaterialModule } from '@app/app-material.module';
import { SuppliersComponent } from './suppliers.component';
import { SupplierWipComponent } from '@app/suppliers/supplier-session/supplier-wip/supplier-wip.component';
import { SupplierArchiveComponent } from '@app/suppliers/supplier-session/supplier-archive/supplier-archive.component';
import { SupplierCarriersComponent } from '@app/suppliers/supplier-session/supplier-carriers/supplier-carriers.component';
import {SupplierQuoteDropComponent} from '@app/suppliers/supplier-session/supplier-quote-drop/supplier-quote-drop.component';
import { DashCardModule, DataTableModule, DragAndDropUploaderModule } from '@app/shared/components';
import { SupplierAddQuoteComponent } from './supplier-session/supplier-add-quote/supplier-add-quote.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    AppMaterialModule,
    DashCardModule,
    DataTableModule,
    DragAndDropUploaderModule,
    MatIconModule,
    MatFormFieldModule,
    MatTabsModule
  ],
  providers: [],
  declarations: [
    SuppliersComponent,
    SupplierWipComponent,
    SupplierArchiveComponent,
    SupplierCarriersComponent,
    SupplierQuoteDropComponent,
    SupplierAddQuoteComponent
  ]
})
export class SuppliersModule { }
