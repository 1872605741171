import { Component, Input } from '@angular/core';

@Component({
  selector: 'aar-rcm-shipping-header-title',
  templateUrl: './rcm-shipping-header-title.component.html',
  styleUrls: ['./rcm-shipping-header-title.component.scss']
})
export class RcmShippingHeaderTitleComponent{

  @Input('directionDescription') set setDirectionDescription(value){
    this.directionDescription = value ? value : 'New Shipping Record';
  }
  @Input('shippingStatus') shippingStatus: string;
  @Input('shippingStage') shippingStage:string;

  directionDescription:string;
}