<div class="aar-suppliers-wip aar-page">
  <div class="content">

    <div class="aar-suppliers-wip-cards-container">
      <aar-dash-card
        type="a"
        label="Total Open ROs"
        [value]="stats.open"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Urgent ROs"
        [value]="stats.urgent"
        [isEnabled]="false"
        [isAlert]="true"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Under Evaluation"
        [value]="stats.evaluation"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Pending Approval"
        [value]="stats.pending"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Customer Queried"
        [value]="stats.queried"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="In Final Repair"
        [value]="stats.final"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Days Average TAT In Evaluation"
        [value]="stats.tatEvaluation"
        [isEnabled]="false"
      ></aar-dash-card>
      <aar-dash-card
        type="a"
        label="Days Average TAT In Final Repair"
        [value]="stats.tatFinal"
        [isEnabled]="false"
      ></aar-dash-card>
    </div>

    <div class="aar-sliding-container">
      <div class="aar-sliding-section">

        <mat-tab-group mat-stretch-tabs>
          <mat-tab [label]="'Under Evaluation ' + (repairOrdersUnderEvaluation ? repairOrdersUnderEvaluation.length : 0)">
            <aar-data-table
              [data]="repairOrdersUnderEvaluation"
              [columnsSettings]="columnsSettings"
              [filterSettings]="tableFilterSettings"
              [showHeader]="false"
              [enableROSearch]="true"
              [loading]="loadingTable"
              (clickItem)="selectRO($event)"
            >
            </aar-data-table>
          </mat-tab>

          <mat-tab [label]="'WIP ' + (repairOrdersWIP ? repairOrdersWIP.length : 0)">
            <aar-data-table
              [data]="repairOrdersWIP"
              [columnsSettings]="columnsSettings"
              [filterSettings]="tableFilterSettings"
              [showHeader]="false"
              [enableROSearch]="true"
              [loading]="loadingTable"
              (clickItem)="selectRO($event)"
            >
            </aar-data-table>
          </mat-tab>
        </mat-tab-group>

      </div>

      <div class="aar-sliding-section">
        <ng-template [ngIf]="!selectedRO" [ngIfElse]="roDetails">
          <div class="aar-sliding-section-empty">
            <mat-icon svgIcon="assignment" class="icon-assignment"></mat-icon>
            <p class="header-2">No repair order selected.</p>
            <p class="header-5">
              TIP. You can adjust your notification settings in the
              <br/>
              My Account section
            </p>
          </div>
        </ng-template>

        <ng-template #roDetails>
          <div class="aar-sliding-section-content">
            <span>RO-{{selectedRO.repairOrderNumber}}</span>
            <img src="assets/images/supplierportal/SupplierRODetail.png" alt="" style="width:100%;">
          </div>
        </ng-template>
      </div>
    </div>

  </div>
</div>
