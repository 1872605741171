import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aar-supplier-quote-drop',
  templateUrl: './supplier-quote-drop.component.html',
  styleUrls: ['./supplier-quote-drop.component.scss']
})
export class SupplierQuoteDropComponent implements OnInit {
  constructor() { }
  showAdditionalInfo = false;
  ngOnInit() {
  }

  toggleAdditionalInfo() {
      this.showAdditionalInfo = !this.showAdditionalInfo;
  }

}
