import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rcmQuoteAllDocumentsFilter'
})
export class RcmQuoteAllDocumentsFilterPipe implements PipeTransform {
  transform(value: any[], term: string): any[] {
    return value.filter((x: any) => x.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
      x.tag.toLowerCase().indexOf(term.toLowerCase()) > -1);
  }
}
