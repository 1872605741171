import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/shared/services/api/api.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { CommonContact } from '@app/shared/services/api/api.models';
import { Router } from '@angular/router';
import { ContactMode } from '../contact.constant';

@Component({
  selector: 'aar-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.scss']
})
export class ContactTableComponent implements OnChanges{
  @Input() columnsSettings = [];
  @Input() data = [];
  @Input() pageSizeOptions = [5, 10, 20]
  @Input() title: string = '';
  @Input() initialSort: string = '';
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  mode : string = ContactMode.View;
  contact: CommonContact = new CommonContact();
  
  constructor(public dialog: MatDialog, private apiService: ApiService,
    private loadingService: LoadingService, public router: Router) {
      
     }

    public setSort(id: string, start?: 'asc' | 'desc') {
      if (!id) return;

      start = start || 'asc';
      const matSort = this.sort;
      const disableClear = false;
      
      //Reset sort state so that "start" is the first sort direction
      matSort.sort({ id: null, start, disableClear });
      matSort.sort({ id, start, disableClear });
  
      //Workaround due to Angular bug with the sort arrow not updating: https://github.com/angular/components/issues/10242
      const sortHeader: MatSortHeader = matSort.sortables.get(this.sort.active) as MatSortHeader;
      if (sortHeader != null) sortHeader._setAnimationTransitionState({fromState: sortHeader._arrowDirection, toState: 'active'});
    }

  ngOnChanges(){
    this.displayedColumns = this.columnsSettings.map(item => item.name);
    this.dataSource = new MatTableDataSource(this.data);
    if (!this.sort) this.sort = new MatSort();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.setSort(this.initialSort, 'asc');
  }

  deleteContact(contactID: number){
    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to delete this contact?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {
      if(shouldConfirm === true){
        this.loadingService.startLoading();
        this.apiService.DeleteContactByContactId(contactID)
          .subscribe(result => {
            if(result == true){
              let index = this.data.findIndex(x => x.contactID === contactID);
              this.data.splice(index, 1);
              this.ngOnChanges();
            }
            this.loadingService.stopLoading();
          }, err => this.loadingService.loadingError());
      }
    });
  }

  addContact() {
    this.mode = ContactMode.Add;
    this.contact = new CommonContact();
    let temp = this.router.url.split("/");
      let contactType = temp[temp.length-1];
      if(contactType === 'internals'){
        this.contact.contactTypeID = 3;
        this.contact.companyID = 47; // TO DO: will take it from somehwere 
      }
      else if(contactType === 'customers'){
        this.contact.contactTypeID = 2;
        this.contact.endCustomerID = 13; // TO DO: not sure where to pick this one
      }
      else if(contactType === 'suppliers'){
        this.contact.contactTypeID = 1;    
        this.contact.supplierID = 1541;  // TO DO: will give an option to choose list of suppliers from Ui
      }
  }

  updateContact(contactID: number){
    this.mode = ContactMode.Edit;
    this.contact = this.data.filter(x=> x.contactID === contactID)[0];
  }

  saveContact(){
    this.loadingService.startLoading();
    if(this.mode === ContactMode.Add){
      this.apiService.CreateContact(new CommonContact())
        .subscribe(result => {
          if(result !== null){
            this.ngOnChanges();
          }
          this.loadingService.stopLoading();
      }, err => this.loadingService.loadingError());
    }
    else if(this.mode === ContactMode.Edit){
      this.apiService.UpdateContact(new CommonContact())
        .subscribe(result => {
          if(result !== null){
            this.ngOnChanges();
          }
          this.loadingService.stopLoading();
      }, err => this.loadingService.loadingError());
    }  
  }
}
