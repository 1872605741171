import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Carrier, RepairOrder, ShippingStatus } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { CustomValidators } from 'ng2-validation';
import { Subscription, zip } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { DatevalidationService } from '@app/shared/services/datevalidation.service';
import { getFormFielErrorMessage, setUtcFlag } from '@app/common/utility';
import * as moment from 'moment';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';


@Component({
  selector: 'aar-rcm-add-shipment',
  templateUrl: './rcm-add-shipment.component.html',
  styleUrls: ['./rcm-add-shipment.component.scss']
})
export class RcmAddShipmentComponent implements OnInit, OnDestroy, OnChanges {
  option = 'option';
  shippingForm: UntypedFormGroup;
  id: Number;
  repairOrder: RepairOrder;
  shippingStatuses: ShippingStatus[];
  carriers: Carrier[];
  direction: number; // default to outbound
  title: string;
  nonEditableFields: string[] = [];
  fieldsToHide: string[] = [];
  personaService: PersonaService;
  readonly today = new Date();
  private externalSubscriptions = new Subscription;

  readonly dateShippedCtlName = 'dateShipped';
  readonly dateDeliveredCtlName = 'dateDelivered';

  readonly dateControlNames = [
    this.dateShippedCtlName,
    this.dateDeliveredCtlName
  ];

  constructor(private apiService: ApiService, private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<RcmAddShipmentComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private authService: AuthService, private dateValidator: DatevalidationService,
      private dataLookupService:DataLookupService) {
      this.repairOrder = data.repairOrder;
      this.direction = data.direction;

      this.shippingForm = this.fb.group({
        rONumber: [this.repairOrder.repairOrderNumber, [Validators.required]],
        rOLineNumber : [Number(this.repairOrder.rOLineNumber), [Validators.required]],
        rOLNumber : [this.repairOrder.repairOrderLineId, [Validators.required]],
        carrierId : [null,[]],
        trackingNumber: ['' ,[]],
        address: [null],
        directionId: ['', [Validators.required]],
        trackingLink: ['', [CustomValidators.url]],
        shippingStatus: ["Pd"],
        dateShipped: [null, [CustomValidators.date]],
        dateDelivered: [null, [CustomValidators.date]],
        shippingReference: ['', [Validators.maxLength(24)]]
         /* Commented under #40361
        isPreventFeedUpdate:[false, [Validators.required]]
        */
      });
      this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
     }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  ngOnInit() {
    this.init();
    this.carriers = this.dataLookupService.cachedCarriers;
    this.nonEditableFields=this.personaService.getNonEditableFields(ComponentType.RCMAddShipping);
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMAddShipping);
    this.personaService.effectFieldDisables(this.shippingForm, this.nonEditableFields);
  }

  ngOnDestroy(): void {
    this.externalSubscriptions.unsubscribe();
  }

  init() {
    this.title = `Add Shipment Details`;

    this.externalSubscriptions.add(this.apiService.getShippingStatuses().subscribe((shippingStatuses) => {
      this.shippingStatuses = shippingStatuses;
    }));

  }

  save() {
    if (this.shippingForm.dirty && this.shippingForm.valid) {
      /*
      The dateShipped and dateDelivered form controls have their values checked
      via a subscription to their ValueChanges observable.  This has the side effect
      of removing the utc flag from their moment value and needs to be added back
      in the setUtcFlag method
      */
      this.setShippedAndDeliveredUtcFlag();
      this.dialogRef.close(this.shippingForm.value);
    }
  }

  private setShippedAndDeliveredUtcFlag() : void{
    const dateShipped = this.getFormControl(this.dateShippedCtlName);
    const dateDelivered = this.getFormControl(this.dateDeliveredCtlName);
    if(dateShipped){
      dateShipped.setValue(setUtcFlag(dateShipped.value)); 
    }
    if(dateDelivered){
      dateDelivered.setValue(setUtcFlag(dateDelivered.value));
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  getFormControl(controlName:string):UntypedFormControl{
    return this.shippingForm.get(controlName) as UntypedFormControl;
  }

  hasError(form: UntypedFormGroup, control: string): boolean {
    return !form.controls[control].valid;
  }

  getErrorMessage(form: UntypedFormGroup, control: string): string {
    return getFormFielErrorMessage(form, control);
  }
}