export const STATUS = [
  {
    label: 'New RO',
    value: 'NEW_RO'
  },
  {
    label: 'Shipped Outbound',
    value: 'UNIT_SHIPPED_OUTBOUND'
  },
  {
    label: 'Supplier Receipt',
    value: 'SUPPLIER_RECEIPT'
  },
  {
    label: 'Quote Review',
    value: 'QUOTE_REVIEW'
  },
  {
    label: 'Quote Processing',
    value: 'QUOTE_PROCESSING'
  },
  {
    label: 'Quote Approval',
    value: 'QUOTE_APPROVED'
  },
  {
    label: 'In Final Repair',
    value: 'IN_FINAL_REPAIR'
  },
  {
    label: 'Shipped Inbound',
    value: 'SHIPPED_INBOUND'
  },
  {
    label: 'Shipping Inbound',
    value: 'UNIT_SHIPPED_INBOUND'
  },
  {
    label: 'Receipt Inspection',
    value: 'RECEIPT_INSPECTION'
  },
  {
    label: 'Receipt & Close',
    value: 'RECEIPT_&_CLOSE'
  },
  {
    label: 'Awaiting Approval',
    value: 'AWAITING_APPROVAL'
  },
  {
    label: 'Approved',
    value: 'APPROVED'
  },
  {
    label: 'RO Closed',
    value: 'RO_CLOSED'
  }
];

export const SUPPLIERS = [
  {
    label: '123 Repairs',
    value: '123 Repairs'
  },
  {
    label: 'Air Supply',
    value: 'Air Supply'
  },
  {
    label: 'AvioRepair',
    value: 'AvioRepair'
  },
  {
    label: 'CaliAir',
    value: 'CaliAir'
  },
  {
    label: 'FixAir',
    value: 'FixAir'
  },
  {
    label: 'Hayward Reapirs',
    value: 'Hayward Reapirs'
  },
  {
    label: 'In Tech Aero',
    value: 'In Tech Aero'
  },
  {
    label: 'Logic',
    value: 'Logic'
  },
  {
    label: 'Logix',
    value: 'Logix'
  },
  {
    label: 'MacClucas Industries',
    value: 'MacClucas Industries'
  },
  {
    label: 'Miami Repairs',
    value: 'Miami Repairs'
  },
  {
    label: 'One Overhaul',
    value: 'One Overhaul'
  },
  {
    label: 'OneFix',
    value: 'OneFix'
  },
  {
    label: 'Parts Supply',
    value: 'Parts Supply'
  },
  {
    label: 'Quick Fix',
    value: 'Quick Fix'
  },
  {
    label: 'Reapir Station',
    value: 'Reapir Station'
  },
  {
    label: 'Repair X',
    value: 'Repair X'
  },
  {
    label: 'Repairs Corp',
    value: 'Repairs Corp'
  },
  {
    label: 'Supplier Store',
    value: 'Supplier Store'
  },
  {
    label: 'Tech Repair',
    value: 'Tech Repair'
  },
  {
    label: 'Test Air',
    value: 'Test Air'
  },
  {
    label: 'Wood Tech',
    value: 'Wood Tech'
  }
];

export const PRIORITIES = [
  {
    label: 'AOG',
    value: 'AOG'
  },
  {
    label: 'Expedite',
    value: 'EXPEDITE'
  },
  {
    label: 'Routine',
    value: 'ROUTINE'
  },
  {
    label: 'High',
    value: 'HIGH'
  },
  {
    label: 'Normal',
    value: 'NORMAL'
  },
  {
    label: 'Low',
    value: 'LOW'
  }
];

export const WORKSCOPES = [
  {
    label: 'Repair',
    value: '1'
  },
  {
    label: 'Overhaul',
    value: '2'
  },
  {
    label: 'Modification',
    value: '3'
  },
  {
    label: 'Test',
    value: '4'
  }
];

export const TIME_FRAMES = [
  {
    label: '50',
    value: 50
  },
  {
    label: '60',
    value: 60
  },
  {
    label: '70',
    value: 70
  }
];

export const COUNTRIES = [
  {
    label: 'United States',
    value: 'US'
  },
  {
    label: 'United Kingdom',
    value: 'UK'
  }
];

export enum RcmStepType {
  Logistic,
  QuoteStatus
}

export enum ShippingDirection {
  Outbound = 1,
  Inbound
}

export enum ActivityType {
  ROCreated = 1,
  PendingShipmentOb,
  InTransitOB,
  SupplierReceiptOfUnit,
  QuoteSentToCustomer,
  QuoteCustomerApproved,
  RepairInProgress,
  RepairCompleteInTransitIB,
  CustomerReceiptOfUnit,
  ROClosed,
  ROCanceled,
  QuoteProcessed
}