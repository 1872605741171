import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RcmStepperService {
  public rcmStepChange: Subject<string>;
  public rcmStepChange$: Observable<string>;

  constructor() {
    this.rcmStepChange = new Subject<string>();
    this.rcmStepChange$ = this.rcmStepChange.asObservable();
  }

  public handleChange(message) {
    this.rcmStepChange.next(message);
  }
}
