<h1 mat-dialog-title>
    <ng-container *ngIf="!data.type">
        <h3>Please change your temporary password</h3>
    </ng-container>
    <ng-container *ngIf="data.type === 'login'">
        <h3>Please re-log in</h3>
    </ng-container>
    <ng-container *ngIf="data.type === 'error'">
        <h3>Error Changing Password. Minimum eight characters, at least one uppercase letter, one lowercase letter, one special character and one number is expected</h3>
    </ng-container>
</h1>
<div mat-dialog-content *ngIf="!data.type">
    <mat-form-field class="dialogue__field">
        <input type="password" autocomplete="off" placeholder="Current password" matInput [formControl]="currentPassword">
    </mat-form-field>          
    <span *ngIf="currentPassword.invalid && currentPassword.touched" class="dialogue__error">Required</span>
    <mat-form-field class="dialogue__field">
        <input type="password" autocomplete="off" placeholder="New password" matInput [formControl]="newPassword">
    </mat-form-field>
    <span *ngIf="newPassword.invalid && newPassword.touched" class="dialogue__error">Required</span>
    <mat-form-field class="dialogue__field">
        <input type="password" autocomplete="off" placeholder="Repeat password" matInput [formControl]="repeatPassword">
    </mat-form-field>
    <span *ngIf="notEqual" class="dialogue__error">Not equal</span>
</div>
<div mat-dialog-actions>
    <ng-container *ngIf="data.type === 'login'; else elseTemplate">
        <button mat-button (click)="apply(true)">Log out</button>        
    </ng-container>
    <ng-template #elseTemplate>
        <button mat-button (click)="apply()">Apply</button>        
    </ng-template>
  <button mat-button (click)="cancel()">Cancel</button>
</div>