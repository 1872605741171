import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivityEvent } from '../activity/activity-event';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  public rcmActivityChangeSubject: Subject<ActivityEvent>;
  public rcmActivityChange$: Observable<ActivityEvent>

  constructor(
    ) {
    this.rcmActivityChangeSubject = new Subject<ActivityEvent>();
    this.rcmActivityChange$ = this.rcmActivityChangeSubject.asObservable();
   }

   public activityChangePublish(repairOrderNumber:string, repairLineNumber:string)
   {
    let activityEvent = new ActivityEvent();
    activityEvent.repairOrderNumber = repairOrderNumber;
    activityEvent.repairLineNumber = repairLineNumber;

    this.rcmActivityChangeSubject.next(activityEvent);
   }
}