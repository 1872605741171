<div class="aar-market-average-box">
    <div class="title-section">
          <h4>Market Average Pricing</h4>
    </div>
    <div class="blur-section">
       <div class="vh-middle-section">
         <span>Learn more about this Cost Control feature!</span>
         <button mat-flat-button>Contact a Specialist</button>
        </div>
       <img class="arr-blur-filter bg-black" src="assets/images/fpo/cost-control-2/market-average-noheader.png" />
    </div>
</div>