<div class="aar-rcm aar-page aar-email-list">
  <div class="left-email-list">
    <aar-email-list
    [messages] = "messages"
    >
    </aar-email-list>
  </div>
  <div class="right-email-list">
    <div class="card__content card__element">
      
        <h4>Email Detail <div class="email-search"><i class="material-icons">
          search
          </i>
          <input type="text" placeholder="Search by RO Number, PN, SN, Supplier"/></div></h4>
        <aar-email-list-block
        [description]="messages[0].metaDataResults.body"
        [title]="messages[0].metaDataResults.subject"
        [addedBy]="messages[0].companyName + ' <' + messages[0].metaDataResults.fromEmail + '>'"
        [addedOn]="messages[0].metaDataResults.sentDateTime"
        [tags]="['UNASSIGNED']"></aar-email-list-block>
     
      <div class="email-image">
        <div class="email-image-box">
          <img src="../../assets/images/fpo/ro-detail/Picture-1.png" />
          <a href="">RO Quote.pdf</a>
        </div>
        <div class="email-image-box">
            <img src="../../assets/images/fpo/ro-detail/Picture-1.png" />
            <a href="">RO Quote.pdf</a>
        </div>
        <div class="email-image-box">
            <img src="../../assets/images/fpo/ro-detail/Picture-1.png" />
            <a href="">RO Quote.pdf</a>
        </div>
      </div>
  </div>
  
  <div class="form-footer border-0">
      <div class="form-footer__elem">
        <button mat-flat-button class="button">Respond</button>
      </div>
  </div>
  
  </div>
</div>
