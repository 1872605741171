import { Injectable } from '@angular/core';

@Injectable()
export abstract class HelperService {

    /**
    * Sets all properties in an object to a specific value
    * 
    * @param objectToChange - The object containing properties to change
    * @param valueToSet - The value to set every property to
    */
    public static setObjectProperties(objectToChange: object, valueToSet: any): void {
        for (var key in objectToChange) {
            objectToChange[key] = valueToSet;
        }
    }
}