<div class="aar-rcm-stepper-item font-regular" [ngClass]="getState()">
  <ng-template [ngIf]="!getError()" [ngIfElse]="error">
    {{ label }}
  </ng-template>
  <ng-template #error>
    <i class="material-icons">report_problem</i>
    <!-- Error  -->
    Quote Processing
    <div class="aar-rcm-stepper-item-error-border" *ngIf="!last"></div>
  </ng-template>
  <i></i>
</div>
