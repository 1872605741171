import { Component, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';

@Component({
  selector: 'aar-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss']
})
export class ToggleGroupComponent implements OnInit {

  @Input('value')value = null;
  @Input('items')items = [];
  @Output('select')select = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  toggleButton(item) {

    this.value = item.value;
    this.select.emit(item.value);
  }
}

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [
    ToggleGroupComponent
  ],
  declarations: [
    ToggleGroupComponent
  ]
})

export class ToggleGroupModule {}
