import {Component, OnInit} from '@angular/core';
import { ApiService } from '@app/shared/services/api/api.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { AARNotification } from '@app/shared/services/api/api.models';
import { NotificationDialog} from './notification-dialog/notification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timer, json } from 'd3';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';

@Component({
  selector: 'aar-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  types = [{
    label: 'AOG',
    value: 'aog'}
  ];
  dateRanges = [];
  private showAll: boolean;
  private message: string;
  public hideRead = true;
  public currentUser: User;
  private notificationCount: number;
  private subscription: Subscription;
  public flags = [{
    label: 'AOG',
    value: 'aog'
  }];


  toggleButton = [
    {label: 'UNREAD', value: 0},
    {label: 'ALL', value: 1},
  ];

  tableData: AARNotification[];
  notifications: AARNotification[];
  columnsSettings = [
    {name: 'isRead', header: '', type: 'notify'},
    {
      name: 'flag',
      header: 'Flag',
      type: 'content-highlight',
      mappings: {'AOG': 'bubble warning aog', 'Hot Part': 'bubble critical'}
    },
    {name: 'subject', header: 'Re:'},
    {name: 'body', header: 'Message'},
    {name: 'type', header: 'Type'},
    {name: 'createDate', header: 'Dated'},
  ];


  constructor(private api: ApiService,  private snackBar: MatSnackBar, private dialog: MatDialog, private authService: AuthService) {

  }

  ngOnInit() {
    this.notificationCount = 0;

    interval(2000).subscribe(x => {
      this.getNotifications();
    });

  }
  getNotificationCount() {

    this.api.getUnreadNotifications().subscribe(result => {
          this.notificationCount = result;

    });
  }

  filterFlags(result) {

      this.tableData = this.notifications.filter((item) => {


        return item.flag.toUpperCase() === result.toUpperCase();
      });

  }
  filterTypes(result) {

      this.tableData = this.notifications.filter((item) => {
        return item.type.toUpperCase() === result.toUpperCase();
      });

  }

   getNotifications() {
     this.getCurrentUser();
     this.api.getUnreadNotifications().subscribe(result => {
       if (this.notificationCount != result) {
      this.notificationCount = result;
      this.api.getNotifications('repairadmin').subscribe(result => {
        this.notifications = result;
        if (this.showAll == true) {
         this.toggleNotificationStatus(0);
        } else {
         this.toggleNotificationStatus(1);
        }

      });

       }

     });

  }
  handleReadStatus(notification) {

    const results =  NotificationDialog.openDialog(this.dialog, notification.isRead, notification.body);
   results.subscribe(isRead => {

    if (isRead == true) {

     this.markRead(notification.notificationID);

  }
  if (isRead == false) {

    this.markUnRead(notification.notificationID);
  }
});
}
  markRead(notificationID: number) {
    this.api.markRead(notificationID).subscribe(result => {

      if (result.markRead == true) {
          this.openSnackBar('Message Marked as Read!');

      } else {
        this.openSnackBar('Message Could not be marked as Read!');
      }
      this.getNotifications();
    }

    );
  }
  markUnRead(notificationID: number) {
    this.api.markUnRead(notificationID).subscribe(result => {

      if (result.markUnRead == true) {
          this.openSnackBar('Message Marked as UnRead!');


      } else {
        this.openSnackBar('Message could not be maked as unRead!');


      }
      this.getNotifications();
    }
    );
  }
  getCurrentUser() {

    if (this.authService.isUserLoggedIn()) {
      this.currentUser = this.authService.getLoggedUser();

    }

    this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;

    });
  }
  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  toggleNotificationStatus(value = 0) {
    if (value === 0) {
      this.showAll = true;
      this.tableData = this.notifications.filter((item) => {
        return item.isRead === false;
      });
    } else if (value === 1) {
      this.showAll = false;
      this.tableData = this.notifications.filter((item) => {
        return true;
      });
    }
  }
}
