import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading = new Subject<boolean>();
  private error = new Subject<boolean>();
  loading$ = this.loading.asObservable();
  error$ = this.error.asObservable();

  ngOnInit() {
    this.loading.next(false);
    this.error.next(false);
  }

  startLoading(): void {
    this.loading.next(true);
  }

  stopLoading(): void {
    this.loading.next(false);
  }

  loadingError(): void {
    this.error.next(true);
  }
}
