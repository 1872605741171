<div class="aar-dashboard aar-page">
  <div class="content">
    <form #filtersForm="ngForm" novalidate>
      <div class="aar-cost-control-filters">
        <div class="aar-filter-search">
          <input type="text" name="filter-search" placeholder="Search Ro Number" (change)="searchByRONumber($event.target.value)" />
        </div>

        <mat-form-field  floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Type of Service" name="workscope" ngModel>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.label">
              {{serviceType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Time Frame">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let timeFrame of timeFrames" [value]="timeFrame.value">
              {{timeFrame.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Supplier" name="supplier" ngModel>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let supplier of suppliers" [value]="supplier.name">
              {{supplier.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  floatLabel="never" class="aar-filter-select">
          <mat-select placeholder="Country" name="country" ngModel>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let country of countries" [value]="country.value">
              {{country.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <!-- <div class="aar-recent-pn">
      Recent PN: <a>123456,</a>&nbsp;<a>234567,</a>&nbsp;<a>345678</a>
    </div> -->

    <div class="aar-cost-control-container">
      <div class="aar-left-side">
        <!-- <div class="aar-market-average-box-1">
          <a href="javascript: history.go(-1)"><img src="assets/images/fpo/cost-control-2/cost-control-ro-detail.png" /></a>
        </div> -->

        <div class="aar-market-average-box-1" *ngIf="tableData.length>0">
          <!-- <img src="assets/images/fpo/cost-control-2/cost-control-ro-detail.png" /> -->
          <div class="costcontrol-header">
            <h2><i class="material-icons">chevron_left</i>RO-{{tableData[0].repairOrderNumber}} <span>PN-751A0000-07</span></h2>
            <div class="header-content">
              <div class="box-content">
                <p>Serial Number</p>
                <span>15-1850</span>
              </div>
              <div class="box-content">
                <p>Workscope</p>
                <span>{{tableData[0].workScopeName}} </span>
              </div>
              <div class="box-content">
                <p>Date</p>
                <span>{{tableData[0].date}}</span>
              </div>
              <div class="box-content">
                <p>Supplier</p>
                <span><a href="#">{{tableData[0].supplier}}</a></span>
              </div>
              <div class="box-content">
                <p>Country</p>
                <span>USA</span>
              </div>
              <div class="box-content">
                <p>Contract</p>
                <span>{{tableData[0].contract}}</span>
              </div>
              <div class="box-content">
                <p>Quoted</p>
                <span class="mark-price">{{tableData[0].cost}}</span>
              </div>
            </div>
          </div>
        </div>

        <aar-data-table
          [title]="'Historical Pricing'"
          [data]="tableData"
          [columnsSettings]="columnsSettings"
          [selectable]="true"
          [linkPath]="'/rcm'"
        >
        </aar-data-table>

        <aar-cost-control-market-average></aar-cost-control-market-average>


      </div>
      <div class="aar-right-side">
        <aar-cost-control-results [tableData]="tableData" [plotDates]="costPlotDateValues" [plotCosts]="costPlotCostsValues"></aar-cost-control-results>
      </div>
    </div>
  </div>
</div>
