export const DATA = [
  {
    serialNumber: '123456-78',
    repairOrderNumber: '100001',
    workScopeName: 'REPAIR',
    date: '2018-01-01',
    supplier: '1ST CHOICE AEROSPACE FL',
    country: 'US',
    contract: 'Yes',
    totalCost: 1723
  },
  {
    serialNumber: '123456-75',
    repairOrderNumber: '100002',
    workScopeName: 'REPAIR',
    date: '2018-02-01',
    supplier: '1ST CHOICE AEROSPACE FL',
    country: 'US',
    contract: 'Yes',
    totalCost: '2020.00'
  },
  {
    serialNumber: '123456-79',
    repairOrderNumber: '100005',
    workScopeName: 'REPAIR',
    date: '2018-03-01',
    supplier: 'AirSupply',
    country: 'UK',
    contract: 'No',
    totalCost: '1729.00'
  },
  {
    serialNumber: '123456-78',
    repairOrderNumber: '100008',
    workScopeName: 'REPAIR',
    date: '2018-04-01',
    supplier: '1ST CHOICE AEROSPACE FL',
    country: 'US',
    contract: 'Yes',
    totalCost: '1863.40'
  },
  {
    serialNumber: '123456-78',
    repairOrderNumber: '100011',
    workScopeName: 'REPAIR',
    date: '2018-05-01',
    supplier: 'ABLE AEROSPACE SERVICES INC',
    country: 'US',
    contract: 'Yes',
    totalCost: '2170.00'
  },
  {
    serialNumber: '23454-78',
    repairOrderNumber: '100012',
    workScopeName: 'REPAIR',
    date: '2018-06-01',
    supplier: 'ABLE AEROSPACE SERVICES INC',
    country: 'UK',
    contract: 'Yes',
    totalCost: '1899.01'
  },
  {
    serialNumber: '367983-78',
    repairOrderNumber: '100113',
    workScopeName: 'REPAIR',
    date: '2018-07-01',
    supplier: 'ABLE AEROSPACE SERVICES INC',
    country: 'US',
    contract: 'Yes',
    totalCost: '1901.40'
  },
  {
    serialNumber: '123456-78',
    repairOrderNumber: '100008',
    workScopeName: 'REPAIR',
    date: '2018-08-01',
    supplier: 'A J WALTER AVIATION LTD',
    country: 'US',
    contract: 'Yes',
    totalCost: '2250.00'
  },
  {
    serialNumber: '37393-78',
    repairOrderNumber: '100111',
    workScopeName: 'REPAIR',
    date: '2018-09-01',
    supplier: 'ABLE AEROSPACE SERVICES INC',
    country: 'UK',
    contract: 'Yes',
    totalCost: '2047.00'
  },
  {
    serialNumber: '2827353-78',
    repairOrderNumber: '100080',
    workScopeName: 'REPAIR',
    date: '2018-10-01',
    supplier: 'A J WALTER AVIATION LTD',
    country: 'US',
    contract: 'No',
    totalCost: '2106.00'
  },
  {
    serialNumber: '392654-78',
    repairOrderNumber: '100107',
    workScopeName: 'REPAIR',
    date: '2018-11-01',
    supplier: 'A J WALTER AVIATION LTD',
    country: 'US',
    contract: 'Yes',
    totalCost: 1830.00
  }
];
