import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from '@app/app-material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './components';
import { LoginSamlIdpComponent } from './components/login/login-saml-idp.component';
import { TermsConditionsPrivacyAcceptanceComponent } from './components/login/terms-conditions-privacy-acceptance.component';

@NgModule({
    imports: [
        CommonModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        LoginComponent,
        LoginSamlIdpComponent,
        TermsConditionsPrivacyAcceptanceComponent
    ]
})
export class AuthModule { }
