import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { startWith, filter } from 'rxjs/operators';
import { AppMaterialModule } from '@app/app-material.module';
import { BreadcrumbService } from '@app/shared/services/breadcrumb/breadcrumb.service';
import { Breadcrumb } from '@app/core/models/breadcrumb.model';

@Component({
  selector: 'aar-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  // The breadcrumbs of the current route
  private currentBreadcrumbs: Breadcrumb[];
  // All the breadcrumbs
  public breadcrumbs: Breadcrumb[];
  // last child action
  public action: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    breadcrumbService.get().subscribe((breadcrumbs: Breadcrumb[]) => {
      this.breadcrumbs = breadcrumbs as Breadcrumb[];
    });
  }

  ngOnInit() {
    const ROUTE_DATA_BREADCRUMB = 'label';
    const ROUTE_PARAM_BREADCRUMB = 'id';

    // subscribe to the NavigationEnd event
    this.router.events.pipe(
      startWith(new NavigationEnd(0, '/', '/'))
    ).pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // reset currentBreadcrumbs
      this.currentBreadcrumbs = [];

      // get the root of the current route
      let currentRoute: ActivatedRoute = this.activatedRoute.root;

      // set the url to an empty string
      let url = '';

      // reset the action
      this.action = '';

      // iterate from activated route to children
      while (currentRoute.children.length > 0) {
        const childrenRoutes: ActivatedRoute[] = currentRoute.children;
        let breadCrumbLabel = '';

        // iterate over each children
        childrenRoutes.forEach(route => {
          // Set currentRoute to this route
          currentRoute = route;
          // Verify this is the primary route
          if (route.outlet !== PRIMARY_OUTLET) {
            return;
          }

          const hasData = (route.routeConfig && route.routeConfig.data);
          const hasDynamicBreadcrumb: boolean = route.snapshot.params.hasOwnProperty(ROUTE_PARAM_BREADCRUMB);

          if (hasData || hasDynamicBreadcrumb) {
            /*
             Verify the custom data property "label"
             is specified on the route or in its parameters.
             Route parameters take precedence over route data
             attributes.
             */
            if (hasDynamicBreadcrumb) {
              breadCrumbLabel = route.snapshot.params[ROUTE_PARAM_BREADCRUMB].replace(/_/g, ' ');
            } else if (route.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
              breadCrumbLabel = route.snapshot.data[ROUTE_DATA_BREADCRUMB];
            }

            // Save the action constant
            if ( route.snapshot.data.hasOwnProperty('action') ) {
              this.action = route.snapshot.data.action;
            }

            // Get the route's URL segment
            const routeURL: string = route.snapshot.url.map(segment => segment.path).join('/');
            url += `/${routeURL}`;

            // Cannot have parameters on a root route
            if (routeURL.length === 0) {
              route.snapshot.params = {};
            }

            // Add breadcrumb
            const breadcrumb: Breadcrumb = {
              label: breadCrumbLabel,
              params: route.snapshot.params,
              url: url
            };

            // Add the breadcrumb.
            this.currentBreadcrumbs.push(breadcrumb);
          }
        });

        this.breadcrumbService.store(this.currentBreadcrumbs);
      }
    });
  }

  public hasParams(breadcrumb: Breadcrumb) {
    return Object.keys(breadcrumb.params).length ? [breadcrumb.url, breadcrumb.params] : [breadcrumb.url];
  }

  public executeAction() {
    this.breadcrumbService.actionEvent(this.action);
  }
}

@NgModule({
  imports: [ CommonModule, RouterModule, AppMaterialModule ],
  exports: [ BreadcrumbComponent ],
  declarations: [ BreadcrumbComponent ],
  providers: [ BreadcrumbService ]
})
export class BreadcrumbModule {}
