<div style="display: none">
  <aar-rcm-stepper
    [currentSelected]="currentStepSelected"
    [steps]="steps"
    [hasError]="stepError"
    [xs]="stepsXs"
    [clickables]="[0, 1, 2, 3, 4, 5, 6, 7, 8]"
    (changeStep)="setStep($event)"
  ></aar-rcm-stepper>
</div>
<mat-accordion>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="aar-panel-title font-regular">
          <h3>LOGISTICS STATUS</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="margin-bottom:5px" style="min-height: 70px;">
      <aar-rcm-status-stepper [xs]="stepsXs"
        [type]="RcmStepType.Logistic"></aar-rcm-status-stepper>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="aar-panel-title font-regular">
          <h3>QUOTE STATUS</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="min-height:70px;">
      <aar-rcm-status-stepper [xs]="stepsXs"
        [type]="RcmStepType.QuoteStatus"></aar-rcm-status-stepper>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<aar-rcm-header
  *ngIf="!loading"
  [roDetails]="repairOrderDetails"
></aar-rcm-header>

<div class="content">
  <div class="aar-sliding-container">
    <div class="aar-sliding-section">
      <aar-rcm-quote-processing
       [repairOrder]="repairOrderDetails"
        (parentNextStep)="setStep($event)"
        *ngIf="currentStepSelected <= 5"
        >
      </aar-rcm-quote-processing>
      <aar-no-activity
        *ngIf="hideContent()"
        [message]="noActivityMessage"
      ></aar-no-activity>
    </div>

    <div class="aar-sliding-section">
      <mat-tab-group [selectedIndex]="selectedTabsIndex" #tabs>

         <!-- Commented under #40361
        <mat-tab label="Activity">
          <aar-rcm-activity
            [repairOrderNumber]="roNum"
            [repairOrderLineNumber]="roLineNumber">
          </aar-rcm-activity>
        </mat-tab>
      -->

        <mat-tab label="Shipping">
          <aar-rcm-shipping
           [repairOrder]="repairOrderDetails"
           [isEmpty]="hideContent(1)"
            [showBoth]="currentStepSelected > 1"
            >
          </aar-rcm-shipping>
        </mat-tab>

        <mat-tab [label]="documentTabName" *ngIf="canEdit">
          <aar-rcm-quote-all-documents
           *ngIf="!documentViewDetail"
            [isEmpty]="hideContent()"
            (itemClick)="switchView($event)"
             [repairOrderDetails]="repairOrderDetails"
             >
          </aar-rcm-quote-all-documents>
          <aar-rcm-quote-document-detail
           *ngIf="documentViewDetail && !hideContent()"
            (close)="switchView()"
            [document]="documentViewDetail"
            >
          </aar-rcm-quote-document-detail>
        </mat-tab>

        <!-- <mat-tab label="Cases">
          <aar-data-table
            *ngIf="!hideContent(4)"
            [title]="'Case History'"
            [data]="repairOrderDetailsTabs.cases"
            [columnsSettings]="casesDisplayedColumns"
            [selectable]="true"
            [showPagination]="false"
          >
          </aar-data-table>
          <aar-no-activity *ngIf="hideContent(4)" [message]="noActivityMessage"></aar-no-activity>
        </mat-tab> -->

        <mat-tab label="Communication" *ngIf="false">
          <aar-rcm-communication
            [isEmpty]="hideContent(4) || !repairOrderCommunications || !repairOrderCommunications.length"
            [repairOrder]="repairOrderDetails"
             [messages]="repairOrderCommunications || []">

          </aar-rcm-communication>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>
