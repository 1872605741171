<div class="rcm-add-communication">
    <h2 class="rcm-add-communication-title">Add Communication</h2>
  
    <div class="rcm-add-communication-body">
      <div class="rcm-add-communication-body-form">
  
        <div class="rcm-add-communication-body-form-row">
          <mat-form-field class="mr">
            <mat-select placeholder="Tags" [(value)]="option">
              <mat-option value="option" disabled>Select Tags</mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field class="mr">
            <mat-select placeholder="Category" [(value)]="option">
              <mat-option value="option" disabled>Select Category</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  

  
        <div class="rcm-add-communication-body-form-row">
          <mat-form-field class="large">
            <input matInput placeholder="Title" value="" />
          </mat-form-field>
        </div>
  
        <div class="rcm-add-communication-body-form-row">
          <mat-form-field class="large">
            <textarea matInput placeholder="Capture notes here" value=""></textarea>
          </mat-form-field>
        </div>

        <div class="rcm-add-communication-body-form-row">
          <div class="rcm-drag">
            <input type="file" class="hidden" #fileChooser/>
            <span class="drag-tag">Drag & drop here to attach <br>or <button (click)="fileChooser.click()">choose a file on your computer</button></span>
          </div>
        </div>

      </div>
    </div>
  
    <div class="rcm-add-communication-footer">
      <button mat-flat-button color="primary" class="aar-button" (click)="onClose()">Send</button>
      <button mat-button color="primary" (click)="onClose()">Cancel</button>
    </div>
  </div>
  