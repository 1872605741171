import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RcmCommunicationEmailComponent } from '@app/rcm/rcm-details/rcm-communication/rcm-communication-email/rcm-communication-email.component';
import { RcmAddCommunicationComponent } from '../modals/rcm-add-communication/rcm-add-communication.component';
import { RepairOrder } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-rcm-communication',
  templateUrl: './rcm-communication.component.html',
  styleUrls: ['./rcm-communication.component.scss']
})
export class RcmCommunicationComponent implements OnInit {
  @Input('messages') messages: any[];
  @Input('isEmpty') isEmpty: boolean;
  @Input('repairOrder') repairOrder: RepairOrder;
  //noActivityMessage = 'No Activity Yet';
  noActivityMessage = "This feature is not enabled for your account. Please contact Airvolution Support for more information!";
  constructor(
    public dialog: MatDialog) { }

  ngOnInit() {
    
    this.messages =  [
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
      {metaDataResults: {body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.', subject: 'New Quote for RO-001943', fromEmail: 'marysmith@aviorepair.com', sentDateTime: '01-09-18 5:00pm' }, companyName: 'Mary Smith'},
    ];
  }

  openAddCommunicationDialog(): void {
    const dialogRef = this.dialog.open(RcmAddCommunicationComponent);
    dialogRef.afterClosed().subscribe(result => { });
  }

  openEmailDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { serviceOrderId: this.repairOrder.repairOrderNumber };
    const dialogRef = this.dialog.open(RcmCommunicationEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { });
  }
}
