<div>
  <div class="title-section" *ngIf="title">
    <h4 class="spacer-right">{{ title }}</h4>
    <button class="add-contact-button-spacer-left" mat-raised-button color="primary" (click)="showAddContactDetailsForm()">Add Contact</button>
  </div>

  <div class="contact-table">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="firstName">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by first name">
          First Name
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by last name">
          Last Name
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
          Title
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">
          Email Address
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
      </ng-container>

      <ng-container matColumnDef="mainPhoneNumber">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by mainPhoneNumber">
          Main Phone Number
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.mainPhoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="alternatePhoneNumber">
        <th class="header-text" mat-header-cell *matHeaderCellDef mat-sort-header
          sortActionDescription="Sort by alternatePhoneNumber">
          Alternate Phone Number
        </th>
        <td class="read-text" mat-cell *matCellDef="let element"> {{element.alternatePhoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th class="header-text" mat-header-cell *matHeaderCellDef></th>
        <td class="read-text" mat-cell *matCellDef="let element">
          <button mat-icon-button>
            <mat-icon color="primary" *ngIf="hasComments(element.comments)"
              (click)="showUpdateContactDetailsForm(element.contactID)">info</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th class="header-text" mat-header-cell *matHeaderCellDef sortActionDescription="Sort by action"></th>
        <td class="read-text" mat-cell *matCellDef="let element">
          <button class="align-right" mat-flat-button color="primary"
            (click)="deleteContact(element.contactID)">Delete</button>
          <button class="align-right" mat-flat-button color="primary"
            (click)="showUpdateContactDetailsForm(element.contactID)">Update</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="border-bottom"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator class="mat-paginator" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
    aria-label="Select page of contact elements">
  </mat-paginator>

</div>
