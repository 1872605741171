import { AuthService } from './../../../../auth/services/auth/auth.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ShippingType } from '@app/shared/services/api/api.models';
import { SharedService } from '@app/shared/services/shared.service';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { User } from '@app/auth/auth.models';
import { RcmStatusStepperService } from '@app/rcm/rcm-stepper/rcm-status-stepper.service';import { MatExpansionPanel } from '@angular/material/expansion';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';

@Component({
  selector: 'aar-rcm-shipping-block',
  templateUrl: './rcm-shipping-block.component.html',
  styleUrls: ['./rcm-shipping-block.component.scss']
})
export class RcmShippingBlockComponent implements OnInit {

  @Input('shippingLogo') shippingLogo = '';
  @Input('urlNumber') urlNumber = '';
  @Input('trackingNumber') trackingNumber = '';
  @Input('trackingLink') trackingLink = '';
  @Input('shippingCarrier') shippingCarrier : number = null;
  @Input('shippingStatus') shippingStatus;
  @Input('address') address;
  @Input('directionId') directionId = 0;
  @Input('sequence') sequence;
  @Input('dateShipped') dateShipped;
  @Input('dateDelivered') dateDelivered;
  @Input('shippingReference') shippingReference;
  @Input('id') id = null;
  @Input('shippingId') shippingId;
  @Input('isPreventFeedUpdate') set setIsPreventFeedUpdate(value) {
    this.isPreventFeedUpdate = value;
    value ? this.isPreventFeedUpdateDisplay = 'Yes' : this.isPreventFeedUpdateDisplay = 'No';
  };
  @Input('repairOrderNumber') repairOrderNumber;
  @Input('repairOrderLineNumber') repairOrderLineNumber;
  @Input('directionDescription') directionDescription = '';
  @Input('panel') panel: MatExpansionPanel;

  user: User;
  showUpdateButton: boolean = true;
  showDeleteButton: boolean = true;
  showPreventFeedUpdateCtrl: boolean = true;
  isPreventFeedUpdate: boolean;
  isPreventFeedUpdateDisplay: string;
  carrierName:string;
  
  fieldsToHide: string[] = [];
  personaService: PersonaService;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private dataLookupService: DataLookupService) {
    this.user = this.authService.getLoggedUser()
    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
  }

  ngOnInit() {
    this.setUpdateVisibility();
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMShippingBlock);
    this.carrierName = this.dataLookupService.cachedCarriers.find(c=>c.id === this.shippingCarrier)?.name;
  }

  private setUpdateVisibility(): void {

    const roleCanUpdate = this.personaService.shippingInfoUpdateRoles().includes(this.user.roleName);
    const hideUpdateButtons = this.personaService.hideShippingUpdates();

    //Role takes precedence over personas in this logic
    if (roleCanUpdate) {
      this.showPreventFeedUpdateCtrl = false;
      this.showDeleteButton = false;
    } else if (hideUpdateButtons) {
      this.showUpdateButton = false;
      this.showDeleteButton = false;
      this.showPreventFeedUpdateCtrl = false;
    }

    this.changeDetector.detectChanges();

  }

  getTrackingLinkStyle() {
    if (this.trackingLink != '') {
      return {};
    }
    let style = {
      'cursor': 'default',
      'text-decoration': 'none',
      'color': 'gray'
    }
    return style;
  }

  private delete(id: number) {
    var result = confirm("Are you sure you want to delete?");
    if (result == true) {
      const deleteShipping = this.createUpdateShippingObject(id);
      this.sharedService.sendDeletedShipping(deleteShipping);
    }
  }

  private updateShipping(id: number) {
    const shippingUpdate = this.createUpdateShippingObject(id);
    this.sharedService.sendUpdatedShipping(shippingUpdate);
  };

  private createUpdateShippingObject(id: number): ShippingType {
    const shippingUpdate: ShippingType = {
      id: id,
      dateDelivered: this.dateDelivered,
      shippingId: this.shippingId,
      shippingStatus: this.shippingStatus,
      dateShipped: this.dateShipped,
      directionId: this.directionId,
      address: this.address,
      carrierId: this.shippingCarrier,
      trackingLink: this.trackingLink,
      trackingNumber: this.trackingNumber,
      shippingReference: this.shippingReference,
      rOLineNumber: null,
      rONumber: null,
      rOLNumber: null,
      isPreventFeedUpdate: this.isPreventFeedUpdate,
      sequence: this.sequence
    };

    return shippingUpdate;
  }

  private isRestrictedPersona(): boolean {
    return this.personaService.hideShippingUpdates();
  }

  private isRestrictedRole(): boolean {
    return this.personaService.shippingInfoUpdateRoles().includes(this.user.roleName);
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  onUpdateClick(event: Event): void {
    event.stopPropagation();
    this.updateShipping(this.id);
  }

  onDeleteClick(event: Event): void {
    this.delete(this.id);
    event.stopPropagation();
  }

  onCloseClick(event: Event): void {
    this.panel.close()
  }

  showDelete(): boolean {
    if (!this.isRestrictedRole()) {
      if (!this.isRestrictedPersona()) {
        return true;
      }
    }
    return false;
  }

  showUpdate(): boolean {
    if (!this.isRestrictedPersona()) {
      return true;
    }
    return false;
  }
}
