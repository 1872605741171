<div class="rcm-export">
    <h2 class="rcm-export-title">Export</h2>
  
    <div class="rcm-export-body">
      <div class="rcm-export-body-form rcm-export-body-form-row">

  
        <div class="container w-45">
        
            <div class="rcm-export-body-form-row">
                <mat-form-field class="large">
                  <input matInput placeholder="Title" value="" />
                </mat-form-field>
              </div>
              
              
        <div class="rcm-export-body-form-row">
          <mat-form-field class="mr">
            <mat-select placeholder="Export Type" [(value)]="option">
              <mat-option value="option" disabled>Select Type</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        
        <div class="rcm-add-border">
        <div class="rcm-export-body-form-row">
            <mat-form-field class="mr">
              <mat-select placeholder="Title Page" [(value)]="option">
                <mat-option value="option" disabled>Select</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

  
        <div class="rcm-export-body-form-row">
          <mat-form-field class="large">
            <textarea matInput placeholder="Title Page Notes" value=""></textarea>
          </mat-form-field>
        </div>
      </div>
      </div>

      <div class="container w-45">
        <div class="rcm-add-img">
          <img src="assets/images/fpo/ro-detail/RO-QUOTE.png"/>
          <div class="rcm-img-pagination">
            <span>1 of 5</span>
          </div>
        </div>
      </div>
    
    </div>
    </div>
  
    <div class="rcm-export-footer">
      <button mat-flat-button color="primary" class="aar-button" (click)="onClose()">Export</button>
      <button mat-button color="primary" (click)="onClose()">Cancel</button>
    </div>
  </div>
  