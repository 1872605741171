<div class="card__content card__element">
    <ng-template [ngIf]="isEmpty" [ngIfElse]="content">
      <aar-no-activity [message]="noActivityMessage"></aar-no-activity>
    </ng-template>

    <ng-template #content>
      <h4>Communication History</h4>
      <div class="aar-communication-height">
        <aar-rcm-communication-block
          *ngFor="let message of messages"
          [description]="message.metaDataResults.body"
          [title]="message.metaDataResults.subject"
          [addedBy]="message.metaDataResults.fromEmail"
          [addedOn]="message.metaDataResults.receivedDateTime"
          [tags]="[message.metaDataResults.priority]">
        </aar-rcm-communication-block>
      </div>
    </ng-template>
</div>

<div class="form-footer">
    <div class="form-footer__elem">
      <button mat-flat-button class="button" title="This feature is not enabled for your account. Please contact Airvolution Support for more information." (click)="openEmailDialog()" disabled>Compose Email</button>
      <button *ngIf="false" mat-flat-button class="button" (click)="openAddCommunicationDialog()">Add Communication</button>
    </div>
  </div>
