import { Injectable } from '@angular/core';
import { DEFAULT_CURRENCIES } from '@app/core/constants/globalConstants';
import { CurrencyLocalisedPipe } from '@app/shared/custom-pipe/currency-pipe/currency-localised.pipe.';
import { Observable, Subject } from 'rxjs';
import { Currency } from '../../api/api.models';
import { DataLookupService } from '../data-lookup/data-lookup.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(
    private dataLookupService: DataLookupService,
    private currencyLocalisedPipe: CurrencyLocalisedPipe
  ) { }

  private _originalMasterQuoteValue: number = 0;
  private _originalMasterQuoteCurrency: number = 0;
  private _originalMasterQuoteId: number = -1;

  private masterTotalSupplierCostSubject = new Subject<string>();

  get originalMasterQuoteId(): number {
    return this._originalMasterQuoteId;
  }

  setOriginalMasterQuote(value: number, currencyId: number, quoteId: number) {
    if (currencyId === -1) {
      this._originalMasterQuoteCurrency = this.defaultCurrency().id
    }
    this._originalMasterQuoteCurrency = currencyId;
    this._originalMasterQuoteId = quoteId;
    this._originalMasterQuoteValue = value;

    this.sendMasterTotalSupplierCost(this.originalMasterValueAndCurrency());

  }

  unsetOriginalMasterQuote(): void {
    this._originalMasterQuoteCurrency = this.defaultCurrency().id;
    this._originalMasterQuoteValue = 0;
    this._originalMasterQuoteId = -1;

    this.sendMasterTotalSupplierCost(this.originalMasterValueAndCurrency());

  }

  resetOriginalMasterValueAndCurrency(): void {
    this.sendMasterTotalSupplierCost(this.originalMasterValueAndCurrency())
  }

  formatValueAndCurrency(unitCost: number, quantity: number, currencyId: number, isMaster: boolean): string {

    const totalValue = (unitCost * quantity);
    const currencyISO = this.currencyISO(currencyId);
    const formattedValue = this.currencyLocalisedPipe.transform(totalValue, currencyISO);

    if (isMaster) {
      this.masterTotalSupplierCostSubject.next(formattedValue);
    }

    return formattedValue;

  }

  masterTotalSupplierCostSubscription(): Observable<string> {
    return this.masterTotalSupplierCostSubject.asObservable();
  }

  sendMasterTotalSupplierCost(totalQuoteCost: string): void {
    this.masterTotalSupplierCostSubject.next(totalQuoteCost);
  }

  private currencyISO(currencyId: number): string {
    return this.dataLookupService.cachedCurrencyForId(currencyId).name
  }

  private defaultCurrency(): Currency {
    return this.dataLookupService.cachedCurrencies.find((curr) => curr.name === DEFAULT_CURRENCIES.USD)
  }

  private originalMasterValueAndCurrency(): string {

    let currencyISO = DEFAULT_CURRENCIES.USD;
    if (this._originalMasterQuoteCurrency !== -1) {
      currencyISO = this.currencyISO(this._originalMasterQuoteCurrency);
    }

    return this.currencyLocalisedPipe.transform(this._originalMasterQuoteValue, currencyISO);
  }
}