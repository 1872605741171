<div class="aar-shipping-status-icon">
  <div *ngIf="shippingStatus === 'Dispatched'; else notDispatched">
    <mat-icon>check_circle_outline</mat-icon>
  </div>
</div>

<ng-template #notDispatched>
  <mat-icon >radio_button_checked</mat-icon>
</ng-template>

<div class="aar-shipping-title">
  <div class="margin-right-sm">
    <h2>
    {{shippingStage}}
    </h2>
  </div>
  <div style="color:white">
    <h2>
      {{ directionDescription }}
    </h2>
  </div>
  <div class="right-justified-md">
    <h2>
      {{shippingStatus}}
    </h2>
  </div>
</div>