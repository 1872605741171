import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aar-cost-control-market-average',
  templateUrl: './cost-control-market-average.component.html',
  styleUrls: ['./cost-control-market-average.component.scss']
})
export class CostControlMarketAverageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
