import { Directive, ElementRef, Input, OnChanges,
  SimpleChanges, OnDestroy, OnInit, NgModule, AfterViewChecked, AfterViewInit } from '@angular/core';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { ChartConfiguration, ChartAPI } from 'c3';


@Directive({
  selector: '[aarNg2C3Chart]',
})
export class Ng2C3ChartDirective implements OnChanges, OnDestroy, OnInit, AfterViewChecked, AfterViewInit {


  private element: HTMLElement;
  private widthMem  = 0;
  private viewInit = false;
  private changeInit = false;
  @Input('c3ChartConfig') c3ChartConfig: ChartConfiguration = {
    data: {
      columns: []
    },
    axis: {}
  };
  @Input('c3Instance') c3Instance: ChartAPI = null;


  constructor(elementReference: ElementRef) {
    this.element = elementReference.nativeElement;
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.c3ChartConfig) {
      this.updateGraph();
    }
  }

  updateGraph() {
    this.changeInit = true;
    if (this.c3Instance != null) {
      this.c3Instance.destroy();
      this.element.innerHTML = '';
    }
    this.c3ChartConfig.bindto = this.element as HTMLElement;

    if (this.c3ChartConfig.legend && this.c3ChartConfig.legend.hide === 'default') {
      this.c3ChartConfig.legend.show = false;
    }

    this.c3ChartConfig.onrendered = () => {
      if (this.c3Instance) {
       // Refrence for future use cases
      }
    };

    this.c3Instance = c3.generate(this.c3ChartConfig);
  }

  ngAfterViewInit() {
    if (this.viewInit === false && this.changeInit === true) {
      this.updateGraph();
    }
    this.viewInit = true;
  }
  // Resize Fix - This is required.
  ngAfterViewChecked(): void {
    if (this.widthMem !== this.element.clientWidth) {
      this.widthMem = this.element.clientWidth;
      if (this.c3Instance) {
        this.c3Instance.resize();
      }
    }
  }


  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    if (this.c3Instance != null) {
      // Instance getting lost while unloading
      // this.c3Instance.unload();
      this.c3Instance.destroy();
    }
  }
}

@NgModule({
  imports: [],
  exports: [ Ng2C3ChartDirective ],
  declarations: [ Ng2C3ChartDirective ]
})

export class Ng2C3ChartModule {}
