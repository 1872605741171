import { Injectable } from '@angular/core';
import { QuoteStatusStepName, RepairOrderLogisticStepName } from '@app/shared/services/api/api.models';
import { ApiRepairOrderQueryService } from '@app/shared/services/api/repair-order-query-api/repair-order-query-api.service';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RcmStatusStepperService {

  private logisticStepNames: RepairOrderLogisticStepName[] = [];
  private quoteStatusStepNames: QuoteStatusStepName[] = [];
  private rcmLogisticStepChangeSubject = new Subject<number>();
  private rcmQuoteStatusStepChangeSubject = new Subject<number>();

  constructor(private repairOrderQueryService: ApiRepairOrderQueryService) {
    this.getAllStepNames();
  }

  private getAllStepNames(): void {
    this.repairOrderQueryService.getRepairOrderStepNames().subscribe((stepObject) => {
      this.logisticStepNames = stepObject.repairOrderStepNames.logisticStepNames;
      this.quoteStatusStepNames = stepObject.repairOrderStepNames.quoteStatusStepNames;
    });
  }
  
  rcmLogisticStepChanged(): Observable<number> {
    return this.rcmLogisticStepChangeSubject.asObservable();
  }

  rcmQuoteStepChanged(): Observable<number> {
    return this.rcmQuoteStatusStepChangeSubject.asObservable();
  }

  currentStepChange(repairOrderNumber: string, repairLineNumber: string): void {
    this.repairOrderQueryService.getCurrentStep(repairOrderNumber, repairLineNumber).subscribe((currentStep) => {
      this.rcmLogisticStepChangeSubject.next(currentStep.logisticStep);
      this.rcmQuoteStatusStepChangeSubject.next(currentStep.quoteStatusStep);
    });
  }

  getLogisticStepNames(): Observable<string[]> {

    const mapFunction = (stepNames: RepairOrderLogisticStepName[]): string[] => {
      return stepNames.map((name) => name.title);
    }

    if (this.logisticStepNames.length > 0) return of(mapFunction(this.logisticStepNames));

    return this.repairOrderQueryService.getRepairOrderStepNames().pipe(map((stepObject) => {
      this.logisticStepNames = stepObject.repairOrderStepNames.logisticStepNames;
      this.quoteStatusStepNames = stepObject.repairOrderStepNames.quoteStatusStepNames;
      return mapFunction(stepObject.repairOrderStepNames.logisticStepNames);
    }));

  }

  getQuoteStatusStepNames(): Observable<string[]> {

    const mapFunction = (stepNames: QuoteStatusStepName[]): string[] => {
      return stepNames.map((name) => name.title);
    }

    if (this.quoteStatusStepNames.length > 0) return of(mapFunction(this.quoteStatusStepNames));

    return this.repairOrderQueryService.getRepairOrderStepNames().pipe(map((stepObject) => {
      this.logisticStepNames = stepObject.repairOrderStepNames.logisticStepNames;
      this.quoteStatusStepNames = stepObject.repairOrderStepNames.quoteStatusStepNames;
      return mapFunction(stepObject.repairOrderStepNames.quoteStatusStepNames);
    }));
  }
}