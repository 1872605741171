import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RcmService {
  private filterSelected = new Subject<string>();
  filterSelected$ = this.filterSelected.asObservable();

  constructor() {}

  ngOnInit() {
    this.filterSelected.next('');
  }

  filterBy(criteria: string): void {
    this.filterSelected.next(criteria);
  }
}
