<ul class="arr-breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-template [ngIf]="!last" [ngIfElse]="renderLast">
      <a [routerLink]="hasParams(breadcrumb)">{{breadcrumb.label}}</a>
      <mat-icon>chevron_right</mat-icon>
    </ng-template>
    <ng-template #renderLast>
      <a>{{breadcrumb.label}}</a>
      <ng-template [ngIf]="!action" [ngIfElse]="renderAction">
      </ng-template>
      <ng-template #renderAction>
      </ng-template>
    </ng-template>
  </li>
</ul>
