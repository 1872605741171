
<div class="aar-rcm-stepper-container">
  <aar-rcm-stepper-item
    *ngFor="let step of steps; let first = first; let last = last; let index = index;"
    [label]="step"
    [currentSelected]="currentSelected"
    [currentPosition]="index"
    [first]="first"
    [last]="last"
    [hasError]="hasError"
    [xs]="xs"
    [clickable]="isClickable(index)"
    (click)="goToStep(index)"
  ></aar-rcm-stepper-item>
</div>
