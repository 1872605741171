import { Component, Input } from '@angular/core';

@Component({
  selector: 'aar-email-list-block',
  templateUrl: './email-list-block.component.html',
  styleUrls: ['./email-list-block.component.scss']
})
export class EmailListBlockComponent {

  public expanded = false;
  @Input('title')title = '';
  @Input('description')description: String = '';
  @Input('addedBy')addedBy = '';
  @Input('addedOn')addedOn = '';
  @Input('tags')tags = [];
}