import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class DataTableService {

  public onFilterUpdate$ = new Subject<any>();

  constructor() { }

  updateTable(newFilters) {
    this.onFilterUpdate$.next(newFilters);
  }

}
