import { Component, OnInit, EventEmitter, Input, Inject, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@app/shared/services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { PERSONA_NAMES } from '@app/core/constants/persona-constants';

@Component({
  selector: 'aar-rcm-quote-document-detail',
  templateUrl: './rcm-quote-document-detail.component.html',
  styleUrls: ['./rcm-quote-document-detail.component.scss']
})

export class RcmQuoteDocumentDetailComponent implements OnInit {
  @Input('document') document: any;
  @Output('close') close = new EventEmitter();
  public loading = false;
  public canDelete = false;
  private curUser: User;
  constructor(
    private iconRegistry: MatIconRegistry, private snackBar: MatSnackBar, private authService: AuthService,
    private sanitizer: DomSanitizer, private dialog: MatDialog, private api: ApiService) { }

  ngOnInit() {
   this.curUser = this.authService.getLoggedUser();

   this.canDelete = this.curUser.personaName !== PERSONA_NAMES.PROGRAMMANAGER;
  }

  backClick() {
    this.close.emit();
  }

  delete(document) {
    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to delete this file?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {
      if (shouldConfirm === true) {
        this.loading = true;
        this.api.deleteDocument(document.documentStorageName, this.curUser.companyID).subscribe(result => {
          this.loading = false;
          if (result.deleteDocument == true) {
              this.openSnackBar('File Successfully Deleted!');
              this.backClick();
          } else {
              this.openSnackBar('File Could Not be Deleted!');
          }
        });
      }
    });
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  download(documentRef) {
    this.api.getFileToDownload(documentRef.documentStorageName, documentRef.name, this.curUser.companyID)
    .subscribe(res => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => {
      this.openSnackBar('File Download Failed. Please contact Admin!');
    }, () => {
    });
  }

}
