import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RepairOrder } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-approval-dash-card',
  templateUrl: './approval-dash-card.component.html',
  styleUrls: ['./approval-dash-card.component.scss']
})
export class ApprovalDashCardComponent {
  @Input('ro') ro: RepairOrder;

  getCost(): number {
   // return this.ro.historicalPricingList && this.ro.historicalPricingList.length ?
    //  this.ro.historicalPricingList[this.ro.historicalPricingList.length - 1].cost : 0;
    return 100;
  }

  convertStatus(): string {
    const regex = /_/g;
    return this.ro.repairOrderStatusName.replace(regex, ' ');
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    ApprovalDashCardComponent
  ],
  exports: [
    ApprovalDashCardComponent
  ]
})

export class ApprovalDashCardModule { }
