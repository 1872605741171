<div class="toggle-wrapper">
  <div class="toggle-label">
    <ng-content select="[aar-label]"></ng-content>
  </div>

  <ng-container *ngIf="!controlName && !group; else isReactive">
    <div class="toggle-parent">
      <input type="checkbox" id="toggle-{{id}}" [checked]="value" [disabled]="disabled" (change)="onChange($event)">
      <label for="toggle-{{id}}"><i></i></label>

      <div *ngIf="showToggleLabels && isChecked()" class="tive-text">{{ positiveLabel || 'Yes' }}</div>
      <div *ngIf="showToggleLabels && !isChecked()" class="tive-text">{{ negativeLabel || 'No' }}</div>
    </div>
  </ng-container>
  
  <ng-template #isReactive>
    <div class="toggle-parent" [formGroup]="group">
      <input type="checkbox" id="toggle-{{id}}" [formControlName]="controlName" (change)="onChange($event)">
      <label for="toggle-{{id}}"><i></i></label>

      <div *ngIf="showToggleLabels && isChecked()" class="tive-text">{{ positiveLabel || 'Yes' }}</div>
      <div *ngIf="showToggleLabels && !isChecked()" class="tive-text">{{ negativeLabel || 'No' }}</div>
    </div>
  </ng-template>
</div>