import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'aar-no-activity',
  templateUrl: './no-activity.component.html',
  styleUrls: ['./no-activity.component.scss']
})
export class NoActivityComponent implements OnInit {
  @Input('message') message: String;

  constructor() { }

  ngOnInit() { }
}

@NgModule({
  imports: [],
  exports: [ NoActivityComponent ],
  declarations: [ NoActivityComponent ]
})

export class NoActivityModule { }
