<div class="loading-container">
  <mat-progress-spinner
    *ngIf="!error"
    color="primary"
    mode="indeterminate"
    [value]="50"
    [diameter]="70"
    [strokeWidth]="5"
  ></mat-progress-spinner>

  <div class="loading-error" *ngIf="error">
    <mat-icon class="icon-error" svgIcon="error" color="warn"></mat-icon>
    <p>
      Data Error.
      <br>
      Unable to load the data.
    </p>
    <button mat-stroked-button (click)="refreshPage()">
      <mat-icon svgIcon="autorenew" class="icon-autorenew"></mat-icon>
      Please try again
    </button>
  </div>
</div>
