import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApiService } from '@app/shared/services/api/api.service';
import { RepairOrder, Supplier } from '@app/shared/services/api/api.models';
import { WORKSCOPES, COUNTRIES, TIME_FRAMES } from '@app/core/constants/enums';
import { DATA } from '@app/core/constants/historialPricing';
import { DataTableService } from '@app/shared/components/data-table/data-table.service';

@Component({
  selector: 'aar-cost-control',
  templateUrl: './cost-control.component.html',
  styleUrls: ['./cost-control.component.scss']
})
export class CostControlComponent implements OnInit {
  @ViewChild('filtersForm') filtersForm: NgForm;
  repairOrders: RepairOrder[];
  suppliers: Supplier[];

  displayedColumns = [
    'serialNumber',
    'repairOrderNumber',
    'workScopeName',
    'date',
    'supplier',
    'country',
    'contract',
    'totalCost'
  ];

  columnsSettings = [
    { name: 'serialNumber', header: 'Serial Number'},
    { name: 'repairOrderNumber', header: 'Repair Order'},
    { name: 'workScopeName', header: 'Workscope'},
    { name: 'date', header: 'Date'},
    { name: 'supplier', header: 'Supplier'},
    { name: 'country', header: 'Location'},
    { name: 'contract', header: 'Contract'},
    { name: 'totalCost', header: 'Total cost'}
  ];

  tableData = DATA;
  serviceTypes = WORKSCOPES;
  countries = COUNTRIES;
  timeFrames = TIME_FRAMES;
  costPlotDateValues = [];
  costPlotCostsValues = [];

  constructor(
    private apiService: ApiService,
    private dataTableService: DataTableService) { }

  ngOnInit() {
    this.getRepairOrders();
    this.getSuppliers();

    this.filtersForm.valueChanges
      .subscribe(changes => {
        this.dataTableService.updateTable(changes);
      });
      this.searchByRONumber();
  }

  getRepairOrders() {
    this.apiService.getMockRepairOrders()
      .subscribe(repairOrders => {
        this.repairOrders = repairOrders.json();
      });
  }

  getSuppliers() {
    this.apiService.getSuppliers()
        .subscribe(suppliers => this.suppliers = suppliers);
  }

    searchByRONumber(roNumber = null) {
      if (roNumber === null || roNumber === '') {
        this.tableData = DATA;
        this.costPlotDateValues = this.tableData.map((item) => {
          return item.date;
        });

        this.costPlotCostsValues = this.tableData.map((item) => {
          return item.totalCost;
        });

      } else {
        this.apiService.getHistoricalPricingByRoNumber(roNumber)
        .subscribe(historicalPricing => {

          this.tableData = historicalPricing.map((item) => {
          return    {
            serialNumber: item.rOCombineID,
            repairOrderNumber: item.repairOrderNumber,
            workScopeName: item.contracted,
            date: item.date,
            supplier: item.supplier ? item.supplier.name : '-',
            country: item.supplier ? item.supplier.location : 'US',
            contract: item.contracted,
            totalCost: item.cost
          };
        });

        this.costPlotDateValues = this.tableData.map((item) => {
          return item.date;
        });

        this.costPlotCostsValues = this.tableData.map((item) => {
          return item.totalCost;
        });

      });

    }


  }
  getCountry(tData) {
    return tData.split(',')[-1];
  }
}
