<div class="aar-shipping-label-and-value-container">
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Shipped</span>
    <span class="aar-shipping-label-value">{{!dateShipped ? "n/a" : dateShipped |
      dateLocalisedPipe}}</span>
  </div>
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Delivered</span>
    <span class="aar-shipping-label-value">{{!dateDelivered ? "n/a" : dateDelivered |
      dateLocalisedPipe}}</span>
  </div>
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Tracking Number</span>
    <span class="aar-shipping-label-value">{{trackingNumber}}</span>
  </div>
</div>

<div class="aar-shipping-label-and-value-container">
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Address</span>
    <span class="aar-shipping-label-value">{{address}}</span>
  </div>
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Shipping Carrier</span>
    <span class="aar-shipping-label-value">{{carrierName}}</span>
  </div>
  <div class="aar-shipping-label-and-value">
    <span class="aar-shipping-label">Shipping Reference</span>
    <span class="aar-shipping-label-value">{{shippingReference}}</span>
  </div>
</div>

 <!-- Commented under #40361
<div class="aar-shipping-label-and-value-container last">
  <div class="aar-shipping-label-and-value-single last">
    <span class="aar-shipping-label">Prevent Feed Update</span>
    <aar-toggle [value]="isPreventFeedUpdate" disabled="true"></aar-toggle>
  </div>
</div>
-->
<div *ngIf="showUpdate() || showDelete(); else noButtons">
  <div class="aar-shipping-button-block">
    <div *ngIf="showUpdate()">
      <button type="button" mat-flat-button color="primary" (click)="onUpdateClick($event)">Update</button>
    </div>
    <div class="margin-left-10" *ngIf="showDelete()">
      <button type="button" mat-flat-button color="primary" (click)="onDeleteClick($event)">Delete</button>
    </div>
    <div class="right-justified">
      <a href="javascript:void(0)" (click)="onCloseClick()">Close</a>
      <div class="expand-less"><a href="javascript:void(0)" (click)="onCloseClick()"><mat-icon>expand_less</mat-icon> </a></div>
    </div>
  </div>
</div>
<ng-template #noButtons>
  <div class="aar-shipping-close-no-buttons">
    <div class="right-justified">
    <a href="javascript:void(0)" (click)="onCloseClick()">Close</a>
    <div class="expand-less" ><a href="javascript:void(0)" (click)="onCloseClick()"><mat-icon>expand_less</mat-icon> </a></div>
    </div>
  </div>
</ng-template>
<!-- Keep this empty div
  without it limitations in the browser prevent the container expanding to encapsulate the button div above -->
<div>&nbsp;</div>