import { ChangeDetectionStrategy, Component,Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getFormFielErrorMessage } from '@app/common/utility';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { ContactMode } from '../contact.constant';
import { AddEditContactComponent } from '../add-edit-dialog/add-edit-contact.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonContact } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-add-edit-contact-dialog',
  templateUrl: './add-edit-contact-dialog.component.html',
  styleUrls: ['./add-edit-contact-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditContactDialogComponent implements OnInit {
  maintainContactForm: UntypedFormGroup;
  contact: CommonContact;
  supplierNameInput:string=''
  isInfoPopUp :any;
  isDisabledPrimaryContact:boolean=false;
  private contactMode: ContactMode;
  isCustomerForm:boolean=false;
   readonly supplierName = 'name';
   readonly isPrimaryContactControlName = 'isPrimaryContact';
  readonly firstNameControlName = 'firstName';
  readonly lastNameControlName = 'lastName';
  readonly mainPhoneNumberControlName = 'mainPhoneNumber';
  readonly emailAddressControlName = 'emailAddress'; 
  readonly commentsControlName = 'comments';
  readonly titleControlName = 'title';

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddEditContactComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.contact = data.contact;
      this.supplierNameInput = data.name;
      this.contactMode = data.contact.contactID === 0 ? ContactMode.Add : ContactMode.Edit;
      this.isInfoPopUp  = data.isInfoPopUp ;
      this.isCustomerForm = data.isCustomer;
  }

  ngOnInit() {      
      this.maintainContactForm = this.formBuilder.group({
        name: [this.supplierNameInput, [Validators.required, Validators.maxLength(45)]],
        isPrimaryContact: [this.contact.isPrimaryContact],
        firstName: [this.contact.firstName, [Validators.required, Validators.maxLength(30)]],
        lastName: [this.contact.lastName, [Validators.required, Validators.maxLength(30)]],
        title: [this.contact.title, Validators.maxLength(75)],
        emailAddress: [this.contact.emailAddress, [Validators.required, Validators.email, Validators.maxLength(50)]],
        mainPhoneNumber: [this.contact.mainPhoneNumber, Validators.maxLength(30)],
        comments: [this.contact.comments, Validators.maxLength(100)],      
      }
    );

    if(this.contact.isPrimaryContact){
      this.isDisabledPrimaryContact = true
    }  
  }

  getTitle(): string {
    return this.contactMode === ContactMode.Add ? 'Add Contact' : 'Update Contact';
  }

  hasError(form: UntypedFormGroup, control: string): boolean {
    return !form.controls[control].valid;
  }

  getErrorMessage(form: UntypedFormGroup, control: string): string {
    return getFormFielErrorMessage(form, control);
  }

  onClose() {
    // Just close the form without a confirmation dialog if nothing has changed provided the data is valid.
    if (!this.maintainContactForm.dirty && this.maintainContactForm.valid) {
      this.dialogRef.close();
      return;
    }

    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to discard the changes?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {
      if (shouldConfirm === true) {
        this.dialogRef.close();
      }
    });
  }

  onSave() {
    if (!this.maintainContactForm.dirty && this.maintainContactForm.valid) {
      this.dialogRef.close();
      return;
    }
    if(this.maintainContactForm.value.isPrimaryContact && this.maintainContactForm.get('isPrimaryContact').dirty ){
      const contactType = this.isCustomerForm ? 'customer' : 'supplier';
      const onSave =CommonDialogComponent.openDialog(this.dialog, '','Do you want to change the Primary Contact for this ' + contactType + '?', 'Yes' , 'No');

      onSave.subscribe(confirmYes => {       
        if (confirmYes == true && this.maintainContactForm.valid) {  
            const {name , ...expectData } = this.maintainContactForm.value;      
            this.dialogRef.close(Object.assign(this.contact, expectData));
        } 
      });
    }else{      
      const {name , ...expectData } = this.maintainContactForm.value;      
      this.dialogRef.close(Object.assign(this.contact, expectData));
    }
  }

}

