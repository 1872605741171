import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AppRoutingModuleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) { }

  checkRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let moduleName = route.data["module"] as string;
    const userInCompanyWithModule = this.authService.isUserInCompanyWithModule(moduleName);
    //console.log(`AppRoutingModuleGuard.userInCompanyWithModule: ${userInCompanyWithModule}`)
    return userInCompanyWithModule;
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkRoute(next, state);
  }
  
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkRoute(next, state);
  }

}
