<div class="aar-sliding-section">
  <h4>Review Workscope</h4>

  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('supplierReference')">
      <label>Supplier Ref </label>
      <span class="aar-sliding-input-value">{{quoteLine.supplierReference}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('datePosted')">
      <label>Date on Quote</label>
      <span class="aar-sliding-input-value">{{quoteLine.datePosted | dateLocalisedPipe:true}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('dateReceived')">
      <label>Date Quote Received</label>
      <span class="aar-sliding-input-value">{{quoteLine.dateReceived | dateLocalisedPipe:true}}</span>
    </div>
  </div>

  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('partNumberReceived')">
      <label>Part Number Received</label>
      <span class="aar-sliding-input-value">{{quoteLine.partNumberReceived}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="false">
      <label>Reason for PN Mismatch</label>
      <span class="aar-sliding-input-value">{{quoteLine.mismatchReasonPnId}}</span>
    </div>
  </div>

  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('serialNumberReceived')">
      <label>Serial Number Received</label>
      <span class="aar-sliding-input-value">{{quoteLine.serialNumberReceived}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('mismatchReasonSnId')">
      <label>Reason for SN Mismatch</label>
      <span class="aar-sliding-input-value">{{ getMismatchReason() }}</span>
    </div>

    <div class="aar-sliding-form-control aar-sliding-form-control2" *ngIf="!isFieldHidden('qtyRceived')">
      <label>Quantity Received</label>
      <span class="aar-sliding-input-value">{{quoteLine.qtyRceived}}</span>
    </div>
  </div>

  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('repairCategoryValue')">
      <label>Unit Category</label>
      <span class="aar-sliding-input-value">{{quoteLine.repairCategoryValue}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('proposedWorkscopeId')">
      <label>Workscope Proposed</label>
      <span class="aar-sliding-input-value">{{workscope.workScopeId + ' (' + workscope.name + ')'}}</span>
    </div>

    <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('quotedTat')">
      <label>Quoted Tat</label>
      <span class="aar-sliding-input-value">{{quoteLine.quotedTat }}</span>
    </div>
  </div>

  <div class="aar-sliding-form-group" *ngIf="!isFieldHidden('findings')">
    <div class="aar-sliding-form-control aar-sliding-form-control3" style="width: 100% !important;">
      <label>Quote Findings</label>
      <span class="aar-sliding-input-value" style="color:text_07">{{quoteLine.findings}}</span>
    </div>

  </div>
</div>

<div class="aar-sliding-form-bdr">
  <div class="aar-sliding-section">
    <h4>Cost Entry</h4>

    <div class="aar-sliding-form-group" style="padding-right:0px">
      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('currency')">
        <label>Currency</label>
        <span class="aar-sliding-input-value">{{currency.name}}</span>
      </div>
      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('totalCost')">
        <label>Total Supplier Cost</label>
        <span class="aar-sliding-input-value">{{quoteLine.totalCost | currencyLocalisedPipe:currencyISOName}}</span>
      </div>
      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('rai')">
        <label>RAI</label>
        <span class="aar-sliding-input-value">{{quoteLine.rai | currencyLocalisedPipe:currencyISOName}}</span>
      </div>
      <div class="aar-sliding-form-control" *ngIf="!isFieldHidden('sos')">
        <label>SOS</label>
        <span class="aar-sliding-input-value">{{quoteLine.sos | currencyLocalisedPipe:currencyISOName}}</span>
      </div>
    </div>
  </div>
</div>

<!--
<div *ngIf="quoteLine.totalCost >= 1800">
  <div class="aar-sliding-section relative">
    <div class="group1">
      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control aar-sliding-form-control3 mb-0">
          <label>Cost Flags</label>
          <span class="aar-sliding-input-value">Price Exceeds <a href="">Contract</a>;&nbsp;&nbsp;&nbsp; Price is Above Market Average Last 12 mo.</span>
        </div>
      </div>
    </div>
  </div>
</div>
-->

<div class="aar-sliding-section" *ngIf="false">
  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control">
      <label>Currency</label>
      <span class="aar-sliding-input-value">{{quoteLine.nupCurrency}}</span>
    </div>

    <div class="aar-sliding-form-control">
      <label>NUP</label>
      <span class="aar-sliding-input-value">{{quoteLine.nUP}}</span>
    </div>

    <div class="aar-sliding-form-control">
      <label>%NUP</label>
      <span class="aar-sliding-input-value">{{quoteLine.nupPercent}}%</span>
    </div>
  </div>

  <div class="aar-sliding-form-group">
    <div class="aar-sliding-form-control">
      <label>Currency</label>
      <span class="aar-sliding-input-value">{{quoteLine.mvCurrency}}</span>
    </div>

    <div class="aar-sliding-form-control">
      <label>Market Value</label>
      <span class="aar-sliding-input-value">{{quoteLine.mV}}</span>
    </div>

    <div class="aar-sliding-form-control">
      <label>%MV</label>
      <span class="aar-sliding-input-value">{{quoteLine.mvPercent}}%</span>
    </div>
  </div>
</div>

<div class="aar-sliding-form-footer">
  <div class="aar-sliding-form-footer1">
    <button mat-flat-button color="primary" (click)="previousSelected()" *ngIf="!hidePrevious">Previous</button>
    <button mat-flat-button color="primary" (click)="createQuoteLine()" *ngIf="!hideSubmit">Submit</button>
    <button mat-flat-button color="primary" (click)="returnToQuoteList()" *ngIf="hideSubmit">Return</button>
    <span>
      <!---
        <button mat-flat-button>
          <i class="material-icons">insert_link</i> RO-Quote-Active.pdf
      </button>-->
    </span>
  </div>
</div>
