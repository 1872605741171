<mat-sidenav-container autosize>

  <mat-sidenav #sidenav opened mode="side" class="aar-sidebar" [ngClass]="{'collapsed': sidebarCollapsed}">
    <h1>{{titleSub1}}<span class="font-light">{{titleSub2}}</span></h1>

    <div class="nav-toggle" (click)="toggleSidebar()">
      <mat-icon *ngIf="!sidebarCollapsed">chevron_left</mat-icon>
      <mat-icon *ngIf="sidebarCollapsed">chevron_right</mat-icon>
    </div>
    <!-- <a routerLink="/login">Login</a> -->
    <mat-nav-list>

      <ng-container [ngSwitch]="user.roleName.toUpperCase()">
        <ng-container *ngSwitchCase="'CUSTOMER'">
          <ng-container *ngTemplateOutlet="customerMenu"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SUPPLIER'">
          <ng-container *ngTemplateOutlet="supplierMenu"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
         <ng-container *ngTemplateOutlet="fullMenu"></ng-container>
        </ng-container>
      </ng-container>

      <ng-template #fullMenu>
        <!-- #30915: Hide notifications until the functionality is working as per the requirement -->
        <a mat-list-item class="dark" routerLink="/notifications" routerLinkActive="active" *ngIf="false">
          <mat-icon class="icon-notification">notifications</mat-icon>
          <span class="label">Notifications</span>
          <span class="aar-notifications-count">{{notifications}}</span>
        </a>

        <a mat-list-item routerLink="/dashboard" routerLinkActive="active" *ngIf="canShowDashboard()">
          <mat-icon class="icon-dashboard">dashboard</mat-icon>
          <span class="label">Dashboard</span>
        </a>

        <a mat-list-item routerLink="/rcm" routerLinkActive="active">
          <mat-icon class="icon-rcm">repeat</mat-icon>
          <span class="label">RCM</span>
        </a>

        <a mat-list-item routerLink="/history" routerLinkActive="active">
          <mat-icon class="icon-history">history</mat-icon>
          <span class="label">Historical Data</span>
        </a>

        <mat-expansion-panel *ngIf="!sidebarCollapsed && canShowAnalyticsAndBI()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="icon-analytics">pie_chart</mat-icon>
              <span class="label">Analytics & BI</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-nav-list class="inner">
            <a mat-list-item class="sub-item" routerLink="/analytics/tat-report" routerLinkActive="active">
              <mat-icon>timer</mat-icon>TAT Report
            </a>
            <a mat-list-item class="sub-item" routerLink="/analytics/status-report" routerLinkActive="active">
              <mat-icon>timeline</mat-icon>Status Report
            </a>
                      <!--
            <a mat-list-item class="sub-item">
              <span class="label">Aging</span>
            </a>
          -->
          </mat-nav-list>
        </mat-expansion-panel>

        <a mat-list-item *ngIf="sidebarCollapsed && canShowAnalyticsAndBI()" [matMenuTriggerFor]="analyticsMenu">
          <mat-icon class="icon-analytics">pie_chart</mat-icon>
          <span class="label">Analytics & BI</span>
        </a>

        <mat-menu #analyticsMenu="matMenu" class="aar-submenu">
          <button mat-menu-item routerLink="/analytics/tat-report"><mat-icon>timer</mat-icon>TAT Report</button>
          <button mat-menu-item routerLink="/analytics/status-report"><mat-icon>timeline</mat-icon>Status Report</button>
          <!--
          <button mat-menu-item>Cost Report</button>
          <button mat-menu-item>Aging</button>
          -->
        </mat-menu>
        
      <mat-expansion-panel *ngIf="!sidebarCollapsed">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon> import_contacts</mat-icon>
            <span class="label">Contacts</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list class="inner">
          <a mat-list-item class="sub-item" routerLink="/contacts/internals" routerLinkActive="active">
            <mat-icon>domain</mat-icon>Internal</a>
          <!-- Re-instate the customer & supplier contact menu items once those screens have been reworked -->
          <a mat-list-item class="sub-item" routerLink="/contacts/customers" routerLinkActive="active"
            *ngIf="canShowCustomerContacts()">
              <mat-icon>group</mat-icon>Customers</a>

          <a mat-list-item class="sub-item" routerLink="/contacts/suppliers" routerLinkActive="active"
            *ngIf="canShowSupplierContacts()">
            <mat-icon>group_work</mat-icon>Suppliers</a>

        </mat-nav-list>
      </mat-expansion-panel>

      <a mat-list-item *ngIf="sidebarCollapsed" [matMenuTriggerFor]="contactsMenu">
        <mat-icon class="icon-analytics">import_contacts</mat-icon>
        <span class="label">Contacts</span>
      </a>

      <mat-menu #contactsMenu="matMenu" class="aar-submenu">
        <button mat-menu-item routerLink="/contacts/internals"><mat-icon>domain</mat-icon>Internal</button>
        <button mat-menu-item routerLink="/contacts/customers" *ngIf="canShowCustomerContacts()"><mat-icon>group</mat-icon>Customers</button>
        <button mat-menu-item routerLink="/contacts/suppliers" *ngIf="canShowSupplierContacts()"><mat-icon>group_work</mat-icon>Suppliers</button>
      </mat-menu>

      </ng-template>

      <ng-template #supplierMenu>
        <!-- #30915: Hide notifications until the functionality is working as per the requirement -->
        <a mat-list-item class="dark" routerLink="/notifications" routerLinkActive="active" *ngIf="false">
          <mat-icon class="icon-notification">notifications</mat-icon>
          <span class="label">Notifications</span>
          <span class="aar-notifications-count">{{notifications}}</span>
        </a>

        <a mat-list-item routerLink="/supplier" routerLinkActive="active">
          <mat-icon class="icon-rcm">repeat</mat-icon>
          <span class="label">Supplier Portal</span>
        </a>

        <a mat-list-item routerLink="/history" routerLinkActive="active">
          <mat-icon class="icon-history">history</mat-icon>
          <span class="label">Historical Data</span>
        </a>

      </ng-template>

      <ng-template #customerMenu>
        <a mat-list-item routerLink="/rcm" routerLinkActive="active">
          <mat-icon class="icon-rcm">repeat</mat-icon>
          <span class="label">RCM</span>
        </a>
      </ng-template>

    </mat-nav-list>

    <mat-nav-list class="aar-lower-nav">

      <a mat-list-item routerLink="/my-account" routerLinkActive="active">
        <mat-icon class="icon-my-account">account_box</mat-icon>
        <span class="label">My Account</span>
      </a>

      <a mat-list-item (click)="logout()">
        <mat-icon class="icon-logout">exit_to_app</mat-icon>
        <span class="label">Logout</span>
      </a>
      <a mat-list-item class="dark" href="https://www.aarcorp.com/airvolution/" target="_blank">
        <mat-icon class="icon-about">flight_takeoff</mat-icon>
        <span class="label">About Airvolution</span>
      </a>
      <a mat-list-item class="dark" href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_tcs_06232023.pdf" target="_blank">
        <mat-icon class="icon-terms">chrome_reader_mode</mat-icon>
        <span class="label">Terms & Conditions</span>
      </a>
      <a mat-list-item class="dark" href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_privacynotice_09272023.pdf" target="_blank">
      <mat-icon class="icon-privacypolicy">vpn_lock</mat-icon>
      <span class="label">Privacy Notice</span>
      </a>
      <a mat-list-item class="dark" href="mailto:airvolutionsupport@aarcorp.com" target="_blank">
        <mat-icon class="icon-email">chat_bubble_outline</mat-icon>
        <span class="label">Contact Us</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="aar-sidenav-content" #pageContainer>
      <aar-header *ngIf="!router.url.includes('notifications')"></aar-header>
      <main [ngClass]="{hidden: loading}">
        <router-outlet></router-outlet>
      </main>
      <aar-loading *ngIf="loading" [error]="loadingError"></aar-loading>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
