import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'aar-rcm-communication-block',
  templateUrl: './rcm-communication-block.component.html',
  styleUrls: ['./rcm-communication-block.component.scss']
})
export class RcmCommunicationBlockComponent {

  public expanded = false;
  @Input('title')title = '';
  @Input('description')description: String = '';
  @Input('addedBy')addedBy = '';
  @Input('addedOn')addedOn = '';
  @Input('tags')tags = [];

  constructor(
    private sanitizer: DomSanitizer) { }

  sanitizeHtml(itemToSanitize) {
    return this.sanitizer.bypassSecurityTrustHtml(itemToSanitize);
  };
}
