<div class="add-edit-contact">

  <h2 class="add-edit-contact-title">{{this.getTitle()}}</h2>

  <div class="add-edit-contact-body">
    <div class="add-edit-contact-body-form" [formGroup]="maintainContactForm">

      <div class="add-edit-contact-body-form-row" mat-dialog-content [formGroup]="maintainContactForm">
        <mat-form-field class="mid">
          <input matInput placeholder="First Name" type="text" [formControlName]="firstNameControlName" />
          <mat-error *ngIf="hasError(maintainContactForm, firstNameControlName)">{{
            getErrorMessage(maintainContactForm, firstNameControlName) }}</mat-error>
        </mat-form-field>
        <mat-form-field class="mid">
          <input matInput placeholder="Last Name" type="text" [formControlName]="lastNameControlName" />
          <mat-error *ngIf="hasError(maintainContactForm, lastNameControlName)">{{
            getErrorMessage(maintainContactForm, lastNameControlName) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="mid">
          <input matInput placeholder="Title" type="text" [formControlName]="titleControlName" />
        </mat-form-field>
        <mat-form-field class="mid">
          <input matInput placeholder="Alternate Phone Number" type="text" [formControlName]="alternatePhoneNumberControlName" />
        </mat-form-field>
      </div>

      <table style="width: 100%;">
        <tr>
          <td class="mid">
            <mat-form-field class="mid1">
              <input matInput placeholder="Main Phone Number" type="text" [formControlName]="mainPhoneNumberControlName" />
            </mat-form-field>
          </td>
          <td class="mid" rowspan="2">
            <mat-form-field class="mid1"><textarea matInput placeholder="Comments" rows="6" [formControlName]="commentsControlName" ></textarea></mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="mid">
            <mat-form-field class="mid1">
              <input matInput placeholder="Email Address" type="text" [formControlName]="emailAddressControlName" />
              <mat-error *ngIf="hasError(maintainContactForm, emailAddressControlName)">{{
                getErrorMessage(maintainContactForm, emailAddressControlName) }}</mat-error>
            </mat-form-field>
          </td>
        </tr>
      </table>

    </div>
  </div>

  <div class="add-edit-contact-footer">
    <button mat-flat-button color="primary" class="aar-button" [disabled]="maintainContactForm.invalid || !maintainContactForm.dirty"
      (click)="onSave()">Save</button>
    <button class="spacer-left" mat-button color="primary" (click)="onClose()">Cancel</button>
  </div>

</div>
<!--<form [formGroup]="maintainContactForm" (ngSubmit)="onSubmit()">
  <div class="contact-table">
  <div class="example-container">
    <div class="aar-header-content display-row">
      <div class ="col-sec">
        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="FIRST NAME" [formControlName]="firstNameControlName">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="TITLE" [formControlName]="titleControlName">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="MAIN PHONE NUMBER" [formControlName]="mainPhoneNumberControlName">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="EMAIL ADDRESS" [formControlName]="emailAddressControlName">
        </mat-form-field>
      </div>
      <div class ="col-sec">
        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="LAST NAME" [formControlName]="lastNameControlName">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="ALTERNATE PHONE NUMBER" [formControlName]="alternatePhoneNumberControlName">
        </mat-form-field>

        <mat-form-field floatLabel="always" class="md full-length">
          <input matInput placeholder="COMMENTS" [formControlName]="commentsControlName">
        </mat-form-field>
      </div>
    </div>

    <div class ="col-sec-btn">
      <button mat-flat-button color="primary" (click)="createContact()" *ngIf="mode==='add'">Save</button>&nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="updateContact()" *ngIf="mode==='edit'">Edit</button>&nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="onCancel()">Cancel</button>
      &nbsp;&nbsp;<button mat-flat-button color="primary" type="submit">Submit</button>
    </div>
  </div>

</div>
</form>-->
