<div class="title-section">
  <h4>Contacts</h4>
</div>
<table mat-table #table  [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z8">
  <ng-container matColumnDef="endCustomerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by endCustomerName
    ">Customer Name</th>
    <td mat-cell class="read-text"  *matCellDef="let element">{{element?.endCustomerName}} </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions" mat-sort-header>&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row"
        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="primaryContect">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by first name">Primary Contact
    </th>
    <td mat-cell class="read-text"  *matCellDef="let element">{{element?.primaryDetail?.firstName}} {{element?.primaryDetail?.lastName }}<Br>
      <a href="{{element.emailAddress }} ">{{element?.primaryDetail?.emailAddress}} </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="mainPhoneNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by first name">Main Phone Number
    </th>
    <td mat-cell class="read-text"   *matCellDef="let element">{{element?.primaryDetail?.mainPhoneNumber}} </td>
  </ng-container>
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by first name">Address</th>
    <td mat-cellclass="read-text"  *matCellDef="let element">{{element.address}} </td>
  </ng-container>
  <ng-container matColumnDef="totalCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by first name">Total Contacts</th>
    <td mat-cell class="read-text"  *matCellDef="let element">{{element.contactList.length}} </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

        <div class="element-container">
          <h2>CUSTOMER DETAILS</h2>

          <div class="example-element">
            <h5>Name</h5><span>{{element.endCustomerName}}</span>
          </div>
          <div class="example-element">
            <h5>Address</h5><span>{{element.location}}</span>
          </div>
          <div class="example-element">
            <h5>Main Phone</h5><span>{{element.contactList[0]?.mainPhoneNumber}}</span>
          </div>

          <h2>CONTACTS</h2>
          <div class="contact-table" *ngIf="element.isShowContactList">
            <table mat-table #table [dataSource]="element.contactList"  class="mat-elevation-z8">

              <ng-container matColumnDef="firstName">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                  FIRST NAME
                </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
              </ng-container>

              <ng-container matColumnDef="lastName">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                LAST NAME
                </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                   TITLE
                </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
              </ng-container>

              <ng-container matColumnDef="emailAddress">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                 EMAIL ADDRESS
                </th>
                <td  mat-cell *matCellDef="let element"> 
                  <a [href]="getEmailLink(element.emailAddress)" >{{element.emailAddress}}</a>
                   </td>
              </ng-container>

              <ng-container matColumnDef="mainPhoneNumber">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                  PHONE NUMBER
                </th>
                <td  mat-cell *matCellDef="let element"> {{element.mainPhoneNumber}} </td>
              </ng-container>

              <ng-container matColumnDef="alternatePhoneNumber">
                <th class="header-text contact-header" mat-header-cell *matHeaderCellDef>
                  PRIMARY CONTACT
                </th>
                <td mat-cell *matCellDef="let element"> {{element.isPrimaryContact === true ? 'Yes' : 'No' }} </td>
              </ng-container>

              <ng-container matColumnDef="comments">
                <th class="header-text" mat-header-cell *matHeaderCellDef></th>
                <td class="read-text" mat-cell *matCellDef="let element">
                  <button mat-icon-button *ngIf="isContactEditable">
                    <mat-icon color="primary" *ngIf="hasComments(element.comments)"
                      (click)="showUpdateContactDetailsForm(dataSource, element, 'info')">info</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th class="header-text" mat-header-cell *matHeaderCellDef></th>
                <td class="read-text" mat-cell *matCellDef="let element; let i = index;">
                  <button disabled class="align-right call" mat-flat-button color="primary" *ngIf="isContactEditable"
                    [disabled]="getCustomerContact(element) === 1"
                    [ngClass]="{'disableBtn': getCustomerContact(element) === 1}"
                    (click)="deleteContact(element.contactID , element)">Delete</button>
                  <button  class="align-right button" mat-flat-button color="primary" *ngIf="isContactEditable"
                  (click)="showUpdateContactDetailsForm(dataSource,element)" >Update</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRow" class="border-bottom"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsRow;"></tr>
            </table>
          </div>
          <div class="expand-bottom">
            <div class="count-element">
             <h5> <p>TOTAL : </p><span> {{element.contactList.length}}</span></h5> 
              <button class="align-right button" mat-flat-button color="primary" *ngIf="isContactEditable"
                (click)="showAddContactDetailsForm(element.contactList ,element , 0)">ADD CONTACT</button>
            </div>
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </div>
        </div>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
<div>
  <mat-paginator class="mat-paginator" [pageSize]="pageSize" (page)="expandedElement = null" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
    aria-label="Select page of contact elements">
  </mat-paginator>
</div>