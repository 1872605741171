import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingRoleGuard implements CanActivate, CanActivateChild {
  supplierWhiteList = [
    '/supplier',
    '/suppliers/wip',
    '/suppliers/archive',
    '/suppliers/carriers',
    '/suppliers/quote-drop',
    '/suppliers/add-quote',
  ];

  customerWhiteList = [
    '/rcm'
  ];

  constructor(private router: Router, private authService: AuthService) { }

  checkRoute(state: RouterStateSnapshot): boolean {
    const user = this.authService.getLoggedUser();
    var isInSupplierWhiteList = this.supplierWhiteList.indexOf(state.url) > -1;
    var isInCustomerWhiteList = this.customerWhiteList.indexOf(state.url) > -1;
    
    // If SUPPLIER check if the route is allowed
    if (user && user.roleName === 'SUPPLIER') {
      if(state.url.indexOf('/rcm/') > -1 || state.url.indexOf('/history') > -1)
      {
         return true;
      }
      else if (isInSupplierWhiteList) {
        return true;
      }

      // If route is not allowed redirect to 'suppliers/wip'
      this.router.navigate(['/supplier']);
      return false;
    }
    if (user && user.roleName === 'CUSTOMER') {
      if(state.url.indexOf('/rcm') > -1)
      {
         return true;
      }
      else if (isInCustomerWhiteList) {
        return true;
      }
      // If route is not allowed redirect to 'rcm'
      this.router.navigate(['/rcm']);
      return false;
    }
    // If is not SUPPLIER check if the route is not allowed and redirect to '/dashboard'
    if (isInSupplierWhiteList) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    // If is not SUPPLIER and the route is allowed, continue
    return true;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot)  {
    return this.checkRoute(state);
  }
}
