import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatevalidationService {

  validateDate(actual: any, expected: any): boolean {
    if (moment(new Date(actual).setHours(0, 0, 0, 0)).isSame(new Date(expected).setHours(0, 0, 0, 0))) {
      return true;
    } else if (moment(new Date(actual).setHours(0, 0, 0, 0)).isSameOrBefore(new Date().setHours(0, 0, 0, 0))) {
      return true;
    }
    return false;
  }

  validateDateIsBeforeOrEqualToDate(actual: Date, expected: Date): boolean {
    if (moment(new Date(actual).setHours(0, 0, 0, 0)).isSameOrBefore(new Date(expected).setHours(0, 0, 0, 0))) {
      return true;
    }
    return false;
  }

  validateDateIsBetweenDatesInclusive(testDate: Date, startDate: Date, endDate: Date): boolean {

    const testMoment = moment(new Date(testDate).setHours(0, 0, 0, 0));
    const startMoment = moment(new Date(startDate).setHours(0, 0, 0, 0));
    const endMoment = moment(new Date(endDate).setHours(0, 0, 0, 0));

    return testMoment.isBetween(startMoment, endMoment,null,'[]');
  }
}
