<div class="add-edit-contact">

  <h2 class="add-edit-contact-title">{{this.getTitle()}}</h2>

  <div class="add-edit-contact-body">
    <div class="add-edit-contact-body-form" [formGroup]="maintainContactForm">

      <div class="add-edit-contact-body-form-row" mat-dialog-content [formGroup]="maintainContactForm">
        <div class="primarytogglediv">
          <mat-form-field class="mid">
            <input matInput placeholder="{{isCustomerForm ? 'Customer' : 'Supplier'}}" type="text" [formControlName]="supplierName" readonly />
            <mat-error *ngIf="hasError(maintainContactForm, supplierName)">{{
              getErrorMessage(maintainContactForm, supplierName) }}</mat-error>
          </mat-form-field>
          <div [ngClass]="isDisabledPrimaryContact ? 'disable-toggle' : ''">
            <mat-label>PRIMARY CONTACT </mat-label>
            <aar-toggle [group]="maintainContactForm" class="sm" [controlName]="isPrimaryContactControlName">
             
            </aar-toggle>
          </div>
        </div>

        <mat-form-field class="mid">
          <input matInput placeholder="First Name" type="text" [formControlName]="firstNameControlName" />
          <mat-error *ngIf="hasError(maintainContactForm, firstNameControlName)">{{
            getErrorMessage(maintainContactForm, firstNameControlName) }}</mat-error>
        </mat-form-field>
        <mat-form-field class="mid">
          <input matInput placeholder="Title" type="text" [formControlName]="titleControlName" />
        </mat-form-field>

        <mat-form-field class="mid">
          <input matInput placeholder="Last Name" type="text" [formControlName]="lastNameControlName" />
          <mat-error *ngIf="hasError(maintainContactForm, lastNameControlName)">
            {{getErrorMessage(maintainContactForm, lastNameControlName) }}</mat-error>
        </mat-form-field>


        <mat-form-field class="mid">
          <input matInput placeholder="Phone Number" type="text" [formControlName]="mainPhoneNumberControlName" />
        </mat-form-field>
      </div>

      <table style="width: 100%;">
        <tr>
          <td class="mid">
            <mat-form-field class="mid1">
              <input matInput placeholder="Email Address" type="text" [formControlName]="emailAddressControlName" />
              <mat-error *ngIf="hasError(maintainContactForm, emailAddressControlName)">{{
                getErrorMessage(maintainContactForm, emailAddressControlName) }}</mat-error>
            </mat-form-field>
          </td>
          <td class="mid" rowspan="2">
            <mat-form-field class="mid1"><textarea matInput placeholder="Comments" rows="6"
                [formControlName]="commentsControlName"></textarea></mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <div class="add-edit-contact-footer">
              <button mat-flat-button color="primary" mat-button color="primary"
                [disabled]="maintainContactForm.invalid || !maintainContactForm.dirty" (click)="onSave()" [ngClass]="isInfoPopUp === 'info' ? 'disable-Save' : ''" >Save</button>
              <button mat-flat-button class="spacer-left" mat-button color="primary" (click)="onClose()">Cancel</button>
            </div>
          </td>
        </tr>

      </table>

    </div>
  </div>
</div>