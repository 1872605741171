<div class="aar-side-panel-head">
  <div class="aar-side-panel-head__sub">
    <span class="title">Part Name</span>
    <span class="close"><span (click)="close()"><i class="material-icons">clear</i></span></span>
  </div>
</div>
<div class="aar-side-panel-detail">
  <h4>Details</h4>

  <div class="aar-side-panel-form-group">
    <div class="aar-side-panel-form-control">
      <label for="">Part Number</label>
      <span class="aar-side-panel-inputvalue">N123456</span>
    </div>

    <div class="aar-side-panel-form-control">
      <label for="">Aircraft</label>
      <span class="aar-side-panel-inputvalue">Boeing 777</span>
    </div>

    <div class="aar-side-panel-form-control">
      <label for="">Serial Number (s)</label>
      <span class="aar-side-panel-inputvalue">011-233-456</span>
      <span class="aar-side-panel-inputvalue">011-233-456</span>
      <span class="aar-side-panel-inputvalue">011-233-456</span>
    </div>
  </div>

  <div class="aar-side-panel-form-group">
    <div class="aar-side-panel-form-control aar-side-panel-form-control_2">
      <label for="">CURRENT DEMAND</label>
      <mat-form-field floatLabel="never" class="mat-form-field-select">
        <mat-select value="0">
          <mat-option [value]="">
            Hot
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="aar-side-panel-form-group">
      <div class="aar-side-panel-form-control aar-side-panel-form-control_3">
        <label for="">Description</label>
        <span class="aar-side-panel-inputvalue">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
             it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span>
      </div>
  </div>

  <div class="aar-side-panel-form-group">
    <div class="aar-side-panel-form-control aar-side-panel-form-control_3">
      <label for="">New Unit Cost</label>
      <span class="aar-side-panel-inputvalue">$203.00</span>
    </div>
  </div>

  <h4>Notes</h4>
  <div class="aar-side-panel-box">
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

      <span class="aar-side-panel-add">Added by<button mat-flat-button>Sarah Jones</button></span>
      <span class="aar-side-panel-add">2017-09-01 2:01AM</span>
      <button mat-flat-button>Add Note</button>
  </div>
</div>
