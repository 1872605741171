<div class="dialog">
  <div *ngIf="showTermsAndConditionsAcceptance">
    <h2 class="centre-text"><b>TERMS & CONDITIONS</b></h2>
    <div class="centre-text">
      <div>
        By selecting "Yes", you agree to abide by the Airvolution<sup>&reg;</sup>&nbsp;<a
          href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_tcs_06232023.pdf"
          target="_blank" (click)="onTermsAndConditionsLinkClick()">Terms & Conditions of Use</a>, which include key provisions around prohibited use and user
        content, among other items.
        <br><br>
        You can revisit these terms at any time by clicking the <b>Terms & Conditions</b> link on the left navigation bar.
        <br><br>
        Do you wish to proceed?
        <div *ngIf="hasSaveError">
          <br><br>
          <mat-error>Failed to save terms & conditions of use acceptance to the database. Please try again. If the problem persists, please contact <b><a class="errorTextColour" href = "mailto: airvolutionsupport@aarcorp.com">airvolutionsupport@aarcorp.com</a></b>.</mat-error>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showPrivacyPolicyAcceptance">
    <h2 class="centre-text"><b>PRIVACY NOTICE</b></h2>
    <div class="centre-text">
      <div>
        By selecting "Yes", you are confirming that you have read and understand the
        Airvolution<sup>&reg;</sup>&nbsp;<a
          href="https://www.aarcorp.com/globalassets/4.-digital-innovation/airvolution/aar_airvolution_privacynotice_09272023.pdf"
          target="_blank" (click)="onPrivacyPolicyLinkClick()">Privacy Notice</a>, which includes key provisions around data collection, use and security,
        among other items.
        <br><br>
        You can revisit this policy at any time by clicking the <b>{{privacyPolicyNotice}}</b> link on the left navigation bar.
        <br><br>
        Do you wish to proceed?
        <div *ngIf="hasSaveError">
          <br><br>
          <mat-error>Failed to save privacy notice acceptance to the database. Please try again. If the problem persists, please contact <b><a class="errorTextColour" href = "mailto: airvolutionsupport@aarcorp.com">airvolutionsupport@aarcorp.com</a></b>.</mat-error>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showConfirmation">
    <h2 class="centre-text"><b>CONFIRMATION</b></h2>
    <div class="centre-text">
      Thank you for agreeing to the Airvolution<sup>&reg;</sup>&nbsp; {{termsAndConditionsNotice}} and {{privacyPolicyNotice}}!
      <br><br>
      Click "OK" to start using the application.
    </div>
  </div>

  <div *ngIf="showNotice">
    <h2 class="centre-text"><b>NOTICE</b></h2>
    <div class="centre-text">
      Since you did not agree to the {{termsAndConditionsNotice}} and/or {{privacyPolicyNotice}}, you are not permitted to access Airvolution<sup>&reg;</sup>&nbsp; at this time.
      <br><br>
      Please contact <a href = "mailto: airvolutionsupport@aarcorp.com">airvolutionsupport@aarcorp.com</a> if you have any questions/require more information.
      <br><br>
      Click "OK" to logout of the application.
    </div>
  </div>

  <div class="dialog-buttons-list">
    <button *ngIf="showYesNoButtons" [disabled]="isSaving || (!isSaving && !hasLinkBeenClicked)" mat-flat-button color="primary" class="aar-button" (click)="onYes()" [title]="getHyperlinkTooltip()">Yes</button>
    <button *ngIf="showYesNoButtons" [disabled]="isSaving" mat-flat-button color="primary" class="aar-button" (click)="onNo()">No</button>
    <button *ngIf="showOkButton" mat-flat-button color="primary" class="aar-button" (click)="onOk()">OK</button>
  </div>
</div>

