import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aar-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  @Input() group;
  @Input() controlName: string;
  @Input() value;
  @Input() disabled: boolean;
  @Input() showToggleLabels = true;
  @Input() positiveLabel;
  @Input() negativeLabel;
  @Output() isToggleOn = new EventEmitter<boolean>();

  id: number;

  constructor() { }

  ngOnInit() {
    this.id = Math.round(Math.random() * 149999990);
  }

  isChecked(): boolean {
    if (this.group) {
      return this.group.get(this.controlName).value;
    }
    return this.value;
  }

  onChange(e: Event) {
    this.isToggleOn.emit((e.target as HTMLInputElement).checked);
  }
}
