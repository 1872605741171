import { Component, OnInit, NgModule } from '@angular/core';
import { AppMaterialModule } from '@app/app-material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'aar-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  // TODO: Setup @Input's for text, label and icon

  constructor() { }

  ngOnInit() {
  }

}

@NgModule({
  imports: [ CommonModule, AppMaterialModule ],
  exports: [ TimelineComponent ],
  declarations: [ TimelineComponent ],
})
export class TimelineModule {}
