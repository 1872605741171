import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingSecurityGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) { }

  checkLoggedIn(state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authService.isUserLoggedIn();

    if (state.url === '/login' && isLoggedIn) {
      this.router.navigate(['/rcm']);
      return false;
    }

    if (state.url !== '/login' && !isLoggedIn) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLoggedIn(state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot)  {
    return this.checkLoggedIn(state);
  }
}
