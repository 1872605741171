export const BREADCRUMBS_ACTIONS = {
  tatExportReport: 'TAT_EXPORT_REPORT'
};


export const ERROR_MESSAGES = {
  required: 'Required',
  email: 'Invalid email',
  invalidDate: 'Invalid date',
  pnMismatch: 'Part Number mismatch'
};

export const REPAIRORDERLINE_STATUS_DESCRIPTIONS = {
  orderCanceled: 'Order canceled',
  orderClosed: 'Order closed',
  directShipmentConfirmInboundShipDate: 'Direct shipment, confirm inbound ship date',
  confirmInboundShipDate: 'Confirm inbound ship date',
  outboundDirectShipmentDetailsOutstanding: 'Outbound direct shipment details outstanding',
  outboundShipmentDetailsOutstanding:'Outbound shipment details outstanding',
  inboundDirectShipmentInTransit: 'Inbound direct shipment in transit',
  inboundShipmentInTransit: 'Inbound shipment in transit'
}

export const DEFAULT_CURRENCIES = {
  USD:'USD'
}

export const URL_ARGUMENTS={
  NavigateToDocumentTab:'navigateToDocumentTab'
}

export const MOMENT_TIMEZONES = {
  US_CENTRAL : 'US/CENTRAL'
}

export const LOCALE_DATE_FORMAT = {
  'en-US' : 'MM/DD/YYYY'
}

export const LOCALE_TIME_FORMAT_24={
  'en-US' : 'HH:mm'
}

export const LOCALE_TIME_FORMAT_12={
  'en-US' : 'hh:mm A'
}
