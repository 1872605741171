<div class="aar-approval-dash-card-container">
  <div class="aar-approval-dash-card-header">

    <div class="aar-approval-dash-card-header-info">
      <a [routerLink]="['/rcm', ro.repairOrderNumber]">{{ro.repairOrderNumber}}</a>
      <div class="aar-approval-dash-card-part">{{ro.partNumber}}, {{ro.partDescription}}</div>
    </div>

    <div class="aar-approval-dash-card-status">{{convertStatus()}}</div>
  </div>

  <div class="aar-approval-dash-card-price-container">
    <span>{{ro.supplierName}}</span>
    <span>${{getCost() | number: '1.2-2'}}</span>
  </div>
</div>
