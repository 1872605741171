import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule, NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'aar-drag-and-drop-uploader',
  templateUrl: './drag-and-drop-uploader.component.html',
  styleUrls: ['./drag-and-drop-uploader.component.scss']
})
export class DragAndDropUploaderComponent implements OnInit {
  @Input('title') title: string;
  files: NgxFileDropEntry[] = [];

  constructor() { }

  ngOnInit() { }

  public dropped(event: NgxFileDropEntry[]) {
    this.files = event;
    //console.log(this.files);
    for (const droppedFile of event) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          //console.log(droppedFile.relativePath, file);

          /**
           // You could upload it like this:
           const formData = new FormData()
           formData.append('logo', file, relativePath)

           // Headers
           const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

           this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
           .subscribe(data => {
            // Sanitized logo returned from backend
          })
           **/

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }
}

@NgModule({
  imports: [
    CommonModule,
    NgxFileDropModule
  ],
  declarations: [
    DragAndDropUploaderComponent
  ],
  exports: [
    DragAndDropUploaderComponent
  ]
})

export class DragAndDropUploaderModule { }
