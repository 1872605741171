import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AppMaterialModule } from '@app/app-material.module';
import { DataTableModule, ChartCardModule } from '@app/shared/components';
import { CostControlComponent } from '@app/cost-control/cost-control.component';
import { CostControlMarketAverageComponent } from './cost-control-market-average/cost-control-market-average.component';
import { CostControlResultsComponent } from './cost-control-results/cost-control-results.component';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    DataTableModule,
    ChartCardModule
  ],
  providers: [],
  declarations: [
    CostControlComponent,
    CostControlMarketAverageComponent,
    CostControlResultsComponent
  ]
})
export class CostControlModule { }
