import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[aarInputWholeNumber]'
})
export class AarInputWholeNumberDirective {

  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^[0-9]*$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    console.log(this.el.nativeElement.value);

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (!String(event.key).match(this.regex)) {
      event.preventDefault();
    }
  }

}
