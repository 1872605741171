<div class="aar-drag-and-drop-uploader">
  <p class="header-6">
    {{title}}
  </p>

  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)"
  >
    <ng-template [ngIf]="files.length">
      <div *ngFor="let file of files">{{file.relativePath}}</div>
    </ng-template>
  </ngx-file-drop>
</div>
 