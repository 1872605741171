import { ICriticalStatsCard } from '@app/core/models/critical-stats-card';

export const CRITICAL_STATS_IDS = {
  ALL_RO: 'ALL_RO',
  AOG: 'AOG',
  IN_FINAL_REPAIR: 'IN_FINAL_REPAIR',
  NEW_RO: 'NEW_RO',
  PAST_DUE: 'PAST_DUE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_CUSTOMER_APPROVAL: 'PENDING_CUSTOMER_APPROVAL',
  PENDING_CUSTOMER_SHIPMENT: 'PENDING_CUSTOMER_SHIPMENT',
  PENDING_INTERNAL_AUTHORIZATION: 'PENDING_INTERNAL_AUTHORIZATION',
  QUOTE_APPROVED: 'QUOTE_APPROVED',
  QUOTE_PROCESSING: 'QUOTE_PROCESSING',
  QUOTE_REVIEW: 'QUOTE_REVIEW',
  RECEIPT_INSPECTION: 'RECEIPT_INSPECTION',
  REPAIR_IN_PROGRESS: 'REPAIR_IN_PROGRESS',
  APPROVAL_REQUIRED : 'APPROVAL_REQUIRED',
  REQUIRES_S0: 'REQUIRES_S0',
  RO_CLOSED: 'RO_CLOSED',
  SUPPLIER_EVALUATION: 'SUPPLIER_EVALUATION',
  SUPPLIER_RECEIPT: 'SUPPLIER_RECEIPT',
  UNIT_SHIPPED_INBOUND: 'UNIT_SHIPPED_INBOUND',
  UNIT_SHIPPED_OUTBOUND: 'UNIT_SHIPPED_OUTBOUND',
  RO_CANCELED: 'RO_CANCELED',
};

export const CRITICAL_STATS_CARD_LABELS = {
  ALL_OPEN_ROS: 'All Open ROs',
  ALL_SHIPPING: 'All Shipping',
  AOG: 'AOG',
  APPROVED_IN_WORK: 'Approved - In Work',
  IN_FINAL_REPAIR: 'In Final Repair',
  IN_SUPPLIER_EVALUATION: 'In Supplier Evaluation',
  NEW_ROS: 'New ROs',
  PAST_DUE: 'Past Due',
  PENDING_APPROVAL: 'Pending Approval',
  PENDING_CUSTOMER_APPROVAL: 'Pending Customer Approval',
  PENDING_CUSTOMER_SHIPMENT: 'Pending Customer Shipment',
  PENDING_INTERNAL_AUTHORIZATION: 'Pending Internal Authorization',
  QUOTE_PROCESSING: 'Quote Processing',
  QUOTE_REVIEW: 'Quote Review',
  RECEIPT_CLOSE: 'Receipt & Close',
  RECEIPT_INSPECTION: 'Receipt Inspection',
  REPAIR_IN_PROGRESS: 'Repair in Progress',
  APPROVAL_REQUIRED: 'Approval Required',
  REQUIRES_SO_DATA: 'Requires SO Data',
  SHIPPING_INBOUND: 'Shipping - Inbound',
  SHIPPING_INBOUND_TO_CUSTOMER: 'Shipping - Inbound to Customer',
  SHIPPING_OUTBOUND: 'Shipping - Outbound',
  SHIPPING_OUTBOUND_FROM_CUSTOMER: 'Shipping - Outbound from Customer',
  SUPPLIER_RECEIPT: 'Supplier Receipt',
  RO_CLOSED: 'All Closed ROs',
  RO_CANCELED: 'All Canceled ROs',
};



export const REPAIR_MANAGER_STATS: ICriticalStatsCard[] = [
  {
    id: CRITICAL_STATS_IDS.ALL_RO,
    label: CRITICAL_STATS_CARD_LABELS.ALL_OPEN_ROS,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.REQUIRES_S0,
    label: CRITICAL_STATS_CARD_LABELS.REQUIRES_SO_DATA,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_OUTBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_OUTBOUND_FROM_CUSTOMER,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.SUPPLIER_EVALUATION,
    label: CRITICAL_STATS_CARD_LABELS.IN_SUPPLIER_EVALUATION,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.APPROVAL_REQUIRED,
    label: CRITICAL_STATS_CARD_LABELS.APPROVAL_REQUIRED,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PENDING_APPROVAL,
    label: CRITICAL_STATS_CARD_LABELS.PENDING_APPROVAL,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PENDING_INTERNAL_AUTHORIZATION,
    label: CRITICAL_STATS_CARD_LABELS.PENDING_INTERNAL_AUTHORIZATION,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PAST_DUE,
    label: CRITICAL_STATS_CARD_LABELS.PAST_DUE,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_INBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_INBOUND_TO_CUSTOMER,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.AOG,
    label: CRITICAL_STATS_IDS.AOG,
    isEnabled: true,
    value: 0,
  },
];

export const CUSTOMER_STATS: ICriticalStatsCard[] = [
  {
    id: CRITICAL_STATS_IDS.ALL_RO,
    label: CRITICAL_STATS_CARD_LABELS.ALL_OPEN_ROS,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_OUTBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_OUTBOUND,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.SUPPLIER_EVALUATION,
    label: CRITICAL_STATS_CARD_LABELS.IN_SUPPLIER_EVALUATION,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PENDING_CUSTOMER_APPROVAL,
    label: CRITICAL_STATS_CARD_LABELS.PENDING_CUSTOMER_APPROVAL,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.REPAIR_IN_PROGRESS,
    label: CRITICAL_STATS_CARD_LABELS.REPAIR_IN_PROGRESS,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_INBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_INBOUND,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.AOG,
    label: CRITICAL_STATS_IDS.AOG,
    isEnabled: true,
    value: 0,
  },
];

export const SUPPLIER_STATS: ICriticalStatsCard[] = [
  {
    id: CRITICAL_STATS_IDS.ALL_RO,
    label: CRITICAL_STATS_CARD_LABELS.ALL_OPEN_ROS,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_OUTBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_OUTBOUND_FROM_CUSTOMER,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.SUPPLIER_EVALUATION,
    label: CRITICAL_STATS_CARD_LABELS.IN_SUPPLIER_EVALUATION,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PENDING_CUSTOMER_APPROVAL,
    label: CRITICAL_STATS_CARD_LABELS.PENDING_CUSTOMER_APPROVAL,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.QUOTE_APPROVED,
    label: CRITICAL_STATS_CARD_LABELS.APPROVED_IN_WORK,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.UNIT_SHIPPED_INBOUND,
    label: CRITICAL_STATS_CARD_LABELS.SHIPPING_INBOUND_TO_CUSTOMER,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.AOG,
    label: CRITICAL_STATS_IDS.AOG,
    isEnabled: true,
    value: 0,
  },
  {
    id: CRITICAL_STATS_IDS.PAST_DUE,
    label: CRITICAL_STATS_CARD_LABELS.PAST_DUE,
    isEnabled: true,
    value: 0,
  },
];
