import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rcmQuoteAllDocumentsFilterType'
})
export class RcmQuoteAllDocumentsFilterTypePipe implements PipeTransform {
  transform(value: any[], filterBy: string): any[] {
    return value.filter((x: any) => filterBy ? x.tag === filterBy : true);
  }
}
