import { NgModule } from '@angular/core';
import { AppMaterialModule } from '@app/app-material.module';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { TermsComponent } from './terms.component';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [],
  declarations: [
    TermsComponent
  ]
})
export class TermsModule { }
