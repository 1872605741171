<div class="block">
    <div class="block__content">
      <div class="block__name">
        <div *ngIf="false" class="block__tags">
          <button mat-flat-button class="right-elem" *ngFor="let tag of tags">{{tag}}</button>
        </div>
        <h5>
          {{addedBy}}
          <span class="block__date">{{addedOn | date:'fullDate'}}</span>
        </h5>
      </div>

      <div [class]="expanded?'block__expanded':'block__collapsed'">
          <h6 class="block__title">{{title}}</h6>
          <div class="block__paragraph" [innerHTML]="sanitizeHtml(description)"></div>
          <!-- <div *ngIf="description.length>=340" [class]="expanded?'collapse-section':'expand-section'">
            <span [class]="expanded?'collapse-section__icon':'expand-section__icon'" (click)="expanded=!expanded">
                <mat-icon class="read-more-icon" svgIcon="read-more"></mat-icon>
            </span>
          </div> -->
        </div>
    </div>
  </div>
